import { Dayjs } from 'dayjs'
import * as React from 'react'
import { DatePicker } from '@organice/atoms/datepicker'
import { PickerTimeProps } from 'antd/es/date-picker/generatePicker'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TimePickerProps
  extends Omit<PickerTimeProps<Dayjs>, 'picker'> {}

export const TimePicker = React.forwardRef<any, TimePickerProps>(
  (props, ref) => {
    return <DatePicker {...props} picker="time" mode={undefined} ref={ref} />
  }
)

TimePicker.displayName = 'TimePicker'
