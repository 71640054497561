import React from 'react'
import { CommentsContext, reducer, DEFAULT_VALUE } from './commentsContext'
import { Comments } from '@organice/organisms/comments'
import { Drawer } from 'antd'
import styled from '@emotion/styled'
import { useResponsive } from '@organice/utils/hooks/useResponsive'

export const CommentsContextProvider: React.FC<{
  children: React.ReactNode
}> = props => {
  const [state, dispatch] = React.useReducer(reducer, DEFAULT_VALUE)
  const { isMobile, isTablet } = useResponsive()

  return (
    <CommentsContext.Provider value={{ state, dispatch }}>
      <DrawerContainer
        width={isMobile ? '90vw' : isTablet ? '60vw' : '40vw'}
        closable={true}
        open={state.isOpen}
        onClose={() => {
          window.location.hash = ''
          dispatch({ type: 'CLOSE_COMMENTS' })
        }}
      >
        {state.isOpen && (
          /*state.id && */ <Comments
            id={state.id}
            type={state.type}
            title={state.title}
            description={state.description}
            additionalData={state?.additionalData}
            guestCanRead={state?.guestCanRead}
            onUpdateCount={state?.onUpdateCount}
          />
        )}
      </DrawerContainer>
      {props.children}
    </CommentsContext.Provider>
  )
}

const DrawerContainer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-header-close-only {
    height: 0;
    padding: 0;
    .ant-drawer-close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 20px;
    }
`
