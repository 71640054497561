import { AttributeTypes_Enum } from '@organice/graphql'
import { RoomConfigContextState } from '@organice/contexts/configRoom'
import {
  ConfigRoomAttribute,
  ConfigRoomService,
  ConfigRoomInfo,
  ConfigRoomAttributeGroup,
} from '@organice/contexts/configRoom/entityTypes'
import { UploadFile } from 'antd/lib/upload/interface'

import {
  prepeareNewRoomAttributeGroupsRelInsert,
  prepeareNewRoomAttributesRelInsert,
  prepeareNewRoomAttributeFilesRelInsert,
} from './prepareRelInserts'
import { getFileId } from '@organice/utils/general'

import { UpdatesSplitByMutation, AttributeFile, FileIdObject } from '../types'

export function prepareRoomInfoChanges(roomState: RoomConfigContextState) {
  const newRoomInfo: ConfigRoomInfo = roomState.roomInfo

  if (!roomState.initialState?.roomInfo) return newRoomInfo
  const prevRoomInfo: ConfigRoomInfo = roomState.initialState?.roomInfo

  const updates: Partial<ConfigRoomInfo> = {}

  Object.keys(newRoomInfo).forEach((roomInfoKey: string) => {
    if (
      newRoomInfo[roomInfoKey as keyof ConfigRoomInfo] !==
      prevRoomInfo[roomInfoKey as keyof ConfigRoomInfo]
    ) {
      updates[roomInfoKey as keyof ConfigRoomInfo] =
        newRoomInfo[roomInfoKey as keyof ConfigRoomInfo]
    }
  })

  return updates
}

type ValuePrepObject = Record<string, (val: any, prevVal: any) => any>

type ChangePrepSettings = {
  [key: string]: ValuePrepObject
}

export type ValueType =
  | ConfigRoomService
  | ConfigRoomAttributeGroup
  | ConfigRoomAttribute

export function prepareRoomServicesChanges(
  roomState: RoomConfigContextState
): UpdatesSplitByMutation<ConfigRoomService> {
  const newRoomServices: ConfigRoomService[] = roomState.roomServices
  const prevRoomServices: ConfigRoomService[] =
    (roomState.initialState?.roomServices as unknown as ConfigRoomService[]) ||
    []

  return prepareChanges(
    'roomServices',
    newRoomServices,
    prevRoomServices
  ) as UpdatesSplitByMutation<ConfigRoomService>
}

// Setting on how to prepare each value by model type (roomServices | roomAttributeGroups | roomAttributes | roomAttributeFiles)
const changePrepareSettings: ChangePrepSettings = {
  roomServices: {
    name: (val: string, prevVal: string | undefined) => val,
    description: (val: string, prevVal: string | undefined) => val,
    roomServiceCategoryId: (val: string, prevVal: string | undefined) => val,
    order: (val: number, prevVal: number | undefined) => val,
    roomAttributeGroups: (
      val: ConfigRoomAttributeGroup[],
      prevVal: ConfigRoomAttributeGroup[] | undefined
    ) => {
      if (!prevVal) {
        return { data: prepeareNewRoomAttributeGroupsRelInsert(val) }
      }
      const roomAttributeGroupChanges: UpdatesSplitByMutation<ValueType> =
        prepareChanges('roomAttributeGroups', val, prevVal)

      if (
        Object.keys(roomAttributeGroupChanges).every(key => {
          const keyTyped = key as keyof UpdatesSplitByMutation<ValueType>
          return roomAttributeGroupChanges[keyTyped].length === 0
        })
      ) {
        return val
      }
      return roomAttributeGroupChanges
    },
  },
  roomAttributeGroups: {
    name: (val: string, prevVal: string | undefined) => val,
    description: (val: string, prevVal: string | undefined) => val,
    valuesRepeatable: (val: boolean, prevVal: boolean | undefined) => val,
    order: (val: number, prevVal: number | undefined) => val,
    roomAttributes: (
      val: ConfigRoomAttribute[],
      prevVal: ConfigRoomAttribute[] | undefined
    ) => {
      if (!prevVal) {
        return { data: prepeareNewRoomAttributesRelInsert(val) }
      }
      return prepareChanges('roomAttributes', val, prevVal)
    },
  },
  roomAttributes: {
    name: (val: string, prevVal: string | undefined) => val,
    description: (val: string, prevVal: string | undefined) => val,
    attributeType: (
      val: AttributeTypes_Enum,
      prevVal: AttributeTypes_Enum | undefined
    ) => val,
    order: (val: number, prevVal: number | undefined) => val,
    config: (
      val: Record<string, any>,
      prevVal: Record<string, any> | undefined
    ) => val,
    roomAttributeFiles: (
      val: UploadFile[],
      prevVal: UploadFile[] | undefined
    ) => {
      if (!prevVal) {
        return { data: prepeareNewRoomAttributeFilesRelInsert(val) }
      }
      return prepareAttributeFileChanges('roomAttributeFiles', val, prevVal)
    },
  },
}

export function prepareChanges(
  type:
    | 'roomServices'
    | 'roomAttributeGroups'
    | 'roomAttributes'
    | 'roomAttributeFiles',
  newData: ValueType[],
  prevData: ValueType[] | undefined
): UpdatesSplitByMutation<ValueType> {
  const updates: Record<string, any>[] = []
  const keysToPrepare: ValuePrepObject = changePrepareSettings[type]

  newData.forEach(nd => {
    const changes: Record<string, any> = {}
    const pd = prevData?.find(pd => pd.id === nd.id)

    Object.keys(keysToPrepare).forEach((key: string) => {
      // new item
      if (!pd) {
        changes[key] = keysToPrepare[key](
          nd[key as keyof ValueType],
          undefined // no prevAttrGroup
        )
        return
      }

      // get updated properties
      if (nd[key as keyof ValueType] !== pd[key as keyof ValueType]) {
        changes[key] = keysToPrepare[key](
          nd[key as keyof ValueType],
          pd[key as keyof ValueType]
        )
      }
    })

    if (Object.keys(changes).length) {
      updates.push({ ...changes, id: pd?.id || null })
    }
  })

  // add to deleted if previsously existing items do not exist in new item anymore
  const deletedProps = prevData?.filter(pd => {
    const previousPropsInNewData = newData.find(nd => nd.id === pd.id)
    return previousPropsInNewData ? false : true
  })

  return {
    updates: updates.filter(d => !!d.id),
    inserts: updates
      .filter(d => d.id === null)
      .map(d => {
        // remove id for inserts (id is only temporary string id)
        const { id, ...data } = d
        return data
      }),
    deletes: deletedProps || [],
  }
}

// attributeFiles need special Treatment (no id, no updates)
function prepareAttributeFileChanges(
  type: 'roomAttributeFiles',
  newData: AttributeFile[],
  prevData: AttributeFile[] | undefined
): UpdatesSplitByMutation<FileIdObject> {
  const newFileIds = newData.map(f => getFileId(f)).filter(Boolean) as bigint[]

  const prevFileIds =
    prevData?.map(f => f.id || (Number(f.uid) as unknown as bigint)) || []

  return {
    updates: [], // there are no updates onroomAttributeFiles!!
    inserts: newFileIds
      .filter(nf => !prevFileIds.includes(nf))
      .map(nf => ({ fileId: nf })),
    deletes: prevFileIds
      .filter(pf => !newFileIds.includes(pf))
      .map(pf => ({ fileId: pf })),
  }
}
