import React from 'react'
import styled from '@emotion/styled'
import { Drawer } from 'antd'
import { Button } from '@organice/atoms'
import { useResponsive } from '@organice/utils/hooks/useResponsive'
import { DoubleRightOutlined } from '@ant-design/icons'
import { useSwipeable } from 'react-swipeable'
import { Global, css } from '@emotion/react'

export const MENU_WIDTH_PX = 300
export const HEADER_HEIGHT_PX = 80
export const BREADCRUMB_HEIGHT_PX = 52

interface SidebarProps {
  className?: string
  withButton?: boolean
  style?: React.CSSProperties
}

export const Sidebar: React.FC<SidebarProps> = props => {
  const [sidebarState, setSidebarState] = React.useState<SidebarContextState>(
    DEFAULT_SIDEBAR_CONTEXT_STATE
  )

  const swipeOpen = useSwipeable({
    onSwiped: eventData => {
      if (eventData.deltaX > 50) {
        setSidebarState({ ...sidebarState, isMobileSidebarOpen: true })
      }
    },
  })

  const swipeClose = useSwipeable({
    onSwiped: eventData => {
      if (eventData.deltaX < -50) {
        setSidebarState({ ...sidebarState, isMobileSidebarOpen: false })
      }
    },
  })

  const { isMobile } = useResponsive()

  if (isMobile) {
    return (
      <SidebarProvider
        sidebarState={sidebarState}
        setSidebarState={setSidebarState}
      >
        <Global
          styles={css`
            .sidebarDrawer {
              .ant-drawer-close {
                display: none;
              }
            }
          `}
        />
        <SwipeTriggerWrapper {...swipeOpen}>
          <SwipeTrigger
            type="default"
            className="ant-drawer-open"
            onClick={() => setSidebarState({ isMobileSidebarOpen: true })}
            icon={<DoubleRightOutlined />}
          />
        </SwipeTriggerWrapper>
        <Drawer
          className="sidebarDrawer"
          open={sidebarState.isMobileSidebarOpen}
          width={'90vw'}
          placement={'left'}
          contentWrapperStyle={{ maxWidth: 550 }}
          bodyStyle={{ padding: 0, overflow: 'visible' }}
          onClose={() => setSidebarState({ isMobileSidebarOpen: false })}
        >
          <MobileSidebar {...props} {...swipeClose} />
        </Drawer>
      </SidebarProvider>
    )
  }
  return <StyledSidebar {...props} />
}

const SwipeTriggerWrapper = styled.div(() => ({
  position: 'fixed',
  left: 0,
  top: 0,
  height: '100vh',
  width: 30,
  background: 'transparent',
  zIndex: 99,
}))

const SwipeTrigger = styled(Button)(({ theme }) => ({
  width: 25,
  height: 60,
  position: 'fixed',
  top: '50%',
  left: -5,
  zIndex: 99,
  transform: `translateY(-50%)`,
  boxShadow: theme.boxShadowBase,
  backgroundColor: theme.grey6,
}))

const MobileSidebar = styled.div<{ withButton?: boolean }>(
  ({ theme, withButton }) => ({
    position: 'sticky',
    left: 0,
    bottom: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: `${withButton ? 'space-between' : 'start'}`,

    '&.unblock': {
      height: `calc(100vh - ${BREADCRUMB_HEIGHT_PX}px)`,
    },
  })
)

const StyledSidebar = styled.div<{ withButton?: boolean }>(
  ({ theme, withButton }) => ({
    width: `${MENU_WIDTH_PX}px`,
    // minWidth: `${MENU_WIDTH_PX}px`,
    borderRight: `1px solid ${theme.borderColorBase}`,
    //paddingTop: '1.25rem',
    position: 'sticky',
    top: `${BREADCRUMB_HEIGHT_PX}px`,
    left: 0,
    bottom: 0,
    height: `calc(100vh - ${HEADER_HEIGHT_PX + BREADCRUMB_HEIGHT_PX}px)`,
    overflowX: 'hidden',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: `${withButton ? 'space-between' : 'start'}`,

    '&.unblock': {
      height: `calc(100vh - ${BREADCRUMB_HEIGHT_PX}px)`,
    },
  })
)

type SidebarContextState = {
  isMobileSidebarOpen: boolean
}

type SidebarContextType = {
  sidebarState: SidebarContextState
  setSidebarState: React.Dispatch<React.SetStateAction<SidebarContextState>>
}

const DEFAULT_SIDEBAR_CONTEXT_STATE = {
  isMobileSidebarOpen: false,
}

const SidebarContext = React.createContext<SidebarContextType>({
  sidebarState: DEFAULT_SIDEBAR_CONTEXT_STATE,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSidebarState: () => {},
})

function SidebarProvider(props: React.PropsWithChildren<SidebarContextType>) {
  const { children, sidebarState, setSidebarState } = props

  const value = { sidebarState, setSidebarState }
  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  )
}

export const useSidebarContext = () => React.useContext(SidebarContext)
