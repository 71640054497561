import React from 'react'
import styled from '@emotion/styled'
import {
  FileOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileWordOutlined,
  FileImageOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { UploadVersions } from '@organice/graphql'
import { Progress } from '@organice/atoms/progress'
import { Text } from '@organice/atoms/text'
import { GeneralDict } from '@organice/utils/form'
import {
  ServiceDisplayMode,
  useServiceDisplayMode,
} from '@organice/contexts/serviceDisplay'

const UPLOAD_URI = `https://${
  import.meta.env.VITE_FRONTEND_API_HOST || document.location.host
}`

interface UploadProps {
  current?: UploadVersions[]
  currentFile?: UploadVersions
  loading?: boolean
  percent?: number
}
const MIME_TYPES: Record<string, React.ReactNode> = {
  pdf: <FilePdfOutlined style={{ color: '#F40F02' }} />,
  xls: <FileExcelOutlined style={{ color: '#217346' }} />,
  doc: <FileWordOutlined style={{ color: '#2b579a' }} />,
  png: <FileImageOutlined />,
  jpg: <FileImageOutlined />,
}

export const UploadEntry: React.FC<UploadProps> = props => {
  const { current, currentFile, loading, percent } = props
  const { file, downloadUrl } = current?.[0] || currentFile || {}
  const { name, extension } = file || {}
  const [state] = useServiceDisplayMode()

  const isExport = state.serviceDisplayMode === ServiceDisplayMode.EXPORT

  const getMimeIcon = (mimeType: string | undefined): React.ReactNode => {
    return MIME_TYPES[String(mimeType)] || <FileOutlined />
  }

  return (
    <UploadContainer>
      <FileLabel
        disabled={loading}
        onClick={() => window.open(`${UPLOAD_URI}${downloadUrl}`)}
      >
        {loading ? <LoadingOutlined /> : getMimeIcon(extension)}
        {loading ? (
          <span>...</span>
        ) : downloadUrl ? (
          <a className="url" href={downloadUrl} target="_blank" rel="noopener">
            {name}.{extension}
          </a>
        ) : (
          <span>
            {name}.{extension}
          </span>
        )}
        {isExport && (
          <DownloadUrl>{`${document.location.origin}${downloadUrl}`}</DownloadUrl>
        )}
      </FileLabel>
      {loading && <Progress size="small" percent={percent} showInfo={false} />}
    </UploadContainer>
  )
}

const UploadContainer = styled.div(({ theme }) => ({
  svg: { fontSize: '20px', marginRight: '5px' },
  h5: { marginBottom: 0 },
}))
const FileLabel = styled(Text)`
  cursor: pointer;
  .anticon {
    margin-right: 10px;
  }
`
const DownloadUrl = styled.div`
  font-style: italic;
`
