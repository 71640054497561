import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Icon, { QuestionCircleOutlined } from '@ant-design/icons'
import EyeIcon from '../../../assets/icons/eye.svg?react'
import EyeInvisibleIcon from '../../../assets/icons/eye-invisible.svg?react'
import { Text, Button } from '@organice/atoms'
import { useMeContext } from '@organice/contexts'
import { useServiceModule } from '@organice/utils/hooks/useServiceModule'
import { ServiceModules } from '@organice/graphql'
import { useServiceDisplayMode, ServiceDisplayMode } from '@organice/contexts'
import { Tooltip } from 'antd'
import { useRegisterAccess } from '@organice/utils/hooks/accesses'

interface ServiceModulePublishStateProps {
  module: Pick<ServiceModules, 'id' | 'published' | 'config' | 'writable'>
}

export const ServiceModulePublishState: React.FC<
  React.PropsWithChildren<ServiceModulePublishStateProps>
> = ({ module, children }) => {
  const { t } = useTranslation()
  const {
    state: { me, isAdmin, isNM, isGuest },
  } = useMeContext()

  const [state] = useServiceDisplayMode()
  const { trackUserServiceModuleAccess } = useRegisterAccess()

  const { togglePublished } = useServiceModule({
    module,
    // Track another user access after publish status has changed to prevent hasChanges (form users own change)
    onPublishChange: isPublished => trackUserServiceModuleAccess(module.id),
  })

  const canPublish = isAdmin || isNM
  const writable = module.writable
  const canSeePublishSettings = canPublish && (isAdmin || isNM || writable)
  const isExport = state.serviceDisplayMode === ServiceDisplayMode.EXPORT

  // Bypass publish config
  if (module.config?.canPublish === false) {
    if (module.published) {
      return <>{children}</>
    }
    return (
      <RegularUserShareContainer>
        <ShareLeft>
          <Icon
            component={EyeInvisibleIcon}
            style={{ fontSize: '2rem', marginRight: '1.25rem' }}
          ></Icon>
          <Text strong>
            {/* Lost Cause Message only or NM & Admins > everyone else sees unpublished */}
            {isAdmin || isNM
              ? t('messages.serviceModules.isUnpublishedLostCauseMessage')
              : t('messages.serviceModules.isUnpublishedRegularUserMessage')}
          </Text>
        </ShareLeft>

        <ShareRight></ShareRight>
      </RegularUserShareContainer>
    )
  }

  if (!module.published) {
    return (
      <>
        {canPublish ? (
          <>
            {!isExport ? (
              <>
                {canSeePublishSettings && (
                  <AdminShareContainer>
                    <ShareLeft>
                      <Icon
                        component={EyeInvisibleIcon}
                        style={{ fontSize: '2rem', marginRight: '1.25rem' }}
                      ></Icon>
                      <Text strong>
                        {t('messages.serviceModules.isUnpublishedAdminMessage')}
                      </Text>
                    </ShareLeft>

                    <ShareRight>
                      {!writable && (
                        <Tooltip
                          title={
                            isAdmin
                              ? t(
                                  'messages.serviceModules.isAdminButNoPublishPermissions'
                                )
                              : t(
                                  'messages.serviceModules.isNMUserButNoPublishPermissions'
                                )
                          }
                          placement="top"
                        >
                          <QuestionCircleOutlined
                            style={{
                              fontSize: '1.2rem',
                              marginRight: '.5rem',
                            }}
                          />
                        </Tooltip>
                      )}
                      <Button
                        onClick={() => togglePublished()}
                        disabled={!writable}
                      >
                        {t('messages.serviceModules.publish')}
                      </Button>
                    </ShareRight>
                  </AdminShareContainer>
                )}
              </>
            ) : (
              <RegularUserShareContainer>
                <ShareLeft>
                  <Icon
                    component={EyeInvisibleIcon}
                    style={{ fontSize: '2rem', marginRight: '1.25rem' }}
                  ></Icon>
                  <Text strong>
                    {t(
                      'messages.serviceModules.isUnpublishedRegularUserMessage'
                    )}
                  </Text>
                </ShareLeft>

                <ShareRight></ShareRight>
              </RegularUserShareContainer>
            )}
            {children}
          </>
        ) : (
          <>
            <RegularUserShareContainer>
              <ShareLeft>
                <Icon
                  component={EyeInvisibleIcon}
                  style={{ fontSize: '2rem', marginRight: '1.25rem' }}
                ></Icon>
                <Text strong>
                  {t('messages.serviceModules.isUnpublishedRegularUserMessage')}
                </Text>
              </ShareLeft>

              <ShareRight></ShareRight>
            </RegularUserShareContainer>
            {children}
          </>
        )}
      </>
    )
  }

  return (
    <>
      {/* adding a way to revoke the published status */}
      {canPublish && !isExport ? (
        <>
          {canSeePublishSettings && (
            <AdminShareContainerPublished>
              <ShareLeft>
                <Icon
                  component={EyeIcon}
                  style={{ fontSize: '2rem', marginRight: '1.25rem' }}
                ></Icon>
                <Text>
                  {t('messages.serviceModules.isPublishedAdminMessage')}
                </Text>
              </ShareLeft>

              <ShareRight>
                {!writable && (
                  <Tooltip
                    title={
                      isAdmin
                        ? t(
                            'messages.serviceModules.isAdminButNoPublishPermissions'
                          )
                        : t(
                            'messages.serviceModules.isNMUserButNoPublishPermissions'
                          )
                    }
                    placement="top"
                  >
                    <QuestionCircleOutlined
                      style={{ fontSize: '1.2rem', marginRight: '.5rem' }}
                    />
                  </Tooltip>
                )}
                <Button onClick={() => togglePublished()} disabled={!writable}>
                  {t('messages.serviceModules.unpublish')}
                </Button>
              </ShareRight>
            </AdminShareContainerPublished>
          )}
          {children}
        </>
      ) : (
        <>
          {isGuest && !isExport && (
            <GuestShareContainerPublished>
              <ShareLeft>
                <Icon
                  component={EyeIcon}
                  style={{ fontSize: '2rem', marginRight: '1.25rem' }}
                ></Icon>
                <Text>
                  {t('messages.serviceModules.isPublishedGuestMessage')}
                </Text>
              </ShareLeft>
            </GuestShareContainerPublished>
          )}
          {children}
        </>
      )}
    </>
  )
}

const AdminShareContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.lightBackground,
  display: 'flex',
  flexWrap: 'wrap',
  padding: '0.625rem 1.25rem',
  marginBottom: '1.25rem',
  marginTop: '-1rem',
  alignItems: 'center',
}))

const AdminShareContainerPublished = styled.div(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '0.625rem 1.25rem',
  marginBottom: '1.25rem',
  marginTop: '-1rem',
  alignItems: 'center',
  border: `1px solid ${theme.borderColorBase}`,
  borderTop: 0,
}))

const GuestShareContainerPublished = styled.div(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '0.625rem 1.25rem',
  marginBottom: '1.25rem',
  marginTop: '-1rem',
  alignItems: 'center',
  border: `1px solid ${theme.borderColorBase}`,
  borderTop: 0,
}))

const RegularUserShareContainer = styled.div(({ theme }) => ({
  backgroundColor: 'rgba(255, 252, 250, 1)',
  border: '1px solid rgb(255, 165, 134)',
  display: 'flex',
  flexWrap: 'wrap',
  padding: '0.625rem 1.25rem',
  marginBottom: '1.25rem',
  marginTop: '-1rem',
  alignItems: 'center',
}))

const ShareLeft = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 10,
  [theme.mq.screenLgMin]: {
    marginBottom: 0,
  },
}))

const ShareRight = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  marginLeft: 32 + 20,
  [theme.mq.screenLgMin]: {
    justifyContent: 'flex-end',
  },
}))
