import React from 'react'
import {
  EditOutlined,
  ShoppingCartOutlined,
  ToolOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'

export enum TagCategories {
  Edit = 'edit',
  Order = 'order',
  Service = 'service',
  Information = 'information',
  Uncategorized = 'uncategorized',
}

export const tagCategories = [
  {
    category: TagCategories.Edit,
    icon: <EditOutlined />,
    name: 'tags.category.edit',
    color: 'cyan',
    selectable: true,
  },
  {
    category: TagCategories.Information,
    icon: <InfoCircleOutlined />, // <RocketOutlined />
    name: 'tags.category.information',
    color: 'blue',
    selectable: true,
  },
  {
    category: TagCategories.Order,
    icon: <ShoppingCartOutlined />,
    name: 'tags.category.order',
    color: 'orange',
    selectable: true,
  },
  {
    category: TagCategories.Service,
    icon: <ToolOutlined />, // <RocketOutlined />
    name: 'tags.category.service',
    color: 'green',
    selectable: true,
  },
  {
    category: TagCategories.Uncategorized,
    icon: <QuestionCircleOutlined />,
    name: 'tags.category.uncategorized',
    // color: 'green',
    selectable: false,
  },
]
