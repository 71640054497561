import React from 'react'
import { AllUsers } from '@organice/graphql'

export type AttributeValueMetaDataType = {
  createdAt: string
  updatedAt: string
  user?: Pick<AllUsers, 'id' | 'firstName' | 'lastName'> | null
  commentsCount?: number
  initialValue?: any
  inititalRoomAttributeValueFiles?: any[]
}

export type AttributeValueMetaDataAttrValueKeyMap = {
  [avKey: string]: AttributeValueMetaDataType
}

export const AttributeValueMetaDataContext = React.createContext<any>({})

interface AttributeValueMetaDataProviderProps {
  attributeValueMetaData: AttributeValueMetaDataAttrValueKeyMap
}

export const AttributeValueMetaDataProvider: React.FC<
  AttributeValueMetaDataProviderProps
> = ({ attributeValueMetaData, children }) => (
  <AttributeValueMetaDataContext.Provider value={attributeValueMetaData}>
    {children}
  </AttributeValueMetaDataContext.Provider>
)

export const useAttributeValueMetaData = (attrValueKey?: string) => {
  const context = React.useContext(AttributeValueMetaDataContext)
  if (context === undefined) {
    console.warn(
      'useAttributeValueMetaData should be used within a AttributeValueMetaContext'
    )
  }

  return {
    allAttributeValueMetaData: context,
    attributeValueMetaData:
      attrValueKey && context?.[attrValueKey] ? context?.[attrValueKey] : {},
  }
}
