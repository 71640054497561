import {
  ConfigAttributeGroup,
  ConfigServiceModule,
  ConfigAttribute,
} from './entityTypes'
import { ServiceContextState } from './contextTypes'

export const getCurrentModuleIndex = (state: ServiceContextState): number => {
  const {
    currentService: { serviceModules },
    activeModule,
  } = state

  if (activeModule === undefined || !serviceModules) {
    return -1
  }

  return serviceModules.findIndex(m => m.tempId === state.activeModule)
}

export const getGroupIndex = (
  module: ConfigServiceModule,
  groupTempId: ConfigAttributeGroup['tempId']
): number => module.attributeGroups.findIndex(g => g.tempId === groupTempId)

export const changeGroup = (
  state: ServiceContextState,
  groupTempId: ConfigAttributeGroup['tempId'],
  newGroup: (group: ConfigAttributeGroup) => Partial<ConfigAttributeGroup>
): ServiceContextState => {
  const {
    currentService: { serviceModules },
  } = state

  const moduleIndex = getCurrentModuleIndex(state)

  if (moduleIndex === -1) {
    return state
  }

  const groupIndex = getGroupIndex(serviceModules[moduleIndex], groupTempId)

  if (groupIndex === -1) {
    return state
  }

  const newGroups = serviceModules[moduleIndex].attributeGroups.map((g, i) => {
    if (i === groupIndex) {
      return { ...g, ...newGroup(g) }
    }

    return g
  })

  const newModules = serviceModules.map((m, i) => {
    if (i === moduleIndex) {
      return { ...m, attributeGroups: newGroups }
    }

    return m
  })

  return {
    ...state,
    currentService: {
      ...state.currentService,
      serviceModules: newModules,
    },
  }
}

export const changeModule = (
  state: ServiceContextState,
  moduleTempId: ConfigServiceModule['tempId'],
  newModule: (module: ConfigServiceModule) => Partial<ConfigServiceModule>
): ServiceContextState => {
  const moduleIndex = state.currentService.serviceModules.findIndex(
    m => m.tempId === moduleTempId
  )

  if (moduleIndex === -1) {
    return state
  }

  const newModules = state.currentService.serviceModules.map((m, i) => {
    if (i === moduleIndex) {
      return { ...m, ...newModule(m) }
    }

    return m
  })

  return {
    ...state,
    currentService: {
      ...state.currentService,
      serviceModules: newModules,
    },
  }
}

export const changeAttribute = (
  state: ServiceContextState,
  attributeId: ConfigAttribute['tempId'],
  newAttribute: (a: ConfigAttribute) => Partial<ConfigAttribute>
): ServiceContextState => {
  const {
    currentService: { serviceModules },
  } = state

  const moduleIndex = getCurrentModuleIndex(state)

  if (moduleIndex === -1) {
    return state
  }

  const [groupIndex, attributeIndex] = serviceModules[
    moduleIndex
  ].attributeGroups.reduce<[number, number]>(
    (acc, curr, i) => {
      if (acc.some(n => n !== -1)) {
        return acc
      }

      const aIndex = curr.attributes.findIndex(a => a.tempId === attributeId)

      if (aIndex === -1) {
        return acc
      }

      return [i, aIndex]
    },
    [-1, -1]
  )

  if ([groupIndex, attributeIndex].some(n => n === -1)) {
    return state
  }

  const newAttributes = serviceModules[moduleIndex].attributeGroups[
    groupIndex
  ].attributes.map((a, i) => {
    if (i === attributeIndex) {
      return { ...a, ...newAttribute(a) }
    }

    return a
  })

  const newGroups = serviceModules[moduleIndex].attributeGroups.map((g, i) => {
    if (i === groupIndex) {
      return { ...g, attributes: newAttributes }
    }

    return g
  })

  const newModules = serviceModules.map<ConfigServiceModule>((m, i) => {
    if (i === moduleIndex) {
      return { ...m, attributeGroups: newGroups }
    }

    return m
  })

  return {
    ...state,
    currentService: {
      ...state.currentService,
      serviceModules: newModules,
    },
  }
}

export const getActiveModuleAfterDelete = (
  state: ServiceContextState,
  moduleToDeleteTempId: ConfigServiceModule['tempId']
): ConfigServiceModule['tempId'] | undefined => {
  const {
    currentService: { serviceModules },
  } = state

  if (serviceModules.length === 1) {
    return undefined
  }

  const moduleToDeleteIndex = serviceModules.findIndex(
    m => m.tempId === moduleToDeleteTempId
  )

  // this shouldn't happen, but just to be safe
  if (moduleToDeleteIndex === -1) {
    return undefined
  }

  if (moduleToDeleteIndex === 0) {
    return serviceModules[1].tempId
  }

  return serviceModules[moduleToDeleteIndex - 1].tempId
}
