import { DateType } from '../../types/service'
import { TimeUtil } from '@organice/utils/date/time'
import { dateConfig } from '@organice/config'
import { DateConfig } from '@organice/molecules'
import { Dayjs } from 'dayjs'
const dayjs = TimeUtil.getDayjs()

type DateDBValue = string | (string | undefined)[] | (string | undefined)[][]

export const formatDateByTypeForDisplay = ({
  value,
  type,
  config,
  isExport,
}: {
  value: DateDBValue
  type: DateType
  config?: DateConfig
  isExport?: boolean
}): string => {
  // const [state] = useServiceDisplayMode()
  // const isExport = state.serviceDisplayMode === ServiceDisplayMode.EXPORT
  if (config?.repeatable) {
    return Array.isArray(value)
      ? value
          .map(d =>
            formatDateByTypeForDisplay({
              value: d as DateDBValue,
              type,
              isExport,
            })
          )
          .join(', ')
      : '-'
  }

  if (!value || (Array.isArray(value) && !value[0])) return !isExport ? '-' : ''

  switch (type) {
    case DateType.DATE:
      if (!String(value)) return '-'
      return TimeUtil.get(String(value)).format(dateConfig.DATE_FORMAT)

    case DateType.DATE_TIME:
      if (!String(value)) return '-'
      return TimeUtil.get(String(value)).format(
        `${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT} [Uhr]`
      )

    case DateType.DATE_RANGE:
      if (!value[0] || !value[1]) return '-'
      return `${TimeUtil.get(value[0] as string).format(
        dateConfig.DATE_FORMAT
      )} - ${TimeUtil.get(value[1] as string).format(dateConfig.DATE_FORMAT)}`

    case DateType.DATE_RANGE_TIME:
      if (!value[0] || !value[1]) return '-'

      return `${TimeUtil.get(value[0] as string).format(
        `${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT} [Uhr]`
      )} - ${TimeUtil.get(value[1] as string).format(
        `${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT} [Uhr]`
      )}`

    case DateType.DATE_TIME_RANGE:
      if (!value[0] || !value[1]) return '-'

      return `${TimeUtil.get(value[0] as string).format(
        `${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT}`
      )} - ${TimeUtil.get(value[1] as string).format(
        `${dateConfig.TIME_FORMAT} [Uhr]`
      )}`

    default:
      return `DATETYPE NOT DEFINED: ${type}`
  }
}

export const convertDBDatesToDayjs = ({
  value,
  type,
}: {
  value: string | (string | undefined)[]
  type: DateType
}) => {
  if (!value || (Array.isArray(value) && !value[0])) return undefined

  switch (type) {
    case DateType.DATE:
    case DateType.DATE_TIME:
      if (!String(value)) return undefined
      return [dayjs(String(value))]

    case DateType.DATE_RANGE:
    case DateType.DATE_RANGE_TIME:
    case DateType.DATE_TIME_RANGE:
      if (!value[0] || !value[1]) return undefined
      return (
        Array.isArray(value) &&
        value.map(dateString =>
          typeof dateString === 'string' ? dayjs(dateString) : undefined
        )
      )

    default:
      return `DATETYPE NOT DEFINED: ${type}`
  }
}

export const getRangeString = (
  start: Dayjs | string,
  end: Dayjs | string
): string => {
  if (!dayjs(start).isValid() || !dayjs(end).isValid())
    return 'Invalid Date Range'

  if (dayjs(start).isSame(dayjs(end), 'day')) {
    const formattedStartDate = dayjs(start).format(`${dateConfig.DATE_FORMAT}`)
    const formattedStartTime = dayjs(start).format('HH:mm [Uhr]')
    const formattedEndTime = dayjs(end).format('HH:mm [Uhr]')
    return `${formattedStartDate} ${formattedStartTime} - ${formattedEndTime}`
  }

  const formattedStart = dayjs(start).format(
    `${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT} [Uhr]`
  )
  const formattedEnd = dayjs(end).format(
    `${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT} [Uhr]`
  )

  return `${formattedStart} - ${formattedEnd}`
}
