import React from 'react'
import { Typography } from 'antd'
const { Link } = Typography

const urlRegEx =
  /(https?:\/\/[a-zA-Z0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF.,@?^=%&:/~+#-]+[^, ; )])|(http?:\/\/[a-zA-Z0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF.,@?^=%&:/~+#-]+[^, ; )])/
const mailRegEx = /([\w.\-_]+?\w+@[\w-_]+[.\w+]{1,})/
const newLineRegEx = /(\\n)/
const quotationMarkRegEx = /(\\")/

export const regexStringsDecoratorRenderer = [
  {
    pattern: urlRegEx,
    renderer: (splitItem: string, index: number) => (
      <Link
        href={splitItem}
        underline
        key={`url-${index}`}
        style={{ padding: 0 }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {splitItem}
      </Link>
    ),
  },
  {
    pattern: mailRegEx,
    renderer: (splitItem: string, index: number) => (
      <Link
        href={`mailto:${splitItem}`}
        underline
        key={`mailto-${index}`}
        style={{ padding: 0 }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {splitItem}
      </Link>
    ),
  },
  {
    pattern: newLineRegEx,
    renderer: (splitItem: string, index: number) => (
      <br key={`rgx-br-${index}`} />
    ),
  },
  {
    pattern: quotationMarkRegEx,
    renderer: (splitItem: string, index: number) => '"',
  },
]

export const regexStringsDecorator = (
  text: string,
  patternAndRendererPairs: any
): any[] => {
  const regex = new RegExp(
    patternAndRendererPairs.map((item: any) => item.pattern.source).join('|'),
    'gim'
  )

  const safeText = text ? String(text) : ''

  const splitText = safeText.split(regex).filter(item => item !== undefined)
  const newText = splitText.map((splitItem, index) => {
    const matchItem = patternAndRendererPairs.find(
      (patternAndRendererPairsItem: any) =>
        patternAndRendererPairsItem.pattern.test(splitItem)
    )
    return matchItem ? matchItem.renderer(splitItem, index) : splitItem
  })
  return newText
}
