import React from 'react'
import { notification, Checkbox, Button } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { Text } from '@organice/atoms/text'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n/i18n'
import { Global } from '@emotion/react'
import { TimeUtil } from '@organice/utils/date/time'
import {
  AttributeTypes_Enum,
  EventAttributes,
  EventAttributeValues,
  Events,
} from '@organice/graphql'
import {
  findEarliestDate,
  eventIdIsOnIgnoreList,
  toggleEventIdToIngoreList,
} from './utils'

import { DateType } from '../../../types/service'
const dayjs = TimeUtil.getDayjs()

type EventData = {
  id: number
  startDate?: Pick<Events, 'startDate'>
  endDate?: Pick<Events, 'endDate'>
  eventAttributeValues?: DeepPartial<EventAttributeValues>[]
}

interface ShortTermWarningProps {
  event: EventData | null | undefined
  onToggleIgnore: (eventId: number) => void
}

export const ShortTermWarning: React.FC<ShortTermWarningProps> = ({
  event,
  onToggleIgnore,
}) => {
  if (!event) {
    console.warn(`ShortTermWarning(): ShortTermWarning needs event prop!`)
    return null
  }

  const { t } = useTranslation()
  const onChangeHandler = (e: CheckboxChangeEvent) => {
    onToggleIgnore && onToggleIgnore(event.id)
  }

  return (
    <div>
      <Global
        styles={{
          '.shortTermWarning .ant-notification-notice-btn': {
            float: 'none',
            marginLeft: 48,
          },
        }}
      />
      <Text>{t('warnings.shortTermWarning.message')}</Text>
      <div style={{ margin: '1rem 0 1.5rem 0' }}>
        <Checkbox onChange={onChangeHandler}>
          {t('warnings.shortTermWarning.suppressNotificationForEvent')}
        </Checkbox>
      </div>
    </div>
  )
}

function fixDateTimeRangeEventAttribute(dateArray: string[]) {
  const basicDate = dayjs(dateArray?.[0])
  if (!basicDate.isValid()) return false

  return dateArray.map((d: string) => {
    const singleDate = dayjs(String(d))

    return dayjs(basicDate).hour(singleDate.hour()).minute(singleDate.minute())
  })
}

export const handleShortTermWarning = (
  event: EventData | null | undefined,
  delay = 0
) => {
  if (!event || !event.startDate || !event.endDate) {
    console.warn(
      `handleShortTermWarning(): shortTermWarning needs event information (id, startDate, endDate)!`
    )
    return
  }
  if (eventIdIsOnIgnoreList(event.id)) return

  const now = dayjs()
  const eventStart = dayjs(String(event.startDate))
  const eventEnd = dayjs(String(event.endDate)).hour(0).minute(0).add(1, 'day')
  const dateAttributeValues = event.eventAttributeValues
    ?.map(attrVal => {
      if (attrVal.eventAttribute?.attributeType === AttributeTypes_Enum.Date) {
        const config = attrVal.eventAttribute.config

        // Fix for evenbtAttributes of type DATE_TIME_RANGE saved with second date from day of editing (should not happen with new dates)
        if (config.dateType === DateType.DATE_TIME_RANGE) {
          if (config.repeatable) {
            return attrVal.value.value.map((dateArray: string[]) =>
              fixDateTimeRangeEventAttribute(dateArray)
            )
          }
          return fixDateTimeRangeEventAttribute(attrVal.value.value)
        }
        return attrVal.value.value
      }

      return false
    })
    .filter(Boolean)

  const earliestDateAttributeValue =
    dateAttributeValues && findEarliestDate(dateAttributeValues)

  const eventIsRunning =
    eventStart.isSameOrBefore(now) && eventEnd.isSameOrAfter(now)
  const buildUpStarted =
    earliestDateAttributeValue &&
    earliestDateAttributeValue.isSameOrBefore(now) &&
    earliestDateAttributeValue.isSameOrBefore(eventStart) &&
    eventEnd.isSameOrAfter(now)

  if (!eventIsRunning && !buildUpStarted) return

  const warningTitle = eventIsRunning
    ? i18n.t('warnings.shortTermWarning.titleEventHasStarted')
    : buildUpStarted
      ? i18n.t('warnings.shortTermWarning.titleBuildUpHasStarted')
      : ''

  const key = `open${Date.now()}`
  const btn = (
    <Button type="primary" onClick={() => notification.close(key)}>
      {i18n.t('warnings.shortTermWarning.messageUnderstood')}
    </Button>
  )
  setTimeout(() => {
    notification.warning({
      className: 'shortTermWarning',
      closeIcon: null,
      duration: null,
      style: { width: '100%', maxWidth: 600 },
      message: warningTitle,
      description: (
        <ShortTermWarning
          event={event}
          onToggleIgnore={eventId => toggleEventIdToIngoreList(eventId)}
        />
      ),
      btn,
      key,
      onClose: close,
    })
  }, delay)
}
