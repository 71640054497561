import { NotificationProps } from '@organice/utils/hooks/useNotifications'
import { Badge, List, Spin } from 'antd'
import { LoadingIndicator } from '@organice/atoms/loadingIndicator'
import styled from '@emotion/styled'
import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import dayjs from 'dayjs'

import { Text } from '../text'
import { Icon } from '../icon'
import MailOpen from '@organice/icons/mail_open.svg?react'
import MailUnread from '@organice/icons/mail_unread.svg?react'
import { dateConfig } from '@organice/config'
// import { useNotifications } from '@organice/utils/hooks/useNotifications'

type NotificationComponentProps = NotificationProps & {
  setReadHandler?: (notificationId: bigint, read: boolean) => void
  loading?: boolean
  notificationActionMessage: string
}

export const Notification: React.FC<NotificationComponentProps> = props => {
  const { t } = useTranslation()
  const [requestLoading, setRequestLoading] = React.useState<boolean>(false)

  const notificationCreated = dayjs(props.createdAt).format('DD.MM.YYYY HH:mm')
  const notificationActionMessage = props.notificationActionMessage // `notifications.actions.${props.notificationActionMessage}.message`

  React.useEffect(() => {
    if (!props.loading) {
      setRequestLoading(false)
    }
  }, [props.loading])

  return (
    <ListItemContainer key={props.id}>
      <Link
        to={props?.to || ''}
        onClick={() =>
          props.setReadHandler &&
          props.setReadHandler(props.id, true) &&
          setRequestLoading(true)
        }
      >
        <NotificationHolder isRead={!props.readAt}>
          <MessageContainer>
            <Trans i18nKey={notificationActionMessage}>
              <HighlightContainer>
                {{ firstName: props.authorFirstName }}
              </HighlightContainer>
              <HighlightContainer>
                {{ lastName: props.authorLastName }}
              </HighlightContainer>
              <HighlightContainer>
                {{ eventName: props.eventName }}
              </HighlightContainer>
              <HighlightContainer>
                {{ roomName: props.roomName }}
              </HighlightContainer>
              <HighlightContainer>
                {{ roomServiceName: props.roomServiceName }}
              </HighlightContainer>
              <HighlightContainer>
                {{ roomServiceOccurrenceName: props.roomServiceOccurrenceName }}
              </HighlightContainer>
              <HighlightContainer>
                {{
                  roomServiceOccurrenceStartDate:
                    props.roomServiceOccurrenceStartDate &&
                    dayjs(props.roomServiceOccurrenceStartDate).format(
                      dateConfig.DATE_TIME_FORMAT
                    ),
                }}
              </HighlightContainer>
              <HighlightContainer>
                {{ serviceName: props.serviceName }}
              </HighlightContainer>
              <HighlightContainer>
                {{ serviceModuleName: props.serviceModuleName }}
              </HighlightContainer>
            </Trans>
          </MessageContainer>
          <BadgeHolder>
            {!props.readAt && <Badge status="processing" />}
          </BadgeHolder>
        </NotificationHolder>
      </Link>
      <NotificationCreatedContainer>
        {notificationCreated}
      </NotificationCreatedContainer>
      <MarkAsReadUnReadContainer
        onClick={() => {
          props.setReadHandler &&
            props.setReadHandler(props.id, !props.readAt) &&
            setRequestLoading(true)
        }}
      >
        {!props.readAt ? (
          <>
            <Icon component={MailOpen} /> {t('notifications.markAsRead')}
          </>
        ) : (
          <>
            <Icon component={MailUnread} /> {t('notifications.markAsUnRead')}
          </>
        )}
        {requestLoading && props.loading && (
          <Spin size="small" style={{ marginLeft: 10 }} />
        )}
      </MarkAsReadUnReadContainer>
    </ListItemContainer>
  )
}

const ListItemContainer = styled(List.Item)`
  align-items: flex-start;
  flex-direction: column;
`

const NotificationHolder = styled.div<{ isRead: boolean }>(
  ({ isRead, theme }) => `
  display: flex;
  max-width: 21.875rem;
  align-items: center;
  color: ${isRead ? theme.grey1 : theme.grey4};
  :hover {
    color: ${theme.primaryColor}
  }

`
)

const BadgeHolder = styled.div`
  padding-left: 1.25rem;
`

const MarkAsReadUnReadContainer = styled(Text)`
  position: relative;
  align-item: center;
  margin-top: 0.625rem;
  color: ${props => props.theme.grey4};
  font-size: 0.75rem;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .ant-spin {
    position: absolute;
    right: -20px;
    top: 0;
  }
`

const NotificationCreatedContainer = styled.div`
  color: ${props => props.theme.grey4};
  font-size: 0.75rem;
  text-align: right;
`
const MessageContainer = styled.div``

const HighlightContainer = styled.span`
  font-weight: 600;
`
