import {
  useUpdateMultipleAttributeValuesAndAddAttributeValueGroupsMutation,
  AttributeValues_Insert_Input,
  AttributeValueGroups_Insert_Input,
  AttributeValueFiles_Insert_Input,
  AttributeFiles,
} from '@organice/graphql'
import {
  Service,
  ServiceModule,
  AttributeValue,
  Attribute,
  AttributeGroup,
  AttributeType,
} from '../../../types/service'
import { prepareDate } from '@organice/utils/service/prepareDate'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormData = Record<string, any>
export type FormDataValue = FormData[keyof FormData]

export const SEPERATOR = '::'
export const NEW_GROUP_INDICATOR = 'newAttributeValueGroups'

type ProcessingAttribute = Pick<Attribute, 'id' | 'config' | 'attributeType'>

type NewlyUploadedFile = {
  uid: string
  name: string
  lastModified: number
  status: string
  size: number
  type: string
  response: {
    size: number
    uid: bigint
    fileId: string // !!
  }
}
type ExistingFile = {
  uid: bigint
  url?: string
  size: number
  type?: AttributeFiles['__typename']
}

type AttributeValueFile = { attributeValueId: bigint; fileId: bigint }

type FileListItem = ExistingFile | NewlyUploadedFile

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUpload = () => {
  const { t } = useTranslation()

  const [
    updateMultipleAttributeValuesAndAddAttributeValueGroups,
    {
      loading: updateValuesAndCreateValueGroupsLoading,
      error: updateValuesAndCreateValueGroupsError,
    },
  ] = useUpdateMultipleAttributeValuesAndAddAttributeValueGroupsMutation()

  const upload = async (data: FormData, service: Service) => {
    // console.log(
    //   `handling data`,
    //   { data, entries: Object.entries(data) },
    //   service
    // )

    const promises = Object.entries(data).map(([key, value]) => {
      const module = service.serviceModules.find(m => m.id === Number(key))

      if (!module) {
        throw new Error(
          `Couldn't find module ${key} in ${JSON.stringify(service, null, 2)}`
        )
      }
      return handleModule({ [key]: value }, module)
    })
    return await Promise.all(promises)
  }

  const handleModule = async (data: FormData, serviceModule: ServiceModule) => {
    // console.log(`handling module`, { data })

    const moduleData = Object.values(data).reduce<FormData>(
      (acc, curr: any) => ({ ...acc, ...curr }),
      {}
    )

    const entries = Object.entries(moduleData)

    /*
    /* 1) find all file attributes for attributeValueFiles mutation
    /* 2) generate all mutation data for new attributeValueGourps (if any)
    /* 3) generate all mutation data for attributeValue updates (if any)
    /*
    /* All mutations should be in a single transaction and succeed or fail together
    */

    const fileChangesByAttributeValueId = getFilesForAttributeValueIds(entries)

    // The attributeValueFiles Update is split into, deletes and inserts (avFiles that remain unchanged are ignored)
    // This is necessary, because of DB permissions. (INSERTs of attributeValueFiles are only allowed for files uploaded by the current user)
    const attributeValueFilesUpdate = splitAttributeValueFiles(
      serviceModule,
      fileChangesByAttributeValueId
    )

    // console.log('attributeValueFilesUpdate', attributeValueFilesUpdate)

    /* New attributeValueGroups if any */
    const newAttributeValueGroups: AttributeValueGroups_Insert_Input[] =
      prepareAttributeValueGroupMutationData(serviceModule, entries)

    /* Aggregate all attributeValue Updates for batch update of existing attributeValues */
    const attrValueUpdates: AttributeValues_Insert_Input[] =
      prepareAttributeValueUpdateMutationData(serviceModule, entries)

    const hasValueUpdates = !!attrValueUpdates.length
    const hasNewAttributeValueGroups = !!newAttributeValueGroups.length

    //Decide which Mutation to use (value updates & new value groups / new valueGroups only / value updates only)
    // if (hasValueUpdates && hasNewAttributeValueGroups) {
    const updateMutation =
      await updateMultipleAttributeValuesAndAddAttributeValueGroups({
        variables: {
          attributeValues: attrValueUpdates,
          attributeValueGroups: newAttributeValueGroups,
          attributeValueFilesToDelete: attributeValueFilesUpdate.delete.map(
            avfToDelete => ({
              attributeValueId: { _eq: avfToDelete.attributeValueId },
              fileId: { _eq: avfToDelete.fileId },
            })
          ),
          attributeValueFiles: attributeValueFilesUpdate.insert,
        },
      })

    const affectedRowsAttrValGroups =
      updateMutation.data?.insert_attributeValueGroups?.affected_rows || 0

    const affectedRowsAttrValues =
      updateMutation.data?.insert_attributeValues?.affected_rows || 0

    checkForUpdateErrors(
      UpdateType.AttributeValueGroups,
      affectedRowsAttrValGroups,
      newAttributeValueGroups.length
    )
    checkForUpdateErrors(
      UpdateType.AtrributeValues,
      affectedRowsAttrValues,
      attrValueUpdates.length
    )
    return updateMutation
  }

  enum UpdateType {
    AtrributeValues = 'attributeValues',
    AttributeValueGroups = 'attributeValueGroups',
  }

  type FileAttributeValueChanges = {
    [key: number]: AttributeValueFile[]
  }

  function splitAttributeValueFiles(
    serviceModule: ServiceModule,
    filesByAttributeValueId: FileAttributeValueChanges
  ) {
    const oldAVFilesByAttributeId: FileAttributeValueChanges = {}
    const oldAVFiles: FileAttributeValueChanges = []
    const idsOfChangedUploadFields = Object.keys(filesByAttributeValueId).map(
      key => parseInt(key)
    )
    const avfUpdate: {
      insert: AttributeValueFile[]
      delete: AttributeValueFile[]
    } = {
      insert: [],
      delete: [],
    }

    // grab old values of attributeValueFiles
    serviceModule.attributeGroups.forEach(aGroup => {
      aGroup.attributeValueGroups.forEach(avGroup => {
        avGroup.attributeValues
          .filter(
            av =>
              av.attribute.attributeType === 'file' &&
              idsOfChangedUploadFields.includes(av.id)
          )
          .forEach(fileAttr => {
            const attrValueFiles = fileAttr.attributeValueFiles.map(avFile => ({
              attributeValueId: fileAttr.id as bigint,
              fileId: avFile.file.id as bigint,
            }))

            oldAVFilesByAttributeId[fileAttr.id] = attrValueFiles
          })
      })
    })

    avfUpdate.delete = [] //avFilesToDelete
    avfUpdate.insert = [] //avFilesToInsert

    const avFilesToInsert = idsOfChangedUploadFields.forEach(
      attributeValueId => {
        const newAttributeValueFilesForId =
          filesByAttributeValueId[attributeValueId]
        const oldAttributeValueFilesForId =
          oldAVFilesByAttributeId[attributeValueId]

        // get new attributeValueFiles that are not in oldAttributeValueFiles as inserts
        const newAttributeValueFiles = newAttributeValueFilesForId.filter(
          newAVF =>
            !oldAttributeValueFilesForId
              .map(of => of.fileId)
              .includes(newAVF.fileId)
        )
        if (newAttributeValueFiles.length) {
          avfUpdate.insert = [...avfUpdate.insert, ...newAttributeValueFiles]
        }

        // get old attributeValueFiles that are missing in newAttributeValueFiles as deletes
        const removedAttributeValueFiles = oldAttributeValueFilesForId.filter(
          oldAVF =>
            !newAttributeValueFilesForId
              .map(of => of.fileId)
              .includes(oldAVF.fileId)
        )
        if (removedAttributeValueFiles.length) {
          avfUpdate.delete = [
            ...avfUpdate.delete,
            ...removedAttributeValueFiles,
          ]
        }
      }
    )

    // console.log('avfUpdate', avfUpdate)
    return avfUpdate
  }

  const checkForUpdateErrors = (
    type: UpdateType,
    affectedRows: number,
    updatesCount: number
  ) => {
    if (affectedRows !== updatesCount) {
      if (affectedRows === 0) {
        throw new Error(
          t(`events.serviceModules.errors.${type}.totalUpdateFail`)
        )
      }

      return true

      // ignoring for now, because the update of new valueGroups with files returns more affected_rows (newgroup + files)
      // leading to error like -3 of 1 updates failed
      throw new Error(
        t(`events.serviceModules.errors.${type}.partialUpdateFail`, {
          failedUpdatesCount: updatesCount - affectedRows,
          totalUpdatesCount: updatesCount,
        })
      )
    }
  }

  const getFilesForAttributeValueIds = (entries: [string, any][]) => {
    const changedFileAttributeValues: FileAttributeValueChanges = {}

    entries.forEach(e => {
      const [attrValueId, value] = e
      // New attributeValue Groups will be ignored here (fileList is a level deeper) & will be added with the new valueGroup relational insert
      if (!value?.fileList) return
      changedFileAttributeValues[Number(attrValueId)] = value.fileList
        .map((f: FileListItem) => {
          if (f?.type === 'attributeFile') return

          if ('status' in f && typeof f.uid === 'string') {
            if (f.status !== 'done') {
              throw new Error(
                t(`events.serviceModules.errors.uploadStillInProgress`, {
                  fileName: f.name,
                })
              )
            }
            return {
              attributeValueId: Number(attrValueId),
              fileId: Number(f.response.fileId),
            }
          }
          return { attributeValueId: Number(attrValueId), fileId: f.uid }
        })
        .filter(Boolean)
    })

    return changedFileAttributeValues
  }

  const prepareAttributeValueGroupMutationData = (
    serviceModule: ServiceModule,
    entries: [string, any][]
  ) => {
    let newAttributeValueGroupsData: AttributeValueGroups_Insert_Input[] = []

    entries.forEach(([key, value]) => {
      if (isNewAttributeValueGroup(key)) {
        const splittedKey = key.split(SEPERATOR)
        const groupId = Number([...splittedKey].pop())

        const group = serviceModule.attributeGroups.find(g => g.id === groupId)

        if (!group) {
          throw new Error(
            `Couldn't find group id ${groupId} in ${JSON.stringify(
              serviceModule,
              null,
              2
            )}`
          )
        }
        newAttributeValueGroupsData = handleNewValueGroups(
          value as FormData[],
          group
        )
      }
    })

    return newAttributeValueGroupsData
  }

  const prepareAttributeValueUpdateMutationData = (
    serviceModule: ServiceModule,
    entries: [string, any][]
  ) => {
    const attrValueUpdates: AttributeValues_Insert_Input[] = []
    entries.forEach(([key, value]) => {
      if (isNewAttributeValueGroup(key)) {
        return
      }
      const attributeValue = serviceModule.attributeGroups.reduce<
        AttributeValue | undefined
      >((acc, curr) => {
        if (acc) {
          return acc
        }
        return curr.attributeValueGroups.reduce<AttributeValue | undefined>(
          (value, avg) => {
            if (value) {
              return value
            }

            return avg.attributeValues.find(a => a.id === Number(key))
          },
          undefined
        )
      }, undefined)

      if (!attributeValue) {
        throw new Error(
          `couldn't find attributeValue ${key} in ${JSON.stringify(
            serviceModule,
            null,
            2
          )}`
        )
      }

      attrValueUpdates.push(
        prepareExistingAttributeValueUpdate({ [key]: value }, attributeValue)
      )
    })

    return attrValueUpdates
  }

  const prepareExistingAttributeValueUpdate = (
    data: Record<string, unknown>,
    attributeValue: AttributeValue
  ) => {
    const [rawValue] = Object.values(data)
    const preparedValue = prepareValue(rawValue, attributeValue.attribute)

    return {
      attributeId: attributeValue.attribute.id,
      attributeValueGroupId: attributeValue.attributeValueGroupId,
      ...preparedValue,
    }
  }

  const prepareValue = (
    rawValue: FormDataValue,
    attribute: ProcessingAttribute
  ): AttributeValues_Insert_Input | AttributeValueFiles_Insert_Input => {
    switch (attribute.attributeType) {
      case AttributeType.Number:
        if (rawValue === null || rawValue == undefined) {
          return { value: { value: null } }
        }

        return { value: { value: Number(rawValue) } }
      case AttributeType.Boolean:
      case AttributeType.Text:
        return { value: { value: rawValue } }

      case AttributeType.File:
        // AttributeType.File is now processed here and used if a new valueGroup is created (no attibuteValue for upload)
        if (rawValue?.fileList) {
          return {
            value: rawValue?.fileList
              .map((f: any) => {
                // ignore attribute files here . theay shall not be changed from the service UI
                if (f.type === 'attributeFile') {
                  return false
                }

                if (Number.isInteger(f.uid)) {
                  return { fileId: f.uid }
                }

                if (f?.response?.uid) {
                  return { fileId: f.response.uid }
                }

                console.warn(
                  `prepareValue(): file is missing uid ${JSON.stringify(
                    rawValue
                  )}`
                )
                return undefined
              })
              .filter(Boolean),
          }
        }
        return [] as AttributeValueFiles_Insert_Input
      case AttributeType.Date:
        return { value: prepareDate(rawValue, attribute) }
      // case AttributeType.Select:
      // return { value: rawValue }
      case AttributeType.User:
        return { value: {}, userId: rawValue?.id || null }
      // case AttributeType.Select:
      // return { value: rawValue }
      default:
        console.warn(
          `prepareValue(): No case for attributeType ${attribute.attributeType}`
        )
        return { value: { value: rawValue } }
    }
  }

  const handleNewValueGroups = (
    allValueGroups: FormData[],
    attributeGroup: AttributeGroup
  ): AttributeValueGroups_Insert_Input[] => {
    // creating the objects attribute of the insert call that will include all of the
    // newly created AttributeValueGroups for a single attributeGroupdId
    const objects = allValueGroups
      // removing empty attributeValueGroups
      .filter(valueGroup => valueGroup !== undefined)
      .map<any>(valueGroup => {
        // AttributeValueGroups_Insert_Input
        /*
        /* EdgeCase-Update: looping over attributes instead of valueGroup entries 
        /* uploads do not appear as form values, so no value would be saved for fileUpload fields in repeatable valueGroups when looping 
        /* over valueGroup entries.
        /* using attributes instead will save an empty value for fileUploads, so the field is rendered and can have comments
        */

        // creating value to attribute id relations
        const attributeValues = attributeGroup.attributes.map(attr => {
          const attributeId = Number(attr.id)
          const rawValue = valueGroup[String(attributeId)] || undefined

          // The value of a new attributeValue is always an empty string (better would be null)
          if (
            rawValue === undefined ||
            (Array.isArray(rawValue) &&
              rawValue.every(v =>
                Array.isArray(v)
                  ? v.every(v => v === undefined)
                  : v === undefined
              ))
          ) {
            return {
              attributeId: attributeId,
              value: { value: null },
            }
          }

          const attributeType = attr.attributeType

          if (attributeType === AttributeType.File) {
            return {
              attributeId: attributeId,
              attributeValueFiles: {
                data: prepareValue(rawValue, attr)?.value,
              },
            }
          }

          return {
            attributeId: attributeId,
            ...prepareValue(rawValue, attr),
          }
        })

        return {
          attributeGroupId: attributeGroup.id,
          attributeValues: {
            data: attributeValues,
          },
        }
      })

    return objects
  }

  const isNewAttributeValueGroup = (key: string) =>
    new RegExp(`^${NEW_GROUP_INDICATOR}`).test(key)

  const loading = updateValuesAndCreateValueGroupsLoading
  const error = updateValuesAndCreateValueGroupsError

  return { upload, isNewAttributeValueGroup, loading, error } as const
}
