import React from 'react'
import { ReactNode } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import { IRoute } from './config'
import { getPathAsRoutesArray, routesFlatPaths } from './util'

export type IRouteForBC = Pick<IRoute, 'title' | 'path'>

const initialState: IRouteForBC[] = []

const BreadcrumbContext = React.createContext<
  | {
      routes: IRouteForBC[]
      dispatch: Dispatch
      currentPath: string | undefined
      setCurrentPath: (path: string) => void
    }
  | undefined
>(undefined)
BreadcrumbContext.displayName = 'BreadcrumbContext'

type Action =
  | { type: 'overrideLastTitle'; title: ReactNode }
  | { type: 'overrideLast'; routes: IRouteForBC | IRouteForBC[] }
  | { type: 'append'; routes: IRouteForBC | IRouteForBC[] }
  | { type: 'prepend'; routes: IRouteForBC | IRouteForBC[] }
  | {
      type: 'injectAtIndex'
      index: number
      routes: IRouteForBC | IRouteForBC[]
    }
  | {
      type: 'replaceAtIndex'
      index: number
      routes: IRouteForBC | IRouteForBC[]
    }
  | { type: 'set'; routes: IRouteForBC[] }
type Dispatch = (action: Action) => void

function breadbrumbReducer(state: IRouteForBC[], action: Action) {
  switch (action.type) {
    case 'set': {
      return action.routes
    }
    case 'overrideLastTitle': {
      if (!state.length) return state
      const [lastRoute] = state.slice(-1)
      const allButLastRoute = state.slice(0, -1)
      return [...allButLastRoute, { ...lastRoute, title: action.title }]
    }
    case 'overrideLast': {
      if (!state.length) return state
      const allButLastRoute = state.slice(0, -1)

      const newLastRoute =
        action.routes instanceof Array ? action.routes : [action.routes]

      return [...allButLastRoute, ...newLastRoute]
    }
    case 'append': {
      if (!state.length) return state
      const routesToAppend =
        action.routes instanceof Array ? action.routes : [action.routes]

      return [...state, ...routesToAppend]
    }
    case 'prepend': {
      if (!state.length) return state
      const routesToAppend =
        action.routes instanceof Array ? action.routes : [action.routes]

      return [...routesToAppend, ...state]
    }
    case 'injectAtIndex': {
      if (!state.length) return state
      const routesToInject =
        action.routes instanceof Array ? action.routes : [action.routes]

      const newRoutes = [...state]
      newRoutes.splice(action.index, 0, ...routesToInject)
      return newRoutes
    }
    case 'replaceAtIndex': {
      if (!state.length) return state

      const routesToInject =
        action.routes instanceof Array ? action.routes : [action.routes]

      const newRoutes = [...state]
      newRoutes.splice(action.index, routesToInject.length, ...routesToInject)
      return newRoutes
    }
    // default: {
    //   throw new Error(`Unhandled action type: ${action.type}`)
    // }
  }
}

type BreadcrumbProviderProps = { children: React.ReactNode }

export function BreadcrumbProvider({ children }: BreadcrumbProviderProps) {
  const [routes, dispatch] = React.useReducer(breadbrumbReducer, initialState)
  const [currentPath, setCurrentPath] = React.useState<string | undefined>()
  const value = { routes, dispatch, currentPath, setCurrentPath }

  return (
    <BreadcrumbContext.Provider value={value}>
      {children}
    </BreadcrumbContext.Provider>
  )
}

type useBreadcrumbsReturn = { routes: IRouteForBC[]; dispatch: Dispatch }

export function useBreadcrumbs(): useBreadcrumbsReturn {
  const context = React.useContext(BreadcrumbContext)
  const { pathname } = useLocation()
  const { path } = routesFlatPaths.find(rfp => matchPath(pathname, rfp)) || {
    path: '/',
  }

  const breadcrumbPath = getPathAsRoutesArray(path, true)
  if (context === undefined) {
    console.error('useBreadcrumb must be used within a BreadcrumbContext')
  }
  React.useEffect(() => {
    if (!context) return
    if (pathname && pathname !== context.currentPath) {
      context.setCurrentPath(pathname)
      context?.dispatch({ type: 'set', routes: breadcrumbPath })
    }
  }, [pathname, path])

  return context || { routes: [], dispatch: () => {} }
}
