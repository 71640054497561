import styled from '@emotion/styled'
import logo from 'assets/svg/nuernberg_messe.svg'
import { OrgaNiceLogoProps } from '../orgaNiceLogo'

export const NUERNBERG_MESSE_LOGO_WIDTH = 328
export const NUERNBERG_MESSE_LOGO_HEIGHT = 130
const DEFAULT_FACTOR = 1

type NuernbergMesseLogoProps = OrgaNiceLogoProps

export const NuernbergMesseLogo = styled.div<NuernbergMesseLogoProps>(
  ({ factor = DEFAULT_FACTOR }) => ({
    background: `url("${logo}")`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: NUERNBERG_MESSE_LOGO_HEIGHT * factor,
    width: NUERNBERG_MESSE_LOGO_WIDTH * factor,
  })
)
