import React, { CSSProperties } from 'react'
import { Spin } from 'antd'
import styled from '@emotion/styled'
import { Text } from '@organice/atoms/text'

interface LoadingIndicator {
  isLocal?: boolean
  message?: string
  style?: CSSProperties
}

export const LoadingIndicator: React.FC<LoadingIndicator> = ({
  isLocal,
  message,
  style = {},
}) => (
  <Wrapper
    style={
      isLocal
        ? style
        : {
            ...style,
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            // transform: 'translateX(-50%)',
          }
    }
  >
    <Content>
      <Spin />
      {message && <Text>{message}</Text>}
    </Content>
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  top: 50%;
  // left: 50%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
