import React from 'react'
import styled from '@emotion/styled'
import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { CommentOutlined, FormOutlined } from '@ant-design/icons'

import { Button } from '@organice/atoms/button'
import { Heading } from '@organice/atoms/heading'

import { CommentsList } from '../../molecules/commentsList'
import { CommentForm } from './../commentForm'
import { GeneralDict } from '@organice/utils/form'
import { useCommentsContext } from '@organice/contexts/comments/useCommentsContext'
import {
  CommentTypes_Enum,
  AllCommentGraphQLTypes as Comments,
  getAdditionalData,
} from '@organice/contexts/comments/commentsContext'
import { useCommentsQueries } from '@organice/utils/hooks/comments/useCommentQueries'

export interface CommentsTabProps {
  id: Pick<Comments, 'id'> | null
  type: CommentTypes_Enum
  publicComments?: boolean
  organisationId?: number
  additionalData?: GeneralDict | null
  userInfo?: GeneralDict | null
  onUpdateCount?(count: number): void
}

type CommentWithIsDoneProp = {
  isDone: Pick<Comments, 'isDone'>
}

export const CommentsTab: React.FC<CommentsTabProps> = ({
  id,
  type,
  publicComments = true,
  organisationId,
  additionalData,
  userInfo,
  onUpdateCount,
}) => {
  const { dispatch } = useCommentsContext()

  const { t } = useTranslation()
  const [addState, setAddState] = React.useState(false)

  const { data, loading, error } = useCommentsQueries({
    id,
    type,
    organisationId,
    publicComments,
  })

  const comments = (data as CommentWithIsDoneProp[])?.filter(
    ({ isDone }) => !isDone
  ) as Comments[]
  const commentsArchived = (data as CommentWithIsDoneProp[])?.filter(
    ({ isDone }) => isDone
  ) as Comments[]

  React.useEffect(() => {
    onUpdateCount &&
      onUpdateCount(
        (data as CommentWithIsDoneProp[])?.filter(c => !c.isDone)?.length || 0
      )
  }, [data])

  return (
    <div>
      <CommentsHeader>
        <Heading level={3}>
          {t('comment.comments')}
          {comments?.length > 0 && ` (${comments.length})`}
        </Heading>
        <Button
          icon={<FormOutlined />}
          onClick={() => setAddState(true)}
          disabled={
            !!(
              userInfo?.isGuestTab &&
              userInfo?.isNMUser &&
              !userInfo?.guestCanRead
            )
          }
        >
          {t('comment.newTheme')}
        </Button>
      </CommentsHeader>
      <Divider />
      {addState && (
        <CommentFormContainer>
          <CommentForm
            type={type}
            organisationId={organisationId}
            additionalData={getAdditionalData(type, id, additionalData)}
            userInfo={userInfo}
            addLabel={t('comment.createTheme')}
            addIcon={<CommentOutlined />}
            placeholder={t('comment.theme')}
            onClose={() => setAddState(false)}
            onSuccess={() => setAddState(false)}
          />
          <Divider />
        </CommentFormContainer>
      )}
      <CommentsList
        type={type}
        comments={comments}
        organisationId={organisationId}
        additionalData={getAdditionalData(type, id, additionalData)}
        userInfo={userInfo}
      />
      {commentsArchived && commentsArchived.length > 0 && (
        <>
          <ArchivedCommentsHeader>
            <Heading level={3}>
              {t('comment.archivedThemes')} ({commentsArchived.length})
            </Heading>
          </ArchivedCommentsHeader>
          <Divider />
          <ArchivedCommentsList>
            <CommentsList type={type} comments={commentsArchived} archived />
          </ArchivedCommentsList>
        </>
      )}
    </div>
  )
}

const CommentsHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '.ant-typography': { marginBottom: 0 },
  padding: '0 2.5rem',
})

const CommentFormContainer = styled.div({
  padding: '0 2.5rem',
})
const ArchivedCommentsHeader = styled.div({
  padding: '1rem 2.5rem 0 2.5rem',
})

const ArchivedCommentsList = styled.div({
  opacity: '0.5',
})
