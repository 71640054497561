import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd'
import styled from '@emotion/styled'

export const Button = styled(AntdButton)(({ theme }) => ({
  borderRadius: theme.borderRadiusBase,
  padding: '0.25rem 1rem',
  color: theme.grey2,

  '& > a': {
    marginLeft: '0.5rem',
    '&:hover': {
      color: theme.grey2,
    },
  },

  '&:hover': {
    background: theme.btnHoverBackground,
    color: theme.grey2,
    borderColor: theme.btnBorderColor,
  },

  '&.ant-btn-icon-only': {
    padding: 'inherit',
  },
  '&.ant-btn-primary': {
    background:
      'linear-gradient(180deg, rgba(255,255,255,.8) 0%, rgba(255,255,255,0) 100%),' +
      theme.grey7,
    border: 0,
    boxShadow:
      'inset -1px 1px 0 rgb(16 22 26 / 10%), inset 0 0 0 1px rgb(16 22 26 / 20%)',

    '&:hover, &:active, &:focus': {
      color: theme.grey2,
      background:
        'linear-gradient(180deg, rgba(255,255,255,.5) 0%, rgba(255,255,255,0) 100%),' +
        theme.grey6,
      boxShadow:
        'inset -1px 1px 0 rgb(16 22 26 / 10%), inset 0 0 0 1px rgb(16 22 26 / 20%)',
    },
    '&:disabled': {
      color: theme.grey5,
      background: theme.grey3,
      border: `1px solid ${theme.grey5}`,
      boxShadow: 'none',
      textShadow: 'none',
    },
  },
  '&.ant-btn-tertiary': {
    borderColor: theme.primaryColor,
  },
}))
export type ButtonProps = AntdButtonProps
