import React from 'react'
import { Service } from '../../types/service'
import { useMeContext } from '../me'
import { Dayjs } from 'dayjs'
import { TimeUtil } from '@organice/utils/date'
const dayjs = TimeUtil.getDayjs()

export const ServiceModuleContext = React.createContext<
  (Service['serviceModules'][0] & { lastVisited?: Dayjs }) | undefined
>(undefined)

export const useServiceModuleContext = () => {
  const context = React.useContext(ServiceModuleContext)
  const {
    state: { me },
  } = useMeContext()
  if (!context) {
    console.warn(
      `useServiceModuleContext() must be used within a ServiceModuleContext.Provider`
    )
  }
  if (!me) {
    console.warn(
      `useServiceModuleContext() must be used within a useMeContext.Provider`
    )
  }

  const myLastVisit = React.useMemo(
    () => context?.lastVisits.find(v => v.userId === me?.id),
    [context]
  )

  return {
    ...context,
    lastVisited: myLastVisit?.updatedAt && dayjs(myLastVisit.updatedAt),
  }
}
