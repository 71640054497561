import React from 'react'
import styled from '@emotion/styled'
import { Heading, Paragraph } from '@organice/atoms'
import { Users, useSetAgreementMutation } from '@organice/graphql'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { Button, notification } from 'antd'
import { useHistory } from '@organice/router'
import { AGREEMENT_VERSION } from '@organice/config'
import { useMeContext } from '@organice/contexts'

interface ConfidentialityAgreementModuleProps {
  user: Users
}
export const ConfidentialityAgreementModule: React.FC<
  ConfidentialityAgreementModuleProps
> = ({ user }) => {
  const history = useHistory()
  const [accepted, setAccepted] = React.useState(true)
  const [acceptAgreementMutation, { data }] = useSetAgreementMutation()
  const { dispatch } = useMeContext()
  const acceptAgreement = async () => {
    const result = await acceptAgreementMutation({
      variables: { version: AGREEMENT_VERSION },
    })

    if (result.data?.update_userSettings?.affected_rows) {
      // Force set agreement cause FF Bug an request
      dispatch({
        type: 'AGREEMENT_ACCEPTED',
        agreementVersion: AGREEMENT_VERSION,
      })
      history.push('/')
      // history.go(0)
    } else {
      notification.error({
        duration: 3,
        message: 'ERROR',
      })
    }
  }

  return (
    <Container>
      <div>
        <Agreement>
          <AgreementHeading>
            <Heading>Vertraulichkeitserklärung</Heading>
            <Heading level={5}>von</Heading>
            <Heading level={3}>
              {user.firstName} {user.lastName}
            </Heading>
            <Heading level={5}>– nachfolgend „Auftragnehmer“ genannt </Heading>
            <Heading level={5}>gegenüber</Heading>
            <br />
            <Heading level={5}>
              {' '}
              NürnbergMesse GmbH, Messezentrum, 90471 Nürnberg
            </Heading>
            <br />
            <Heading level={5}> – nachfolgend „Auftraggeber“ genannt –</Heading>
            <Heading level={5}>
              – zusammen nachfolgend „Parteien“ genannt –
            </Heading>
          </AgreementHeading>
          <AgreementContent>
            <Heading level={5}>Präambel</Heading>
            <Paragraph>
              Der Auftragnehmer erhält über die Software OrgaNice
              veranstaltungsbezogene Informationen und wird über die zu
              erbringende Leistungen mit den jeweiligen Bedarfsspezifikationen
              je Veranstaltung informiert. Zur Erfüllung seiner Tätigkeit stellt
              der Auftraggeber dem Auftragnehmer Informationen und Unterlagen
              zur Verfügung, die die Parteien als vertraulich und schützenwert
              einstufen. Derartige vertrauliche Informationen, insbesondere
              veranstaltungsbezogene Informationen, Termine, Aufgaben, Notizen
              und digitale Aufzeichnungen sind im Orgaplan (OrgaNice) des
              Auftraggebers digital hinterlegt, auf die der Auftragnehmer
              Zugriffsrechte erhält.
            </Paragraph>
            <Paragraph>
              Zum Schutz der vertraulichen Informationen des Auftraggebers
              erklärt der Auftragnehmer Folgendes:
            </Paragraph>
            <Heading level={5}>§ 1 Verpflichtung zur Vertraulichkeit</Heading>
            <Paragraph>
              (1) Der Auftragnehmer verpflichtet sich, die erhaltenen
              vertraulichen Informationen vertraulich zu behandeln. Das bedeutet
              insbesondere, dass er diese Informationen an Dritte weder selbst
              noch durch Mitarbeiter bekanntzugeben oder sonst für andere als
              die vertraglich zwischen den Parteien vereinbarten Zwecke zu
              nutzen hat. Eine anderweitige Nutzung oder Weitergabe der
              Informationen ist nur zulässig, wenn und soweit der Auftraggeber
              zuvor schriftlich eingewilligt hat.
            </Paragraph>
            <Paragraph>
              (2) Der Auftragnehmer verpflichtet sich, die erhaltenen
              vertraulichen Informationen mindestens mit der Sorgfalt zu
              behandeln, die er in eigenen Angelegenheiten anwendet.
            </Paragraph>
            <Paragraph>
              (3) Der Auftragnehmer wird sämtliche Berechtigten Personen, die
              vertrauliche Informationen erhalten, über Inhalt und Umfang der
              Rechte und Pflichten aus dieser Vereinbarung informieren und
              sicherstellen, dass alle berechtigten Personen die Bestimmungen
              dieser Vereinbarung einhalten.
            </Paragraph>
            <Paragraph>
              (4) Der Auftragnehmer verpflichtet sich, bei Verarbeitung der
              vertraulichen Informationen die gesetzlichen und vertraglichen
              Vorschriften zum Datenschutz einzuhalten. Dies beinhaltet auch dem
              aktuellen Stand der Technik angepasste technische
              Sicherheitsmaßnahmen (Art. 32 DS-GVO) und die Verpflichtung der
              Mitarbeiter auf das Datengeheimnis (Art. 28 Abs. 3 lit. b DS-GVO).{' '}
            </Paragraph>
            <Paragraph>
              (5) Der Auftragnehmer nutzt die erhaltenen vertraulichen
              Informationen ausschließlich zur Erfüllung seiner Tätigkeit. Die
              Rechte an den Informationen, die der Auftragnehmer von dem
              Auftraggeber erhalten hat, verbleiben bei dem Auftraggeber, soweit
              nichts anderes vertraglich geregelt wird.
            </Paragraph>
            <Paragraph>
              (6) Der Auftragnehmer verpflichtet sich ferner, alle Unterlagen
              vollständig und unaufgefordert an den Auftraggeber bei
              Auftragsende zurückzugeben. Überlassene Unterlagen dürfen nicht
              kopiert und vervielfältigt werden. Dies gilt auch für digitale
              Vervielfältigungen.
            </Paragraph>
            <Heading level={5}>
              {/* eslint-disable-next-line no-irregular-whitespace */}
              § 2 Ausnahmen von der Vertraulichkeitsverpflichtung
            </Heading>
            <Paragraph>
              (1) Diese Verpflichtung zum Schutze vertraulicher Information
              beinhaltet nicht solche Informationen, die öffentlich bekannt
              sind.
            </Paragraph>
            <Paragraph>
              (2) Die Pflicht zur Vertraulichkeit besteht nicht gegenüber
              Gerichten und Behörden, soweit eine (auch strafrechtliche)
              Rechtspflicht zur Weitergabe/Herausgabe besteht oder die jeweilige
              Information in einem zivilrechtlichen Prozess zwischen den
              Parteien oder einer der Parteien und einem Dritten relevant ist.
              Über eine Herausgabe von vertraulichen Informationen ist der
              Auftraggeber unverzüglich zu benachrichtigen.
            </Paragraph>
          </AgreementContent>
        </Agreement>
        <ButtonBar>
          <Checkbox onChange={e => setAccepted(e.target.checked)}>
            Hiermit bestätige ich, dass ich die Vertraulichkeitserklärung
            gelesen und akzeptiert habe
          </Checkbox>
          <br />
          <Button
            disabled={!accepted}
            onClick={() => {
              acceptAgreement()
            }}
          >
            Gelesen und akzeptiert
          </Button>
        </ButtonBar>
      </div>
    </Container>
  )
}
const Container = styled.div`
  display: flex;
  justify-content: center;
`
const Agreement = styled.div`
  width: calc(100vw - 40px);
  @media screen and (min-width: ${props => props.theme.screenMd}) {
    width: 80vw;
  }
  padding: 20px 20px 0 20px;
  max-width: 960px;
  overflow-y: auto;
  max-height: calc(100vh - 300px);
  border: 1px solid ${props => props.theme.grey5};
`
const AgreementHeading = styled.div`
  text-align: center;
`
const AgreementContent = styled.div``

const ButtonBar = styled.div`
  padding: 20px 20px 0 20px;
  button {
    margin-top: 20px;
  }
`
