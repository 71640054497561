import React from 'react'
import { ServiceListQuery } from '@organice/graphql'
import { Popover } from '../popover'
import { Modal } from '../modal'
import { useTranslation } from 'react-i18next'
import { Divider } from 'antd'
import styled from '@emotion/styled'
import { DeleteOutlined, EditOutlined, ExportOutlined } from '@ant-design/icons'

const ICON_FONT_SIZE = '1.25rem'

export type Service = NonNullable<ServiceListQuery['services'][0]>

export type ServiceMutationFunction = (id: Service['id']) => void

export interface ServicesListEntryPopoverProps {
  id: Service['id']
  onRemove?: ServiceMutationFunction
  onRename?: ServiceMutationFunction
  externalLink?: string
}

interface ServicesListEntryPopoverState {
  showPopover?: boolean
}

export const ServicesListEntryPopover: React.FC<
  ServicesListEntryPopoverProps
> = ({ children, onRemove, onRename, externalLink, id }) => {
  const [state, setState] = React.useState<ServicesListEntryPopoverState>({})
  const { t } = useTranslation()

  const handleRemove = () => {
    closePopover()

    Modal.confirm({
      title: t('templates.deleteModal.deleteServiceTitle'),
      okText: t('templates.deleteModal.ok'),
      cancelText: t('templates.deleteModal.cancel'),
      content: t('templates.deleteModal.deleteServiceText'),
      closable: true,
      maskClosable: true,
      onOk: () => {
        if (onRemove) {
          onRemove(id)
        }
      },
    })
  }

  const handleRename = () => {
    if (onRename) {
      onRename(id)
    }

    closePopover()
  }

  const closePopover = () => {
    setState(s => ({ ...s, showPopover: false }))
  }

  const renderMenu = () => (
    <ContextMenuList>
      {/* <ContextMenuListItem onClick={handleRename}>
          <RenameIcon />
          {t('templates.contextMenu.rename')}
        </ContextMenuListItem> */}
      {/* <a href={externalLink} target="_blank" rel="noreferrer">
          <ContextMenuListItem onClick={closePopover}>
            <NewTabIcon />
            {t('templates.contextMenu.openInNewTab')}
          </ContextMenuListItem>
        </a> */}

      <ContextMenuDivider />

      <ContextMenuListItemDelete onClick={handleRemove}>
        <DeleteIcon></DeleteIcon>
        {t('templates.contextMenu.remove')}
      </ContextMenuListItemDelete>
    </ContextMenuList>
  )

  return (
    <Popover
      open={state.showPopover}
      trigger="click"
      onOpenChange={showPopover => setState(s => ({ ...s, showPopover }))}
      placement="bottomRight"
      content={renderMenu}
    >
      {children}
    </Popover>
  )
}

const ContextMenuList = styled.ul({
  padding: 0,
  margin: 0,
})

const ContextMenuListItem = styled.li(({ theme }) => ({
  listStyleType: 'none',
  padding: '.5rem',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  transition: 'color .3s',

  '&:hover': {
    backgroundColor: theme.lightBackground,
    color: theme.primaryColor,
  },
}))

const ContextMenuListItemDelete = styled(ContextMenuListItem)(props => ({
  color: props.theme.errorColor,

  '&:hover': {
    color: props.theme.errorColor,
  },
}))

const DeleteIcon = styled(DeleteOutlined)(props => ({
  color: props.theme.errorColor,
  fontSize: ICON_FONT_SIZE,
  marginRight: '.5rem',
}))

const RenameIcon = styled(EditOutlined)({
  fontSize: ICON_FONT_SIZE,
  marginRight: '.5rem',
})

const NewTabIcon = styled(ExportOutlined)({
  fontSize: ICON_FONT_SIZE,
  marginRight: '.5rem',
})

const ContextMenuDivider = styled(Divider)({ margin: '.5rem 0' })
