import React from 'react'
import { ListItem } from '../listItem'
import styled from '@emotion/styled'
import { ServiceBundles } from '@organice/graphql'
import { useHistory } from 'react-router-dom'
import { Tooltip } from '@organice/atoms'

export type BundleListEntryData = Pick<ServiceBundles, 'id' | 'name'>

export interface BundlesListEntryProps {
  data: BundleListEntryData
  getLink?(id: string): string
  // popoverProps?: Omit<BundlesListPopoverProps, 'id'>
}

export const BundlesListEntry: React.FC<BundlesListEntryProps> = React.memo(
  ({
    data,
    getLink = (id: string) => `/configuration/bundle/${id}`,
    // popoverProps,
  }) => {
    const history = useHistory()

    const nameLength = data.name.length
    const toolTipFrom = 30
    const addToolTip = nameLength > toolTipFrom

    // const renderEllipsis = () => {
    //   return (
    //     <BundlesListPopover {...popoverProps} id={data.id}>
    //       <EllipsisContainer>
    //         <EllipsisOutlined></EllipsisOutlined>
    //       </EllipsisContainer>
    //     </BundlesListPopover>
    //   )
    // }

    return (
      // <BorderlessListItem extra={renderEllipsis()}>
      <BorderlessListItem onClick={() => history.replace(getLink(data.id))}>
        {addToolTip ? (
          <Tooltip placement="top" title={data.name}>
            <ListItemText>{data.name}</ListItemText>
          </Tooltip>
        ) : (
          <ListItemText>{data.name}</ListItemText>
        )}
      </BorderlessListItem>
    )
  },
  (prevProps, nextProps) =>
    prevProps.data.id === nextProps.data.id &&
    prevProps.data.name === nextProps.data.name
)

const BorderlessListItem = styled(ListItem)(({ theme }) => ({
  '&&&': {
    border: 0,
    cursor: 'pointer',
    lineHeight: '1.467',
    padding: '0.3125rem 0.625rem',
    '&:hover': {
      backgroundColor: theme.lightBackground,
      borderRadius: theme.borderRadiusBase,
      '& > div': {
        visibility: 'visible',
      },
    },
    '& > div': {
      visibility: 'hidden',
    },
  },
}))

const ListItemText = styled.span({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  paddingRight: '0.625rem',
  fontSize: '15px',
  fontWeight: 500,
})

// const EllipsisContainer = styled.div({ cursor: 'pointer' })
