import React from 'react'
import { Header } from '@organice/organisms/header'
import { useMeContext } from '@organice/contexts'

export const DefaultLayout: React.FC<{
  children?: React.ReactNode
}> = props => {
  const authState = useMeContext()
  return (
    <>
      <Header />
      {props.children}
    </>
  )
}
