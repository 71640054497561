import { DocumentNode } from '@apollo/client'
import {
  OperationDefinitionNode,
  VariableDefinitionNode,
} from 'graphql/language/ast'

type Mutable<T> = {
  -readonly [P in keyof T]: T[P]
}
declare function clone<T>(val: T): Mutable<T>
type GraphQLSelection = OperationDefinitionNode['selectionSet']['selections'][0]
type GraphQLVariableDefinition = VariableDefinitionNode

export function injectMutations(
  baseMutation: DocumentNode,
  arrayOfMutations: DocumentNode[]
) {
  const combinedSelectionSets = arrayOfMutations.reduce(
    (res: GraphQLSelection[], cur) => {
      const sel: Mutable<GraphQLSelection>[] = []
      cur.definitions.forEach(def => {
        const defNode = def as OperationDefinitionNode

        if (defNode?.selectionSet.selections) {
          sel.push(...defNode.selectionSet.selections)
        }
      })
      return [...res, ...sel]
    },
    []
  )

  const combinedVariableDefinitions = arrayOfMutations.reduce(
    (res: GraphQLVariableDefinition[], cur) => {
      const varDefs: Mutable<GraphQLVariableDefinition>[] = []
      const set = cur.definitions.forEach(def => {
        const defNode = def as OperationDefinitionNode

        if (defNode?.variableDefinitions) {
          varDefs.push(...defNode.variableDefinitions)
        }
      })
      return res.concat(varDefs)
    },
    []
  )

  const baseMutationDefinition = baseMutation
    .definitions[0] as OperationDefinitionNode

  const baseQuerySelectionSets: GraphQLSelection[] = [
    ...baseMutationDefinition.selectionSet.selections,
  ]

  const baseQueryVarDef: GraphQLVariableDefinition[] =
    baseMutationDefinition.variableDefinitions
      ? [...baseMutationDefinition.variableDefinitions]
      : []

  const combinedMutationDefinition = {
    ...baseMutationDefinition,
    variableDefinitions: baseQueryVarDef.concat(combinedVariableDefinitions),
    selectionSet: {
      ...baseMutationDefinition.selectionSet,
      selections: baseQuerySelectionSets.concat(combinedSelectionSets),
    },
  }

  return { ...baseMutation, definitions: [combinedMutationDefinition] }
}

export function injectMutations_string(
  mutationStringArray: string[],
  baseMutationString: string
) {
  const lastCurlyBraceIndex = baseMutationString.lastIndexOf('}')
  return `
          ${baseMutationString.substring(0, lastCurlyBraceIndex)}
          ${mutationStringArray.join('\n')}
          ${baseMutationString.substring(lastCurlyBraceIndex)}
          `
}
