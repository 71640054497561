import React from 'react'
import {
  CreateEventMutation,
  useCreateEventMutation,
  DeleteEventMutation,
  useDeleteEventMutation,
  Events,
  CreateEventMutationOptions,
  useSetEventFavouriteMutation,
  useUnsetEventFavouriteMutation,
  useUpdateEventMutation,
  UpdateEventMutation,
  useUpdateEventWithOrganisersMutation,
  Events_Set_Input,
  EventOrganisers_Insert_Input,
  EventAttributeValues_Insert_Input,
} from '@organice/graphql'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'
import { ApolloError, gql } from '@apollo/client'
import { useMeContext } from '@organice/contexts'

type toggelFavouriteProps = Pick<Events, 'id' | 'isFavourite'> | null

interface EventUpdateInput extends Events_Set_Input {
  id?: bigint
  eventOrganisers?: EventOrganisers_Insert_Input[]
  eventAttributes?: EventAttributeValues_Insert_Input[]
}

type UseEventReturn = {
  addEvent(options: CreateEventMutationOptions): void
  addStates: {
    addLoading: boolean
    addError: ApolloError | undefined
    addData: CreateEventMutation | null | undefined
  }
  updateEvent(options: EventUpdateInput): void
  updateStates: {
    updateLoading: boolean
    updateError: ApolloError | undefined
    updateData: UpdateEventMutation | null | undefined
  }
  deleteEvent(id: bigint): void
  loading: boolean
  deleteStates: {
    deleteLoading: boolean
    deleteError: ApolloError | undefined
    deleteData: DeleteEventMutation | null | undefined
  }
  toggelFavourite(event: toggelFavouriteProps): void
  favouriteStates: {
    favouriteLoading: boolean
    favouriteError: ApolloError | undefined
  }
}

interface EventMutationCallbacks {
  onAdded?(event?: CreateEventMutation['insert_events_one']): void
  onUpdated?(event?: UpdateEventMutation['update_events_by_pk']): void
  onDeleted?(event?: DeleteEventMutation['delete_events_by_pk']): void
}

export function useEventMutations(
  callbacks?: EventMutationCallbacks
): UseEventReturn {
  const { t } = useTranslation()
  const { state: useState } = useMeContext()
  const currenUser = useState?.me

  /************/
  /* ADD EVENT */
  /************/
  const [addEvent, { loading: addLoading, error: addError, data: addData }] =
    useCreateEventMutation({
      update(cache, { data }) {
        const insert_events_one = data?.insert_events_one

        // event though the mutation returns the type and an id, the cache is not updated automatically
        // so we need to insert it manually to be visible in the list immediately
        cache.modify({
          fields: {
            events(existingEvents = []) {
              const newEvent = cache.writeFragment({
                data: insert_events_one,
                fragment: gql`
                  fragment NewEvent on Events {
                    id
                    name
                  }
                `,
              })
              return [...existingEvents, newEvent]
            },
          },
        })
      },
    })

  React.useEffect(() => {
    if (addData) {
      notification.success({
        duration: 3,
        message: t('events.actions.eventCreated'),
      })
      if (callbacks?.onAdded) callbacks.onAdded(addData.insert_events_one)
    }
  }, [addData])

  React.useEffect(() => {
    if (addError) {
      notification.error({
        duration: 3,
        message: t('events.actions.addError'),
      })
    }
  }, [addError])

  /***************/
  /* UPDATE EVENT */
  /***************/

  /* There are 2 Flavours of eventUpdate (with organisers & without organisers) */
  /* Simply call updateEvent(eventInput: EventUpdateInput) with eventOrganisers in the eventInput */
  /* If the eventInput contains eventOrganisers the updateQuery will be from useUpdateEventWithOrganisersMutation () */
  /* If the eventInput does not eventOrganisers the updateQuery will be from useUpdateEventMutation () */
  /* The difference is:
  /*  - "update" of many to many relation between events & users (delete & insert) transactional
  /*  - update Apollo cache after mutation, so the new orgnaisers are visible immediately
  /* see src/graphql/events/update-event.mutation.graphql for the difference */

  const [
    updateEventWithoutOrganisers,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useUpdateEventMutation({
    update(cache, { data }) {
      notification.success({
        duration: 3,
        message: t('events.actions.eventSaved'),
      })
      if (callbacks?.onUpdated) {
        const data = updateData ?? updateWithOrganisersData
        callbacks.onUpdated(data?.update_events_by_pk)
      }
    },
  })

  const [
    updateEventWithOrganisers,
    {
      loading: updateWithOrganisersLoading,
      error: updateWithOrganisersError,
      data: updateWithOrganisersData,
    },
  ] = useUpdateEventWithOrganisersMutation({
    update(cache, { data }) {
      const update_events_by_pk = data?.update_events_by_pk
      const modifiedEvent = {
        __typename: 'events',
        id: update_events_by_pk?.id,
      }
      cache.modify({
        id: cache.identify(modifiedEvent),
        fields: {
          eventOrganisers(cachedEventOrganisers) {
            if (update_events_by_pk?.eventOrganisers) {
              return update_events_by_pk?.eventOrganisers
            }
            return cachedEventOrganisers
          },
        },
        broadcast: true,
      })

      notification.success({
        duration: 3,
        message: t('events.actions.eventSaved'),
      })
      if (callbacks?.onUpdated) {
        const data = updateData ?? updateWithOrganisersData
        callbacks.onUpdated(data?.update_events_by_pk)
      }
    },
  })

  // React.useEffect(() => {

  //   if (updateData || updateWithOrganisersData) {
  //     notification.success({
  //       duration: 3,
  //       message: t('events.actions.eventSaved'),
  //     })
  //     if (callbacks?.onUpdated) {
  //       const data = updateData ?? updateWithOrganisersData
  //       callbacks.onUpdated(data?.update_events_by_pk)
  //     }
  //   }
  // }, [updateData, updateWithOrganisersData])

  function updateEvent(eventInput: EventUpdateInput) {
    const {
      eventOrganisers,
      eventAttributes,
      id: eventId,
      ...eventValues
    } = eventInput

    const eventAttributesWithEventId = eventAttributes?.map(attr => ({
      ...attr,
      eventId: eventId,
    }))

    const idArrayOfAttributeWithoutValue = eventAttributesWithEventId
      ?.filter(attr => {
        if (typeof attr.value.value === 'string') {
          if (!attr.value.value) {
            return true
          }
        }

        if (Array.isArray(attr.value.value)) {
          if (
            !attr.value.value.length ||
            attr.value.value.some((val: any) => val === undefined)
          ) {
            return true
          }
        }
        // TODO: empty number field has a value of 0
        // Check how to decide if this should be removed or if 0 is the real value
      })
      .map(a => a.eventAttributeId)

    // do note upsert empty values
    const eventAttributesToUpsert = eventAttributesWithEventId?.filter(
      attr => !idArrayOfAttributeWithoutValue?.includes(attr.eventAttributeId)
    )

    if (eventOrganisers) {
      updateEventWithOrganisers({
        variables: {
          id: eventId,
          _set: eventValues,
          organisers: eventOrganisers.map(o => ({ ...o, eventId: eventId })),
          eventAttributesToUpsert: eventAttributesToUpsert ?? [],
          eventAttributesToDelete: idArrayOfAttributeWithoutValue ?? [],
        },
      })
    } else {
      updateEventWithoutOrganisers({
        variables: {
          id: eventId,
          _set: eventValues,
          eventAttributesToUpsert: eventAttributesToUpsert ?? [],
          eventAttributesToDelete: idArrayOfAttributeWithoutValue ?? [],
        },
      })
    }
  }

  /***************/
  /* DELETE EVENT */
  /***************/
  const [
    deleteEvent,
    { loading: deleteLoading, error: deleteError, data: deleteData },
  ] = useDeleteEventMutation({
    update(cache, { data }) {
      const delete_events_by_pk = data?.delete_events_by_pk

      const deletedEvent = {
        __typename: 'events',
        id: delete_events_by_pk?.id,
      }

      cache.evict({ id: cache.identify(deletedEvent) })
    },
  })

  React.useEffect(() => {
    if (deleteData?.delete_events_by_pk?.id) {
      notification.success({
        duration: 3,
        message: t('events.actions.deleteSuccess'),
      })

      if (callbacks?.onDeleted) {
        callbacks.onDeleted(deleteData.delete_events_by_pk)
      }
    }
  }, [deleteData])

  function deleteEventById(id: bigint): void {
    if (!id) return
    deleteEvent({
      variables: {
        id: id,
      },
    })
  }

  /**********/
  /* TOOGLE FAVOURITE EVENT */
  /**********/

  // update return insert_eventFavourites_one can be null since (https://git.pave.de/orga/orga/-/issues/323)
  // so eventIdRef stores the  eventId that was used in the mutation to update the cache if the mutation return is null
  const eventIdRef = React.useRef<bigint | undefined>()
  const [
    setFavourite,
    {
      // data: setFavouriteData,
      loading: setFavouriteLoading,
      error: setFavouriteError,
    },
  ] = useSetEventFavouriteMutation({
    update(cache, { data }) {
      const insert_eventFavourites_one = data?.insert_eventFavourites_one

      const modifiedEvent = {
        __typename: 'events',
        id: insert_eventFavourites_one?.eventId || eventIdRef.current,
      }
      eventIdRef.current = undefined

      cache.modify({
        id: cache.identify(modifiedEvent),
        fields: {
          isFavourite(cachedIsFavourite) {
            return true
          },
        },
        broadcast: true,
      })
    },
  })

  const [
    unsetFavourite,
    {
      // data: unsetFavouriteData,
      loading: unsetFavouriteLoading,
      error: unsetFavouriteError,
    },
  ] = useUnsetEventFavouriteMutation({
    update(cache, { data }) {
      const delete_eventFavourites_by_pk = data?.delete_eventFavourites_by_pk
      const modifiedEvent = {
        __typename: 'events',
        id: delete_eventFavourites_by_pk?.eventId,
      }

      cache.modify({
        id: cache.identify(modifiedEvent),
        fields: {
          isFavourite(cachedIsFavourite) {
            return false
          },
        },
        broadcast: true,
      })
    },
  })

  function toggelFavourite(event: toggelFavouriteProps) {
    if (!event) return
    const { id, isFavourite } = event
    eventIdRef.current = id

    if (isFavourite) {
      unsetFavourite({
        variables: {
          eventId: id,
          userId: currenUser?.id,
        },
      })
    }

    if (!isFavourite) {
      setFavourite({
        variables: {
          eventId: id,
        },
      })
    }
  }

  /**********/
  /* ERRORS */
  /**********/
  React.useEffect(() => {
    if (
      addError ||
      deleteError ||
      setFavouriteError ||
      unsetFavouriteError ||
      updateError ||
      updateWithOrganisersError
    ) {
      notification.error({
        duration: 3,
        message:
          deleteError?.toString() ||
          addError?.toString() ||
          setFavouriteError?.toString() ||
          unsetFavouriteError?.toString() ||
          updateWithOrganisersError?.toString() ||
          updateError?.toString(),
      })
    }
  }, [
    addError,
    deleteError,
    updateError,
    setFavouriteError,
    unsetFavouriteError,
    updateWithOrganisersError,
  ])

  return {
    addEvent,
    addStates: {
      addLoading,
      addError,
      addData,
    },
    updateEvent,
    updateStates: {
      updateLoading: updateLoading || updateWithOrganisersLoading,
      updateError: updateError || updateWithOrganisersError,
      updateData: updateData || updateWithOrganisersData,
    },
    deleteEvent: deleteEventById,
    deleteStates: {
      deleteLoading,
      deleteError,
      deleteData,
    },

    loading:
      addLoading ||
      updateLoading ||
      updateWithOrganisersLoading ||
      deleteLoading ||
      setFavouriteLoading ||
      unsetFavouriteLoading
        ? true
        : false,
    toggelFavourite,
    favouriteStates: {
      favouriteLoading: setFavouriteLoading || unsetFavouriteLoading,
      favouriteError: setFavouriteError || unsetFavouriteError,
    },
  }
}
