import React from 'react'
import { Badge } from '../badge'
import { Button } from '../button'
import { Icon } from '../icon'
import { BadgeProps } from 'antd'

import IconBell from '@organice/icons/bell.svg?react'
import IconBellRinging from '@organice/icons/bell-ringing.svg?react'
import styled from '@emotion/styled'

interface NotificationBellProps extends BadgeProps {
  active?: boolean
}

export const NotificationBell: React.FC<NotificationBellProps> = ({
  active,
  ...rest
}) => {
  const defaultProps = { overflowCount: 10, offset: [-8, 3] }
  return (
    <Badge {...Object.assign(defaultProps, rest)}>
      <BellButton
        type="text"
        className={rest.count ? 'active' : ''}
        icon={<Icon component={rest.count ? IconBellRinging : IconBell} />}
      />
    </Badge>
  )
}

export const BellButton = styled(Button)(({ theme }) => ({
  svg: {
    fontSize: '22px',
    marginTop: '4px',
  },
  '[class$="path"]': {
    fill: theme.iconBaseColor,
  },
  '[class$="shape"]': {
    fill: theme.iconShadeColor,
  },
  '&.active': {
    '[class$="path"]': {
      fill: theme.iconActiveBaseColor,
    },
    '[class$="shape"]': {
      fill: theme.iconActiveShadeColor,
    },
  },
  background: 'transparent!important',
  border: 'none!important',
}))
