import styled from '@emotion/styled'
import { useMeContext } from '@organice/contexts'
import { Alert } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const usePermissions = () => {
  const {
    state: { isAdmin, isNM, me },
  } = useMeContext()

  const currentUsersGroups = me?.organisationGroups

  function belongsToGroup(group: { name?: string }) {
    const { name } = group
    return currentUsersGroups?.some(g => g.name === name)
  }

  return { belongsToGroup, NoPermissionDisplay }
}

const NoPermissionDisplay: React.FC = () => {
  const { t } = useTranslation()
  return (
    <NoPermissionsWrapper>
      <Alert
        className="permission-error"
        message={t('common.error.permissionError')}
        description={t('common.error.permissionErrorMessage')}
        type="error"
        showIcon
      />
    </NoPermissionsWrapper>
  )
}

const NoPermissionsWrapper = styled.div(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem',
  '.permission-error': {
    width: '100%',
    maxWidth: 980,
  },
}))
