import styled from '@emotion/styled'
import { Notifications } from '../../molecules/notifications'
import { AccountMenu } from '../../molecules/accountMenu'
import { Menu } from '../../molecules/menu'
import React from 'react'
import Icon from '@ant-design/icons'
import { Drawer } from 'antd'
import { Button, NotificationBell, OrgaNiceLogo, MenuItem } from '../../atoms' //'@organice/atoms'
import { Menu as AntdMenu } from 'antd'
import linesPattern from 'assets/svg/lines_pattern.svg'
import IconUser from '@organice/icons/user_profile.svg?react'

import { Breadcrumbs } from '../../organisms/breadcrumbs' //'@organice/organisms'
import { Link } from 'react-router-dom'
import { Popover } from 'antd'
import { routesMenu } from './../../../router/util'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMeContext } from '@organice/contexts'
import { useHistory } from 'react-router'
import {
  useNotifications,
  useEventCreationHack,
} from '@organice/utils/hooks/useNotifications'
import { useResponsive } from '@organice/utils/hooks/useResponsive'
import { useTheme } from '@emotion/react'
import { useHeader } from './useHeader'
import { notification } from 'antd'
import {
  MenuOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { useSwipeable } from 'react-swipeable'
import { HEADER_HEIGHT_PX } from '@organice/molecules/sidebar'

export interface HeaderProps {
  renderRight?(): React.ReactNode
  hideNavigation?: boolean
  hideBreadCrumb?: boolean
  noHeader?: boolean
  stickyHeaderTop?: boolean
}

export const Header: React.FC<HeaderProps> = props => {
  const { headerProps } = useHeader()
  const notifications = useNotifications({ notificationsPerPage: 10 })

  const { notificationsTotal } = notifications

  const { pathname } = useLocation()
  const { t } = useTranslation()
  const currentPath = pathname?.match(/^\/([^/]*).*/)?.[1] || ''
  const authState = useMeContext()
  const {
    state: { me, isAdmin, isOrgaAdmin, loading: authLoading },
  } = authState
  const history = useHistory()

  const theme = useTheme()
  const { isMobileOrTablet } = useResponsive()

  // eslint-disable-next-line no-empty-pattern
  // const {} = useEventCreationHack()

  const { logout: logoutMutation } = useMeContext()
  // const [logoutMutation] = useLogoutMutation()

  const logout = async () => {
    const logoutSuccess = await logoutMutation()

    if (logoutSuccess === true) {
      history.push('/login')
      return //history.go(0)
    } else {
      notification.error({
        duration: 3,
        message: t('logout.error'),
      })
    }
  }

  const renderRight = props.renderRight || headerProps?.renderRight
  const hideNavigation = props.hideNavigation || headerProps?.hideNavigation
  const hideBreadCrumb = props.hideBreadCrumb || headerProps?.hideBreadCrumb
  const noHeader = props.noHeader || headerProps?.noHeader
  const stickyHeaderTop = props.stickyHeaderTop || headerProps?.stickyHeaderTop

  const headerBottomRightRef = React.useRef<HTMLDivElement>(null)

  const [notificationsOpen, setNotificationsOpen] =
    React.useState<boolean>(false)

  const [menuOpen, setMenuOpen] = React.useState(false)
  const menuHandlers = useSwipeable({
    onSwiped: eventData => {
      if (eventData.deltaX > 50) {
        setMenuOpen(false)
      }
    },
  })

  const notificationHandlers = useSwipeable({
    onSwiped: eventData => {
      if (eventData.deltaX > 50) {
        setNotificationsOpen(false)
      }
    },
  })

  React.useEffect(() => {
    setMenuOpen(false)
    setNotificationsOpen(false)
  }, [pathname])

  if (noHeader) return null

  if (isMobileOrTablet) {
    return (
      <>
        <HeaderTop stickyHeaderTop={stickyHeaderTop}>
          <OrgaNiceLogo
            factor={0.66}
            style={{ marginBottom: '-8px', cursor: 'pointer' }}
            onClick={() => history.push('/')}
          />
          <div>
            <MobileBellButton onClick={() => setNotificationsOpen(true)}>
              <NotificationBell
                active={currentPath === 'notifications'}
                count={notificationsTotal}
              />
            </MobileBellButton>
            <MenuOutlined
              onClick={() => setMenuOpen(!menuOpen)}
              style={{ fontSize: 20 }}
            />
          </div>
        </HeaderTop>
        <HeaderBottom stickyHeaderTop={stickyHeaderTop}>
          <HeaderBottomLeft>
            <BreadcrumbHolder
              hideBreadCrumb={hideBreadCrumb || false}
              maxWidth={
                headerBottomRightRef.current
                  ? headerBottomRightRef.current.getBoundingClientRect().width
                  : 0
              }
            >
              <Breadcrumbs />
            </BreadcrumbHolder>
          </HeaderBottomLeft>
          <HeaderBottomRight
            ref={headerBottomRightRef}
            className="headerBottomRight"
          >
            {renderRight && renderRight()}
          </HeaderBottomRight>
        </HeaderBottom>
        <Drawer
          destroyOnClose={true}
          closable={false}
          placement="right"
          width={'90vw'}
          contentWrapperStyle={{ maxWidth: 400 }}
          onClose={() => {
            setNotificationsOpen(false)
          }}
          open={notificationsOpen}
        >
          <div {...notificationHandlers} style={{ height: '100%' }}>
            <Notifications
              visible={notificationsOpen}
              notifications={notifications}
            />
          </div>
        </Drawer>
        <Drawer
          destroyOnClose={true}
          closable={false}
          placement="right"
          bodyStyle={{ padding: 0, margin: 0 }}
          width={'90vw'}
          contentWrapperStyle={{ maxWidth: 400 }}
          onClose={() => {
            setMenuOpen(false)
          }}
          open={menuOpen}
        >
          <MobileMenu className="mobi">
            <div
              {...menuHandlers}
              style={{
                height: '100%',
              }}
            >
              <AntdMenu
                mode="inline"
                selectedKeys={[`/${currentPath}`]}
                style={{
                  backgroundColor: theme.lightGreyBackground,
                  alignSelf: 'flex-end',
                }}
              >
                <AntdMenu.SubMenu
                  key="accountSubMenu"
                  title={`${me?.firstName} ${me?.lastName}`}
                  icon={<Icon component={IconUser} />}
                >
                  <AntdMenu.Item key="account">
                    <Link to={'/account'}>
                      <SettingOutlined
                        style={{ fontSize: 20, color: '#b1b1b1' }}
                      />{' '}
                      {t('account.account')}
                    </Link>
                  </AntdMenu.Item>

                  <AntdMenu.Item onClick={logout} key="logout">
                    <LogoutOutlined
                      style={{ fontSize: 20, color: '#b1b1b1' }}
                    />{' '}
                    {t('account.logout')}
                  </AntdMenu.Item>
                </AntdMenu.SubMenu>
              </AntdMenu>
              <DividerMobile />

              <AntdMenu mode="inline" selectedKeys={[`/${currentPath}`]}>
                {routesMenu
                  .filter(route => {
                    // Only Admin
                    if (route.adminOnly && !isAdmin) {
                      return false
                    }
                    // Only Admin and OrgaAdmin
                    if (route.orgaAdminOnly && !isOrgaAdmin && !isAdmin) {
                      return false
                    }
                    return true
                  })

                  .map(({ path, title, iconMenu }) => (
                    <AntdMenu.Item key={path} icon={iconMenu}>
                      <Link to={path}>{t(`${title}`)}</Link>
                    </AntdMenu.Item>
                  ))}
              </AntdMenu>

              <Divider />
            </div>
          </MobileMenu>
        </Drawer>
      </>
    )
  }

  return (
    <>
      <HeaderTop stickyHeaderTop={stickyHeaderTop}>
        <OrgaNiceLogo
          // factor={0.66}
          style={{ marginBottom: '-8px', cursor: 'pointer' }}
          onClick={() => history.push('/')}
        />
        {authLoading ? null : (
          <>
            {!hideNavigation && (
              <HeaderMenu>
                {routesMenu && (
                  <Menu mode="horizontal" selectedKeys={[`/${currentPath}`]}>
                    {routesMenu
                      .filter(route => {
                        // Only Admin
                        if (route.adminOnly && !isAdmin) {
                          return false
                        }
                        // Only Admin and OrgaAdmin
                        if (route.orgaAdminOnly && !isOrgaAdmin && !isAdmin) {
                          return false
                        }
                        return true
                      })

                      .map(({ path, title, iconMenu }) => (
                        <MenuItem key={path} icon={iconMenu}>
                          <Link to={path}>{t(`${title}`)}</Link>
                        </MenuItem>
                      ))}
                  </Menu>
                )}
                <Divider />
                <SecondaryMenu>
                  <Popover content={AccountMenu}>
                    <Link to="/account">
                      <AccountButton
                        className={`${currentPath === 'account' && 'active'} `}
                        type="text"
                        icon={<Icon component={IconUser} />}
                      />
                      {`${me?.firstName} ${me?.lastName}`}
                    </Link>
                  </Popover>
                </SecondaryMenu>
                <SecondaryMenu>
                  <Popover
                    placement="bottomRight"
                    content={
                      <Notifications
                        visible={notificationsOpen}
                        notifications={notifications}
                      />
                    }
                    open={notificationsOpen}
                    onOpenChange={setNotificationsOpen}
                    destroyTooltipOnHide={true}
                    overlayInnerStyle={{
                      maxHeight: 'calc(100vh - 80px)',
                      overflow: 'scroll',
                    }}
                    // title={t('notifications.title')}
                  >
                    <NotificationBell
                      active={currentPath === 'notifications'}
                      count={notificationsTotal}
                      style={{ backgroundColor: theme.primaryColor }}
                    />
                  </Popover>
                </SecondaryMenu>
              </HeaderMenu>
            )}
          </>
        )}
      </HeaderTop>
      <HeaderBottom stickyHeaderTop={stickyHeaderTop}>
        <HeaderBottomLeft>
          <BreadcrumbHolder
            hideBreadCrumb={hideBreadCrumb || false}
            maxWidth={
              headerBottomRightRef.current
                ? headerBottomRightRef.current.getBoundingClientRect().width
                : 0
            }
          >
            <Breadcrumbs />
          </BreadcrumbHolder>
        </HeaderBottomLeft>
        <HeaderBottomRight
          ref={headerBottomRightRef}
          className="headerBottomRight"
        >
          {renderRight && renderRight()}
        </HeaderBottomRight>
      </HeaderBottom>
    </>
  )
}

const HeaderTop = styled.div<{
  stickyHeaderTop?: boolean
}>(({ theme, stickyHeaderTop }) => ({
  display: 'flex',
  height: '80px',
  width: '100%',
  padding: '0 40px 0 30px',
  verticalAlign: 'top',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#fff',
  [theme.mq.screenMdMin]: {
    ...(stickyHeaderTop
      ? {
          position: 'sticky',
          top: 0,
          zIndex: 101,
        }
      : {}),
  },
}))

const HeaderBottom = styled.div<{
  stickyHeaderTop?: boolean
}>(({ theme, stickyHeaderTop }) => ({
  //borderTop: `1px solid ${theme.primaryColor}`,
  boxShadow: `inset 0 1px 0px ${theme.primaryColor}`,
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
  padding: '10px 0',
  backgroundImage: `url("${linesPattern}")`,
  backgroundColor: 'white',
  // position: 'sticky',
  top: stickyHeaderTop ? HEADER_HEIGHT_PX : 0,
  zIndex: 100,
  [theme.mq.screenMdMin]: {
    position: 'sticky',
  },
}))

const HeaderBottomLeft = styled.div(({ theme }) => ({
  // flexGrow: 1,
  paddingLeft: '20px',
}))

const HeaderBottomRight = styled.div(({ theme }) => ({
  // flex: 1,
  flexGrow: 1,
  paddingRight: '40px',
  justifyContent: 'flex-end',
  gap: '.625rem',
  [`@media only screen and (max-width: ${theme.screenMd})`]: {
    padding: '0 20px',
    justifyContent: 'flex-start',
  },
}))

const BreadcrumbHolder = styled.div<{
  hideBreadCrumb: boolean
  maxWidth: number
}>(({ theme, hideBreadCrumb, maxWidth }) => ({
  pointerEvents: hideBreadCrumb ? 'none' : 'inherit',
  backgroundColor: theme.componentBackground,
  display: 'inline-flex',
  padding: '5px 10px',
  marginRight: '.625rem',
  //height: 32,
  overflow: 'hidden',
  '> .ant-breadcrumb': {
    lineHeight: '20px',
    '> span': {
      color: theme.grey2,
      '&:last-child': {
        fontWeight: 700,
      },
    },
    '& a': {
      color: theme.grey2,
      '&:hover': {
        color: theme.primaryColor,
      },
    },
  },
}))

const MobileBellButton = styled.div(({ theme }) => ({
  display: 'inline-block',
  margin: '0 25px',
  position: 'relative',
  top: 1,
}))

const HeaderMenu = styled.div(() => ({
  display: 'flex',
  gap: '10px',
  height: '40px',
}))

const Divider = styled.div(({ theme }) => ({
  borderLeft: `1px solid ${theme.grey6}`,
  margin: '0 20px 0 0',
}))
const SecondaryMenu = styled.header(() => ({
  display: 'flex',
  fontSize: '15px',
  fontWeight: 500,
  alignItems: 'center',

  '& > a': {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: '0 5px',
    },
  },
}))

const MobileMenu = styled.div(({ theme }) => ({
  'li, li a': {
    fontSize: '1rem',
  },
  '.anticon': {
    fontSize: 24,
  },
}))

const DividerMobile = styled.div<{ marginBottom?: number }>(
  ({ theme, marginBottom = 0 }) => ({
    borderBottom: `1px solid ${theme.grey6}`,
    margin: `0 0 ${marginBottom}px 0`,
  })
)

export const AccountButton = styled(Button)(({ theme }) => ({
  svg: {
    fontSize: '32px',
    marginLeft: '-1px',
    marginTop: '-1px',
  },
  '[class$="path"]': {
    fill: theme.iconBaseColor,
  },
  '[class$="shape"]': {
    fill: theme.iconShadeColor,
  },
  '&.active': {
    '[class$="path"]': {
      fill: theme.iconActiveBaseColor,
    },
    '[class$="shape"]': {
      fill: theme.iconActiveShadeColor,
    },
  },
}))
