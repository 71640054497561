import { TimeUtil } from '@organice/utils/date'
const dayjs = TimeUtil.getDayjs()

export interface MayHaveId {
  id: string
}
export function convertToArrayOfIds<T extends MayHaveId>(data: T[]): string[] {
  return data?.map(e => e.id) || []
}

export function convertTimstampToBoolean(
  data: string | boolean
): boolean | string | null {
  if (typeof data === 'boolean') {
    if (data === true) {
      return dayjs.utc().format()
    }
    return null
  }

  if (typeof data === 'undefined') {
    return null
  }
  return !!data
}
