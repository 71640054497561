import React from 'react'
import {
  IsPublicAttributeValueCommentMutation,
  useIsPublicAttributeValueCommentMutation,
  useIsPublicUploadCommentMutation,
  IsPublicUploadCommentMutation,
  useIsPublicRoomAttributeValueCommentMutation,
  IsPublicRoomAttributeValueCommentMutation,
} from '@organice/graphql'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'
import { ApolloError } from '@apollo/client'
import { CommentTypes_Enum } from '@organice/contexts/comments/commentsContext'

type UseIsPublicCommentReturn = {
  setPublicComment(id: string): void
  setPrivateComment(id: string, organisationId: number): void
  data:
    | IsPublicAttributeValueCommentMutation['update_attributeValueComments_by_pk']
    | IsPublicUploadCommentMutation['update_uploadComments_by_pk']
    | IsPublicRoomAttributeValueCommentMutation['update_roomAttributeValueComments_by_pk']
    | null
    | undefined
  loading: boolean
  error: ApolloError | undefined
  success: boolean
}

type Options = {
  commentType: CommentTypes_Enum
}

export function useIsPublicComment(options: Options): UseIsPublicCommentReturn {
  const { commentType } = options
  const { t } = useTranslation()

  const [
    isPublicAttributeValueCommentById,
    {
      data: attributeValueCommentData,
      loading: attributeValueCommentLoading,
      error: attributeValueCommentError,
    },
  ] = useIsPublicAttributeValueCommentMutation()

  const [
    isPublicUploadCommentById,
    {
      data: uploadCommentData,
      loading: uploaCommentLoading,
      error: uploaCommentError,
    },
  ] = useIsPublicUploadCommentMutation()

  const [
    isPublicRoomAttributeValueCommentById,
    {
      data: roomAttributeValueCommentData,
      loading: roomAttributeValueCommentLoading,
      error: roomAttributeValueCommentError,
    },
  ] = useIsPublicRoomAttributeValueCommentMutation()

  const data =
    attributeValueCommentData?.update_attributeValueComments_by_pk ||
    uploadCommentData?.update_uploadComments_by_pk ||
    roomAttributeValueCommentData?.update_roomAttributeValueComments_by_pk
  const loading =
    attributeValueCommentLoading ||
    uploaCommentLoading ||
    roomAttributeValueCommentLoading
  const error =
    attributeValueCommentError ||
    uploaCommentError ||
    roomAttributeValueCommentError

  React.useEffect(() => {
    if (data?.id) {
      notification.success({
        duration: 3,
        message: t(
          !data?.organisationId
            ? 'comment.actions.makePublicSuccess'
            : 'comment.actions.makePrivateSuccess'
        ),
      })
    }
  }, [data])

  React.useEffect(() => {
    if (error) {
      notification.error({
        duration: 3,
        message: t('comment.actions.isPublicError'),
      })
    }
  }, [error])

  function setPublicComment(id: string) {
    if (!id) return
    switch (commentType) {
      case CommentTypes_Enum.AttribtueValueComment:
        return isPublicAttributeValueCommentById({
          variables: {
            id: id,
            organisationId: null,
          },
        })

      case CommentTypes_Enum.UploadComment:
        return isPublicUploadCommentById({
          variables: {
            id: id,
            organisationId: null,
          },
        })
      case CommentTypes_Enum.RoomAttributeValueComment:
        return isPublicRoomAttributeValueCommentById({
          variables: {
            id: id,
            organisationId: null,
          },
        })
      default:
        console.warn(
          `useIsPublicComment(): No Mutation for Comment Type "${commentType}"`
        )
    }
  }

  function setPrivateComment(id: string, organisationId: number) {
    if (!id) return
    switch (commentType) {
      case CommentTypes_Enum.AttribtueValueComment:
        return isPublicAttributeValueCommentById({
          variables: {
            id: id,
            organisationId,
          },
        })
      case CommentTypes_Enum.UploadComment:
        return isPublicUploadCommentById({
          variables: {
            id: id,
            organisationId,
          },
        })
      case CommentTypes_Enum.RoomAttributeValueComment:
        return isPublicRoomAttributeValueCommentById({
          variables: {
            id: id,
            organisationId,
          },
        })
      default:
        console.warn(
          `useIsPublicComment(): No Mutation for Comment Type "${commentType}"`
        )
    }
  }

  return {
    setPublicComment,
    setPrivateComment,
    data,
    loading,
    error,
    success: !!data?.id,
  }
}
