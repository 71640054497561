import React from 'react'
import styled from '@emotion/styled'
import { Button } from '../button'
import { CommentOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

import { useCommentsContext } from '@organice/contexts/comments'
import { GeneralDict } from '@organice/utils/form'
import { useTranslation } from 'react-i18next'
import {
  CommentTypes_Enum,
  AllCommentGraphQLTypes as Comments,
} from '@organice/contexts/comments/commentsContext'

export interface CommentIconProps {
  type: CommentTypes_Enum
  id: Pick<Comments, 'id'> | null
  count?: number
  title?: string
  description?: string | null
  additionalData?: GeneralDict | null
  open?: number
  style?: React.CSSProperties
  isInactive?: boolean
  inactiveToolTipMessage?: string
  guestCanRead?: boolean
}
export const CommentIcon: React.FC<CommentIconProps> = ({
  count,
  type,
  id,
  title,
  description,
  additionalData = null,
  open,
  style,
  isInactive = false,
  inactiveToolTipMessage,
  guestCanRead,
}) => {
  const { dispatch } = useCommentsContext()

  const [currentCount, setCurrentCount] = React.useState(count)
  const { t } = useTranslation()

  const openComment = () =>
    dispatch({
      type: 'OPEN_COMMENTS',
      state: {
        id,
        type,
        title,
        description,
        additionalData,
        highlight: open,
        guestCanRead,
        onUpdateCount: count => setCurrentCount(count),
      },
    })
  // DETECT IS OPEN

  React.useEffect(() => {
    if (open) {
      openComment()
    }
  }, [open])

  const isNewAttributeValueGroup = Array.isArray(id)
  const disableComments = isInactive || isNewAttributeValueGroup

  return (
    <>
      {disableComments ? (
        <Tooltip
          title={
            inactiveToolTipMessage
              ? t(inactiveToolTipMessage)
              : t('comment.deactivatedBecauseNewValueGroup')
          }
        >
          {/* The div is necessary, because antD Tooltip does not work on disbaled Buttons */}
          {/* See: https://github.com/ant-design/ant-design/issues/9581#issuecomment-605200095 */}
          <div>
            <StyledButton
              disabled={true}
              icon={<CommentOutlined />}
              style={style}
            >
              <div>
                {currentCount || 0} {open ? 'OPEN' : ''}
              </div>
            </StyledButton>
          </div>
        </Tooltip>
      ) : (
        <StyledButton
          disabled={isNewAttributeValueGroup}
          icon={<CommentOutlined />}
          onClick={openComment}
          style={style}
        >
          <div>
            {currentCount || 0} {open ? 'OPEN' : ''}
          </div>
        </StyledButton>
      )}
    </>
  )
}

const StyledButton = styled(Button)`
  padding: 0px;
  height: auto;
  line-height: 1;
  display: flex;
  align-items: center;

  &:disabled {
    pointer-events: none;
  }

  span {
    border-right: 1px solid #d9dadb;
    padding: 2px;
  }
  div {
    padding: 2px;
    min-width: 23px;
  }
`
