import React from 'react'
import styled from '@emotion/styled'
import { CustomTimeInput } from '@organice/molecules/custom-time-input'
import { DatePickerAutoConfirm } from '../datepicker'
import { TimeUtil } from '@organice/utils/date/time'
import { useTranslation } from 'react-i18next'
import { PickerProps } from 'antd/es/date-picker/generatePicker'
import { Dayjs } from 'dayjs'
import { valuesAreEqual } from '@organice/utils/date/date-values-equal'

type DateValue = Dayjs | undefined | null

type DateWithTimeRangeProps<T = Omit<PickerProps<Dayjs>, 'value'>> = T & {
  autoConfirm?: boolean
  showTime?: boolean
  format?: string
  inputFormat?: string
  displayFormat?: string
  parentRange?: [Dayjs, Dayjs]
  minuteStep?: number
  onChange?: (values: [DateValue, DateValue, DateValue]) => void
  value?: DateValue[]
  defaultValue?: Dayjs[]
}
const dayjs = TimeUtil.getDayjs()

export const DateWithTimeRange: React.FC<DateWithTimeRangeProps> = props => {
  const {
    inputFormat,
    displayFormat,
    defaultPickerValue,
    parentRange,
    value,
    defaultValue,
  } = props
  const { t } = useTranslation()

  const [initialized, setInitialized] = React.useState<boolean>(false)
  const [state, setState] = React.useState<
    [DateValue, DateValue, DateValue] | undefined
  >()

  const datePickerRef = React.useRef<any>(null)
  const startTimePickerRef = React.useRef<any>(null)

  // map [Date, Date] to internal [Date, Date, Date]
  React.useEffect(() => {
    if (Array.isArray(value)) {
      if (!valuesAreEqual(value, state)) {
        switch (value.length) {
          case 0:
            setState([undefined, undefined, undefined])
            break
          case 1:
            setState([value[0], value[0], undefined])
            break
          case 2:
            setState([value[0], value[0], value[1]])
            break
          default:
            setState([value[0], value[1], value[2]])
            break
        }
      }
      return
    }
    if (state === undefined && defaultValue) {
      setState([defaultValue[0], defaultValue[1], defaultValue[2]])
    }
  }, [value])

  React.useEffect(() => {
    if (state) {
      if (initialized) {
        props.onChange && props.onChange(state)
      } else {
        setTimeout(() => setInitialized(true), 0)
      }
    }
  }, [state, initialized])

  // if (!state) return null

  return (
    <DateTimeRangeContainer>
      <StyledDatePickerAutoConfirm
        ref={datePickerRef}
        inputFormat={inputFormat}
        displayFormat={displayFormat}
        defaultPickerValue={defaultPickerValue}
        parentRange={parentRange}
        value={state?.[0]}
        onChange={value => {
          const time = state?.[1] || dayjs().hour(0).minute(0)
          setState([
            value?.hour(time.hour()).minute(time.minute()),
            state?.[1],
            state?.[2],
          ])
          if (!state?.[1]) {
            startTimePickerRef.current?.focus()
          }
        }}
      />
      <CustomTimeInput
        ref={startTimePickerRef}
        placeholder={t('service.attribute.date.start')}
        value={state?.[1] || undefined}
        onChange={value => {
          if (value) {
            const date = state?.[0]
            const time = value
            setState([
              date ? date.hour(time.hour()).minute(time.minute()) : undefined,
              date ? date.hour(time.hour()).minute(time.minute()) : time,
              state?.[2],
            ])
          }
        }}
      />
      <CustomTimeInput
        // ref={endTimePickerRef}
        placeholder={t('service.attribute.date.end')}
        value={state?.[2] || undefined}
        onChange={value => {
          if (value) {
            const date = state?.[0]
            const time = value
            setState([
              state?.[0],
              state?.[1],
              date ? date.hour(time.hour()).minute(time.minute()) : time,
            ])
          }
        }}
      />
    </DateTimeRangeContainer>
  )
}

const DateTimeRangeContainer = styled.div({
  display: 'flex',
  width: '100%',
  '& > div:not(:last-of-type)': {
    marginRight: '1rem',
  },
})

const StyledDatePickerAutoConfirm = styled(DatePickerAutoConfirm)({
  '&.is-focused, &.ant-picker-focused': {
    border: 'none',
  },
})
