import React from 'react'
import styled from '@emotion/styled'
import { PlusOutlined } from '@ant-design/icons'
import { Tag, Input, AutoComplete, Space, Tooltip, notification } from 'antd'
import { useServicesTagsLazyQuery } from '@organice/graphql'
import { EnterOutlined, CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Global } from '@emotion/react'
import { tagCategories, TagCategories } from './tag-categories'
import { TagTypeSelector } from './tag-type-selector'
import { diff } from 'deep-diff'

interface TagsProps {
  value?: (string | StringTag | TagWithCategory)[]
  readOnly?: boolean
  onChange?(tags: (string | StringTag | TagWithCategory)[]): void
  direction?: 'vertical' | 'horizontal'
  maxPerCategory?: number
}

export const Tags: React.FC<TagsProps> = ({
  value,
  readOnly,
  onChange,
  direction = 'horizontal',
  maxPerCategory,
}) => {
  const { t } = useTranslation()
  const [fetchTags, { data: tagSuggestions }] = useServicesTagsLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  const saveInputRef = React.useRef<Input>(null)
  const [suggestions, setSuggestions] = React.useState<string[]>([])
  const [options, setOptions] = React.useState<(StringTag | TagWithCategory)[]>(
    []
  )
  const [tags, setTags] = React.useState(value || [])
  const [tagCategory, setTagCategory] = React.useState<
    (typeof tagCategories)[0]
  >(tagCategories[0])
  const [addVisible, setAddVisible] = React.useState(false)
  const [isFocused, setIsFocused] = React.useState<boolean>(false)

  const tagByCategory = React.useMemo(() => {
    const tbt: Record<TagCategories, (string | StringTag | TagWithCategory)[]> =
      {
        [TagCategories.Uncategorized]: [],
        [TagCategories.Edit]: [],
        [TagCategories.Order]: [],
        [TagCategories.Service]: [],
        [TagCategories.Information]: [],
      }

    tags.forEach(t => {
      if (typeof t === 'object' && (t as TagWithCategory)?.category) {
        const tagWitCat = t as TagWithCategory
        const existingCategory = tagCategories.find(
          tagCategory => tagCategory.category === tagWitCat?.category
        )?.category
        if (existingCategory) {
          tbt[existingCategory || TagCategories.Uncategorized].push(t)
        } else {
          tbt[TagCategories.Uncategorized].push(t)
        }
      }
      if (typeof t === 'string') {
        tbt[TagCategories.Uncategorized].push(t)
      }
    })

    return tbt
  }, [tags])

  React.useEffect(() => {
    if (tagSuggestions?.serviceTags) {
      const flattenedTags = tagSuggestions?.serviceTags
        .map(tag => tag.value)
        .filter(Boolean) as string[]

      setSuggestions(Array.from(new Set(flattenedTags)))
    }
  }, [tagSuggestions, tags, tagCategory])

  const handleSearch = (value: string) => {
    const tagStringsInCurrentCategory = tagByCategory[tagCategory.category].map(
      tag => (typeof tag === 'string' ? tag : tag.value)
    )

    setOptions(
      suggestions
        .filter((sug: string | TagWithCategory) => {
          const suggesstionString = typeof sug === 'string' ? sug : sug.value

          if (tagStringsInCurrentCategory.includes(suggesstionString))
            return false

          return suggesstionString
            .toString()
            .toLowerCase()
            .match(value.toLowerCase())
        })
        .map(matched => ({
          value: matched,
        }))
    )
  }

  const [newTagInputValue, setNewTagInputValue] = React.useState<
    string | undefined
  >(undefined)
  const onSelect = (value: string) => {
    setNewTagInputValue(value)
  }

  const addTag = (tag: TagWithCategory) => {
    /*     const val = saveInputRef.current?.state.value || ''
     */ if (tag.value.trim() !== '') {
      if (tags.every(addedTag => diff(addedTag, tag))) {
        const newTags = [...tags, tag]
        setTags(newTags)
        onChange && onChange(newTags)
        setAddVisible(false)
      } else {
        const categoryName =
          (Array.isArray(tagCategories) &&
            tagCategories?.find(c => c.category === tag.category)?.name) ||
          undefined

        if (!categoryName) {
          notification.warn({
            message: t('tags.labelAlreadyPresent', {
              label: tag.value,
            }),
          })
          return
        }

        notification.warn({
          message: t('tags.labelAlreadyPresentWithCategory', {
            label: tag.value,
            categoryName: t(categoryName),
          }),
        })
      }
    }
  }

  const removeTag = (tag: string | StringTag | TagWithCategory) => {
    const newTags = tags.filter(t => {
      if (typeof tag === 'string') return t !== tag
      if (typeof tag === 'object') {
        if (typeof t === 'string') return true

        if (
          (t as TagWithCategory).value == (tag as TagWithCategory).value &&
          (t as TagWithCategory).category == (tag as TagWithCategory).category
        ) {
          return false
        }
      }

      return true
    })

    setTags(newTags)
    onChange && onChange(newTags)
  }

  React.useEffect(() => {
    if (value) setTags(value)
  }, [value])

  React.useEffect(() => {
    !readOnly && fetchTags()
  }, [])

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setAddVisible(false)
        setIsFocused(false)
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  function getTagsList(
    tags: (string | TagWithCategory | StringTag)[],
    options: {
      tagCategory: (typeof tagCategories)[0]
      max?: number
    }
  ) {
    const { tagCategory, max } = options || {}
    const moreTags = max && tags.length - max > 0 && tags.length - max
    return (
      <>
        {(max ? tags.slice(0, max) : tags).map(tag => (
          <Tag
            className="edit-tag"
            key={JSON.stringify(tag)} // Important: Keys need to include the category (if exists) to be unique -> without it, it can lead to strange remove behaviour
            closable={!readOnly}
            onClose={e => {
              removeTag(tag)
            }}
            color={tagCategory.color || undefined}
          >
            <span>{typeof tag === 'string' ? tag : tag.value}</span>
          </Tag>
        ))}
        {moreTags ? (
          <Tooltip
            trigger={'click'}
            title={tags
              .slice(max, tags.length)
              .map(tag => (typeof tag === 'string' ? tag : tag.value))
              .join(', ')}
          >
            <Tag className="more-tags">
              {t(moreTags > 1 ? 'tags.multipleMoreTags' : 'tags.oneMoreTag', {
                count: max && tags.length - max,
              })}
            </Tag>
          </Tooltip>
        ) : null}
      </>
    )
  }

  return (
    <TagsContainer>
      <Global
        styles={{
          '.tagCategorySelector .ant-popover-inner-content': {
            padding: 0,
          },
        }}
      />
      <Space direction={direction} wrap style={{ display: 'flex' }}>
        {tagCategories.map(tc => {
          if (tagByCategory?.[tc.category].length === 0) return null
          return (
            <TagCategoryWrapper key={tc.category}>
              <Tooltip title={t(tc.name)}>{tc.icon}</Tooltip>
              <TagCategoryTagsWrapper key={tc.category} wrap>
                {getTagsList(tagByCategory?.[tc.category], {
                  tagCategory: tc,
                  max: maxPerCategory,
                })}
              </TagCategoryTagsWrapper>
            </TagCategoryWrapper>
          )
        })}
      </Space>
      <NewTagWrapper>
        {!readOnly &&
          (addVisible ? (
            <NewTag>
              <TagTypeSelector
                className="tagTypSelect"
                tagCategory={tagCategory}
                onTagSelect={tagCategory => {
                  setTagCategory(tagCategory)
                  saveInputRef.current?.focus()
                }}
                isFocused={isFocused}
              />
              <AutoComplete
                options={options}
                onSearch={handleSearch}
                onSelect={onSelect}
                autoFocus
              >
                <TagInput
                  ref={saveInputRef}
                  type="text"
                  onFocus={() => setIsFocused(true)}
                  onBlur={e => {
                    setIsFocused(false)
                  }}
                  onPressEnter={() => {
                    // onSelect from autocomplete fires first, so wait 1ms to get the value from a possible selection from suggestions

                    setTimeout(() => {
                      const tagEntry = {
                        value: saveInputRef.current?.input.value,
                        category: tagCategory.category,
                      }
                      addTag(tagEntry)
                    }, 1)
                  }}
                  value={newTagInputValue}
                  onChange={e => setNewTagInputValue(e.currentTarget.value)}
                />
              </AutoComplete>
              <TagAddWrapper
                isFocused={isFocused}
                onClick={() => {
                  if (saveInputRef.current?.input.value) {
                    const tagEntry = {
                      value: saveInputRef.current?.input.value,
                      category: tagCategory.category,
                    }
                    addTag(tagEntry)
                  }
                  setAddVisible(false)
                  setIsFocused(false)
                  setNewTagInputValue(undefined)
                }}
              >
                {newTagInputValue ? <EnterOutlined /> : <CloseOutlined />}
              </TagAddWrapper>
            </NewTag>
          ) : (
            <TagNew color="#f50" onClick={() => setAddVisible(true)}>
              <PlusOutlined /> {t('tags.addNew')}
            </TagNew>
          ))}
      </NewTagWrapper>
    </TagsContainer>
  )
}

const TagsContainer = styled.div`
  margin-bottom: 1.25rem;
`

const TagInput = styled(Input)`
  width: 100px;
  border-left: none;
  border-right: none;
  border-radius: 0;

  &:focus {
    box-shadow: 0 0 0 2px rgb(255 80 20 / 20%);
    clip-path: inset(-2px 0 -2px 0);
  }
`

const NewTagWrapper = styled.div`
  margin: 8px 0;
`

const TagNew = styled(Tag)`
  width: 100px;
  cursor: pointer;
  margin-left: 23px;
`
const NewTag = styled.div`
  display: flex;
  margin-left: 22px;
`

const TagCategoryWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  .anticon {
    padding-top: 4px;
  }

  .more-tags {
    cursor: pointer;
  }
`

const TagCategoryTagsWrapper = styled(Space)`
  .ant-tag {
    margin: 0;
  }
`

const TagAddWrapper = styled.div<NewTagSideButtons>(
  ({ theme, isFocused = false }) => `
  height: 32px;
  padding: 0 5px;
  cursor: pointer;
  background: ${theme.lightGreyBackground};
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid ${isFocused ? theme.primaryColor : '#d9dadb'};
  border-left-color: #d9dadb;
  display: flex;
  justify-content: center;
  align-items: center; 
  ${
    isFocused
      ? `box-shadow: 0 0 0 2px rgb(255 80 20 / 20%);   clip-path: inset(-2px -2px -2px 0);`
      : ``
  }
  `
)
