import { safelyParseJSON } from '@organice/utils/general'
import type { Dayjs } from 'dayjs'
import { TimeUtil } from '@organice/utils'

const dayjs = TimeUtil.getDayjs()
const EVENT_ID_IGNORE_LIST_KEY = 'ignoreShortTermWarningsForEvents'

export function findEarliestDate(
  dateArray: Array<Dayjs | Dayjs[] | string | string[]>
) {
  if (!Array.isArray(dateArray)) return
  let earliestDate: Dayjs | undefined

  dateArray.forEach(d => {
    if (!Array.isArray(d) && dayjs(d).isValid()) {
      if (!earliestDate) {
        earliestDate = dayjs(d)
        return
      }
      if (dayjs(d).isBefore(earliestDate)) {
        earliestDate = dayjs(d)
        return
      }
    }

    if (Array.isArray(d)) {
      const earliestDateInArray = findEarliestDate(d)
      if (!(earliestDateInArray instanceof dayjs)) return

      if (earliestDate) {
        if (earliestDate && dayjs(earliestDateInArray).isBefore(earliestDate)) {
          earliestDate = dayjs(earliestDateInArray)
          return
        }
      } else {
        // !earliestDate
        earliestDate = dayjs(earliestDateInArray)
      }
    }
  })

  return earliestDate
}

export function eventIdIsOnIgnoreList(eventId: number) {
  const ignoreList = localStorage.getItem(EVENT_ID_IGNORE_LIST_KEY)
  return ignoreList ? safelyParseJSON(ignoreList).includes(eventId) : false
}

export function toggleEventIdToIngoreList(eventId: number) {
  const ignoreListString = localStorage.getItem(EVENT_ID_IGNORE_LIST_KEY)
  if (!ignoreListString) {
    localStorage.setItem(EVENT_ID_IGNORE_LIST_KEY, JSON.stringify([eventId]))
    return
  }

  const ignoreListParsed = safelyParseJSON(ignoreListString)
  if (ignoreListParsed.includes(eventId)) {
    localStorage.setItem(
      EVENT_ID_IGNORE_LIST_KEY,
      JSON.stringify(ignoreListParsed.filter(id => id !== eventId))
    )
  } else {
    localStorage.setItem(
      EVENT_ID_IGNORE_LIST_KEY,
      JSON.stringify([...ignoreListParsed, eventId])
    )
  }
}
