import { DateType } from '../../../types/service'
import type { Dayjs } from 'dayjs'
import type {
  TargetField,
  SourceField,
  DateSuggestionOptions,
  RepeatableDateSuggestionOptions,
  EventAttrGroupsDataWithOrder,
  EventAttrGroupsDataWithoutOrder,
  ObjectWithOrderProperty,
  EventDateType,
  DateVariants,
  DateConfig,
} from './types'

export const isRangeType = (dateType: DateType | undefined | null) => {
  if (!dateType) return false
  return [DateType.DATE_RANGE, DateType.DATE_RANGE_TIME].includes(dateType)
}

export const sortByOrderProperty = (
  a: ObjectWithOrderProperty,
  b: ObjectWithOrderProperty
) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0)

function getAllDaysOfRange(dateRange: [Dayjs, Dayjs]): Dayjs[] {
  const [start, end] = dateRange
  const diffInDays = Math.abs(start.diff(end, 'days'))

  return Array.from(Array(diffInDays + 1).keys()).map((_, i) =>
    start.add(i, 'days')
  )
}

export function getDateVariants(
  value: DateSuggestionOptions,
  config: DateConfig
): DateVariants | DateVariants[] {
  let output: DateVariants | DateVariants[]

  switch (config.dateType) {
    case DateType.DATE:
    case DateType.DATE_TIME:
      return config.repeatable
        ? (value as [Dayjs][]).map((dateArray: [Dayjs]) => ({
            single: dateArray[0],
            range: [dateArray[0], dateArray[0]],
            allDaysOfRange: [dateArray[0]],
            dayWithTimeRange: [dateArray[0], dateArray[0], dateArray[0]],
          }))
        : {
            single: value as Dayjs,
            range: [value as Dayjs, value as Dayjs],
            allDaysOfRange: [value as Dayjs],
            dayWithTimeRange: [value as Dayjs, value as Dayjs, value as Dayjs],
          }
      break
    case DateType.DATE_RANGE:
    case DateType.DATE_RANGE_TIME:
      return config.repeatable
        ? (value as [Dayjs, Dayjs][]).map((dateArray: [Dayjs, Dayjs]) => ({
            single: undefined,
            range: dateArray,
            allDaysOfRange: getAllDaysOfRange(dateArray),
            dayWithTimeRange: undefined,
          }))
        : {
            single: undefined,
            range: value as [Dayjs, Dayjs],
            allDaysOfRange: getAllDaysOfRange(value as [Dayjs, Dayjs]),
            dayWithTimeRange: undefined,
          }
      break
    case DateType.DATE_TIME_RANGE:
      return config.repeatable
        ? (value as [Dayjs, Dayjs, Dayjs][]).map(
            (dateArray: [Dayjs, Dayjs, Dayjs]) => ({
              single: dateArray[0],
              range: [dateArray[0], dateArray[0]],
              allDaysOfRange: [dateArray[0]],
              dayWithTimeRange: dateArray,
            })
          )
        : {
            single: (value as [Dayjs, Dayjs, Dayjs])[0],
            range: [
              (value as [Dayjs, Dayjs, Dayjs])[0],
              (value as [Dayjs, Dayjs, Dayjs])[0],
            ],
            allDaysOfRange: [(value as [Dayjs, Dayjs, Dayjs])[0]],
            dayWithTimeRange: value as [Dayjs, Dayjs, Dayjs],
          }
      break
    default:
      console.warn(
        `getDateVariants(): Ignoring unknown DateType "${config.dateType}"`
      )
  }

  return {
    single: undefined,
    range: undefined,
    allDaysOfRange: undefined,
    dayWithTimeRange: undefined,
  }
}
