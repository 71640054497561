import { useMeContext } from '@organice/contexts'
import {
  useInsertServiceOrganisationAccessesMutation,
  useInsertRoomMatrixOrganisationAccessesMutation,
  useSetUsersLastServiceModuleVisitMutation,
  useSetUsersLastRoomServiceOccurrenceVisitMutation,
} from '@organice/graphql'

export function useRegisterAccess() {
  const [trackOrganisationServiceAccess] =
    useInsertServiceOrganisationAccessesMutation()

  const [trackOrganisationRoomMatrixAccess] =
    useInsertRoomMatrixOrganisationAccessesMutation()

  function trackServiceAccess(serviceIds: bigint[]) {
    trackOrganisationServiceAccess({
      variables: {
        serviceAccessObjects: serviceIds.map(sId => ({ serviceId: sId })),
      },
    })
  }

  function trackRoomMatrixAccess(eventId: bigint[]) {
    trackOrganisationRoomMatrixAccess({ variables: { eventId: eventId } })
  }

  const [setUsersLastVisitRoomServiceOccurrence] =
    useSetUsersLastRoomServiceOccurrenceVisitMutation()

  function trackUsersRoomServiceOccurrenceAccess(
    roomServiceOccurrenceIds: (bigint | number)[]
  ) {
    if (!roomServiceOccurrenceIds?.length) return

    const uniqueRoomServiceOccurrenceIds = Array.from(
      new Set(roomServiceOccurrenceIds)
    )

    return setUsersLastVisitRoomServiceOccurrence({
      variables: {
        roomServiceOccurrenceAccesses: uniqueRoomServiceOccurrenceIds.map(
          rsoId => ({
            roomServiceOccurrenceId: rsoId,
          })
        ),
      },
    })
  }

  const [setUsersLastVisitServiceModule] =
    useSetUsersLastServiceModuleVisitMutation()

  function trackUserServiceModuleAccess(serviceModuleId: bigint | number) {
    if (!serviceModuleId) return
    return setUsersLastVisitServiceModule({
      variables: {
        serviceModuleId,
      },
    })
  }

  return {
    trackServiceAccess,
    trackRoomMatrixAccess,
    trackUsersRoomServiceOccurrenceAccess,
    trackUserServiceModuleAccess,
  }
}
