import React from 'react'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import {
  ButtonProps,
  Popconfirm,
  PopconfirmProps,
  Modal,
  ModalProps,
} from 'antd'
import { Button } from '../button'

interface DeleteButtonComponent extends React.FC {
  PopConfirm: typeof DeleteButtonWithPopConfirm
  ModalConfirm: typeof DeleteButtonWithModalConfirm
}

export const DeleteButton: DeleteButtonComponent = (props: ButtonProps) => {
  return (
    <Button {...props} icon={props.icon || <DeleteOutlined />}>
      {props.value}
    </Button>
  )
}

const DeleteButtonWithPopConfirm: React.FC<
  PopconfirmProps & ButtonProps
> = props => {
  return (
    <Popconfirm
      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
      {...props}
    >
      <Button {...props} icon={props.icon || <DeleteOutlined />}>
        {props.value}
      </Button>
    </Popconfirm>
  )
}

DeleteButton.PopConfirm = DeleteButtonWithPopConfirm

const DeleteButtonWithModalConfirm: React.FC<ModalProps & ButtonProps> = ({
  onOk,
  okText,
  cancelText,
  ...props
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <>
      <Modal
        {...props}
        open={isOpen}
        onOk={e => {
          onOk && onOk(e)
          setIsOpen(false)
        }}
        onCancel={props.onCancel ? props.onCancel : e => setIsOpen(false)}
      />

      <Button
        {...props}
        onClick={e => {
          setIsOpen(true)
          props.onClick && props.onClick(e)
        }}
        icon={props.icon || <DeleteOutlined />}
      >
        {props.value}
      </Button>
    </>
  )
}
DeleteButton.ModalConfirm = DeleteButtonWithModalConfirm
