import React from 'react'
import styled from '@emotion/styled'
import { Form, Mentions, Spin, Tag, notification } from 'antd'
import {
  FormOutlined,
  UserOutlined,
  BankOutlined,
  CalendarOutlined,
} from '@ant-design/icons'

import { Button } from '@organice/atoms/button'
import { useTranslation } from 'react-i18next'
import { useCommentsContext } from '@organice/contexts/comments/useCommentsContext'
import {
  CommentTypes_Enum,
  AllCommentGraphQLTypes as Comments,
  Comments_Insert_Input,
} from '@organice/contexts/comments/commentsContext'
import { useMeContext } from '@organice/contexts/me'
import { useMentionsContext } from '@organice/contexts/mentions'
import { MentionsParser, MENTIONS_PREFIXES } from '@organice/utils/mentions'
import { FormUtil, GeneralDict } from '@organice/utils/form'
import { useCommentMutations } from '@organice/utils/hooks/comments/useCommentMutations'

const { Option } = Mentions

interface CommentFormProps {
  onSuccess(comment: Comments): void
  onClose(): void
  type: CommentTypes_Enum
  data?: Comments
  organisationId?: number
  placeholder?: string
  addLabel?: string
  addIcon?: React.ReactNode
  additionalData?: GeneralDict
  userInfo?: GeneralDict | null
}

export const CommentForm: React.FC<CommentFormProps> = ({
  onSuccess,
  onClose,
  data,
  type,
  organisationId,
  placeholder,
  addLabel,
  addIcon,
  additionalData = {},
  userInfo = {},
}) => {
  const { t } = useTranslation()
  const { dispatch } = useCommentsContext()
  const {
    state: { me, isNM, isGuest },
  } = useMeContext()

  const {
    state: { loading: mentionsLoading, users, events, organisations },
  } = useMentionsContext()

  /* New Topic: organisationId is passed as prop */
  /* New SubComment: organisationId is passed in additionalData (parentComment info) */
  const orgIdFilter = isGuest
    ? me?.organisation?.id
    : organisationId || additionalData.organisationId

  const internalTab = orgIdFilter === me?.organisationId
  const isNMUserInGuestTab = !!(userInfo?.isGuestTab && userInfo?.isNMUser)

  const eventOrganisers =
    (isNM || !orgIdFilter || isGuest) &&
    Array.isArray(userInfo?.eventOrganisers)
      ? userInfo?.eventOrganisers?.map(
          eventOrganiser => eventOrganiser?.user?.id
        ) || []
      : []

  const mentionableEventOrganisers =
    eventOrganisers?.length > 0
      ? users.filter(u => eventOrganisers.includes(u.id))
      : []

  const mentionableUsers = orgIdFilter
    ? users
        .filter(u => orgIdFilter === u.organisationId)
        .filter(u => !eventOrganisers.includes(u.id))
    : users
        .filter(u => !u?.organisation?.isGuestOrga)
        .filter(u => !eventOrganisers.includes(u.id))

  const mentionableOrganisations = orgIdFilter
    ? organisations.filter(o => orgIdFilter === o.id)
    : organisations.filter(o => !o.isGuestOrga)

  const [form] = Form.useForm()
  const initivalFormValues = data || {}

  const [mentionPrefix, setMentionPrefix] = React.useState('@')
  const [mentionText, setMentionText] = React.useState('')
  const { addComment, updateComment, loading, error, addData, updateData } =
    useCommentMutations({ type })

  const onSearch = (text: string, prefix = MENTIONS_PREFIXES.users) => {
    setMentionText(text)
    setMentionPrefix(prefix)
  }

  const saveAddComment = async () => {
    const input: Comments_Insert_Input = FormUtil.convertValues(
      form.getFieldsValue(),
      {
        fieldConversionSettings: {
          comment: () =>
            MentionsParser.encode(form.getFieldValue('comment'), {
              users,
              events,
              organisations,
            }),
        },
      }
    )

    // additionalData.attributeValueId is an array if this is a comment on an attributeValue
    // in a new attributeValueGroup that has not been saved yet.
    // >> Throwing an error, because it could not be saved anyway, because the attributeValue does not exist yet
    if (Array.isArray(additionalData.attributeValueId)) {
      notification.error({
        message: t('comment.saveErrorNewAttributeValueGroup'),
      })
      dispatch({ type: 'UPDATE_ERROR' })
      return
    }

    let sendObject = {
      ...input,
      ...additionalData,
      // commentType: type,
    }

    if (organisationId) {
      sendObject = { ...sendObject, organisationId }
    }

    console.log('sendObject', sendObject)
    if (data?.id) {
      updateComment({ commentId: data?.id, sendObject })
    } else {
      addComment(sendObject)
    }
  }

  React.useEffect(() => {
    if (error) {
      notification.error({
        duration: 3,
        message: error?.toString(),
      })
      dispatch({ type: 'UPDATE_ERROR' })
    }
  }, [error])

  React.useEffect(() => {
    if (loading) {
      dispatch({ type: 'UPDATE_DATA' })
    }
  }, [loading])

  React.useEffect(() => {
    const data = addData || updateData
    if (data) {
      form.resetFields()

      onSuccess && onSuccess(data as Comments)
      onClose && onClose()
      dispatch({ type: 'UPDATED_DATA' })
    }
  }, [addData, updateData])

  const labelOtherUsers = () => {
    if (internalTab) return t('comment.optionLabelInternalUsers')
    if (isNMUserInGuestTab) return t('comment.optionLabelGuestUsers')
    return t('comment.optionLabelOtherUsers')
  }

  return (
    <Form
      form={form}
      initialValues={initivalFormValues}
      onFinish={saveAddComment}
    >
      <Spin spinning={loading && !error}>
        <Form.Item
          name="comment"
          rules={[
            {
              required: true,
              message: t('comment.validation.comment'),
            },
          ]}
        >
          <Mentions
            autoFocus
            loading={mentionsLoading}
            onSearch={onSearch}
            prefix={Object.values(MENTIONS_PREFIXES)}
            rows={3}
            placeholder={placeholder || t('comment.placeholder')}
          >
            {mentionPrefix === MENTIONS_PREFIXES.users && (
              <>
                {mentionableEventOrganisers?.length > 0 && !mentionText && (
                  <Option
                    disabled
                    key="eventOrganisers"
                    value="eventOrganisers"
                  >
                    {t('comment.optionLabelEventOrganisers')}
                  </Option>
                )}
                {mentionableEventOrganisers?.map(user => (
                  <Option
                    key={`user:${user?.id}`}
                    value={`${user?.firstName} ${user?.lastName}`}
                  >
                    <UserOutlined style={{ opacity: 0.5, marginRight: 5 }} />
                    {user?.firstName} {user?.lastName}
                    {me?.id === user?.id && (
                      <IndicatorTag style={{ marginLeft: 8 }}>
                        {t('comment.meTag')}
                      </IndicatorTag>
                    )}
                  </Option>
                ))}
                {!mentionText && (
                  <Option disabled key="otherUsers" value="otherUsers">
                    {labelOtherUsers()}
                  </Option>
                )}
                {mentionableUsers?.map(user => (
                  <Option
                    key={`user:${user?.id}`}
                    value={`${user?.firstName} ${user?.lastName}`}
                  >
                    <UserOutlined style={{ opacity: 0.5, marginRight: 5 }} />
                    {user?.firstName} {user?.lastName}
                    {me?.id === user?.id && (
                      <IndicatorTag style={{ marginLeft: 8 }}>
                        {t('comment.meTag')}
                      </IndicatorTag>
                    )}
                  </Option>
                ))}
              </>
            )}

            {mentionPrefix === MENTIONS_PREFIXES.organisations && (
              <>
                {!mentionText && (
                  <Option disabled key="organisations" value="organisations">
                    {t('comment.optionLabelOrganisations')}
                  </Option>
                )}
                {mentionableOrganisations?.map(o => (
                  <Option key={`organisation:${o?.id}`} value={`${o?.name}`}>
                    <BankOutlined style={{ opacity: 0.5, marginRight: 5 }} />
                    {o?.name}
                    {me?.organisationId === o?.id && (
                      <IndicatorTag style={{ marginLeft: 8 }}>
                        {t('comment.myOrganisationTag')}
                      </IndicatorTag>
                    )}
                  </Option>
                ))}
              </>
            )}

            {/* {mentionPrefix === MENTIONS_PREFIXES.organisations && (
              <>
                {mentionableOrganisations?.map(o => (
                  <Option key={`organisation:${o?.id}`} value={`${o?.name}`}>
                    <BankOutlined style={{ opacity: 0.5, marginRight: 5 }} />{' '}
                    {o?.name}
                  </Option>
                ))}
              </>
            )} */}

            {mentionPrefix === MENTIONS_PREFIXES.events &&
              events?.map(event => (
                <Option key={`event:${event?.id}`} value={`${event?.name}`}>
                  <CalendarOutlined style={{ opacity: 0.5, marginRight: 5 }} />{' '}
                  {event?.name}
                </Option>
              ))}
          </Mentions>
        </Form.Item>
        <CommentFormActionsBar>
          <Button onClick={onClose}>{t('comment.cancel')}</Button>
          <Button
            disabled={
              !!(
                userInfo?.isGuestTab &&
                userInfo?.isNMUser &&
                !userInfo?.guestCanRead
              )
            }
            type="primary"
            icon={addIcon || <FormOutlined />}
            onClick={() => form.submit()}
          >
            {addLabel || t('comment.answer')}
          </Button>
        </CommentFormActionsBar>
      </Spin>
    </Form>
  )
}
const CommentFormActionsBar = styled.div({
  display: 'flex',
  gap: '0.625rem',
  justifyContent: 'flex-end',
})

const IndicatorTag = styled(Tag)(({ theme }) => ({
  backgroundColor: `${theme.btnHoverBackground}`,
  borderColor: theme.primaryColor,
}))
