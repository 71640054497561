import { Comment as AntdComment, CommentProps } from 'antd'
import { Global, useTheme } from '@emotion/react'

import React from 'react'

export const Comment: React.FC<CommentProps> = props => {
  const theme = useTheme()
  return (
    <>
      <Global
        styles={{
          '.ant-comment-inner': {
            padding: '0.625rem 2.5rem',
          },
          '.ant-comment-actions': {
            position: 'absolute',
            right: 0,
            top: '-12px',
          },
          '.ant-comment-content-author, .ant-comment-content-detail': {
            paddingRight: '2.5rem',
          },
          '.ant-comment-content-author': {
            marginBottom: '0.625rem',
          },
          '.ant-comment-content-author-name > *': {
            color: theme.grey1,
          },
          '.ant-comment-nested': {
            marginLeft: '2.5rem',
          },
        }}
      />
      <AntdComment {...props} />
    </>
  )
}

//ant-comment-actions
