import React from 'react'
import styled from '@emotion/styled'

interface HasChangesMarkerProps {
  hasChanges?: boolean
  placement?: 'left' | 'leftOutside'
  preventUpdates?: boolean
  style?: React.CSSProperties
}

export const HasChangesMarker: React.FC<HasChangesMarkerProps> = ({
  hasChanges,
  placement,
  preventUpdates = false,
  style,
}) => {
  const [changes, setChanges] = React.useState(hasChanges)

  React.useEffect(() => {
    if (preventUpdates) return
    if (hasChanges !== changes) {
      setChanges(hasChanges)
    }
  }, [hasChanges, preventUpdates])

  return changes ? (
    <MarkerDot placement={placement || 'left'} style={style} />
  ) : null
}

type MarkerDotProps = {
  placement?: HasChangesMarkerProps['placement']
}

const dotSize = 6
const pullOut = 20

const MarkerDot = styled.span<MarkerDotProps>(({ theme, placement }) => ({
  height: dotSize,
  minHeight: dotSize,
  width: dotSize,
  minWidth: dotSize,
  backgroundColor: theme.primaryColor,
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: pullOut - dotSize,
  marginLeft: placement === 'leftOutside' ? -pullOut : 0,
}))
