import styled from '@emotion/styled'
import {
  Button,
  Descriptions,
  Drawer,
  Dropdown,
  MenuProps,
  message,
} from 'antd'
import {
  InfoCircleOutlined,
  LinkOutlined,
  CopyOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import React from 'react'

import { useTranslation } from 'react-i18next'
import { useResponsive } from '@organice/utils/hooks/useResponsive'

const CURRENT_BRANCH = import.meta.env.VITE_CURRENT_BRANCH
const CURRENT_COMMIT_HASH = import.meta.env.VITE_CURRENT_COMMIT_HASH
const CURRENT_COMMIT_MSG = import.meta.env.VITE_CURRENT_COMMIT_MSG
const APP_INFO_Z_INDEX = 999

export const BranchInfo = () => {
  const [open, setOpen] = React.useState(false)
  const [isMinimized, setIsMinimized] = React.useState(false)
  const { t } = useTranslation()
  const { windowSize } = useResponsive()

  const isVertical = windowSize.innerWidth < 600

  const items: MenuProps['items'] = [
    {
      label: isMinimized ? t('appInfo.expand') : t('appInfo.collapse'),
      key: '1',
      onClick: () => setIsMinimized(!isMinimized),
    },
  ]

  return (
    <>
      <Dropdown
        placement="top"
        overlayStyle={{ zIndex: APP_INFO_Z_INDEX + 1 }}
        trigger={['contextMenu']}
        menu={{ items }}
      >
        <AppInfoTrigger isMinimized={isMinimized}>
          <div
            style={{ display: 'flex', gap: 16 }}
            onClick={() => {
              setOpen(!open)
            }}
          >
            {open ? <CloseOutlined /> : <InfoCircleOutlined />}
            <span className="branch-name">{CURRENT_BRANCH}</span>
          </div>
        </AppInfoTrigger>
      </Dropdown>
      <Drawer
        headerStyle={{ display: 'none' }}
        title={null}
        placement="bottom"
        closable={true}
        onClose={o => setOpen(false)}
        open={open}
        height={isVertical ? '50vh' : 350}
        zIndex={APP_INFO_Z_INDEX - 1}
      >
        <ContentWrapper>
          <Descriptions
            title={t('appInfo.title')}
            bordered
            column={1}
            size="small"
            layout={isVertical ? 'vertical' : 'horizontal'}
          >
            <Descriptions.Item label={t('appInfo.branch')}>
              <CopyOutlined
                onClick={() => {
                  navigator.clipboard
                    .writeText(CURRENT_BRANCH)
                    .then(() => {
                      message.success(t('appInfo.copiedToClipboard'))
                    })
                    .catch(() => {
                      message.error(t('appInfo.copyToClipboardFailed'))
                    })
                }}
              />
              <Button
                type="link"
                href={`https://git.cueconcept.de/orga/orga/-/tree/${CURRENT_BRANCH}?ref_type=heads`}
                target="_blank"
                icon={<LinkOutlined />}
              >
                <span style={{ fontWeight: 'bold' }}>{CURRENT_BRANCH}</span>
              </Button>
            </Descriptions.Item>
            <Descriptions.Item label={t('appInfo.commitMessage')}>
              <Button
                style={{ paddingLeft: 0 }}
                type="link"
                href={`https://git.cueconcept.de/orga/orga/-/commit/${CURRENT_COMMIT_HASH}`}
                target="_blank"
                icon={<LinkOutlined />}
              >
                {CURRENT_COMMIT_MSG}
              </Button>
            </Descriptions.Item>
            <Descriptions.Item label={t('appInfo.commitHash')}>
              <CopyOutlined
                onClick={() => {
                  navigator.clipboard
                    .writeText(CURRENT_COMMIT_HASH)
                    .then(() => {
                      message.success(t('appInfo.copiedToClipboard'))
                    })
                    .catch(() => {
                      message.error(t('appInfo.copyToClipboardFailed'))
                    })
                }}
              />
              <Button
                type="link"
                href={`https://git.cueconcept.de/orga/orga/-/commit/${CURRENT_COMMIT_HASH}`}
                target="_blank"
                icon={<LinkOutlined />}
              >
                {CURRENT_COMMIT_HASH}
              </Button>
            </Descriptions.Item>
          </Descriptions>
        </ContentWrapper>
      </Drawer>
    </>
  )
}

const AppInfoTrigger = styled.div<{ isMinimized?: boolean }>(
  ({ theme, isMinimized }) => ({
    transition: 'all 0.4s ease-out',
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: APP_INFO_Z_INDEX,
    padding: '.325rem .8rem',
    borderTopRightRadius: 8,
    width: 'auto',
    maxWidth: isMinimized ? 40 : '100%',
    backgroundColor: theme.grey7,
    color: theme.grey2,
    borderTop: `1px solid ${theme.grey5}`,
    borderRight: `1px solid ${theme.grey5}`,
    cursor: 'pointer',
    display: 'flex',
    gap: 8,
    '&:hover': {
      backgroundColor: theme.grey5,
    },
    '.branch-name': {
      opacity: isMinimized ? 0 : 1,
      maxWidth: '50vw',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  })
)

const ContentWrapper = styled.div(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  maxWidth: '100%',
  [theme.mq.screenMdMin]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}))
