import React from 'react'
import styled from '@emotion/styled'
import { Divider, Form, Input, Spin } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Button } from '@organice/atoms'
import { Users } from '@organice/graphql'
import { useUserMutations } from '@organice/utils/hooks'

import { ApolloError } from '@apollo/client'
// At least one numeric one letter and one special char
const PASSWORD_RULES = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/

type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus']

export interface ChangePasswordValidationProps {
  value: string
  validateStatus?: ValidateStatus
  errorMsg?: string | null
}

export interface ChangePasswordProps {
  user: Users
  onChange?(passwordSet: boolean): void
  onCancel?(): void
  onError?(status: ApolloError): void
  noFormWrapper?: boolean
}

export const ChangePassword: React.FC<ChangePasswordProps> = ({
  user,
  onChange,
  onCancel,
  onError,
  noFormWrapper = false,
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = React.useState(true)

  const { updateUserPassword, updatePasswordLoading } = useUserMutations({
    onUpdatedPassword: success => onChange && onChange(success),
  })

  const saveForm = () => {
    setIsSaveButtonDisabled(true)
    updateUserPassword({
      variables: {
        id: user.id,
        password: form.getFieldValue('password'),
      },
      update(cache, { data }) {
        const setPassword = data?.setPassword
        if (!setPassword) return

        form.resetFields()
        const userToUpdate = {
          __typename: 'users',
          id: user.id,
        }
        cache.modify({
          id: cache.identify(userToUpdate),
          fields: {
            hasPassword(cachedUser) {
              return true
            },
          },
          broadcast: true,
        })
      },
    })
  }

  const pswdForm = (
    <>
      <Form.Item
        label={t('user.password')}
        name="password"
        rules={[
          {
            required: true,
          },
          () => ({
            validator(_, value) {
              return value && value.match(PASSWORD_RULES)
                ? Promise.resolve()
                : Promise.reject(new Error(t('user.passwordValidation')))
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password autoComplete="new-password" spellCheck="false" />
      </Form.Item>
      <Form.Item
        label={t('user.passwordRepeat')}
        name="passwordRepeat"
        hasFeedback
        rules={[
          {
            required: true,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value && getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(t('user.passwordRepeatValidation'))
              )
            },
          }),
        ]}
      >
        <Input.Password autoComplete="new-password" spellCheck="false" />
      </Form.Item>
    </>
  )

  if (noFormWrapper) {
    return pswdForm
  }

  return (
    <Form form={form} name="basic" onFinish={saveForm} labelCol={{ span: 10 }}>
      <Spin spinning={updatePasswordLoading}>{pswdForm}</Spin>
      <Divider />
      <ButtonContainer>
        <Button onClick={() => onCancel && onCancel()}>
          {t('user.cancel')}
        </Button>
        <Button
          icon={<CheckOutlined />}
          type={isSaveButtonDisabled ? 'default' : 'primary'}
          onClick={() => form.submit()}
          loading={updatePasswordLoading}
        >
          {t(`user.setPassword`)}
        </Button>
      </ButtonContainer>
    </Form>
  )
}
const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',

  '& > button': {
    '&:not(:last-of-type)': {
      marginRight: '0.625rem',
    },
  },
})
