import React from 'react'
import styled from '@emotion/styled'

type DivProps = JSX.IntrinsicElements['div']
type DivPropsWithChildren = React.PropsWithChildren<DivProps>

export const Content = React.forwardRef<HTMLDivElement, DivPropsWithChildren>(
  (props, ref) => {
    return <ContentContainer {...props} ref={ref} />
  }
)

const ContentContainer = styled.div(({ theme }) => ({
  padding: '1.25rem',
  flexGrow: 1,
  overflowX: 'hidden',
  width: 'calc(100vw - 300px)',
  [theme.breakpoints[3].mq]: {
    padding: '2.5rem',
  },
  '.ant-affix': {
    left: 0,
    [theme.breakpoints[3].mq]: {
      left: 'auto',
    },
  },
}))

ContentContainer.displayName = 'ContentContainer'
