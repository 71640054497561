import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { Input, Radio, Select } from '@organice/atoms'
import { Row, Col } from 'antd'
// import { EventListFilter,  } from '@organice/graphql'
import { FormUtil } from '@organice/utils'
import { EventTypes_Enum } from '@organice/graphql'
import { Dayjs } from 'dayjs'
import { useMeContext } from '@organice/contexts'

/*
/* This Component manages the Filter State and calls onFilterChange( filter ) Callback if the filter chagnges.
/* It can be used "a bit like a TextInput" from a Component on a higher Level.
/* From there the filter can be passed to the EventList Component to get the corresponding results.
*/

export type DateFilter = [Dayjs | undefined, Dayjs | undefined]

export type EventListFilter = {
  search?: string | null
  type?: EventTypes_Enum | null
  isArchived?: boolean | null
  isCancelled?: boolean | null
  isFavourite?: boolean | null
  startDate?: DateFilter // date between
  endDate?: DateFilter
  guestOrganisationId?: bigint | null
}

export interface EventListFilterBarProps {
  onFilterChange(filter: EventListFilter): void
}

// eventStatus for Radio Element (values are Booleans of isArchived Property as enums for Radio UI Element)
const eventStatus = [
  { value: false, label: 'events.current' },
  { value: true, label: 'events.archive' },
]

export const defaultFilter: EventListFilter = {
  search: null,
  type: null,
  isArchived: null,
  isCancelled: null,
  isFavourite: null,
}

export const EventListFilterBar: React.FC<EventListFilterBarProps> = ({
  onFilterChange,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [filter, setFilter] = React.useState<EventListFilter>(defaultFilter)

  const {
    state: { isGuest },
  } = useMeContext()

  const [filterBarHorizontalAlignment, setFilterBarHorizontalAlignment] =
    React.useState<'start' | 'end' | 'center'>('end')

  React.useEffect(() => {
    onFilterChange(filter)
  }, [filter])

  React.useEffect(() => {
    // The justify-prop of the antDesign <Row> Component does not take an object {xs:{}, sm:{}...} like the <Col>
    // so the alignment ist calulated here

    const calcFilterBarHorizontalAlignment = () => {
      const windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth

      const screenXlInt = parseInt(theme.screenXl.replace(/\D/g, ''), 10)

      if (windowWidth < screenXlInt) {
        setFilterBarHorizontalAlignment('start')
        return
      }
      setFilterBarHorizontalAlignment('end')
    }

    calcFilterBarHorizontalAlignment()
    window.addEventListener('resize', calcFilterBarHorizontalAlignment)

    return () =>
      window.removeEventListener('resize', calcFilterBarHorizontalAlignment)
  }, [])

  return (
    <FilterWrapper>
      <Row gutter={10} justify={filterBarHorizontalAlignment}>
        <Col>
          <Radio.Group
            defaultValue={eventStatus[0].value}
            onChange={e => {
              setFilter({ ...filter, isArchived: e.target.value })
            }}
          >
            {eventStatus.map(eventStatus => (
              <Radio.Button
                key={`eventStatus:${eventStatus.value}`}
                value={eventStatus.value}
              >
                {t(eventStatus.label)}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Col>
        <Col xs={{ span: 24, order: 3 }} lg={{ span: 8, order: 2 }}>
          <Input.Search
            allowClear
            placeholder={t('events.searchPlaceholder')}
            onChange={e => {
              setFilter({ ...filter, search: e.target.value })
            }}
          />
        </Col>
        {!isGuest && (
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 8, order: 2, flex: '1 1 auto' }}
            lg={{ order: 3 }}
            className="flex-sm-auto"
          >
            <Select
              allowClear
              placeholder={t('events.eventTypePlaceholder')}
              onChange={value => {
                if (!value) {
                  setFilter({ ...filter, type: null })
                  return
                }

                const valuePascalCase = `${value
                  .toString()[0]
                  .toUpperCase()}${value.toString().slice(1)}`

                const eventType =
                  EventTypes_Enum[
                    valuePascalCase as keyof typeof EventTypes_Enum
                  ]
                setFilter({ ...filter, type: eventType })
              }}
            >
              {FormUtil.eventTypesSelect.map(eventType => (
                <Select.Option
                  key={`eventType:${eventType.value}`}
                  value={eventType.value}
                >
                  {t(eventType.label)}
                </Select.Option>
              ))}
            </Select>
          </Col>
        )}
      </Row>
    </FilterWrapper>
  )
}

const FilterWrapper = styled.div`
  width: 100%;
  margin: 20px 0;

  .ant-col {
    margin: 5px 0;
    & > div {
      width: 100%;
    }
  }

  .ant-radio-group {
    text-align: right;

    @media screen and (max-width: ${props => props.theme.screenXl}) {
      text-align: left;
    }
  }
  &&& {
    .ant-radio-group {
      & > label.ant-radio-button-wrapper {
        &:hover {
          background: #fffcfa;
          color: ${props => props.theme.grey2};
          border-color: #ffa586;
        }
      }
      & > label.ant-radio-button-wrapper-checked {
        background: #fffcfa;
        color: ${props => props.theme.grey2};
        border-color: #ffa586;
      }
    }
  }

  // There is no way to switch between flex="auto" & col-span values with antD <Col> Props

  .flex-sm-auto {
    @media screen and (max-width: ${props => props.theme.screenXl}) {
      flex: 1 1 auto;
      max-width: 100%;
    }
  }
`
