import React from 'react'
import { Menu as AntdMenu, MenuProps } from 'antd'
import { Global, useTheme } from '@emotion/react'

export interface MenuData {
  id: string
  link: string
  label?: string
  icon?: React.ReactNode
}

export const Menu: React.FC<MenuProps> = props => {
  const theme = useTheme()
  return (
    <>
      <Global
        styles={{
          '.ant-menu-horizontal': { border: 'none', lineHeight: '40px' },
          '.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu':
            { padding: '0 10px' },
          '.ant-menu-horizontal > .ant-menu-item': {
            /* backgroundColor: 'green',
            border: '1px solid blue', */
            display: 'flex',
            alignItems: 'center',
            fontSize: '15px',
            marginLeft: '10px',
            fontWeight: 500,
            padding: 0,
            borderRadius: theme.borderRadiusBase,
            '&:hover': { backgroundColor: theme.lightBackground },
            a: { color: theme.textColor },
            'a:hover': { color: theme.textColor },
          },
          '.ant-menu-item .ant-menu-item-icon': { fontSize: '32px' },
          '.ant-menu-item::after': { display: 'none' },
          '.ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-item-selected:hover':
            {
              backgroundColor: theme.grey3,
              borderRadius: theme.borderRadiusBase,
              a: { color: theme.grey2 },
            },

          '.ant-menu-item-icon [class$="path"]': {
            fill: theme.iconBaseColor,
          },
          '.ant-menu-item-icon [class$="shape"]': {
            fill: theme.iconShadeColor,
          },
          '.ant-menu-item-selected .ant-menu-item-icon [class$="path"]': {
            fill: theme.iconActiveBaseColor,
          },
          '.ant-menu-item-selected .ant-menu-item-icon [class$="shape"]': {
            fill: theme.iconActiveShadeColor,
          },
        }}
      />
      <AntdMenu {...props} />
    </>
  )
}
