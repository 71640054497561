import React from 'react'
import {
  useCreateRoomFolderMutation,
  CreateRoomFolderMutation,
  useUpdateRoomFolderMutation,
  UpdateRoomFolderMutation,
  RoomFolders_Set_Input,
  RoomFolders_Insert_Input,
  useDeleteRoomFolderMutation,
  DeleteRoomFolderMutation,
  RoomListDocument,
} from '@organice/graphql'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'
import { ApolloError } from '@apollo/client'

interface RoomFolderMutationCallbacks {
  onAdded?(event?: CreateRoomFolderMutation['insert_roomFolders_one']): void
  onUpdated?(event?: UpdateRoomFolderMutation['update_roomFolders_by_pk']): void
  onDeleted?(event?: DeleteRoomFolderMutation['delete_roomFolders_by_pk']): void
}

type UseRoomFolderReturn = {
  addRoomFolder(roomFolder: RoomFolders_Insert_Input): void
  addStates: {
    loading: boolean
    error: ApolloError | undefined
    data: CreateRoomFolderMutation | null | undefined
  }
  updateRoomFolder(id: bigint, updates: RoomFolders_Set_Input): void
  updateStates: {
    loading: boolean
    error: ApolloError | undefined
    data: CreateRoomFolderMutation | null | undefined
  }
  deleteRoomFolder(id: bigint): void
  deleteStates: {
    loading: boolean
    error: ApolloError | undefined
    data: CreateRoomFolderMutation | null | undefined
  }
  loading: boolean
  error: ApolloError | undefined
}

export function useRoomFolderMutations(
  callbacks?: RoomFolderMutationCallbacks
): UseRoomFolderReturn {
  const { t } = useTranslation()

  /* ADD ROOM FOLDER */
  const [
    updateRoomFolderQuery,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useUpdateRoomFolderMutation({
    refetchQueries: [{ query: RoomListDocument }],
  })

  function updateRoomFolder(id: bigint, updates: RoomFolders_Set_Input) {
    updateRoomFolderQuery({
      variables: {
        id: id,
        updates: updates,
      },
    })
  }

  React.useEffect(() => {
    if (updateData) {
      notification.success({
        duration: 3,
        message: t(
          'configuration.rooms.list.createModal.addRoomFolderSuccess',
          { roomFolderName: updateData.update_roomFolders_by_pk?.name }
        ),
      })
      if (callbacks?.onAdded)
        callbacks.onAdded(updateData.update_roomFolders_by_pk)
    }
  }, [updateData])

  React.useEffect(() => {
    if (updateError) {
      notification.error({
        duration: 3,
        message: t('configuration.rooms.list.createModal.addRoomFolderError'),
      })
    }
  }, [updateError])

  /* UPDATE ROOM FOLDER */
  const [
    addRoomFolderQuery,
    { loading: addLoading, error: addError, data: addData },
  ] = useCreateRoomFolderMutation({
    refetchQueries: [{ query: RoomListDocument }],
  })

  function addRoomFolder(roomFolder: RoomFolders_Insert_Input) {
    addRoomFolderQuery({
      variables: { roomFolder },
    })
  }

  React.useEffect(() => {
    if (addData) {
      notification.success({
        duration: 3,
        message: t(
          'configuration.rooms.list.createModal.addRoomFolderSuccess',
          { roomFolderName: addData.insert_roomFolders_one?.name }
        ),
      })
      if (callbacks?.onAdded) callbacks.onAdded(addData.insert_roomFolders_one)
    }
  }, [addData])

  React.useEffect(() => {
    if (addError) {
      notification.error({
        duration: 3,
        message: t('configuration.rooms.list.createModal.addRoomFolderError'),
      })
    }
  }, [addError])

  /* DELETE ROOM FOLDER */
  const [
    deleteRoomFolderMutation,
    { loading: deleteLoading, error: deleteError, data: deleteData },
  ] = useDeleteRoomFolderMutation({
    //refetchQueries: [{ query: RoomListDocument }],
    update(cache, { data }) {
      const delete_roomFolders_by_pk = data?.delete_roomFolders_by_pk
      const deletedRoomFolder = {
        __typename: 'roomFolders',
        id: delete_roomFolders_by_pk?.id,
      }
      cache.evict({ id: cache.identify(deletedRoomFolder), broadcast: true })
    },
  })

  function deleteRoomFolder(id: bigint) {
    deleteRoomFolderMutation({
      variables: {
        id: id,
      },
    })
  }

  React.useEffect(() => {
    if (deleteData) {
      notification.success({
        duration: 3,
        message: t(
          'configuration.rooms.list.createModal.deleteRoomFolderSuccess',
          { roomFolderName: deleteData.delete_roomFolders_by_pk?.name }
        ),
      })
      if (callbacks?.onAdded)
        callbacks.onAdded(deleteData.delete_roomFolders_by_pk)
    }
  }, [deleteData])

  React.useEffect(() => {
    if (deleteError) {
      notification.error({
        duration: 3,
        message: t(
          'configuration.rooms.list.createModal.deleteRoomFolderError'
        ),
      })
    }
  }, [deleteError])

  return {
    addRoomFolder,
    addStates: {
      loading: addLoading,
      error: addError,
      data: addData,
    },
    updateRoomFolder,
    updateStates: {
      loading: updateLoading,
      error: updateError,
      data: updateData,
    },
    deleteRoomFolder,
    deleteStates: {
      loading: deleteLoading,
      error: deleteError,
      data: deleteData,
    },
    loading: addLoading || deleteLoading,
    error: addError || deleteError,
  }
}
