import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as de from './de.json'
import Polyglot from 'i18next-polyglot'

const resources = {
  de: {
    translation: de,
  },
}

i18n
  .use(Polyglot) // passes i18n down to react-i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3',
    resources,
    lng: 'de',
    // set false, because breadcrumbs use t() function and e.g. event.title can contain ":" (Werkstätten:Messe)
    // if nsSeparator might become necessary in the future: use the character that is most unlikely to appear in any kind of title :)
    nsSeparator: false,
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
