import React from 'react'
import {
  useSetPublishOnServiceModuleMutation,
  ServiceModules,
} from '@organice/graphql'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

interface UseServiceModuleOptions {
  module: Pick<ServiceModules, 'id' | 'published' | 'writable'>
  onPublishChange?: (isPublished: boolean | null | undefined) => void
}

export function useServiceModule(options: UseServiceModuleOptions) {
  const { module, onPublishChange } = options
  const { t } = useTranslation()

  const [setPublish, { data, error, loading }] =
    useSetPublishOnServiceModuleMutation()

  async function togglePublished() {
    const result = await setPublish({
      variables: {
        id: module.id,
        published: !module.published,
      },
    })
    console.log('togglePublished', result)
    onPublishChange &&
      onPublishChange(result.data?.update_serviceModules_by_pk?.published)
  }

  React.useEffect(() => {
    if (data) {
      const isPublished = data.update_serviceModules_by_pk?.published
      notification.success({
        duration: 3,
        message: isPublished
          ? t('messages.serviceModules.successfullyPublished')
          : t('messages.serviceModules.successfullyUnPublished'),
      })
    }
  }, [data])

  React.useEffect(() => {
    if (error) {
      notification.error({
        duration: 3,
        message: t('messages.serviceModules.errorSettingPublished'),
      })
    }
  }, [error])

  return { togglePublished, data, error, loading }
}
