import React from 'react'

import {
  AttributeValueComments as AttributeValueCommentTyped,
  RoomAttributeValueComments as RoomAttributeValueCommentTyped,
  UploadComments as UploadCommentTyped,
} from '@organice/graphql'
import { GeneralDict } from '@organice/utils'

import {
  AttributeValueComments_Insert_Input,
  RoomAttributeValueComments_Insert_Input,
  UploadComments_Insert_Input,
  AttributeValueComments_Bool_Exp,
  RoomAttributeValueComments_Bool_Exp,
  UploadComments_Bool_Exp,
  AttributeValueComments_Set_Input,
  UploadComments_Set_Input,
  RoomAttributeValueComments_Set_Input,
} from '@organice/graphql'

export type Comments_Insert_Input =
  | AttributeValueComments_Insert_Input
  | UploadComments_Insert_Input
  | RoomAttributeValueComments_Insert_Input

export type Comments_Set_Input =
  | AttributeValueComments_Set_Input
  | UploadComments_Set_Input
  | RoomAttributeValueComments_Set_Input

export type Comments_Bool_Exp =
  | AttributeValueComments_Bool_Exp
  | RoomAttributeValueComments_Bool_Exp
  | UploadComments_Bool_Exp

export enum CommentTypes_Enum {
  RoomAttributeValueComment = 'roomAttributeValueComments',
  AttribtueValueComment = 'attributeValueComments',
  UploadComment = 'uploadComments',
}

// export type AllCommentGraphQLTypes = Omit<
//   AttributeValueCommentTyped,
//   'attributeValueId' | 'attributeValue'
// > & {
//   __typename?:
//     | 'roomAttributeValueComments'
//     | 'attributeValueComments'
//     | 'uploadComments'
//     | undefined
//   attributeValueId?:
//     | Pick<AttributeValueCommentTyped, 'attributeValueId'>
//     | undefined
//   attributeValue?:
//     | Pick<AttributeValueCommentTyped, 'attributeValue'>
//     | undefined
//   uploadId?: Pick<UploadCommentTyped, 'uploadId'> | undefined
//   upload?: Pick<UploadCommentTyped, 'upload'> | undefined
//   uploadVersionId?: Pick<UploadCommentTyped, 'uploadVersionId'> | undefined
//   uploadVersion?: Pick<UploadCommentTyped, 'uploadVersion'> | undefined
//   roomAttributeValueId?:
//     | Pick<RoomAttributeValueCommentTyped, 'roomAttributeValueId'>
//     | undefined
//   roomAttributeValue?:
//     | Pick<RoomAttributeValueCommentTyped, 'roomAttributeValue'>
//     | undefined
// }

export type AllCommentGraphQLTypes =
  | AttributeValueCommentTyped
  | RoomAttributeValueCommentTyped
  | UploadCommentTyped

interface CommentsState {
  id: Pick<AllCommentGraphQLTypes, 'id'> | null
  type: CommentTypes_Enum
  title?: string
  description?: string | null
  additionalData?: GeneralDict | null
  isOpen?: boolean
  onUpdateCount?(count: number): void
  dataUpdating?: boolean
  highlight?: number
  guestCanRead?: boolean
}

export const DEFAULT_VALUE: CommentsState = {
  id: null,
  type: CommentTypes_Enum.AttribtueValueComment,
  isOpen: false,
  dataUpdating: true,
}

export type CommentsStateAction =
  | {
      type:
        | 'OPEN_COMMENTS'
        | 'CLOSE_COMMENTS'
        | 'UPDATE_DATA'
        | 'UPDATED_DATA'
        | 'UPDATE_ERROR'
        | 'REMOVE_HIGHLIGHT'
      state?: CommentsState
      activeTab?: number
    }
  | {
      type: 'RESET_ID'
      id: Pick<CommentsState, 'id'>
    }
  | {
      type: 'SET_HIGHLIGHT'
      highlight: number
    }

export const reducer: React.Reducer<CommentsState, CommentsStateAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'OPEN_COMMENTS':
      return { ...state, ...action.state, isOpen: true }
    case 'RESET_ID':
      return { ...state, id: action.id, isOpen: true }
    case 'UPDATE_DATA':
      return { ...state, dataUpdating: true }
    case 'UPDATED_DATA':
      return { ...state, ...action.state, dataUpdating: false }
    case 'UPDATE_ERROR':
      return { ...state, dataUpdating: false }
    case 'SET_HIGHLIGHT':
      return { ...state, highlight: action.highlight }
    case 'REMOVE_HIGHLIGHT':
      return { ...state, highlight: undefined }

    default:
      return DEFAULT_VALUE
  }
}

export const CommentsContext = React.createContext<{
  state: CommentsState
  dispatch: React.Dispatch<CommentsStateAction>
}>({ state: DEFAULT_VALUE, dispatch: () => null })

// export const useCommentsContext = () => React.useContext(CommentsContext)

export const getAdditionalData = (
  type: CommentTypes_Enum,
  id: Pick<AllCommentGraphQLTypes, 'id'> | null,
  additionalData?: Record<string, any> | null
) => {
  let returnValue: Record<string, any> = { attributeValueId: id }
  if (type === CommentTypes_Enum.UploadComment) {
    returnValue = { uploadId: id }
    // returnValue = { uploadVersionId: id }
  }
  if (type === CommentTypes_Enum.RoomAttributeValueComment) {
    returnValue = { roomAttributeValueId: id }
    // returnValue = { uploadVersionId: id }
  }
  if (additionalData) {
    return { ...returnValue, ...additionalData }
  }
  return returnValue
}
