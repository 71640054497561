import React from 'react'
import { Modal } from '../modal'
import { useTranslation } from 'react-i18next'
import { Input, Button, Heading } from '@organice/atoms'
import { Form } from 'antd'
import styled from '@emotion/styled'
import { CheckOutlined } from '@ant-design/icons'

interface RoomFolderModalProps {
  title: string
  onClose?: () => void
  onFinish?: (values: any) => void
  loading?: boolean
  roomFolderName?: string
}

export const RoomFolderModal: React.FC<RoomFolderModalProps> = ({
  title,
  onClose,
  onFinish,
  loading,
  roomFolderName,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      title={null}
      open={true}
      onCancel={() => onClose && onClose()}
      footer={null}
    >
      <Heading>{title}</Heading>
      <Form
        layout="vertical"
        onFinish={values => onFinish && onFinish(values)}
        initialValues={{
          name: roomFolderName,
        }}
      >
        <Form.Item
          label={t('configuration.rooms.list.createModal.folderName')}
          name="name"
          rules={[
            {
              required: true,
              message: t('configuration.rooms.list.createModal.noTitle'),
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <ButtonWrapper>
          <Button onClick={() => onClose && onClose()} loading={loading}>
            {t('common.form.cancel')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            icon={<CheckOutlined />}
          >
            {t('common.form.save')}
          </Button>
        </ButtonWrapper>
      </Form>
    </Modal>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .ant-btn {
    margin-left: 0.625rem;
  }
`
