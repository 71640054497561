import React from 'react'
import styled from '@emotion/styled'
import Icon, {
  LoadingOutlined,
  GlobalOutlined,
  CommentOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Tabs } from 'antd'

import IconBusiness from '@organice/icons/business.svg?react'
import { Heading } from '../../atoms/heading'
import { Paragraph } from '@organice/atoms/paragraph'
import { Text } from '@organice/atoms/text'
import { CommentsTab } from '../../molecules/commentsTab'
import { useCommentsContext } from '@organice/contexts/comments/useCommentsContext'
import {
  CommentTypes_Enum,
  AllCommentGraphQLTypes as CommentsType,
} from '@organice/contexts/comments/commentsContext'

import { useEventByIdLazyQuery, Users } from '@organice/graphql'

import { useTranslation } from 'react-i18next'
import { GeneralDict } from '@organice/utils'
import Spin from 'antd/es/spin'
import { useLocation } from 'react-router'
import {
  regexStringsDecorator,
  regexStringsDecoratorRenderer,
} from '@organice/utils/stringsDecorator'
import {
  NM_ORGANISATION_ID,
  useMeContext,
} from '@organice/contexts/me/meContext'
import { useTheme } from '@emotion/react'

const { TabPane } = Tabs

interface CommentsProps {
  id: Pick<CommentsType, 'id'> | null
  type: CommentTypes_Enum
  title?: string
  description?: string | null
  additionalData?: GeneralDict | null
  guestCanRead?: boolean
  onUpdateCount?(count: number): void
}

export const Comments: React.FC<CommentsProps> = ({
  id,
  title,
  type,
  description,
  additionalData = null,
  guestCanRead = false,
  onUpdateCount,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { state: userData } = useMeContext()
  const [loading, setLoading] = React.useState(true)
  const { hash, pathname } = useLocation()

  const eventId = pathname?.match(/events\/([0-9]+)/)?.[1] || null
  const activeTab = hash?.match(/comment\/([0-9]+)\/([0-9]+)/)?.[1] || 1
  const commentId = hash?.match(/comment\/([0-9]+)\/([0-9]+)/)?.[2] || null

  /* Event */
  const [fetchEvent, { data, loading: loadingEvent }] = useEventByIdLazyQuery({
    variables: {
      id: eventId,
      fetchRubin: userData?.me?.organisation?.isGuestOrga ? false : true,
      fetchLastUpdatedInfo: userData?.me?.organisation?.isGuestOrga
        ? false
        : true,
      fetchGuestCanRead:
        userData?.me?.organisationId === NM_ORGANISATION_ID ? true : false,
    },
    fetchPolicy: 'cache-and-network',
  })
  const event = data?.events_by_pk

  React.useEffect(() => {
    fetchEvent()
  }, [])

  const {
    state: { dataUpdating, highlight },
    dispatch,
  } = useCommentsContext()

  React.useEffect(() => {
    if (!highlight && commentId) {
      dispatch({ type: 'SET_HIGHLIGHT', highlight: parseInt(commentId) })
    }
  }, [commentId])

  const [currentUser, setCurrentUser] = React.useState<Users>()
  React.useEffect(() => {
    setLoading(false)
    dispatch({ type: 'UPDATED_DATA' })
    setCurrentUser(userData?.me as Users)
  }, [userData])

  const [guestCount, setGuestCount] = React.useState(0)
  const [privateCount, setPrivateCount] = React.useState(0)
  const [publicCount, setPublicCount] = React.useState(0)

  React.useEffect(() => {
    onUpdateCount && onUpdateCount(privateCount + publicCount + guestCount)
  }, [privateCount, publicCount, guestCount])

  const isGuestUser = !!currentUser?.organisation?.isGuestOrga
  const isGuestEvent = !!event?.guestOrganisationId
  const isNMUser = currentUser?.organisationId === NM_ORGANISATION_ID

  return (
    <CommentsHolder>
      <CommentsHeader>
        <Title level={2}>{title}</Title>
        <Text>
          {description &&
            regexStringsDecorator(description, regexStringsDecoratorRenderer)}
        </Text>
      </CommentsHeader>
      <CommentsInner>
        {(dataUpdating || loadingEvent) && (
          <CommentsLoading>
            <Spin />
          </CommentsLoading>
        )}
        {!loadingEvent && (
          <Tabs defaultActiveKey={String(activeTab)}>
            {isGuestEvent && (isGuestUser || isNMUser) && (
              <TabPane
                disabled={loading}
                forceRender={true}
                tab={
                  <>
                    {loading ? <LoadingOutlined /> : <CommentOutlined />}{' '}
                    {t('comment.tabs.guestTabHeadline')}
                  </>
                }
                key="0"
              >
                <ParagraphContainer>
                  <Paragraph>{t('comment.tabs.guestDescription')}</Paragraph>
                </ParagraphContainer>
                {isNMUser && !guestCanRead && (
                  <InfoContainer>
                    <Icon
                      component={
                        InfoCircleOutlined as React.ForwardRefExoticComponent<any>
                      }
                      style={{
                        fontSize: '1rem',
                        marginRight: '1.25rem',
                        color: theme.primaryColor,
                      }}
                    />
                    <Text>{t('comment.tabs.guestTabAdminInfo')}</Text>
                  </InfoContainer>
                )}
                <CommentsTab
                  id={id}
                  type={type}
                  publicComments={false}
                  organisationId={
                    isGuestUser ? undefined : event?.guestOrganisationId
                  }
                  additionalData={additionalData}
                  onUpdateCount={count => setGuestCount(count)}
                  userInfo={{
                    isGuestUser: isGuestUser,
                    isNMUser: isNMUser,
                    guestCanRead: guestCanRead,
                    isGuestTab: true,
                    eventOrganisers: event?.eventOrganisers || [],
                  }}
                />
              </TabPane>
            )}

            {!isGuestUser && (
              <TabPane
                disabled={loading}
                forceRender={true}
                tab={
                  <>
                    {loading ? (
                      <LoadingOutlined />
                    ) : (
                      <Icon component={IconBusiness} />
                    )}{' '}
                    {currentUser?.organisation?.name}
                  </>
                }
                key="1"
              >
                <ParagraphContainer>
                  <Paragraph>{t('comment.tabs.internalDescription')}</Paragraph>
                </ParagraphContainer>
                <CommentsTab
                  id={id}
                  type={type}
                  publicComments={false}
                  organisationId={currentUser?.organisation?.id}
                  additionalData={additionalData}
                  onUpdateCount={count => setPrivateCount(count)}
                  userInfo={{
                    eventOrganisers: event?.eventOrganisers || [],
                  }}
                />
              </TabPane>
            )}
            {!isGuestUser && (
              <TabPane
                forceRender={true}
                tab={
                  <>
                    <GlobalOutlined />
                    {t('comment.tabs.allComments')}
                  </>
                }
                key="2"
              >
                <ParagraphContainer>
                  <Paragraph>{t('comment.tabs.publicDescription')}</Paragraph>
                </ParagraphContainer>
                <CommentsTab
                  type={type}
                  id={id}
                  additionalData={additionalData}
                  onUpdateCount={count => setPublicCount(count)}
                  userInfo={{
                    eventOrganisers: event?.eventOrganisers || [],
                  }}
                />
              </TabPane>
            )}
          </Tabs>
        )}
      </CommentsInner>
    </CommentsHolder>
  )
}
const CommentsHolder = styled.div({})

const CommentsHeader = styled.div`
  background-color: ${props => props.theme.grey3};
  padding: 2.5rem 2.5rem 3.75rem 2.5rem;
`

const CommentsInner = styled.div`
  position: relative;
  margin-top: -2.8rem;
  .ant-tabs-nav {
    padding: 0 2.5rem;
  }
`
const CommentsLoading = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 40vw;
  height: 100%;
  z-index: 1;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled(Heading)({
  display: 'flex',
  alignItems: 'center',
  '&&': {
    marginBottom: '0.1875rem',
  },
})

const ParagraphContainer = styled.div`
  padding: 0 2.5rem;
`

const InfoContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flex: 1,
  padding: '0.625rem 1.25rem',
  marginBottom: '1rem',
  marginTop: '1rem',
  marginLeft: '2.5rem',
  marginRight: '2.5rem',
  alignItems: 'center',
  border: `1px solid ${theme.primaryColor}`,
}))
