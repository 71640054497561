import { LoadingIndicator } from '@organice/atoms'
import { useAllUsersListQuery } from '@organice/graphql'
import { AutoComplete, AutoCompleteProps } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Person } from '../person'
import styled from '@emotion/styled'
import { BaseSelectRef } from 'rc-select'

type UserValue = {
  id?: bigint
  firstName?: string | null
  lastName?: string | null
  attributes?: Record<string, any>
  organisation?: {
    id: bigint
    name: string
  } | null
}

type UserAutocompleteProps = AutoCompleteProps & {
  organisationId?: bigint | number
  initialValue?: UserValue | null
}

type UserOptionType = {
  key: bigint
  value: string
  label: React.ReactNode
  user: UserValue
}

export const UserAutocomplete: React.FC<UserAutocompleteProps> = props => {
  const { organisationId, value } = props

  const { data, loading, error } = useAllUsersListQuery({
    variables: {
      where: {
        _and: [
          {
            organisationId: {
              _eq: organisationId,
            },
          },
          {
            isDisabled: {
              _neq: true,
            },
          },
        ],
      },
    },
  })

  const { t } = useTranslation()

  const [options, setOptions] = React.useState<UserOptionType[]>([])
  const [isTouched, setIsTouched] = React.useState(false)

  function createStringValueFromUser(user: UserValue | null | undefined) {
    if (!user) return ''
    return `${user.firstName || ''} ${user.lastName || ''}${user.attributes?.department ? ` (${user.attributes.department})` : ''}`
  }

  React.useEffect(() => {
    if (!data || isTouched) return
    setOptions(
      data.allUsers.map(u => ({
        label: (
          <>
            <span style={{ fontWeight: 'bold' }}>
              {u?.firstName} {u.lastName}{' '}
            </span>
            {u.attributes?.department ? (
              <span style={{ color: 'gray' }}>
                {' '}
                ({u.attributes.department})
              </span>
            ) : null}
          </>
        ),
        value: createStringValueFromUser(u as UserValue) || '',
        key: u.id,
        user: u as UserValue,
      })) || []
    )
  }, [data, isTouched])

  const handleSearch = (value: string) => {
    let res: UserOptionType[] = []
    if (!isTouched) setIsTouched(true)
    if (data) {
      res = data.allUsers
        ?.filter(u =>
          !value
            ? true
            : String(u.firstName)
                .toLowerCase()
                .startsWith(value.toLowerCase()) ||
              String(u.lastName).toLowerCase().startsWith(value.toLowerCase())
        )
        .map(u => ({
          label: (
            <>
              <span style={{ fontWeight: 'bold' }}>
                {u.firstName} {u.lastName}{' '}
              </span>
              {u.attributes?.department ? (
                <span style={{ color: 'gray' }}>
                  {' '}
                  ({u.attributes.department})
                </span>
              ) : null}
            </>
          ),
          value: createStringValueFromUser(u as UserValue),
          key: u.id,
          user: u,
        }))
    }
    setOptions(res)
  }

  const initialUserStringValue = createStringValueFromUser(props.initialValue)
  const userStringValue =
    props.value && typeof props.value !== 'string'
      ? createStringValueFromUser(props.value)
      : undefined

  const [edit, setEdit] = React.useState(false)
  const autocompleteRef = React.useRef<BaseSelectRef>(null)

  return (
    <AutocompleteWrapper edit={edit}>
      <AutoComplete
        onFocus={() => setEdit(true)}
        onBlur={() => setEdit(false)}
        ref={autocompleteRef}
        allowClear
        defaultValue={initialUserStringValue}
        style={{ width: '100%' }}
        onSearch={handleSearch}
        options={options}
        notFoundContent={
          loading ? (
            <LoadingIndicator isLocal />
          ) : (
            <span>{t('service.attribute.user.notifications.noUserFound')}</span>
          )
        }
        {...props}
        value={userStringValue}
        onChange={(value, option) => {
          setIsTouched(true)
          props.onChange &&
            props.onChange(
              option ? (option as UserOptionType).user : null,
              option
            )

          if (option.constructor === Object && Object.keys(option).length) {
            autocompleteRef.current?.blur()
          }
        }}
      />
      {value && !edit ? (
        <PersonWrapper>
          <Person
            user={value}
            onNameClicked={() => autocompleteRef.current?.focus()}
          />
        </PersonWrapper>
      ) : null}
    </AutocompleteWrapper>
  )
}

const AutocompleteWrapper = styled.div<{ edit?: boolean }>(({ edit }) => ({
  position: 'relative',
  width: '100%',
  input: {
    overflow: 'hidden',
    textIndent: edit ? 0 : -99999,
  },
}))

const PersonWrapper = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  padding: 5,
  paddingLeft: 8,
})
