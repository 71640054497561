import { createContext } from 'react'
import React from 'react'
import { FormInstance } from 'antd/es/form/Form'

export type FormData = Record<string, any>

export const FormContext = createContext<{
  form: FormInstance<any>
  updaterFunc?: (changedData: FormData, allData: FormData) => void
  showDateSuggestions: boolean
  setFormHasUnsavedChanges?: (state: boolean) => void
} | null>(null)

interface FormContextProviderProps {
  form: FormInstance<any>
  updaterFunc?: (update: FormData) => void
  showDateSuggestions?: boolean
  setFormHasUnsavedChanges?: (state: boolean) => void
}

export const FormContextProvider: React.FC<FormContextProviderProps> = ({
  form,
  updaterFunc,
  setFormHasUnsavedChanges,
  showDateSuggestions = false,
  children,
}) => {
  return (
    <FormContext.Provider
      value={{
        form,
        updaterFunc,
        showDateSuggestions,
        setFormHasUnsavedChanges,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const useFormContext = (): any => {
  const context = React.useContext(FormContext)
  if (!context) {
    console.error(`useFormContext() must be used within <FormContextProvider>`)
    return {}
  }

  const {
    form,
    updaterFunc,
    setFormHasUnsavedChanges,
    showDateSuggestions = true,
  } = context

  return { form, updaterFunc, setFormHasUnsavedChanges, showDateSuggestions }
}
