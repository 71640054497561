import { TimeUtil } from './time'
import { Dayjs } from 'dayjs'
const dayjs = TimeUtil.getDayjs()

type DateValue = Dayjs | undefined | null

export const valuesAreEqual = (
  externalState: DateValue | DateValue[] | DateValue[][] | undefined | null,
  internalState: DateValue | DateValue[] | DateValue[][] | undefined | null
): boolean => {
  if (externalState === internalState) return true
  if (externalState && !internalState) return false
  if (internalState && !externalState) return false

  if (!Array.isArray(externalState) || !Array.isArray(internalState)) {
    return dayjs(externalState as Dayjs | Date | string).isSame(
      dayjs(internalState as Dayjs | Date | string)
    )
  }

  if (externalState?.length !== internalState?.length) {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const allValuesEqual = externalState?.every(
    (date: DateValue | DateValue[], index: number) => {
      if (Array.isArray(date)) {
        if (!Array.isArray(internalState?.[index])) return false
        return valuesAreEqual(date, internalState?.[index])
      }

      return date && internalState?.[index]
        ? dayjs(date).isSame(
            dayjs(internalState?.[index] as Dayjs | Date | string)
          )
        : internalState?.[index] === date
    }
  )

  return Boolean(allValuesEqual)
}
