import React from 'react'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Divider,
  Table,
  Form,
  Alert,
  TableColumnsType,
  notification,
} from 'antd'
import styled from '@emotion/styled'
import { Heading, Select } from '@organice/atoms'
import {
  useOrganisationsListQuery,
  OrganisationsListQuery,
  useServiceModuleAccessesByModuleIdsQuery,
  useAddServiceModulesAccessesMutation,
  ServiceModuleAccessesByModuleIdsQuery,
  useUpdateServiceModuleAccessMutation,
  useDeleteServiceModuleAccessMutation,
  ServiceModuleAccesses_Insert_Input,
} from '@organice/graphql'
import { TimeUtil } from '@organice/utils/date'
import {
  ServicePermissionsMatrix,
  ServicePermissionsMatrixProps,
} from './servicePermissionsMatrix'
import i18n from '../../../i18n/i18n'
import { ServiceModule } from '../../../types/service'
import { useMeContext } from '@organice/contexts'

const ALL_GROUPS_ID = 'allGroups'
const ALL_ORGANISATIONS_ID = 'allOrganisations'
const ALL_GUEST_ORGANISATIONS_ID = 'allGuestOrganisations'

const ORGANISATION = 'organisation'
const ORGANISATION_GROUP = 'organisationGroup'
const MODULE = 'module'

type Organisation = OrganisationsListQuery['organisations'][0]
type OrganisationGroup = Organisation['organisationGroups'][0]
type ServiceModuleAccesses =
  ServiceModuleAccessesByModuleIdsQuery['serviceModuleAccesses'][0]

export interface ServicePermissionsState {
  selectedOrganisation?: Organisation['id']
  selectedOrganisationGroup?: OrganisationGroup['id']
  selectedModule?: ServiceModule['id']
}

// we need this looser type because we may want to show the permissions tab for config services
// that have unsaved modules
interface WipModule {
  id?: ServiceModule['id']
  name?: ServiceModule['name']
}

export interface ServicePermissionsProps {
  serviceModules: WipModule[]
}

interface TableData {
  rows: Record<string, RowData>
}

interface RowData {
  organisationId: Organisation['id']
  organisationName: Organisation['name']
  organisationGroupId: OrganisationGroup['id'] | null
  organisationGroupName: OrganisationGroup['name']
  updatedAt: ServiceModuleAccesses['updatedAt']
  serviceModuleAccesses: Pick<
    ServiceModuleAccesses,
    'writable' | 'serviceModuleId' | 'id'
  >[]
}

const columns: TableColumnsType<RowData> = [
  {
    title: i18n.t('permissions.table.organisation'),
    dataIndex: 'organisationName',
    key: 'organisation',
    sorter: (a, b) => {
      const nameA = a.organisationName || ''
      const nameB = b.organisationName || ''

      return nameA.localeCompare(nameB)
    },
    defaultSortOrder: 'ascend',
  },
  {
    title: i18n.t('permissions.table.group'),
    dataIndex: 'organisationGroupName',
    key: 'group',
  },
  {
    title: i18n.t('permissions.table.date'),
    dataIndex: 'updatedAt',
    key: 'date',
    render: (value: string) => TimeUtil.get(value).format('LLL'),
  },
]

const getOrganisationMetaData = (
  organisations: Organisation[],
  organisationId: Organisation['id'],
  organisationGroupId: OrganisationGroup['id'] | null,
  guestUsers: boolean
): Pick<RowData, 'organisationGroupName' | 'organisationName'> => {
  const currentOrganisation = organisations.find(o => o.id === organisationId)
  if (!currentOrganisation) {
    if (organisationId === null && !guestUsers) {
      return {
        organisationName: i18n.t('permissions.options.allOrganisations'),
        organisationGroupName: i18n.t('permissions.options.allGroups'),
      }
    }

    if (organisationId === null && guestUsers) {
      return {
        organisationName: i18n.t('permissions.options.guestOrganisations'),
        organisationGroupName: i18n.t('permissions.options.allGroups'),
      }
    }

    return {
      organisationName: i18n.t('permissions.organisations.unknown'),
      organisationGroupName: i18n.t('permissions.options.allGroups'),
    }
  }

  if (!organisationGroupId) {
    return {
      organisationName: currentOrganisation.name,
      organisationGroupName: i18n.t('permissions.options.allGroups'),
    }
  }

  const organisationGroup = currentOrganisation.organisationGroups.find(
    o => o.id === organisationGroupId
  )

  if (!organisationGroup) {
    return {
      organisationName: currentOrganisation.name,
      organisationGroupName: i18n.t('permissions.organisations.unknownGroup'),
    }
  }

  return {
    organisationName: currentOrganisation.name,
    organisationGroupName: organisationGroup?.name,
  }
}

const transformServiceAccesses = (
  serviceAccesses: ServiceModuleAccesses[],
  organisations: Organisation[]
): TableData => {
  return serviceAccesses.reduce<TableData>(
    (accData, currAccess) => {
      // get the metadata
      const metaData = getOrganisationMetaData(
        organisations,
        currAccess.organisationId,
        currAccess.organisationGroupId,
        currAccess.guestUsers
      )

      // try to get the existing entry
      const groupId =
        currAccess.organisationGroupId !== null
          ? currAccess.organisationGroupId
          : ALL_GROUPS_ID

      const key = `${
        currAccess.guestUsers ? 'guestKey' : currAccess.organisationId
      }${groupId}`
      const oldEntry = accData.rows[key]

      // if there was no entry for the current organisationId, create one
      if (!oldEntry) {
        const newEntry: RowData = {
          organisationId: currAccess.organisationId,
          organisationGroupId: currAccess.organisationGroupId,
          updatedAt: currAccess.updatedAt,
          ...metaData,
          serviceModuleAccesses: [
            {
              ...currAccess,
            },
          ],
        }

        return { ...accData, rows: { ...accData.rows, [key]: newEntry } }
      }

      // if there already was an entry for the current organisationId, add the access data to
      // the serviceModuleAccesses array
      const newEntry: RowData = {
        ...oldEntry,
        serviceModuleAccesses: [
          ...oldEntry.serviceModuleAccesses,
          {
            ...currAccess,
          },
        ],
      }

      return { ...accData, rows: { ...accData.rows, [key]: newEntry } }
    },
    { rows: {} }
  )
}

export const ServicePermissions: React.FC<ServicePermissionsProps> = ({
  serviceModules,
}) => {
  const { t } = useTranslation()
  const {
    state: { isAdmin, isNM },
  } = useMeContext()

  const readOnlyMode = !isAdmin && isNM
  const [form] = Form.useForm()

  const moduleIds = serviceModules.map(s => s.id)

  const {
    data: dataServiceModuleAccesses,
    refetch: refetchServiceModuleAccesses,
  } = useServiceModuleAccessesByModuleIdsQuery({
    variables: { ids: moduleIds },
  })

  const [addServiceModuleAccesses] = useAddServiceModulesAccessesMutation()
  const [updateServiceModuleAccess] = useUpdateServiceModuleAccessMutation()
  const [deleteServiceModuleAccess] = useDeleteServiceModuleAccessMutation()

  const [state, setState] = React.useState<ServicePermissionsState>({})

  const { data: organisationsData, loading: organisationsLoading } =
    useOrganisationsListQuery()

  const chosenOrganisation = ((
    id?: Organisation['id']
  ): OrganisationsListQuery['organisations'][0] | undefined => {
    if (id === ALL_ORGANISATIONS_ID) {
      return {
        id: ALL_ORGANISATIONS_ID,
        name: t('permissions.options.allOrganisations'),
        isGuestOrga: false,
        organisationGroups: [],
      }
    }
    if (id === ALL_GUEST_ORGANISATIONS_ID) {
      return {
        id: ALL_GUEST_ORGANISATIONS_ID,
        name: t('permissions.options.guestOrganisations'),
        isGuestOrga: true,
        organisationGroups: [],
      }
    }

    if (!organisationsData || id === undefined) {
      return
    }

    const selectedOrganisation = organisationsData.organisations.find(
      o => o.id === id
    )

    if (!selectedOrganisation) {
      return
    }

    return selectedOrganisation
  })(state.selectedOrganisation) // note the IIFE

  const hasUnsavedModules = serviceModules.some(m => m.id === undefined)

  const handleSubmit = async (data: {
    [ORGANISATION]: number | string
    [ORGANISATION_GROUP]: number | string
    [MODULE]: number[]
  }) => {
    try {
      const [organisation, organisationGroup, serviceModuleIds] = [
        data[ORGANISATION],
        data[ORGANISATION_GROUP],
        data[MODULE],
      ]

      const organisationGroupId =
        organisationGroup === ALL_GROUPS_ID ? null : organisationGroup

      const organisationId =
        organisation === ALL_ORGANISATIONS_ID ||
        organisation === ALL_GUEST_ORGANISATIONS_ID
          ? null
          : organisation

      const objects = serviceModuleIds.map<ServiceModuleAccesses_Insert_Input>(
        id => ({
          writable: false,
          serviceModuleId: id,
          organisationId,
          organisationGroupId,
          guestUsers:
            organisation === ALL_GUEST_ORGANISATIONS_ID ? true : false,
        })
      )

      console.log(objects)

      await addServiceModuleAccesses({
        variables: {
          objects,
        },
        // refetchQueries: [
        //   {
        //     query: ServiceModuleAccessesByModuleIdsDocument,
        //     variables: { id: id },
        //   },
        // ],
      })

      await refetchServiceModuleAccesses()

      form.resetFields()

      setState(s => ({
        ...s,
        selectedModule: undefined,
        selectedOrganisation: undefined,
        selectedOrganisationGroup: undefined,
      }))

      notification.success({ message: t('permissions.notifications.success') })
    } catch (e) {
      notification.error({ message: String(e) })
    }
  }

  const handleWritableChange = async (
    serviceModuleAccessId: ServiceModuleAccesses['id'],
    writable: boolean,
    serviceModuleId?: bigint
  ) => {
    await updateServiceModuleAccess({
      variables: {
        pk_columns: { id: serviceModuleAccessId },
        _set: { writable },
      },
      update(cache, { data }) {
        const { serviceModuleId, writable: updatedWritable } =
          data?.update_serviceModuleAccesses_by_pk || {}
        if (!serviceModuleId) return
        const modifiedServiceModule = {
          __typename: 'serviceModules',
          id: serviceModuleId,
        }
        cache.modify({
          id: cache.identify(modifiedServiceModule),
          fields: {
            writable(cachedWritable) {
              return updatedWritable
            },
          },
          broadcast: true,
        })
      },
    })

    await refetchServiceModuleAccesses()

    notification.success({ message: t('permissions.notifications.success') })
  }

  const handleDelete = async (id: ServiceModuleAccesses['id']) => {
    await deleteServiceModuleAccess({ variables: { id } })

    await refetchServiceModuleAccesses()

    notification.success({
      message: t('permissions.notifications.successDelete'),
    })
  }

  const transformedAccesses = transformServiceAccesses(
    dataServiceModuleAccesses?.serviceModuleAccesses || [],
    organisationsData?.organisations || []
  )
  const dataSource = Object.values(transformedAccesses.rows).map((d, key) => ({
    ...d,
    key,
  }))

  const disabledServiceModules = (() => {
    if (
      !dataServiceModuleAccesses ||
      state.selectedOrganisation === undefined ||
      state.selectedOrganisationGroup === undefined
    ) {
      return []
    }

    return dataServiceModuleAccesses.serviceModuleAccesses
      .filter(sMA => {
        if (sMA.organisationId !== state.selectedOrganisation) {
          return false
        }

        if (
          sMA.organisationGroupId === null &&
          state.selectedOrganisationGroup !== ALL_GROUPS_ID
        ) {
          return false
        }

        if (
          sMA.organisationGroupId !== null &&
          sMA.organisationGroupId !== state.selectedOrganisationGroup
        ) {
          return false
        }

        return true
      })
      .map(sMA => sMA.serviceModuleId)
  })() // note the IIFE

  return (
    <PermissionsContainer>
      {hasUnsavedModules && (
        <AlertContainer>
          <Alert
            message={t('permissions.alerts.unsavedModules.message')}
            description={t('permissions.alerts.unsavedModules.description')}
            type="warning"
            showIcon
          />
        </AlertContainer>
      )}
      {!readOnlyMode && (
        <Form onFinish={handleSubmit} layout="vertical" form={form}>
          <HeaderBar>
            <Form.Item
              name={ORGANISATION}
              label={t('permissions.header.organisation')}
            >
              <Select
                loading={organisationsLoading}
                onChange={id => {
                  setState(s => ({
                    ...s,
                    selectedOrganisation: id,
                    selectedOrganisationGroup: ALL_GROUPS_ID,
                  }))

                  form.resetFields([ORGANISATION_GROUP])
                }}
              >
                <Select.Option value={ALL_ORGANISATIONS_ID}>
                  {t('permissions.options.allOrganisations')}
                </Select.Option>
                <Select.Option value={ALL_GUEST_ORGANISATIONS_ID}>
                  {t('permissions.options.guestOrganisations')}
                </Select.Option>
                {organisationsData?.organisations?.map((organisation, key) => (
                  <Select.Option value={organisation.id} key={key}>
                    {organisation.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={t('permissions.header.group')}
              name={ORGANISATION_GROUP}
              initialValue={ALL_GROUPS_ID}
            >
              <Select
                disabled={!chosenOrganisation}
                onChange={id =>
                  setState(s => ({ ...s, selectedOrganisationGroup: id }))
                }
              >
                <Select.Option value={ALL_GROUPS_ID}>
                  {t('permissions.options.allGroups')}
                </Select.Option>

                {chosenOrganisation &&
                  chosenOrganisation.organisationGroups.map(
                    (organisationGroup, key) => (
                      <Select.Option value={organisationGroup.id} key={key}>
                        {organisationGroup.name}
                      </Select.Option>
                    )
                  )}
              </Select>
            </Form.Item>

            <Form.Item label={t('permissions.header.access')} name={MODULE}>
              <Select
                disabled={!chosenOrganisation}
                onChange={v => setState(s => ({ ...s, selectedModule: v }))}
                mode="multiple"
                maxTagCount="responsive"
              >
                {serviceModules
                  .filter(m => m.id !== undefined)
                  .map((serviceModule, key) => (
                    <Select.Option
                      value={serviceModule.id}
                      key={key}
                      disabled={disabledServiceModules.includes(
                        serviceModule.id
                      )}
                    >
                      {serviceModule.name !== ''
                        ? serviceModule.name
                        : `${t('configService.module.newModule')} (${key})`}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                icon={<PlusOutlined />}
                disabled={
                  state.selectedModule === undefined ||
                  (Array.isArray(state.selectedModule) &&
                    state.selectedModule.length === 0)
                }
                htmlType="submit"
              >
                {t('permissions.header.add')}
              </Button>
            </Form.Item>
          </HeaderBar>
        </Form>
      )}

      {!readOnlyMode && <Divider />}

      <Heading level={4}>{t('permissions.headline')}</Heading>

      <Table
        pagination={false}
        columns={columns}
        expandable={{
          expandedRowRender: row => {
            const permissionMatrixProps: ServicePermissionsMatrixProps = {
              rows: row.serviceModuleAccesses.map(a => {
                const module = serviceModules.find(
                  m => m.id === a.serviceModuleId
                )

                if (!module) {
                  // TODO: real error message, shouldn't happen tho
                  throw new Error(
                    `Couldn't find module with id ${a.serviceModuleId}`
                  )
                }

                return {
                  serviceModuleId: a.serviceModuleId,
                  writable: !!a.writable,
                  serviceModuleName: module.name || '',
                  serviceModuleAccessId: a.id,
                }
              }),
            }

            return (
              <ServicePermissionsMatrix
                {...permissionMatrixProps}
                onWritableChange={handleWritableChange}
                onAccessDelete={handleDelete}
                readOnly={readOnlyMode}
              />
            )
          },
        }}
        dataSource={dataSource}
      />
    </PermissionsContainer>
  )
}

const PermissionsContainer = styled.div`
  margin-top: 20px;
  border: 1px solid ${props => props.theme.borderColorBase};
  border-radius: 3px;
  padding: 1.25rem;
  background-color: #fff;
`
const HeaderBar = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 15px;

  & > div {
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    select {
      width: 100%;
    }
  }
`

const AlertContainer = styled.div({ marginBottom: '1.25rem' })
