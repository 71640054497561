import { V_Organice_Event_Stamm, Events_Insert_Input } from '@organice/graphql'
import { GeneralDict } from '@organice/utils'
import dayjs from 'dayjs'

export type rubinStammSelectObject = {
  key: string
  label: string
  value: string
}

type ConvertRubinStammModelProps =
  | rubinStammSelectObject
  | V_Organice_Event_Stamm

export const convertRubinStammModel = (
  input?: ConvertRubinStammModelProps,
  values?: GeneralDict
) => {
  function convert(item: rubinStammSelectObject | V_Organice_Event_Stamm) {
    if ('value' in item) {
      const eventsInsertInput: Events_Insert_Input = {
        rubinHashEvent: item.value,
      }
      return eventsInsertInput
    }

    if (!item.T_HASH_Event) {
      return
    }

    const labelStr = `${item.Titel} (Rubin-ID: ${item.ID_Rubin})`
    const labelWithDate =
      labelStr +
      ` ${item.Startdatum && dayjs(item.Startdatum).format('DD.MM.YYYY')} - ${
        item.Enddatum && dayjs(item.Enddatum).format('DD.MM.YYYY')
      }`

    return {
      key: item.T_HASH_Event,
      label: labelWithDate,
      value: item.T_HASH_Event,
    }
  }
  if (input === null) return null
  if (input === undefined) return undefined

  return convert(input)
}
