import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'

/* FieldWrapper */
interface FieldWrapperComponent extends React.FC {
  Display: typeof Display
  Edit: typeof Edit
}

export const FieldWrapper: FieldWrapperComponent = ({
  children,
}): ReactElement => {
  return <FieldWrapperStyled>{children}</FieldWrapperStyled>
}

/* FieldWrapper.Display */
interface DisplayProps {
  isEditMode?: boolean
  isTitle?: boolean
}

const Display: React.FC<DisplayProps> = ({ children, isEditMode = false }) => {
  if (isEditMode) return null

  return <FieldWrapperDisplayStyled>{children}</FieldWrapperDisplayStyled>
}
FieldWrapper.Display = Display

/* FieldWrapper.Edit */
interface EditProps {
  isEditMode?: boolean
  isHeading?: boolean
  level?: 1 | 2 | 3 | 4 | 5 // like Typography.Heading from antD
}

type HeadingSize = {
  fontSize: string
  lineHeight: number
}

const Edit: React.FC<EditProps> = ({
  children,
  isEditMode = false,
  isHeading,
  level,
}) => {
  if (!isEditMode) return null

  const theme = useTheme()
  const headlineSizes = [
    theme.heading1Size,
    theme.heading2Size,
    theme.heading3Size,
    theme.heading4Size,
    theme.heading5Size,
  ]

  return isHeading ? (
    <FieldWrapperEditTitleStyled
      fontSize={level ? headlineSizes[level - 1] : headlineSizes[0]}
      lineHeight={level !== 1 ? 1.35 : 1.23}
    >
      {children}
    </FieldWrapperEditTitleStyled>
  ) : (
    <FieldWrapperEditStyled>{children}</FieldWrapperEditStyled>
  )
}
FieldWrapper.Edit = Edit

const FieldWrapperStyled = styled.div`
  width: 100%;
`

const FieldWrapperEditStyled = styled.div`
  width: 100%;
`

const FieldWrapperEditTitleStyled = styled.div<HeadingSize>`
  input {
    font-size: ${props => props.fontSize};
    font-weight: 600;
    line-height: ${props => props.lineHeight}; //1.35; //1.23;
  }
`

const FieldWrapperDisplayStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .ant-typography {
    margin: 0;
    width: auto;
    padding: 5px 12px; // 1px border of input added
  }
`
