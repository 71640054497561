import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { Divider, Tooltip } from 'antd'
import { MentionsParser } from '@organice/utils/mentions'
import {
  EditOutlined,
  FormOutlined,
  EllipsisOutlined,
  ContainerOutlined,
  CheckOutlined,
  UndoOutlined,
  GlobalOutlined,
} from '@ant-design/icons'
import { Button } from '../button'
import { Comment } from '../comment'
import { Icon } from '../icon'
import { Paragraph } from '../paragraph'
import { Text } from '../text'
import { DeleteButton } from '../deleteButton'

import { Popover } from '../../molecules/popover'

import IconBusiness from '@organice/icons/business.svg?react'
import { TimeUtil, Dayjs } from '@organice/utils/date'
import { CommentForm } from './../../molecules/commentForm'

import { useTranslation } from 'react-i18next'
import { useCommentsContext } from '@organice/contexts/comments/useCommentsContext'
import { useMeContext } from '@organice/contexts/me'
import { useMentionsContext } from '@organice/contexts/mentions'
import {
  useArchiveComment,
  useDeleteComment,
  useIsPublicComment,
} from '@organice/utils/hooks/comments'

import {
  CommentTypes_Enum,
  AllCommentGraphQLTypes as CommentTyped,
} from '@organice/contexts/comments/commentsContext'
import { GeneralDict } from '@organice/utils/form'
import { Person } from '@organice/molecules'

const ANIMATION_DURATION_SECONDS = 1.5

type CommentEntryProps = CommentTyped & {
  type: CommentTypes_Enum
  subComments?: boolean
  archived?: boolean
  additionalData?: Record<string, any>
  userInfo?: GeneralDict | null
}

export const CommentEntry: React.FC<CommentEntryProps> = props => {
  const {
    state: { me, isAdmin, isNM },
  } = useMeContext()

  const { t } = useTranslation()
  const [addState, setAddState] = React.useState(false)
  const [editState, setEditState] = React.useState(false)
  const [showPopover, setShowPopover] = React.useState(false)
  const {
    state: { users, events },
  } = useMentionsContext()
  const {
    state: { highlight },
    dispatch,
  } = useCommentsContext()
  const { deleteComment, loading: deleteLoading } = useDeleteComment({
    commentType: props.type,
  })
  const {
    archiveComment,
    restoreComment,
    loading: archiveLoading,
  } = useArchiveComment({
    commentType: props.type,
  })
  const {
    setPublicComment,
    setPrivateComment,
    loading: setPublicLoading,
  } = useIsPublicComment({
    commentType: props.type,
  })

  const [data, setData] = React.useState(props)

  React.useEffect(() => {
    const isLoading = deleteLoading || setPublicLoading || archiveLoading
    if (isLoading) {
      dispatch({ type: 'UPDATE_DATA' })
    } else {
      dispatch({ type: 'UPDATED_DATA' })
    }
  }, [deleteLoading, archiveLoading, setPublicLoading])

  React.useEffect(() => setData(props), [props])

  const [commentParsed, setCommentParsed] = React.useState(
    MentionsParser.decode(data.comment, false, isAdmin)
  )

  const [commentRaw, setCommentRaw] = React.useState(
    MentionsParser.decode(data.comment, true, isAdmin) as string
  )

  React.useEffect(() => {
    if (data) {
      setCommentParsed(MentionsParser.decode(data.comment, false, isAdmin))
      setCommentRaw(MentionsParser.decode(data.comment, true) as string)
    }
  }, [data])

  const editComment = () => {
    setShowPopover(false)
    setEditState(true)
  }

  const renderMenu = () => (
    <ContextMenuList>
      <ContextMenuListItem>
        <Button type="text" icon={<EditOutlined />} onClick={editComment}>
          {t('comment.edit')}
        </Button>
      </ContextMenuListItem>

      {data.subComments && !props?.userInfo?.isGuestUser && (
        <ContextMenuListItem key={props.id}>
          <Button
            type="text"
            icon={
              data.organisationId ? (
                <GlobalOutlined />
              ) : (
                <Icon component={IconBusiness} />
              )
            }
            onClick={() => {
              data.organisationId
                ? setPublicComment(props.id)
                : setPrivateComment(props.id, me?.organisationId)

              setShowPopover(false)
            }}
          >
            {t(`comment.${data.organisationId ? 'makePublic' : 'makePrivate'}`)}
          </Button>
        </ContextMenuListItem>
      )}
      <ContextMenuDivider />
      <ContextMenuListItem>
        <DeleteButton.ModalConfirm
          title={t('comment.deleteConfirmTitle')}
          value={`${t('comment.delete')}`}
          danger
          type="text"
          onOk={() => {
            deleteComment(props.id)
          }}
          onClick={() => setShowPopover(false)}
          okText={t('comment.deleteConfirmYes')}
          cancelText={t('common.no')}
        >
          {t('comment.deleteConfirmContent')}
        </DeleteButton.ModalConfirm>
      </ContextMenuListItem>
    </ContextMenuList>
  )

  const parseCommentTime = (
    createdAt: string,
    updatedAt: string
  ): React.ReactNode => {
    const _createdAt: Dayjs = TimeUtil.getUtc(createdAt)
    const _updatedAt: Dayjs = TimeUtil.getUtc(updatedAt)

    if (!_updatedAt || _createdAt.isSame(_updatedAt))
      return <Text type="secondary">{_createdAt.fromNow()}</Text>

    return (
      <>
        <Text type="secondary">{_createdAt.fromNow()}</Text>{' '}
        <Text italic type="secondary">
          ({t('comment.changed')} {_updatedAt.fromNow()})
        </Text>
      </>
    )
  }

  React.useEffect(() => {
    window.setTimeout(() => dispatch({ type: 'REMOVE_HIGHLIGHT' }), 1000)
  }, [])

  return (
    <Container animation={highlight === data.id}>
      <Comment
        datetime={parseCommentTime(data.createdAt, data.updatedAt)}
        actions={[
          <>
            {!data.archived && data.user?.id === me?.id && (
              <Popover
                trigger="hover"
                open={showPopover}
                onOpenChange={showPopover => setShowPopover(showPopover)}
                placement="bottomRight"
                content={renderMenu}
              >
                <Button size={'small'} icon={<EllipsisOutlined />}></Button>
              </Popover>
            )}
          </>,
          <>
            {data.archived &&
              data.subComments &&
              (isAdmin || isNM || data.user?.id === me?.id) && (
                <Tooltip title={t('comment.restore')}>
                  <Button
                    icon={<UndoOutlined />}
                    size={'small'}
                    onClick={() => {
                      dispatch({ type: 'UPDATE_DATA' })
                      restoreComment(props.id)
                    }}
                  ></Button>
                </Tooltip>
              )}
          </>,
        ]}
        author={
          <strong>
            {data.user?.id ? (
              <Person user={data.user as { id: number } & Record<string, any>}>
                {data.user?.firstName} {data.user?.lastName}
              </Person>
            ) : null}
          </strong>
        }
        content={
          !editState ? (
            <Paragraph type={data.archived ? 'secondary' : undefined}>
              {Array.isArray(commentParsed)
                ? commentParsed.map((commentPart, i) => (
                    <React.Fragment key={`c-${props.id}-p${i}`}>
                      {commentPart}
                    </React.Fragment>
                  ))
                : commentParsed}{' '}
            </Paragraph>
          ) : (
            <CommentForm
              type={props.type}
              data={{
                ...data,
                comment: commentRaw,
              }}
              userInfo={props?.userInfo}
              addLabel={t('comment.change')}
              addIcon={<CheckOutlined />}
              onClose={() => setEditState(false)}
              onSuccess={() => {
                setEditState(true)
              }}
            />
          )
        }
      >
        {data.subComments && (
          <>
            {data.comments?.map((comment: CommentTyped, i) => (
              <React.Fragment key={`c-${comment.id}-p${i}`}>
                {comment?.id && (
                  <CommentEntry
                    type={props.type}
                    {...comment}
                    key={`comment:${comment?.id}`}
                    archived={data.archived}
                  />
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </Comment>

      {!data.archived && data.subComments && (
        <BottomContainer>
          {addState ? (
            <CommentForm
              type={props.type}
              userInfo={props?.userInfo}
              onClose={() => setAddState(false)}
              additionalData={{ parentId: data.id, ...props.additionalData }}
              onSuccess={() => setAddState(false)}
            />
          ) : (
            <CommentActionsBar>
              <Button
                disabled={
                  !!(
                    props.userInfo?.isGuestTab &&
                    props.userInfo?.isNMUser &&
                    !props.userInfo?.guestCanRead
                  )
                }
                icon={<FormOutlined />}
                onClick={() => setAddState(true)}
              >
                {t('comment.comment')}
              </Button>
              {(isAdmin || isNM || data.user?.id === me?.id) && (
                <Button
                  icon={<ContainerOutlined />}
                  onClick={() => {
                    dispatch({ type: 'UPDATE_DATA' })
                    archiveComment(props.id)
                  }}
                >
                  {' '}
                  {t('comment.archive')}
                </Button>
              )}
            </CommentActionsBar>
          )}
          <Divider />
        </BottomContainer>
      )}
    </Container>
  )
}

const Container = styled.div<{ animation: boolean }>(
  ({ theme, animation }) => ({
    '.ant-comment-content-detail': {
      animation: animation
        ? `${keyframes`
    from {
      background-color: ${theme.primaryColor};
    }
  `} ${ANIMATION_DURATION_SECONDS}s ease-out`
        : undefined,
    },

    '&:hover': {
      backgroundColor: theme.grey3,
    },
  })
)
const BottomContainer = styled.div({
  padding: '0 2.5rem',
})

const CommentActionsBar = styled.div({
  display: 'flex',
  gap: '0.625rem',
})

const ContextMenuList = styled.ul({
  padding: 0,
  margin: 0,
})

const ContextMenuListItem = styled.li(({ theme }) => ({
  listStyleType: 'none',
}))

const ContextMenuDivider = styled(Divider)({ margin: '.5rem 0' })
