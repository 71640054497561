import React from 'react'
import { AllCommentGraphQLTypes } from '@organice/contexts/comments/commentsContext'

export const ValueContext = React.createContext<{
  displayValues: { [key: string]: any }
  fieldKeyToIdMap: { [key: string]: Pick<AllCommentGraphQLTypes, 'id'> }
  occurrenceData?: {
    roomServiceOccurrenceId?: number | null
  }
}>({ displayValues: {}, fieldKeyToIdMap: {}, occurrenceData: {} })
