import { AGREEMENT_VERSION } from '@organice/config'
import { useMeContext } from '@organice/contexts/me'
import { IRoute } from '@organice/router/config'
import React from 'react'
import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom'
import { LoadingIndicator } from '../loadingIndicator'

interface ProtectedRouteProps extends RouteProps {
  adminOnly?: IRoute['adminOnly']
  orgaAdminOnly?: IRoute['orgaAdminOnly']
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
  const {
    state: { me, loading, error, isNM, isAdmin, isOrgaAdmin, agreementVersion },
  } = useMeContext()

  const { pathname } = useLocation()
  const redirectAfterLogin =
    pathname !== '/' ? `?next=${encodeURI(pathname)}` : ''

  if (loading) {
    return <LoadingIndicator />
  }

  if (error && !me) {
    return <Redirect to={`/login${redirectAfterLogin}`} />
  }
  // ADMIN ONLY
  if (props.adminOnly && me && !loading && !isAdmin) {
    return <Redirect to="/404" />
  }
  // ORGA ADMIN ONLY
  if (props.orgaAdminOnly && me && !loading && !isOrgaAdmin && !isAdmin) {
    return <Redirect to="/404" />
  }

  if (
    me &&
    props.path !== '/account/confidentiality-agreement' &&
    !isAdmin &&
    !isNM &&
    (agreementVersion || 0) < AGREEMENT_VERSION
  ) {
    return <Redirect to="/account/confidentiality-agreement" />
  }

  return <Route {...props} />
}
