import React from 'react'

import { CommentEntry } from '@organice/atoms/commentEntry'
import { GeneralDict } from '@organice/utils/form'
import {
  CommentTypes_Enum,
  AllCommentGraphQLTypes as Comments,
} from '@organice/contexts/comments/commentsContext'

interface CommentsListProps {
  comments: Comments[]
  type: CommentTypes_Enum
  archived?: boolean
  additionalData?: GeneralDict
  organisationId?: number
  userInfo?: GeneralDict | null
}

export const CommentsList: React.FC<CommentsListProps> = ({
  comments,
  type,
  archived,
  additionalData,
  organisationId,
  userInfo,
}) => {
  let addData = additionalData
  if (organisationId) {
    addData = { ...addData, organisationId }
  }
  return (
    <>
      {comments?.map(comment => (
        <CommentEntry
          key={`comment:${comment.id}`}
          type={type}
          additionalData={addData}
          {...comment}
          subComments={true}
          archived={archived}
          userInfo={userInfo}
        />
      ))}
    </>
  )
}
