import React from 'react'
import { HeaderProps } from '.'

// export interface HeaderProps {
//     renderRight?(): React.ReactNode
//     hideNavigation?: boolean
//     hideBreadCrumb?: boolean
//   }

type HeaderContextType = {
  headerProps: HeaderProps | undefined
  setHeaderProps: React.Dispatch<React.SetStateAction<HeaderProps | undefined>>
}

export const HeaderContext = React.createContext<HeaderContextType | undefined>(
  undefined
)
HeaderContext.displayName = 'HeaderContext'

type HeaderProviderProps = { children: React.ReactNode }

export function HeaderProvider({ children }: HeaderProviderProps) {
  const [headerProps, setHeaderProps] = React.useState<
    HeaderProps | undefined
  >()
  const value = { headerProps, setHeaderProps }
  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  )
}

type useHeaderReturn = HeaderContextType

export function useHeader(): useHeaderReturn {
  const context = React.useContext(HeaderContext)

  if (context === undefined) {
    throw new Error('useHeader must be used within a HeaderProvider')
  }

  return context
}
