import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import * as durationPlugin from 'dayjs/plugin/duration'
import isSameOrafter from 'dayjs/plugin/isSameOrAfter'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import isoWeek from 'dayjs/plugin/isoWeek'
// this allows the use of the variable name "timezone" later on while pleasing tslint
import * as timezonePlugin from 'dayjs/plugin/timezone'

import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(advancedFormat)
dayjs.extend(timezonePlugin.default)
dayjs.extend(durationPlugin.default)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrafter)
dayjs.extend(isBetween)
dayjs.extend(LocalizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(isLeapYear)
dayjs.extend(isoWeek)

export interface OffsetToStringMapping {
  timezone: string
  dst: boolean
  string: string
}

export interface OffsetToStringConfig {
  mappings: OffsetToStringMapping[]
}

export interface TimezoneDataMapping {
  [key: string]: { [key: string]: { dst: string; nonDst: string } }
}

export const MOCK_TIME = 'mockTime'
export const MOCK_TIME_ZONE = 'mockTimezone'

export interface MockDate {
  freezedMockDate?: string
  mockDiffMs?: number
}
export type Dayjs = dayjs.Dayjs

export class TimeUtil {
  public static dayjs = dayjs

  /**
   * Returns an instance in local time for a given string representation of a date
   */
  public static get = (s: string): dayjs.Dayjs => {
    return dayjs(s).local()
  }

  /**
   * Returns an instance in UTC time zone for a given string
   */
  public static getUtc = (s: string): dayjs.Dayjs => dayjs.utc(s)

  /**
   * Returns an instance with the current time based on your local time
   */
  public static getNow = (): dayjs.Dayjs => {
    const timezone = TimeUtil.getUsersTimezone()

    return dayjs().tz(timezone)
  }

  /**
   * Returns an instance with the current utc time
   * @param s
   */
  public static getNowUtc = (): dayjs.Dayjs => {
    return dayjs.utc()
  }

  /**
   * Converts a given instance into an instance in utc time
   *
   * @param d
   */
  public static convertToUtc = (d: dayjs.Dayjs): dayjs.Dayjs => d.utc()

  /**
   * Converts a given instance into a given timezone
   */
  public static convertToTimeZone = (
    d: dayjs.Dayjs,
    timezone: string
  ): dayjs.Dayjs => d.tz(timezone)

  /**
   * Returns the UTC offset for a given timezone
   */
  public static getUtcOffsetForTz = (tz: string): number =>
    dayjs.utc().tz(tz).utcOffset()

  /**
   * Returns true if a given time zone is in DST right now
   * @param tz
   */
  public static getIsDst = (date: dayjs.Dayjs, tz: string): boolean => {
    // https://stackoverflow.com/a/11888430
    const jan = dayjs.tz('2020-01-01', tz)
    const jun = dayjs.tz('2020-06-01', tz)

    // if there is no difference, there is no DST for the given time zone
    if (jan.utcOffset() === jun.utcOffset()) {
      return false
    }

    return (
      dayjs.tz(date.format('YYYY-MM-DDD'), tz).utcOffset() === jun.utcOffset()
    )
  }

  /**
   * Returns the current user's time zone as detected by the browser
   */
  public static getUsersTimezone(): string {
    // this gets the time zone from the browser
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  /**
   * Returns a duration object based on a given time in ms
   *
   * @param ms
   */
  public static getDuration = (ms: number): plugin.Duration =>
    dayjs.duration(ms, 'ms')

  /**
   * Setting i18n
   *
   * Example:
   * import locale_de from 'dayjs/locale/de'
   * TimeUtil.setLocale(locale_de)
   */
  public static setLocale = (locale: string | ILocale): string =>
    dayjs.locale(locale)

  public static getDayjs = () => dayjs

  public static getAbbrName = (): string => dayjs().format('z')

  /**
   * Extend dayjs with given Plugin
   */
  public static extend = (plugin: dayjs.PluginFunc): dayjs.Dayjs =>
    dayjs.extend(plugin)

  /**
   * returns the localized date range string
   *
   */

  public static getRangeString = (
    start: dayjs.Dayjs,
    end: dayjs.Dayjs,
    // tslint:disable-next-line: typedef
    hideTime = false
  ): string => {
    const localeFormat: string =
      dayjs.Ls[dayjs.locale()].formats.LL || 'MMMM D, YYYY' // fallback EN locale
    const LLWithoutYear: string =
      localeFormat.replace(', YYYY', '').replace('YYYY', '') || 'LL'
    const dayFormat: string = localeFormat.includes('D.') ? 'D.' : 'D'
    const yearFormat: string = localeFormat.includes(', YYYY')
      ? ', YYYY'
      : 'YYYY'

    // Same Date
    if (start.format('HHmmDMYYYY') === end.format('HHmmDMYYYY')) {
      return start.format(`LL${!hideTime ? 'L' : ''}`)
    }

    // Same Day
    if (start.format('DMYYYY') === end.format('DMYYYY')) {
      if (hideTime) {
        return start.format('LL')
      }

      return `${start.format('LLL')} - ${end.format('LT')}`
    }

    // Same Month
    if (start.format('MMYYYY') === end.format('MMYYYY')) {
      const localLL = localeFormat?.replace(
        dayFormat,
        `${start.format(dayFormat)}${!hideTime ? ' LT ' : ''} - ${end.format(
          dayFormat
        )}${!hideTime ? ' LT ' : ''}`
      )

      return end.format(localLL)
    }

    // Same Year
    if (start.format('YYYY') === end.format('YYYY')) {
      return `${start.format(
        `${LLWithoutYear} ${!hideTime ? ' LT ' : ''}`
      )} - ${end.format(
        `${LLWithoutYear}${!hideTime ? ' LT' : ''}${yearFormat}`
      )}`.trim()
    }

    return `${start.format(
      `${LLWithoutYear}${yearFormat}${!hideTime ? ' LT ' : ''}`
    )} - ${end.format(
      `${LLWithoutYear}${yearFormat}${!hideTime ? ' LT ' : ''}`
    )}`.trim()
  }

  /**
   * returns the localized date range string
   *
   */

  public static sortDateArray = (
    dateArray: (Dayjs | undefined)[],
    direction: 'asc' | 'desc' = 'asc'
  ) => {
    try {
      direction === 'asc'
        ? dateArray.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1))
        : dateArray.sort((a, b) => (dayjs(a).isBefore(dayjs(b)) ? 1 : -1))
    } catch (e) {
      console.error(`sortDateArray: Could not sort dateArray}`, dateArray)
      return dateArray
    }
  }

  public static sortDateArray2D = (
    dateArray: (Dayjs | undefined | string)[][],
    direction: 'asc' | 'desc' = 'asc'
  ) => {
    try {
      if (direction === 'asc') {
        dateArray.sort((a, b) => {
          if (dayjs(a[0]).isAfter(dayjs(b[0]))) {
            return 1
          } else {
            if (
              dayjs(a[0]).isSame(dayjs(b[0])) &&
              dayjs(a[1]).isAfter(dayjs(b[1]))
            ) {
              return 1
            } else {
              return -1
            }
          }
        })
      } else {
        dateArray.sort((a, b) => {
          if (dayjs(a[0]).isBefore(dayjs(b[0]))) {
            return 1
          } else {
            if (
              dayjs(a[0]).isSame(dayjs(b[0])) &&
              dayjs(a[1]).isBefore(dayjs(b[1]))
            ) {
              return 1
            } else {
              return -1
            }
          }
        })
      }
    } catch (e) {
      console.error(`sortDateArray: Could not sort dateArray}`, dateArray)
      return dateArray
    }
  }

  public static getDatetimeStringRelativeToNow(
    date: Dayjs | string,
    translations: {
      today: string
      yesterday: string
    },
    withTime = true
  ) {
    const dayjsDate = date instanceof dayjs ? (date as Dayjs) : dayjs(date)
    const now = dayjs()
    let dateString = dayjsDate.format('DD.MM.YYYY')

    if (dayjsDate.isSame(now, 'year')) {
      dateString = dayjsDate.format('dd, D. MMMM')
    }
    if (dayjsDate.isSame(now, 'day')) {
      dateString = translations.today || dateString
    }
    if (dayjsDate.isSame(now.subtract(1, 'day'), 'day')) {
      dateString = translations.yesterday || dateString
    }

    const time = dayjsDate.format(`HH:mm`)

    return withTime ? `${dateString} ${time}` : dateString
  }
}
