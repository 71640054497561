import { TimeUtil } from '@organice/utils'
import { Dayjs } from 'dayjs'
import {
  RoomMatrixBarData,
  RoomMatrixData,
  NewRoomMatrixDateRowConfig,
  RowTypePrefixes,
  BarTypePrefixes,
  BarType,
  RowType,
  RoomMatrixRowTreeElement,
} from './roomMatrixTypes'
import { atom, RecoilState } from 'recoil'

const dayjs = TimeUtil.getDayjs()

export const CURRENT_DATE_POSITION = 50

export interface GetDateConfig {
  knownDate: Dayjs
  knownDatePositionPercent: number
  newDatePositionPercent: number
  resolutionInMinutes: number
}

export interface GetPositionConfig {
  knownDate: Dayjs
  knownDatePosition: number
  newDate: Dayjs
  resolutionInMinutes: number
}

export interface MinutesToPixelsConfig {
  minutes: number
  resolution: number
  widthPx: number
}

export class RoomMatrixUtil {
  static replaceBarData = (
    data: RoomMatrixData,
    bar: RoomMatrixBarData
  ): RoomMatrixData => ({
    ...data,
    bars: RoomMatrixUtil.replaceBar(data.bars, bar),
  })

  static replaceBar = (
    bars: RoomMatrixBarData[],
    barToReplace: RoomMatrixBarData
  ) => {
    const remainingBars = bars.filter(b => b.id !== barToReplace.id)

    return [...remainingBars, barToReplace]
  }

  static minutesToPixels = ({
    minutes,
    resolution,
    widthPx,
  }: MinutesToPixelsConfig) => Math.round((minutes / resolution) * widthPx)

  static getConfigForResolution = function <
    T extends Pick<NewRoomMatrixDateRowConfig, 'minResolution'>,
  >(resolution: number, configs: T[]) {
    if (configs.length === 0) {
      throw new Error(`Got empty resolution config object`)
    }

    const eligibleConfigs = configs.filter(c => c.minResolution <= resolution)

    return eligibleConfigs.reduce<T>((result, current) => {
      if (current.minResolution > result.minResolution) {
        return current
      }

      return result
    }, eligibleConfigs[0])
  }

  static getPositionForDate = ({
    knownDate,
    knownDatePosition,
    newDate,
    resolutionInMinutes,
  }: GetPositionConfig) => {
    if (dayjs(knownDate).isSame(newDate)) {
      return knownDatePosition
    }

    const minutesBetweenDates = RoomMatrixUtil.getMinutesForTimeSpan(
      knownDate,
      newDate
    )

    const minutesInPercent = (minutesBetweenDates * 100) / resolutionInMinutes

    if (dayjs(newDate).isBefore(knownDate)) {
      return knownDatePosition - minutesInPercent
    }

    return knownDatePosition + minutesInPercent
  }

  static getDateForPosition = ({
    resolutionInMinutes,
    newDatePositionPercent,
    knownDate,
    knownDatePositionPercent,
  }: GetDateConfig) => {
    const onePercentInMinutes = resolutionInMinutes / 100
    const differencePercent = newDatePositionPercent - knownDatePositionPercent

    const minutesToAdd = Math.round(onePercentInMinutes * differencePercent)
    const newDate = dayjs(knownDate).add(minutesToAdd, 'minutes')

    return newDate
  }

  static getMinutesForTimeSpan = (date1: Dayjs, date2: Dayjs) =>
    // the boolean argument makes moment return a floating point value that is rounded to full minutes
    // the Math.abs is used, because this function is supposed to give the distance between two dates regardless of
    // the direction
    Math.abs(Math.round(dayjs(date1).diff(date2, 'minutes', true)))

  static getWidthForTimeSpan = (
    date1: Dayjs,
    date2: Dayjs,
    resolution: number
  ): number => {
    const diff = RoomMatrixUtil.getMinutesForTimeSpan(date1, date2)

    return RoomMatrixUtil.getWidthForMinutes(diff, resolution)
  }

  static getWidthForMinutes = (minutes: number, resolution: number) => {
    return (minutes * 100) / resolution
  }

  static rowContainsBar = (row: DOMRect, bar: DOMRect) => {
    if (RoomMatrixUtil.getIsWithin(row, bar)) {
      return true
    }

    const intersection = RoomMatrixUtil.getIntersection(row, bar)

    if (!intersection) {
      return false
    }

    const intersectionInPercent = intersection / bar.height

    if (intersectionInPercent === 0.5) {
      // if the intersection is exactly 50 percent of the bar, the bar is contained by the top row (which has the intersection on the bottom)
      return typeof RoomMatrixUtil.getIntersectionTop(row, bar) !== 'undefined'
    }

    return intersectionInPercent > 0.5
  }

  private static getIsWithin = (row: DOMRect, bar: DOMRect) => {
    return row.top <= bar.top && row.bottom >= bar.bottom
  }

  private static getIntersection = (row: DOMRect, bar: DOMRect) => {
    const intersectionTop = RoomMatrixUtil.getIntersectionTop(row, bar)

    if (intersectionTop) {
      return intersectionTop
    }

    const intersectionBottom = RoomMatrixUtil.getIntersectionBottom(row, bar)

    return intersectionBottom
  }

  private static getIntersectionTop = (row: DOMRect, bar: DOMRect) => {
    const intersectsTop = bar.bottom > row.top && bar.top < row.top

    if (!intersectsTop) {
      return
    }

    return bar.bottom - row.top
  }

  private static getIntersectionBottom = (row: DOMRect, bar: DOMRect) => {
    const intersectsBottom = bar.bottom > row.bottom && bar.top < row.bottom

    if (!intersectsBottom) {
      return
    }

    return row.bottom - bar.top
  }
}

// export function getPrefixByType(type: RowType | BarType) {
//   let prefix = null

//   Object.keys(BarType).forEach(barTypeKey => {
//     if (type === BarType[barTypeKey as keyof BarType]) {
//       prefix = BarTypePrefixes[barTypeKey as keyof BarTypePrefixes]
//     }
//   })
//   if (prefix) return prefix

//   const rowTypePrefix = Object.keys(RowType).forEach(rowTypeKey => {
//     if (type === RowType[rowTypeKey as keyof RowType]) {
//       prefix = RowTypePrefixes[rowTypeKey as keyof RowTypePrefixes]
//     }
//   })

//   return prefix
// }

export function idToKey(
  id: number | string,
  prefix: RowTypePrefixes | BarTypePrefixes
) {
  return btoa(`${prefix}.${id}`)
}
export function keyToId(key: string) {
  try {
    const id = parseInt(String(atob(key).split('.').pop()))
    return Number.isNaN(id) ? null : id
  } catch {
    // New Keys are not base64
    const id = parseInt(String(key.split('.').pop()))

    return Number.isNaN(id) ? null : id
  }
}

export function getTypeByKey(key: string) {
  try {
    const [bar, barType, id] = atob(key).split('.')

    if (Number.isNaN(id)) {
      return false
    }

    switch (`${bar}.${barType}`) {
      case BarTypePrefixes.RoomOccurrence:
        return BarType.RoomOccurrence
        break
      case BarTypePrefixes.RoomServiceOccurrence:
        return BarType.RoomServiceOccurrence
        break
      default:
        return false
    }
  } catch {
    return null
  }
}

export function getRowTypeByKey(key: string) {
  try {
    const [rowType, id] = atob(key).split('.')

    if (Number.isNaN(id)) {
      return false
    }

    switch (rowType) {
      case RowTypePrefixes.RoomFolder:
        return RowType.RoomFolder
        break
      case RowTypePrefixes.EventRooms:
        return RowType.EventRooms
        break
      case RowTypePrefixes.Rooms:
        return RowType.Rooms
        break
      case RowTypePrefixes.RoomService:
        return RowType.RoomService
        break
      default:
        return false
    }
  } catch {
    return null
  }
}

type GlobalMatrixObjectMap = {
  [key: string]: {
    name: string
    path: string[]
  }
}

export function createGlobalMatrixObjectMap(
  tree: RoomMatrixRowTreeElement[],
  bars: RoomMatrixBarData[]
) {
  function parseTree(t: RoomMatrixRowTreeElement[], path: string[] = []) {
    let roomsMap: GlobalMatrixObjectMap = {}

    t.forEach(item => {
      roomsMap[item.key] = {
        name: item.name,
        path: [...path, String(item.key)],
      }

      if (item.children && item.children.length) {
        roomsMap = {
          ...roomsMap,
          ...parseTree(item.children, [...path, String(item.key)]),
        }
      }
    })

    return roomsMap
  }

  function parseBars(
    globalRoomObjectMap: GlobalMatrixObjectMap,
    bars: RoomMatrixBarData[]
  ) {
    const barsMap: GlobalMatrixObjectMap = {}

    bars.forEach(b => {
      barsMap[b.id] = {
        name: String(b.content),
        path: globalRoomObjectMap[b.parentId]
          ? [...globalRoomObjectMap[b.parentId].path, String(b.id)]
          : [],
      }
    })

    return barsMap
  }

  const globalRoomObjectMap = parseTree(tree)
  const globalBarObjectMap = parseBars(globalRoomObjectMap, bars)
  return { ...globalRoomObjectMap, ...globalBarObjectMap }
}

export function getGobalMatrixObjectById(
  id: string | undefined,
  globalMatrixObjectMap: GlobalMatrixObjectMap
) {
  if (!id || !globalMatrixObjectMap[id]) return null
  return {
    ...globalMatrixObjectMap[id],
    namePath: globalMatrixObjectMap[id].path.map(itemId => {
      return globalMatrixObjectMap[itemId]
        ? globalMatrixObjectMap[itemId].name
        : ''
    }),
  }
}

const defaultStepSizeInMinutes = 5
export const getSteppedDifferenceInMinutes = (
  differenceInMinutes: number,
  stepSizeInMinutes: number = defaultStepSizeInMinutes
) => Math.round(differenceInMinutes / stepSizeInMinutes) * stepSizeInMinutes

const memoizeAtomByKey = (func: (key: string, defaultVal: any) => any) => {
  const results: Record<string, any> = {}

  return (key: string, defaultVal?: any) => {
    if (!key) return func(key, defaultVal)

    const argsKey = JSON.stringify(key)
    if (!results[argsKey]) {
      results[argsKey] = func(key, defaultVal)
      return results[argsKey]
    }
    return results[argsKey]
  }
}

export const itemWithID = memoizeAtomByKey(
  (key: string, defaultVal?: any): RecoilState<any> =>
    atom({ key, default: defaultVal })
)

export const getIsInViewKey = (key: string) => {
  return `${key}-isInView`
}
