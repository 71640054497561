import React from 'react'

type KeyEventHandlers = {
  keyName: string
  keyHandler: (e: KeyboardEvent) => void
}

function useKeyboardEventListeners(
  keyHandlers: KeyEventHandlers[],
  debug = false
) {
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { key } = event

      debug && console.log('keyName is', key)
      keyHandlers.forEach(handler => {
        const { keyName, keyHandler } = handler

        if (key === keyName) {
          keyHandler(event)
        }
      })
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [keyHandlers])
}

export { useKeyboardEventListeners }
