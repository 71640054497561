import React from 'react'
import styled from '@emotion/styled'
import { MobileOutlined, MailOutlined, LaptopOutlined } from '@ant-design/icons'
import { Tooltip, Spin, Skeleton } from 'antd'

import { GeneralDict } from '@organice/utils'
import { useNotificationPreferences } from '@organice/utils/hooks'
import { Icon } from './../../atoms'
import { useTranslation } from 'react-i18next'
import {
  NotificationActions_Enum,
  NotificationPreferenceStages_Enum,
} from '@organice/graphql'

export interface IconBaseProps extends React.HTMLProps<HTMLSpanElement> {
  spin?: boolean
  rotate?: number
}

export interface CustomIconComponentProps {
  width: string | number
  height: string | number
  fill: string
  viewBox?: string
  className?: string
  style?: React.CSSProperties
}

export interface IconComponentProps extends IconBaseProps {
  viewBox?: string
  component?: React.ComponentType<
    CustomIconComponentProps | React.SVGProps<SVGSVGElement>
  >
  ariaLabel?: React.AriaAttributes['aria-label']
}

interface NotificationTypesElementProps {
  active?: boolean
}

interface PreferencesNotificationElementProps {
  notificationAction: NotificationActions_Enum
  notificationPreferenceStage: NotificationPreferenceStages_Enum
}

export const PreferencesNotificationElement: React.FC<
  PreferencesNotificationElementProps
> = ({ notificationAction, notificationPreferenceStage }) => {
  const { t } = useTranslation()
  const {
    notificationChannels,
    toggleNotificationPreference,
    getPreferenceId,
    loading,
    myPreferences: { loading: loadingAllPreferences, error: loadingAllError },
  } = useNotificationPreferences()

  const icons: GeneralDict = {}
  icons[notificationChannels.Web] = LaptopOutlined
  icons[notificationChannels.Email] = MailOutlined
  icons[notificationChannels.Message] = MobileOutlined

  const [selectedChannel, setSelectedChannel] = React.useState<
    string | undefined
  >()

  if (loadingAllPreferences || loadingAllError) {
    return (
      <NotificationTypesContainer>
        {Object.entries(notificationChannels).map(
          ([key, notificationChannel]) => (
            <Skeleton.Input
              style={{ width: 20, height: 32 }}
              active={true}
              size="small"
            />
          )
        )}
      </NotificationTypesContainer>
    )
  }

  return (
    <NotificationTypesContainer>
      {Object.entries(notificationChannels).map(
        ([key, notificationChannel]) => {
          const preferenceId = getPreferenceId({
            notificationAction,
            notificationPreferenceStage,
            notificationChannel,
          })

          return (
            <Tooltip
              title={t(`account.notificationChannels.${notificationChannel}`)}
              key={key}
            >
              <NotificationTypesElement
                active={!!preferenceId}
                onClick={() => {
                  if (!loading) {
                    toggleNotificationPreference({
                      id: preferenceId,
                      notificationAction,
                      notificationPreferenceStage,
                      notificationChannel,
                    })
                    setSelectedChannel(
                      `${notificationAction}.${notificationPreferenceStage}.${notificationChannel}`
                    )
                  }
                }}
              >
                {loading &&
                  selectedChannel ===
                    `${notificationAction}.${notificationPreferenceStage}.${notificationChannel}` && (
                    <LoadingIndicator />
                  )}
                <Icon
                  style={{
                    opacity:
                      loading &&
                      selectedChannel ===
                        `${notificationAction}.${notificationPreferenceStage}.${notificationChannel}`
                        ? 0
                        : 1,
                  }}
                  component={
                    icons[notificationChannel] as React.ComponentType<
                      CustomIconComponentProps | React.SVGProps<SVGSVGElement>
                    >
                  }
                />
              </NotificationTypesElement>
            </Tooltip>
          )
        }
      )}
    </NotificationTypesContainer>
  )
}

const NotificationTypesContainer = styled.div`
  display: flex;

  & > * {
    margin-right: 10px;
  }
`
const NotificationTypesElement = styled.div<NotificationTypesElementProps>(
  ({ active, theme }) => `
  position: relative;
  cursor: pointer;
  font-size: 20px;
  color: ${active ? theme.primaryColor : theme.borderColorBase};

`
)

const LoadingIndicator = styled(Spin)`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
`
