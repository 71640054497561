/* This function ist used to get the right commentId from a notification object (regarding all comments: commentId is only unique in the right comment table)
/* This function is located in /hooks/comments, because it will change together with the comments (in case of change)

/* Some background:
/* Comments used to be in a single table and have been split into multiple tables (attributeValueComments, uploadComments, roomAtrributeValueComments)
/* The tables are basically the same, but have different relations to attributeValue, uploads or roomAttribtueValues.
/* A comment will always only have a relation to one of these values.

/* useNotifications() e.g. generates deep links to comments and uses commentId as a part of the url.
*/
export function findCommentIdByRegEx(
  dict: Record<string, any>,
  regEx = /.*CommentId$/
) {
  let commentId = null

  Object.keys(dict).forEach(key => {
    if (String(key).match(regEx) && dict[key]) {
      commentId = dict[key]
    }
  })
  return commentId
}
