import React from 'react'
import axios, { AxiosError } from 'axios'
import styled from '@emotion/styled'
import {
  Button,
  notification,
  Upload,
  Tooltip,
  Form,
  Spin,
  Progress,
} from 'antd'
import {
  LinkOutlined,
  PaperClipOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { UploadFile as AntDUploadFile, RcFile } from 'antd/lib/upload/interface'
import {
  Uploads,
  Uploads_Insert_Input,
  AttributeFiles,
  AttributeValueFiles,
  ServiceModules,
  RoomAttributeValueFiles,
  RoomAttributeFiles,
} from '@organice/graphql'
import {
  RoomAttributeFile,
  RoomAttributeValueFile,
} from '../../../types/room-service'
import { useUploadMutations } from '@organice/utils/hooks/useUploadMutation'

import {
  useServiceDisplayMode,
  ServiceDisplayMode,
  useServiceModulePermissions,
} from '@organice/contexts/serviceDisplay'

import { useTranslation } from 'react-i18next'

export type UploadFile<T = any> = AntDUploadFile & {
  extension?: string
}

const UPLOAD_URI = `https://${
  import.meta.env.VITE_FRONTEND_API_HOST || document.location.host
}`

export interface UploadAdd extends Pick<Uploads, 'id' | 'name'> {
  percent?: number
}

export interface FileAdd extends Pick<Uploads, 'name'> {
  percent?: number
}

export interface FileAdded extends Pick<Uploads, 'name'> {
  size: number
  fileId: bigint
}

export const convertAttributeValueFilesToFileList = (
  data?: AttributeValueFiles[],
  attributeFiles?: {
    downloadUrl?: AttributeFiles['downloadUrl']
    file: Pick<AttributeFiles['file'], 'id' | 'name' | 'size' | 'extension'>
  }[],
  roomAttributeFiles?: {
    downloadUrl?: AttributeFiles['downloadUrl']
    file: Pick<AttributeFiles['file'], 'id' | 'name' | 'size' | 'extension'>
  }[]
): UploadFile[] => {
  let files: UploadFile[] = []

  if (attributeFiles) {
    files = attributeFiles?.map(aF => {
      return {
        uid: aF.file.id,
        name: aF.file.name,
        url: aF.downloadUrl as string,
        size: aF.file.size,
        type: 'attributeFile',
        extension: aF.file.extension,
      }
    })
  }

  if (roomAttributeFiles) {
    files = roomAttributeFiles?.map(aF => {
      return {
        uid: aF.file.id,
        name: aF.file.name,
        url: aF.downloadUrl as string,
        size: aF.file.size,
        type: 'roomAttributeFile',
        extension: aF.file.extension,
      }
    })
  }
  if (data) {
    files = [
      ...files,
      ...data.map(file => ({
        uid: file.file.id,
        name: file.file.name,
        url: file.downloadUrl as string,
        size: file.file.size,
        extension: file.file.extension,
      })),
    ]
  }
  return files
}

function sanitizeFileName(fileName: string) {
  return fileName.replace(/[`~!@#$%^°&*()|+=?;:'",<>{}[\]\\/\s]/g, '') || 'file'
}

function prepareUploaFormItemId(id: any): (string | number)[] | undefined {
  if (id === undefined || id === null) {
    return undefined
  }

  if (typeof id === 'string') {
    return [id]
  }
  if (Number.isInteger(id)) {
    return [id]
  }

  if (
    Array.isArray(id) &&
    id.every(i => Number.isInteger(i) || typeof i === 'string')
  ) {
    return id
  }

  return undefined
}

export interface FileUploadProps {
  module?: ServiceModules
  attributeGroupId?: bigint
  attribute?: number | null
  attributeId?: bigint
  attributeValueId?: number | null
  attributeFiles?: AttributeFiles[]
  roomAttributeFiles?: RoomAttributeFile[]
  id?: Pick<Uploads, 'id'> // uploadId || attributeValueId !!
  name?: string | React.Key[]
  fileList?: AttributeValueFiles[] | RoomAttributeValueFile[] | UploadFile[]
  isConfiguration?: boolean
  disableUpload?: boolean
  onAdded?(upload: UploadAdd): void
  onProgress?(upload: FileAdd): void
  onComplete?(upload: FileAdded): void
  onChanged?(files: UploadFile[]): void
  children?: React.ReactNode
}

export const FileUpload: React.FC<FileUploadProps> = ({
  children,
  module,
  attributeGroupId,
  attribute,
  attributeFiles,
  roomAttributeFiles,
  attributeValueId,
  attributeId,
  id,
  name,
  fileList,
  isConfiguration,
  disableUpload,
  onAdded,
  onProgress,
  onComplete,
  onChanged,
}) => {
  const { t } = useTranslation()
  const [state] = useServiceDisplayMode()
  const serviceModulePermissions = useServiceModulePermissions()
  const { deleteAttributeValueFile, deleteAttributeFile } = useUploadMutations()

  const [files, setFiles] = React.useState<UploadFile[]>([])
  const [statusChange, setStatusChange] = React.useState<
    'uploadStarted' | 'uploadSuccess' | 'uploadError' | 'fileRemoved' | null
  >()

  React.useEffect(() => {
    setFiles(
      attribute === null
        ? (fileList as UploadFile[])
        : convertAttributeValueFilesToFileList(
            fileList as AttributeValueFiles[],
            attributeFiles || roomAttributeFiles
          )
    )
  }, [fileList])

  const [inited, setInited] = React.useState(false)

  const checkUploadLimit = (file: any) => {
    try {
      const uploadLimit = import.meta.env.VITE_UPLOAD_LIMIT_IN_MB
      if (!uploadLimit) return true

      const maxFileSize = Number(uploadLimit) * 1000000
      if (uploadLimit && file.size > maxFileSize) {
        notification.error({
          duration: 3,
          message: t('common.error.fileIsTooBig', {
            maxFileSize: uploadLimit,
          }),
        })
        return false
      }
    } catch (err) {
      console.warn(err)
    }
    return true
  }

  const handleAxiosError = (error: AxiosError): void => {
    const statusCode = error?.response?.status
    const errorMessagesByStatusCode: { [statusCode: string]: string } = {
      '413': 'common.error.fileIsTooBig',
    }

    if (statusCode && String(statusCode) in errorMessagesByStatusCode) {
      notification.error({
        duration: 3,
        message: t(errorMessagesByStatusCode[String(statusCode)]),
      })
      return
    }

    notification.error({
      duration: 3,
      message: error?.toString(),
    })
  }

  const isOffline = () => {
    if (!window.navigator.onLine) {
      notification.error({
        duration: 3,
        message: t('common.error.missingNetworkConnection'),
      })
      return true
    }
    return false
  }

  const beforeUploadHandler = (file: RcFile) => {
    if (!file.name.includes('.')) {
      notification.error({
        duration: 3,
        message: t('common.error.unknownFileType', { fileName: file.name }),
      })
      return Upload.LIST_IGNORE
    }
    if (!checkUploadLimit(file)) return Upload.LIST_IGNORE
    if (isOffline()) return Upload.LIST_IGNORE
    return true
  }

  const uploadHandler = async (
    options: any,
    insertData?: Uploads_Insert_Input
  ) => {
    const {
      onSuccess,
      onError,
      onProgress: defaultOnProgress,
      file,
      fileList,
    } = options

    const fileName = sanitizeFileName(file?.name)
    const uploadUri = `${UPLOAD_URI}/files/upload/${encodeURIComponent(
      fileName
    )}`

    const config = {
      headers: { 'content-type': file.type },
      withCredentials: true,
      onUploadProgress: (event: any) => {
        const uploadObj = {
          name: fileName,
          loading: event.loaded === event.total ? false : true,
          percent: (event.loaded / event.total) * 100,
        }
        defaultOnProgress(uploadObj)
        onProgress && onProgress(uploadObj)
      },
    }

    try {
      const result = await axios.put(uploadUri, file, config)

      const newFileId = parseInt(result?.data?.fileId)
      const newFile: FileAdded = {
        name: fileName,
        ...result?.data,
        fileId: newFileId,
      }

      if (newFileId) {
        onSuccess({
          ...result?.data,
          uid: newFileId,
        })

        setStatusChange('uploadSuccess')

        onComplete && onComplete(newFile)
      }
    } catch (err) {
      setStatusChange('uploadError')
      handleAxiosError(err as AxiosError)
      onError({ err })
    }
  }

  React.useEffect(() => {
    // only update state if a file changes 'uploadStarted' | 'uploadSuccess' | 'uploadError'
    // updateing the contoext on every upload precentage state update creates too many state updates
    if (inited && statusChange) {
      onChanged && onChanged(files)
      setStatusChange(null)
    }
  }, [files, statusChange])

  const isExport = state.serviceDisplayMode === ServiceDisplayMode.EXPORT
  const readOnly = state.serviceDisplayMode === ServiceDisplayMode.READ_ACCESS

  React.useEffect(() => {
    setInited(true)
  }, [])

  if (isExport) {
    return (
      <DisplayList>
        {files.map(file => {
          const fileName = file?.extension
            ? `${file.name}.${file.extension}`
            : file.name

          return (
            file.type !== 'attributeFile' && (
              <DisplayListItem key={file.uid}>
                <div className="fileName">{fileName}</div>
                <a
                  className="url"
                  href={file.url}
                  target="_blank"
                  rel="noopener"
                >{`${document.location.origin}${file.url}`}</a>
              </DisplayListItem>
            )
          )
        })}
      </DisplayList>
    )
  }

  if (readOnly) {
    return (
      <FileList>
        {files.map((file, key) => (
          <React.Fragment key={key}>
            {renderAttributeFileEntry(file)}
          </React.Fragment>
        ))}
      </FileList>
    )
  }

  if (!module) {
    // [0, '1234'] > new List item structure means new valueGroup
    // TODO: any more explicit implementation?
    const isNewValueGroup = Array.isArray(id) && id.length > 1

    return (
      <UploadWrapper>
        <Form.Item noStyle name={name || prepareUploaFormItemId(id)}>
          <Upload
            multiple
            disabled={
              isConfiguration
                ? false
                : !serviceModulePermissions.writable || disableUpload
            }
            fileList={files?.length ? files : undefined}
            onChange={({ fileList: newFileList, file, event }) => {
              if (file.status === 'error') {
                setFiles(newFileList.filter(f => f.uid !== file.uid))
                return
              }
              setFiles(newFileList)
            }}
            onRemove={async file => {
              // TODO: Remove isConfiguration part - Service Config & RoomService Config are different here
              // catch will return true, even if it fails, because RoomService Config needs it
              // files should never be removed directly, but on save. ServiceConfig needs to be updates
              try {
                // Configuration uses deleteAttributeFile, because attributeValue only exist for eventAttirbutes
                if (isConfiguration) {
                  const result = await deleteAttributeFile({
                    variables: {
                      fileId: file.uid,
                      attributeId: attributeId,
                    },
                  })
                  const deletedAttributeFile =
                    result.data?.delete_attributeFiles_by_pk

                  if (deletedAttributeFile) {
                    setFiles(files.filter(f => f.uid !== file.uid))
                    setStatusChange('fileRemoved')
                    return true
                  }
                  return false
                }
                setFiles(files.filter(f => f.uid !== file.uid))
                setStatusChange('fileRemoved')
                return true // not really deleting here

                // no attributeValueFile saved yet if isNewValueGroup, so no deletion possible
                if (isNewValueGroup) return true

                const result = await deleteAttributeValueFile({
                  variables: {
                    fileId: file.uid,
                    attributeValueId: attributeValueId,
                  },
                })
                const deletedAttributeValueFile =
                  result.data?.delete_attributeValueFiles_by_pk

                if (deletedAttributeValueFile) {
                  return true
                }
                // if result.data?.delete_attributeValueFiles_by_pk is null (probably an error, but it happens at the moment)
                return false
              } catch (err) {
                // console.error(err)
                setFiles(files.filter(f => f.uid !== file.uid))
                setStatusChange('fileRemoved')
                return true
                return false
              }
              return true
            }}
            beforeUpload={beforeUploadHandler}
            customRequest={options => uploadHandler(options)}
            itemRender={(originNode, file, currFileList, actions) => {
              if (!isConfiguration && file.type === 'attributeFile') {
                return renderAttributeFileEntry(file)
              }
              if (!isConfiguration) {
                return renderRegularFileEntry(file, actions)
              }
              return renderRegularFileEntry(file, actions, isConfiguration)
              return originNode
            }}
          >
            {children}
          </Upload>
        </Form.Item>
      </UploadWrapper>
    )
  }

  if (isConfiguration ? false : !serviceModulePermissions.writable) {
    return null
  }

  return (
    <UploadWrapperHideList>
      <Upload
        multiple
        disabled={isConfiguration ? false : !serviceModulePermissions.writable}
        beforeUpload={beforeUploadHandler}
        customRequest={options =>
          uploadHandler(options, {
            serviceModuleId: module.id,
          })
        }
      >
        {children}
      </Upload>
    </UploadWrapperHideList>
  )
}

type FileItemActions = {
  download: () => void
  preview: () => void
  remove: () => void
}

const WritePermissionTooltip: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation()
  const serviceModulePermissions = useServiceModulePermissions()
  if (serviceModulePermissions.writable) return <>{children}</>
  return (
    <Tooltip title={t('permissions.missingDeletePermission')}>
      {children}
    </Tooltip>
  )
}

export const renderRegularFileEntry = (
  file: UploadFile,
  actions: FileItemActions,
  isConfiguration = false
): React.ReactNode => {
  const serviceModulePermissions = useServiceModulePermissions()
  const fileName = file?.extension
    ? `${sanitizeFileName(file.name)}.${file.extension}`
    : sanitizeFileName(file.name)

  return (
    <RegularFileListIntem className="fileListItem">
      <div className="ant-upload-list-text-container">
        <div className="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-text">
          <div className="ant-upload-list-item-info">
            <span className="ant-upload-span">
              {file.status === 'uploading' ? (
                <Spin indicator={<LoadingOutlined />} />
              ) : (
                <PaperClipOutlined />
              )}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="ant-upload-list-item-name"
                title={fileName}
                href={file.url}
              >
                {fileName}
              </a>
              <span className="ant-upload-list-item-card-actions">
                {isConfiguration ? (
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => actions.remove()}
                    size="small"
                  />
                ) : (
                  <WritePermissionTooltip>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => actions.remove()}
                      size="small"
                      disabled={!serviceModulePermissions.writable}
                      style={{
                        opacity: serviceModulePermissions.writable ? 1 : 0.5,
                      }}
                    />
                  </WritePermissionTooltip>
                )}
              </span>
            </span>

            {file.status === 'uploading' && (
              <UploadItemProgress>
                <Progress
                  strokeWidth={2}
                  percent={file.percent}
                  showInfo={false}
                />
              </UploadItemProgress>
            )}
          </div>
        </div>
      </div>
    </RegularFileListIntem>
  )
}

const UploadItemProgress = styled.div`
  .ant-progress {
    display: block;
    line-height: 0;
  }
`

const RegularFileListIntem = styled.div`
  .ant-upload-list-item-card-actions {
    opacity: 0;
  }
  &:hover {
    .ant-upload-list-item-card-actions {
      opacity: 1;
    }
  }
`

export const renderAttributeFileEntry = (file: UploadFile): React.ReactNode => {
  const fileName = file?.extension
    ? `${sanitizeFileName(file.name)}.${file.extension}`
    : sanitizeFileName(file.name)

  return (
    <AttributeFile className="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-text">
      <div className="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-text">
        <div className="ant-upload-list-item-info">
          <span className="ant-upload-span">
            {file.status === 'uploading' ? (
              <Spin indicator={<LoadingOutlined />} />
            ) : (
              <LinkOutlined />
            )}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="ant-upload-list-item-name"
              title={fileName}
              href={file.url}
            >
              {fileName}
            </a>{' '}
          </span>
        </div>
      </div>
    </AttributeFile>
  )
}

const UploadWrapper = styled.div`
  text-align: right;
  width: 100%;
  .ant-upload {
    width: 100%;
    .ant-btn {
      width: 100%;
    }
  }
  .ant-upload-list {
    text-align: left;
  }

  .ant-upload-disabled {
    display: none;
  }
`

const UploadWrapperHideList = styled.div`
  .ant-upload-list {
    display: none;
  }
`

const AttributeFile = styled.span(
  ({ theme }) => `
  svg{
    fill:${theme.primaryColor}
  }
  font-style: italic;
`
)

const DisplayList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
const DisplayListItem = styled.li`
  .fileName {
    font-weight: bold;
  }
  .url {
    font-style: italic;
  }
`

const FileList = styled.div`
  .ant-upload-list-item {
    margin-top: 0;
  }
  .ant-upload-span {
    display: flex;
    align-items: center;
    & > a {
      padding-left: 5px;
    }
  }
`
