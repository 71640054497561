import React from 'react'

export type ServiceModulePermissionsType = {
  serviceModuleId?: bigint
  writable: boolean
}

export const ServiceModulePermissionsContext =
  React.createContext<ServiceModulePermissionsType>({ writable: false })

interface ServiceModulePermissionsProviderProps {
  serviceModulePermissions: ServiceModulePermissionsType
}

export const ServiceModulePermissionsProvider: React.FC<
  React.PropsWithChildren<ServiceModulePermissionsProviderProps>
> = ({ children, serviceModulePermissions }) => {
  return (
    <ServiceModulePermissionsContext.Provider value={serviceModulePermissions}>
      {children}
    </ServiceModulePermissionsContext.Provider>
  )
}

export const useServiceModulePermissions = () => {
  const context = React.useContext(ServiceModulePermissionsContext)
  if (context === undefined) {
    console.warn(
      'useServiceModulePermissions should be used within a ServiceModulePermissionsContext'
    )
  }
  return context
}
