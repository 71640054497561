import React from 'react'
import { DateType, ModuleType, AttributeType } from './entityTypes'

import {
  ServiceContextAction,
  ServiceContextState,
  ServiceContextType,
} from './contextTypes'

import {
  changeAttribute,
  changeGroup,
  changeModule,
  getActiveModuleAfterDelete,
} from './util'

import { UploadFile } from 'antd/lib/upload/interface'
import { AttributeTypes_Enum } from '@organice/graphql'

export const DEFAULT_ATTRIBUTE_TYPE = AttributeType.Text
export const DEFAULT_DATE_TYPE = DateType.DATE
export const DEFAULT_ALLOWUPLOAD = true
export const DEFAULT_ALLOW_DECIMALS = false
export const DEFAULT_MODULE_TYPE = ModuleType.Attributes
export const INTERVAL_DURATION_MS = 500

export enum Step {
  EMPTY = 'EMPTY',
}

const DEFAULT_VALUE: ServiceContextState = {
  // logical clock
  step: 0,
  currentId: 0,
  currentService: {
    serviceModules: [],
    name: '',
  },
  errors: [],
  deletedAttributeGroups: [],
  deletedServiceModules: [],
}

const defaultConfigByAttributeType = {
  [AttributeType.Boolean as string]: {},
  [AttributeType.Date as string]: {
    dateType: DEFAULT_DATE_TYPE,
  },
  [AttributeType.Number as string]: {
    allowDecimals: DEFAULT_ALLOW_DECIMALS,
  },
  [AttributeType.File as string]: {
    allowUpload: DEFAULT_ALLOWUPLOAD,
  },
  [AttributeType.Text as string]: {},
  [AttributeType.User]: {},
}

function getDefaultConfigByAttributeType(
  attributeType: AttributeTypes_Enum | undefined
) {
  if (!attributeType) return {}

  if (attributeType in defaultConfigByAttributeType) {
    return { config: defaultConfigByAttributeType[attributeType] }
  }

  console.warn(
    `getDefaultConfigByAttributeType: No default for attributeType ${attributeType}`
  )
  return {}
}

export const ServiceContext = React.createContext<ServiceContextType>([
  DEFAULT_VALUE,
  () => undefined,
])

export const serviceReducer: React.Reducer<
  ServiceContextState,
  ServiceContextAction
> = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return { ...action.payload, initialState: action.payload }
    case 'SET_ERRORS':
      return { ...state, errors: action.payload }
    case 'SET_CURRENT_SERVICE':
      return {
        ...state,
        currentService: { ...action.payload, hasChanged: true },
        step: state.step + 1,
      }
    case 'SET_SERVICE_NAME': {
      return {
        ...state,
        currentService: {
          ...state.currentService,
          name: action.payload,
          hasChanged: true,
        },
        step: state.step + 1,
      }
    }
    case 'SET_SERVICE_TAGS': {
      return {
        ...state,
        currentService: {
          ...state.currentService,
          tags: action.payload,
          hasChanged: true,
        },
        step: state.step + 1,
      }
    }

    case 'DELETE_SERVICE': {
      return DEFAULT_VALUE
    }
    case 'SET_MODULES': {
      return {
        ...state,
        currentService: {
          ...state.currentService,
          serviceModules: action.payload.map(m => ({ ...m, hasChanged: true })),
          hasChanged: true,
        },
        step: state.step + 1,
      }
    }
    case 'ADD_NEW_MODULE': {
      return {
        ...state,
        currentId: state.currentId + 1,
        activeModule: state.currentId,
        currentService: {
          ...state.currentService,
          hasChanged: true,
          serviceModules: [
            ...state.currentService.serviceModules,
            {
              attributeGroups: [],
              name: '',
              config: { canPublish: true },
              tempId: state.currentId,
              moduleType: DEFAULT_MODULE_TYPE,
              hasChanged: true,
            },
          ],
        },
        step: state.step + 1,
      }
    }
    case 'SET_MODULE_NAME': {
      const changedState = changeModule(state, action.payload.tempId, () => ({
        name: action.payload.name,
        hasChanged: true,
      }))

      return { ...changedState, step: state.step + 1 }
    }
    case 'SET_MODULE_TYPE': {
      const changedState = changeModule(state, action.payload.tempId, () => ({
        moduleType: action.payload.moduleType,
        hasChanged: true,
      }))

      return { ...changedState, step: state.step + 1 }
    }

    case 'SET_MODULE_CANPUBLISH': {
      const changedState = changeModule(state, action.payload.tempId, m => ({
        published: !action.payload.canPublish,
        hasChanged: true,
        config: {
          ...m.config,
          canPublish: action.payload.canPublish,
        },
      }))

      return { ...changedState, step: state.step + 1 }
    }
    case 'SET_MODULE_UPLOAD_CONFIG': {
      const { tempId, ...rest } = action.payload

      const changedState = changeModule(state, action.payload.tempId, m => ({
        hasChanged: true,
        config: {
          ...m.config,
          ...rest,
        },
      }))

      return { ...changedState, step: state.step + 1 }
    }
    case 'SET_ACTIVE_MODULE': {
      return {
        ...state,
        activeModule: action.payload,
      }
    }
    case 'DELETE_MODULE': {
      const {
        currentService: { serviceModules },
      } = state

      const activeModule = getActiveModuleAfterDelete(
        state,
        action.payload.tempId
      )

      const deletedServiceModules = ((oldDeleted: number[]) => {
        const moduleExistsInDb = action.payload.id !== undefined

        if (moduleExistsInDb) {
          return [...oldDeleted, action.payload.id]
        }

        return oldDeleted
      })(state.deletedServiceModules) // note the IIFE

      return {
        ...state,
        activeModule,
        currentService: {
          ...state.currentService,
          hasChanged: true,
          serviceModules: serviceModules.filter(
            m => m.tempId !== action.payload.tempId
          ),
        },
        step: state.step + 1,
        deletedServiceModules,
      }
    }
    case 'SET_GROUPS': {
      const stateWithNewGroups = changeModule(
        state,
        action.payload.tempId,
        () => ({
          attributeGroups: action.payload.attributeGroups.map(g => ({
            ...g,
            hasChanged: true,
          })),
          hasChanged: true,
        })
      )

      return { ...stateWithNewGroups, step: state.step + 1 }
    }
    case 'ADD_NEW_GROUP': {
      const stateWithAddedGroup = changeModule(state, action.payload, m => ({
        ...m,
        hasChanged: true,
        attributeGroups: [
          ...m.attributeGroups,
          {
            name: '',
            attributes: [],
            tempId: state.currentId,
            hasChanged: true,
          },
        ],
      }))

      return {
        ...stateWithAddedGroup,
        currentId: state.currentId + 1,
        step: state.step + 1,
      }
    }

    case 'SET_GROUP_NAME': {
      const changedState = changeGroup(state, action.payload.tempId, () => ({
        name: action.payload.name,
        hasChanged: true,
      }))

      return { ...changedState, step: state.step + 1 }
    }
    case 'SET_GROUP_DESCRIPTION': {
      const changedState = changeGroup(state, action.payload.tempId, () => ({
        description: action.payload.description,
        hasChanged: true,
      }))

      return { ...changedState, step: state.step + 1 }
    }
    case 'SET_GROUP_REPEATABLE': {
      const changedState = changeGroup(state, action.payload.tempId, () => ({
        valuesRepeatable: action.payload.valuesRepeatable,
        hasChanged: true,
      }))

      return { ...changedState, step: state.step + 1 }
    }
    case 'DELETE_GROUP': {
      if (state.activeModule === undefined) {
        return state
      }

      const changedState = changeModule(state, state.activeModule, m => ({
        ...m,
        hasChanged: true,
        attributeGroups: m.attributeGroups.filter(
          g => g.tempId !== action.payload.tempId
        ),
      }))

      const deletedAttributeGroups = ((oldDeletedGroups: number[]) => {
        const groupExists = action.payload.id !== undefined

        if (groupExists) {
          return [...oldDeletedGroups, action.payload.id]
        }

        return oldDeletedGroups
      })(state.deletedAttributeGroups)

      return { ...changedState, step: state.step + 1, deletedAttributeGroups }
    }
    case 'SET_ATTRIBUTES': {
      const stateWithNewAttributes = changeGroup(
        state,
        action.payload.tempId,
        () => ({
          attributes: action.payload.attributes.map(a => ({
            ...a,
            hasChanged: true,
          })),
          hasChanged: true,
        })
      )

      return { ...stateWithNewAttributes, step: state.step + 1 }
    }
    case 'ADD_ATTRIBUTE': {
      const stateWithAddedAttribute = changeGroup(state, action.payload, g => ({
        attributes: [
          ...g.attributes,
          {
            tempId: state.currentId,
            attributeType: DEFAULT_ATTRIBUTE_TYPE,
            allowUpload: DEFAULT_ALLOWUPLOAD,
            config: {
              dateType: DEFAULT_DATE_TYPE,
            },
            name: '',
            hasChanged: true,
          },
        ],
      }))

      return {
        ...stateWithAddedAttribute,
        currentId: state.currentId + 1,
        step: state.step + 1,
      }
    }
    case 'SET_ATTRIBUTE_NAME': {
      const changedState = changeAttribute(
        state,
        action.payload.tempId,
        () => ({
          name: action.payload.name,
          hasChanged: true,
        })
      )

      return { ...changedState, step: state.step + 1 }
    }
    case 'SET_ATTRIBUTE_TYPE': {
      const changedState = changeAttribute(
        state,
        action.payload.tempId,
        () => ({
          attributeType: action.payload.attributeType,
          ...getDefaultConfigByAttributeType(action.payload.attributeType),
          hasChanged: true,
        })
      )

      return { ...changedState, step: state.step + 1 }
    }
    case 'SET_ATTRIBUTE_MAX_NUMBER': {
      const changedState = changeAttribute(state, action.payload.tempId, a => ({
        config: { ...a.config, max: action.payload.max },
        hasChanged: true,
      }))

      return { ...changedState, step: state.step + 1 }
    }
    case 'SET_ATTRIBUTE_ALLOW_DECIMALS': {
      const changedState = changeAttribute(state, action.payload.tempId, a => ({
        config: { ...a, allowDecimals: action.payload.allowDecimals },
        hasChanged: true,
      }))

      return { ...changedState, step: state.step + 1 }
    }
    case 'SET_ATTRIBUTE_DATE': {
      const changedState = changeAttribute(state, action.payload.tempId, a => ({
        config: { ...a.config, dateType: action.payload.dateType },
        hasChanged: true,
      }))

      return { ...changedState, step: state.step + 1 }
    }
    case 'SET_ATTRIBUTE_REPEATABLE': {
      const changedState = changeAttribute(state, action.payload.tempId, a => ({
        config: { ...a.config, repeatable: action.payload.repeatable },
        hasChanged: true,
      }))

      return { ...changedState, step: state.step + 1 }
    }
    case 'SET_ATTRIBUTE_DESCRIPTION': {
      const changedState = changeAttribute(state, action.payload.tempId, a => ({
        ...a,
        description: action.payload.description,
        hasChanged: true,
      }))

      return { ...changedState, step: state.step + 1 }
    }
    case 'SET_ATTRIBUTE_FILES': {
      const changedState = changeAttribute(
        state,
        action.payload.tempId,
        () => ({
          attributeFiles: action.payload.files as UploadFile[],
          hasChanged: true,
        })
      )

      return { ...changedState, step: state.step + 1 }
    }
    case 'SET_ATTRIBUTE_ALLOW_UPLOAD_FILE': {
      const changedState = changeAttribute(state, action.payload.tempId, a => ({
        config: { ...a, allowUpload: action.payload.allowUpload },
        hasChanged: true,
      }))

      return { ...changedState, step: state.step + 1 }
    }
    case 'SET_ATTRIBUTE_USER_ORGANISATION': {
      const changedState = changeAttribute(state, action.payload.tempId, a => ({
        ...a,
        organisation:
          action.payload.organisation && action.payload.organisation,

        hasChanged: true,
      }))

      return { ...changedState, step: state.step + 1 }
    }

    case 'DELETE_ATTRIBUTE': {
      const changedState = changeGroup(state, action.payload.groupId, g => ({
        ...g,
        hasChanged: true,
        attributes: g.attributes.filter(
          a => a.tempId !== action.payload.tempId
        ),
      }))

      return { ...changedState, step: state.step + 1 }
    }
    case 'SAVE_DATA': {
      return state
    }
    case 'RESET_DATA': {
      return state.initialState
        ? {
            ...state.initialState,
            step: 0,
            initialState: { ...state.initialState, step: 0 },
          }
        : DEFAULT_VALUE
    }
  }
}

export const useService = (): ServiceContextType =>
  React.useContext(ServiceContext)

export interface ServiceProviderProps {
  defaultValue?: ServiceContextState
  forceUpdateId?: number
}

export const ServiceProvider: React.FC<ServiceProviderProps> = props => {
  const [data, dispatch] = React.useReducer(serviceReducer, DEFAULT_VALUE)

  React.useEffect(() => {
    if (!props.defaultValue) {
      return
    }

    dispatch({
      type: 'SET_STATE',
      payload: { ...props.defaultValue },
    })
  }, [props.forceUpdateId])

  return <ServiceContext.Provider value={[data, dispatch]} {...props} />
}
