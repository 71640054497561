import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  split,
  concat,
} from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { isLocalStorageEnabled } from '@organice/utils/general/localstorage-cookie-detection'
import { SubscriptionClient } from 'subscriptions-transport-ws'

const WS_URL = `wss://${
  import.meta.env.VITE_FRONTEND_API_HOST || document.location.host
}/v1/graphql`
const HTTP_URI = `https://${
  import.meta.env.VITE_FRONTEND_API_HOST || document.location.host
}/v1/graphql`

const subscriptionClient = new SubscriptionClient(WS_URL, {
  reconnect: true,
  lazy: true,
})
const wsLink = new WebSocketLink(subscriptionClient)
const httpLink = new HttpLink({
  uri: HTTP_URI,
  credentials: 'include',

  // Use explicit `window.fetch` so tha outgoing requests
  // are captured and deferred until the Service Worker is ready.
  fetch: (...args) => fetch(...args),
})

// add the authorization to the headers
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token: string = isLocalStorageEnabled()
      ? localStorage.getItem('token') || ''
      : ''

    if (!token) return headers

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }
  })

  return forward(operation)
})

const combinedLinks = split(
  // split based on operation type
  ({ query }) => {
    const mainDefinition = getMainDefinition(query)
    return (
      mainDefinition.kind === 'OperationDefinition' &&
      mainDefinition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, combinedLinks),
  cache: new InMemoryCache({}),
  //connectToDevTools: true,
})

export default apolloClient
