import {
  ConfigRoomAttribute,
  ConfigRoomAttributeGroup,
} from '@organice/contexts/configRoom'
import { RoomServices_Insert_Input } from '@organice/graphql'
export function prepeareNewRoomAttributeFilesRelInsert(
  roomAttributeFiles: ConfigRoomAttribute['roomAttributeFiles']
) {
  if (!roomAttributeFiles) return []

  return roomAttributeFiles.map(f => ({ fileId: f.uid }))
}

export function prepeareNewRoomAttributesRelInsert(
  roomAttributes: ConfigRoomAttribute[]
) {
  return roomAttributes.map(a => {
    const { id, roomAttributeFiles, chosen, ...roomAttribute } = a

    return {
      ...roomAttribute,
      roomAttributeFiles: {
        data: prepeareNewRoomAttributeFilesRelInsert(a.roomAttributeFiles),
      },
    }
  })
}

export function prepeareNewRoomAttributeGroupsRelInsert(
  roomAttributeGroups: ConfigRoomAttributeGroup[]
): RoomServices_Insert_Input[] {
  return roomAttributeGroups.map(rag => {
    const { id, selected, chosen, roomAttributes, ...ragNoId } = rag
    return {
      ...ragNoId,
      roomAttributes: {
        data: prepeareNewRoomAttributesRelInsert(roomAttributes),
      },
    }
  })
}
