import { Dayjs } from 'dayjs'
import { TimeUtil } from '@organice/utils/date'
import { GeneralDict } from '../conversions'
const dayjs = TimeUtil.getDayjs()

/*
Given a Date String from GraphQL > return dayjs Object
Given a dayjs Object > return a Date String fro GraphQL (default Format YYYY-MM-DD)
*/

export const convertDate = (input: Dayjs | string, values?: GeneralDict) => {
  if (typeof input === 'string') {
    return dayjs(input)
  }
  return dayjs(input).format('YYYY-MM-DD')
  return
}
