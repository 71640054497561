import React from 'react'
import {
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpdateUserPasswordMutation,
  useResetUserFailedLoginsMutation,
  AddUserMutationOptions,
  UpdateUserMutationOptions,
  DeleteUserMutationOptions,
  UpdateUserPasswordMutationOptions,
  ResetUserFailedLoginsMutationOptions,
  Users,
  Users_Constraint,
} from '@organice/graphql'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'
import { ApolloError } from '@apollo/client'

type UseUserReturn = {
  addUser(options: AddUserMutationOptions): void
  updateUser(options: UpdateUserMutationOptions): void
  deleteUser(options: DeleteUserMutationOptions): void
  updateUserPassword(options: UpdateUserPasswordMutationOptions): void
  resetUserFailedLogins(options: ResetUserFailedLoginsMutationOptions): void
  addLoading: boolean
  addError: ApolloError | undefined

  updateLoading: boolean
  updateError: ApolloError | undefined

  deleteLoading: boolean
  deleteError: ApolloError | undefined

  resetFailedLoginsLoading: boolean
  resetFailedLoginsError: ApolloError | undefined

  updatePasswordLoading: boolean
  updatePasswordError: ApolloError | undefined
}

interface UserMutationCallbacks {
  onAdded?(user?: Users): void
  onUpdated?(user?: Users): void
  onDeleted?(user?: Users): void
  onUpdatedPassword?(passwordSet: boolean): void
  onResetFailedLogins?(user?: Users): void
}

const userErrorMessages: Partial<Record<Users_Constraint, string>> = {
  [Users_Constraint.UsersEmailKey]: 'accounts.errors.emailNotUnique',
}

export function useUserMutations(
  callbacks?: UserMutationCallbacks
): UseUserReturn {
  const { t } = useTranslation()

  /************/
  /* ADD USER */
  /************/
  const [addUser, { loading: addLoading, error: addError, data: addData }] =
    useAddUserMutation()

  React.useEffect(() => {
    if (addData) {
      notification.success({
        duration: 3,
        message: t('user.action.userAdded'),
      })
      if (callbacks?.onAdded)
        callbacks.onAdded(addData.insert_users_one as Users)
    }
  }, [addData])

  /***************/
  /* UPDATE USER */
  /***************/
  const [
    updateUser,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useUpdateUserMutation()

  React.useEffect(() => {
    if (updateData) {
      notification.success({
        duration: 3,
        message: t('user.action.userSaved'),
      })
      if (callbacks?.onUpdated)
        callbacks.onUpdated(updateData.update_users_by_pk as Users)
    }
  }, [updateData])

  /***************/
  /* DELETE USER */
  /***************/
  const [
    deleteUser,
    { loading: deleteLoading, error: deleteError, data: deleteData },
  ] = useDeleteUserMutation()

  React.useEffect(() => {
    if (deleteData) {
      notification.success({
        duration: 3,
        message: t('user.action.userDeleted'),
      })
      if (callbacks?.onDeleted)
        callbacks.onDeleted(deleteData.delete_users_by_pk as Users)
    }
  }, [deleteData])

  /*********************/
  /* CHANGED PASSWORD  */
  /*********************/

  const [
    updateUserPassword,
    {
      loading: updatePasswordLoading,
      data: updatePasswordData,
      error: updatePasswordError,
    },
  ] = useUpdateUserPasswordMutation()

  React.useEffect(() => {
    if (updatePasswordData) {
      notification.success({
        duration: 3,
        message: t('user.action.userPasswordChanged'),
      })
      if (callbacks?.onUpdatedPassword)
        callbacks.onUpdatedPassword(
          updatePasswordData.setPassword?.passwordSet as boolean
        )
    }
  }, [updatePasswordData])

  /************************/
  /* RESET FAILED LOGINS  */
  /************************/

  const [
    resetUserFailedLogins,
    {
      loading: resetFailedLoginsLoading,
      error: resetFailedLoginsError,
      data: resetFailedLoginsData,
    },
  ] = useResetUserFailedLoginsMutation()

  React.useEffect(() => {
    if (resetFailedLoginsData) {
      notification.success({
        duration: 3,
        message: t('user.action.resetFailedLogins'),
      })
      if (callbacks?.onResetFailedLogins)
        callbacks.onResetFailedLogins(
          resetFailedLoginsData.update_users_by_pk as Users
        )
    }
  }, [resetFailedLoginsData])

  /**********/
  /* ERRORS */
  /**********/
  React.useEffect(() => {
    const e =
      addError ||
      deleteError ||
      updateError ||
      resetFailedLoginsError ||
      updatePasswordError

    if (!e) return

    const violatedConstraint = extractViolatedConstraint(e)

    const msgI18nKey = userErrorMessages[violatedConstraint as Users_Constraint]
    notification.error({
      duration: 3,
      message: msgI18nKey ? t(msgI18nKey) : e.toString(),
    })
  }, [
    addError,
    deleteError,
    updateError,
    resetFailedLoginsError,
    updatePasswordError,
  ])

  return {
    addUser,
    updateUser,
    deleteUser,
    updateUserPassword,
    resetUserFailedLogins,
    addLoading,
    addError,
    resetFailedLoginsLoading,
    resetFailedLoginsError,
    updatePasswordLoading,
    updatePasswordError,
    updateLoading,
    updateError,
    deleteLoading,
    deleteError,
  }
}

function extractViolatedConstraint(error: Error): string | null {
  const errorMessage = error.message

  const constraintRegex = /violates.*constraint "(.*?)"/

  const match = errorMessage.match(constraintRegex)
  if (match && match[1]) {
    return match[1]
  }

  return null
}
