import React from 'react'
import {
  useAddAttributeValueCommentMutation,
  AddAttributeValueCommentMutation,
  useAddUploadCommentMutation,
  AddUploadCommentMutation,
  AddUploadCommentMutationResult,
  useAddRoomAttributeValueCommentMutation,
  AddRoomAttributeValueCommentMutation,
  RoomAttributeValueComments_Insert_Input,
  useUpdateAttributeValueCommentMutation,
  UpdateAttributeValueCommentMutation,
  UpdateAttributeValueCommentMutationResult,
  useUpdateRoomAttributeValueCommentMutation,
  UpdateRoomAttributeValueCommentMutation,
  UpdateRoomAttributeValueCommentMutationResult,
  AddRoomAttributeValueCommentMutationResult,
  AttributeValueComments_Insert_Input,
  UploadComments_Insert_Input,
  useUpdateUploadCommentMutation,
  UpdateUploadCommentMutationResult,
  UpdateUploadCommentMutation,
  GetRoomServiceOccurrenceDocument,
} from '@organice/graphql'
import {
  CommentTypes_Enum,
  Comments_Insert_Input,
  Comments_Set_Input,
} from '@organice/contexts/comments/commentsContext'
import { ApolloError, FetchResult } from '@apollo/client'
import { getIdsFromFieldKey } from '@organice/organisms/roomMatrix/roomMatrixModals/utils'
import { useCommentsContext } from '@organice/contexts/comments/useCommentsContext'
import { useMeContext } from '@organice/contexts/me'

type UpdateCommentInput = {
  commentId: bigint
  sendObject: Comments_Set_Input
}

type UseCommentMutationsProps = {
  type: CommentTypes_Enum
}

type UseCommentMutationsReturn = {
  addComment: (
    sendObject: Comments_Insert_Input
  ) =>
    | Promise<
        FetchResult<
          AddAttributeValueCommentMutation,
          Record<string, any>,
          Record<string, any>
        >
      >
    | Promise<
        FetchResult<
          AddUploadCommentMutationResult,
          Record<string, any>,
          Record<string, any>
        >
      >
    | Promise<
        FetchResult<
          AddRoomAttributeValueCommentMutationResult,
          Record<string, any>,
          Record<string, any>
        >
      >
    | undefined
  updateComment: (
    sendObject: UpdateCommentInput
  ) =>
    | Promise<
        FetchResult<
          AddAttributeValueCommentMutation,
          Record<string, any>,
          Record<string, any>
        >
      >
    | Promise<
        FetchResult<
          UpdateUploadCommentMutationResult,
          Record<string, any>,
          Record<string, any>
        >
      >
    | Promise<
        FetchResult<
          UpdateRoomAttributeValueCommentMutationResult,
          Record<string, any>,
          Record<string, any>
        >
      >
    | undefined
  loading: boolean
  error: ApolloError | undefined
  addData:
    | AddAttributeValueCommentMutation['insert_attributeValueComments_one']
    | AddUploadCommentMutation['insert_uploadComments_one']
    | AddRoomAttributeValueCommentMutation['insert_roomAttributeValueComments_one']
  updateData:
    | UpdateAttributeValueCommentMutation['update_attributeValueComments_by_pk']
    | UpdateUploadCommentMutation['update_uploadComments_by_pk']
    | UpdateRoomAttributeValueCommentMutation['update_roomAttributeValueComments_by_pk']
}

export const useCommentMutations = (
  options: UseCommentMutationsProps
): UseCommentMutationsReturn => {
  const { type } = options
  const { dispatch } = useCommentsContext()
  const {
    state: { isGuest },
  } = useMeContext()

  const [
    addAttributeValueCommentMutation,
    {
      loading: addAttributeValueCommentLoading,
      data: addAttributeValueCommentData,
      error: addAttributeValueCommentError,
    },
  ] = useAddAttributeValueCommentMutation()

  const [
    addUploadCommentMutation,
    {
      loading: addUploadCommentLoading,
      data: addUploadCommentData,
      error: addUploadCommentError,
    },
  ] = useAddUploadCommentMutation()

  const [
    addRoomAttributeValueCommentMutation,
    {
      loading: addRoomAttributeValueCommentLoading,
      data: addRoomAttributeValueCommentData,
      error: addRoomAttributeValueCommentError,
    },
  ] = useAddRoomAttributeValueCommentMutation()

  const [
    addNewRoomAttributeValueCommentMutation,
    {
      loading: newRoomAttributeValueCommentLoading,
      data: newRoomAttributeValueCommentData,
      error: newRoomAttributeValueCommentError,
    },
  ] = useAddRoomAttributeValueCommentMutation()

  function addComment(
    sendObject: Comments_Insert_Input & { [key: string]: any }
  ) {
    switch (type) {
      case CommentTypes_Enum.AttribtueValueComment:
        return addAttributeValueCommentMutation({
          variables: {
            input: sendObject as AttributeValueComments_Insert_Input,
          },
        })

      case CommentTypes_Enum.UploadComment:
        return addUploadCommentMutation({
          variables: {
            input: sendObject as UploadComments_Insert_Input,
          },
        })

      case CommentTypes_Enum.RoomAttributeValueComment:
        /* eslint-disable no-case-declarations */
        const {
          attributeValueKey,
          roomServiceOccurrenceId,
          roomAttributeValueId,
          ...commentObj
        } = sendObject

        /* roomAttributeValue dos not exist > needs insert of roomAttributeValue to add comment */
        if (!roomAttributeValueId) {
          const idsFromAttributeValueKey = getIdsFromFieldKey(attributeValueKey)

          if (!idsFromAttributeValueKey?.roomAttributeId) {
            console.warn(
              `useCommentMutations(): addNewRoomAttributeValueCommentMutation() needs roomAttributeId AND roomAttributeValueGroupId to create a new roomAttributeValue for the comment`
            )
            return undefined
          }

          /* roomAttributeValueGroup & roomAttributeValue do not exist > add group with empty roomAttributeValue.value to add comment */
          if (
            idsFromAttributeValueKey &&
            !idsFromAttributeValueKey?.roomAttributeValueGroupId
          ) {
            return addNewRoomAttributeValueCommentMutation({
              variables: {
                input: {
                  ...commentObj,
                  roomAttributeValue_for_comments: {
                    data: {
                      roomAttributeId: idsFromAttributeValueKey.roomAttributeId,
                      roomAttributeValueGroup: {
                        data: {
                          roomServiceOccurrenceId: roomServiceOccurrenceId,
                          roomAttributeGroupId:
                            idsFromAttributeValueKey.roomAttributeGroupId,
                        },
                      },
                    },
                  },
                } as RoomAttributeValueComments_Insert_Input,
              },
              refetchQueries: [
                {
                  query: GetRoomServiceOccurrenceDocument,
                  variables: {
                    id: roomServiceOccurrenceId,
                    fetchLastUpdatedInfo: isGuest ? false : true,
                  },
                },
              ],
            })
          }

          /* roomAttributeValue does not exist > add with empty roomAttributeValue.value to add comment */
          return addNewRoomAttributeValueCommentMutation({
            variables: {
              input: {
                ...commentObj,
                roomAttributeValue_for_comments: {
                  data: {
                    roomAttributeId: idsFromAttributeValueKey?.roomAttributeId,
                    roomAttributeValueGroupId:
                      idsFromAttributeValueKey?.roomAttributeValueGroupId,
                  },
                },
              } as RoomAttributeValueComments_Insert_Input,
            },
            refetchQueries: [
              {
                query: GetRoomServiceOccurrenceDocument,
                variables: {
                  id: roomServiceOccurrenceId,
                  fetchLastUpdatedInfo: isGuest ? false : true,
                },
              },
            ],
          })

          /* eslint-enable no-case-declarations */
        }

        return addRoomAttributeValueCommentMutation({
          variables: {
            input: {
              ...commentObj,
              roomAttributeValueId,
            } as RoomAttributeValueComments_Insert_Input,
          },
        })

      default:
        console.warn(
          `useCommentMutations(): No INSERT Mutation for CommentType "${type}"`
        )
        return undefined
    }
  }

  React.useEffect(() => {
    const id =
      newRoomAttributeValueCommentData?.insert_roomAttributeValueComments_one
        ?.roomAttributeValueId
    if (id) {
      dispatch({ type: 'RESET_ID', id: id })
    }
  }, [newRoomAttributeValueCommentData])

  const [
    updateAttributeValueCommentMutation,
    {
      loading: updateAttributeValueCommentLoading,
      data: updateAttributeValueCommentData,
      error: updateAttributeValueCommentError,
    },
  ] = useUpdateAttributeValueCommentMutation()

  const [
    updateUploadCommentMutation,
    {
      loading: updateUploadCommentLoading,
      data: updateUploadCommentData,
      error: updateUploadCommentError,
    },
  ] = useUpdateUploadCommentMutation()

  const [
    updateRoomAttributeValueCommentMutation,
    {
      loading: updateRoomAttributeValueCommentLoading,
      data: updateRoomAttributeValueCommentData,
      error: updateRoomAttributeValueCommentError,
    },
  ] = useUpdateRoomAttributeValueCommentMutation()

  function updateComment(updateCommentInput: UpdateCommentInput) {
    const { commentId, sendObject } = updateCommentInput

    switch (type) {
      case CommentTypes_Enum.AttribtueValueComment:
        return updateAttributeValueCommentMutation({
          variables: {
            id: commentId,
            object: { ...sendObject },
          },
        })
      case CommentTypes_Enum.UploadComment:
        return updateUploadCommentMutation({
          variables: {
            id: commentId,
            object: { ...sendObject },
          },
        })
      case CommentTypes_Enum.RoomAttributeValueComment:
        return updateRoomAttributeValueCommentMutation({
          variables: {
            id: commentId,
            object: { ...sendObject },
          },
        })

      default:
        console.warn(
          `useCommentMutations(): No UPDATE Mutation for CommentType "${type}"`
        )
        return undefined
    }
  }

  const error =
    addAttributeValueCommentError ||
    addUploadCommentError ||
    addRoomAttributeValueCommentError ||
    newRoomAttributeValueCommentError ||
    updateAttributeValueCommentError ||
    updateUploadCommentError ||
    updateRoomAttributeValueCommentError
  const loading =
    addAttributeValueCommentLoading ||
    addUploadCommentLoading ||
    addRoomAttributeValueCommentLoading ||
    newRoomAttributeValueCommentLoading ||
    updateAttributeValueCommentLoading ||
    updateUploadCommentLoading ||
    updateRoomAttributeValueCommentLoading
  const addData =
    addAttributeValueCommentData?.insert_attributeValueComments_one ||
    addUploadCommentData?.insert_uploadComments_one ||
    addRoomAttributeValueCommentData?.insert_roomAttributeValueComments_one ||
    newRoomAttributeValueCommentData?.insert_roomAttributeValueComments_one
  const updateData =
    updateAttributeValueCommentData?.update_attributeValueComments_by_pk ||
    updateUploadCommentData?.update_uploadComments_by_pk ||
    updateRoomAttributeValueCommentData?.update_roomAttributeValueComments_by_pk

  return { addComment, updateComment, loading, error, addData, updateData }
}
