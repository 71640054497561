import React, { Suspense } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { ProtectedRoute } from '@organice/atoms/protectedRoute'
import { IRoute } from './config'

const RouteWithSubRoutes = (route: IRoute) => {
  return (
    <Suspense fallback={route.fallback}>
      {route.protected ? (
        <ProtectedRoute
          path={route.path}
          exact={route.exact}
          adminOnly={route.adminOnly}
          render={props => {
            return route.redirect ? (
              <Redirect to={route.redirect} />
            ) : (
              route.component && (
                <route.component {...props} routes={route.routes} />
              )
            )
          }}
        />
      ) : (
        <Route
          path={route.path}
          exact={route.exact}
          render={props => {
            return route.redirect ? (
              <Redirect to={route.redirect} />
            ) : (
              route.component && (
                <route.component {...props} routes={route.routes} />
              )
            )
          }}
        />
      )}
    </Suspense>
  )
}

export default RouteWithSubRoutes
