import React from 'react'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Dayjs } from 'dayjs'
import { Button } from 'antd'
import { dateConfig } from '@organice/config'
import { DateType } from '../../../types/service'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { isRangeType } from './utils'
import { TimeUtil } from '@organice/utils/date'
const dayjs = TimeUtil.getDayjs()
import type {
  TargetField,
  SourceField,
  DateSuggestionOptions,
  DateVariants,
} from './types'

export const DateRangeSuggestion: React.FC<{
  sourceField: SourceField
  dateVariants: DateVariants | DateVariants[]
  targetField?: TargetField
  showTime?: boolean
  onSelect: (selected: DateSuggestionOptions) => void
}> = ({
  sourceField,
  onSelect,
  showTime = false,
  targetField,
  dateVariants,
}) => {
  const { t } = useTranslation()
  const [startDate, endDate] = sourceField.value
  const targetIsRepeatable = targetField?.config.repeatable || false
  const targetIsRangeType = isRangeType(targetField?.config.dateType)
  const [collapseOpen, setCollapseOpen] = React.useState<boolean>(false)

  if (!startDate || !endDate) {
    console.warn(
      `DateRangeSuggestion: Missing 'startDate' or 'endDate' for ${JSON.stringify(
        sourceField
      )}`
    )
    return null
  }

  const arrayWrapper = (output: Dayjs | Dayjs[] | Dayjs[][]) => {
    if (targetIsRepeatable) return [output]
    return output
  }

  const prepareForDateTimeRange = (
    selected: Dayjs | Dayjs[]
  ): Dayjs | Dayjs[] | Dayjs[][] => {
    if (targetField?.config.dateType === DateType.DATE_TIME_RANGE) {
      if (Array.isArray(selected)) {
        if (dayjs(startDate).isSame(dayjs(endDate), 'day')) {
          return selected
        }
        const selectedArr = selected.map(selectedDate => [
          selectedDate,
          selectedDate,
        ])
        return selectedArr
      }
      return [selected, selected] // startDate & startTime
    }
    return selected
  }

  const selectDate = (selected: Dayjs | Dayjs[], irgnoreWrapper = false) => {
    irgnoreWrapper
      ? onSelect(prepareForDateTimeRange(selected))
      : onSelect(arrayWrapper(prepareForDateTimeRange(selected)))
  }

  const getRangeButtons = () => {
    if (targetIsRangeType) {
      return (
        <Button
          onClick={() => selectDate([startDate, endDate])}
          type="link"
        >{`${dateToString(startDate)} - ${dateToString(endDate)}`}</Button>
      )
    }
    return (
      <>
        <Button onClick={() => selectDate(startDate)} type="link">
          {dateToString(startDate)}
        </Button>{' '}
        -{' '}
        <Button onClick={() => selectDate(endDate)} type="link">
          {dateToString(endDate)}
        </Button>
      </>
    )
  }

  const dateToString = (date: Dayjs, timeOnly = false) => {
    if (timeOnly) {
      return dayjs(date).format(dateConfig.TIME_FORMAT)
    }

    return showTime
      ? dayjs(date).format(
          `${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT}`
        )
      : dayjs(date).format(dateConfig.DATE_FORMAT)
  }

  if (dayjs(startDate).isSame(dayjs(endDate), 'day')) {
    if (targetField?.config.dateType === DateType.DATE_TIME_RANGE) {
      return (
        <>
          <Button
            type="link"
            onClick={() => selectDate([startDate, startDate, endDate])}
          >
            {dateToString(startDate)} - {dateToString(endDate, true)}
          </Button>{' '}
        </>
      )
    }
    return (
      <div style={{ marginLeft: targetIsRangeType ? 0 : 14 }}>
        {showTime ? (
          targetIsRangeType ? (
            <>
              <Button
                type="link"
                onClick={() => selectDate([startDate, endDate])}
              >
                {dateToString(startDate)} - {dateToString(endDate, true)}
              </Button>{' '}
            </>
          ) : (
            <>
              <Button type="link" onClick={() => selectDate(startDate)}>
                {dateToString(startDate)}
              </Button>{' '}
              -{' '}
              <Button type="link" onClick={() => selectDate(endDate)}>
                {dateToString(endDate, true)}
              </Button>
            </>
          )
        ) : (
          <Button
            type="link"
            onClick={() =>
              selectDate(targetIsRangeType ? [startDate, endDate] : startDate)
            }
          >
            {dateToString(startDate)}
          </Button>
        )}
      </div>
    )
  }

  if (targetIsRangeType) {
    return (
      <>
        <Button type="link" onClick={() => selectDate([startDate, endDate])}>
          {dateToString(startDate)} - {dateToString(endDate)}
        </Button>{' '}
      </>
    )
  }

  const allDaysOfRange = (dateVariants as DateVariants)?.allDaysOfRange || []

  return (
    <DateRangeSuggestionCollapse>
      <DateRangeSuggestionCollapseHeader>
        <DateRangeSuggestionCollapseIcon
          onClick={() => setCollapseOpen(!collapseOpen)}
        >
          {collapseOpen ? <UpOutlined /> : <DownOutlined />}
        </DateRangeSuggestionCollapseIcon>
        <div>{getRangeButtons()}</div>
      </DateRangeSuggestionCollapseHeader>
      {collapseOpen && (
        <DateRangeSuggestionCollapseContent>
          {allDaysOfRange.map(d => (
            <div className="dateSuggestionFromRange" key={d.format()}>
              <Button
                type="link"
                onClick={() => selectDate(d)}
                style={{ display: 'block' }}
              >
                {dateToString(d)}
              </Button>
            </div>
          ))}
        </DateRangeSuggestionCollapseContent>
      )}
      {collapseOpen &&
        targetField?.config.repeatable &&
        allDaysOfRange.length > 1 && (
          <AddAllBtnWrapper>
            <Button
              onClick={() => selectDate(allDaysOfRange, true)}
              size="small"
            >
              {/* ignoreWrapper is true, because allDaysOfRange is already wrapper and applies only to repeatable targetFields */}
              {t('dateSuggestions.addAll')}
            </Button>
          </AddAllBtnWrapper>
        )}
    </DateRangeSuggestionCollapse>
  )
}

const DateRangeSuggestionCollapse = styled.div``

const DateRangeSuggestionCollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  cursor: pointer;
`

const DateRangeSuggestionCollapseHeader = styled.div`
  display: flex;
`

const DateRangeSuggestionCollapseContent = styled.div`
  .dateSuggestionFromRange {
    padding-left: 20px;
  }

  .dateSuggestionFromRange:nth-of-type(odd) {
    background-color: white;
  }
`

const AddAllBtnWrapper = styled.div`
  padding: 5px 25px;
  background-color: white;
`
