import React from 'react'
import { InputProps } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { ClockCircleOutlined, CloseCircleFilled } from '@ant-design/icons'
import styled from '@emotion/styled'

type ReactRefVariants =
  | React.MutableRefObject<HTMLInputElement>
  | React.RefObject<HTMLInputElement>
  | ((instance: any) => void)
  | null

interface CustomTimeInputProps
  extends Omit<InputProps, 'defaultValue' | 'value' | 'onChange'> {
  defaultPickerValue?: Dayjs | undefined
  defaultValue?: undefined | string | Dayjs
  value?: undefined | string | Dayjs
  onChange?: (time: Dayjs | undefined) => void
  minuteStep?: number
  ref?: React.Ref<HTMLInputElement>
}

// mergeRefs to keep innerRef & forwarderedRef in sync
const mergeRefs = (
  ...refs: (
    | React.MutableRefObject<any>
    | ((instance: any) => void)
    | undefined
    | null
  )[]
) => {
  return (node: any) => {
    for (const ref of refs) {
      if (ref && typeof ref !== 'function') {
        ref.current = node
      }
    }
  }
}

export const CustomTimeInput: React.ForwardRefExoticComponent<CustomTimeInputProps> =
  React.forwardRef<HTMLInputElement, CustomTimeInputProps>(
    (props, forwardedRef) => {
      const { minuteStep } = props

      const [focused, setFocused] = React.useState<boolean>(false)
      const [hovered, setHovered] = React.useState<boolean>(false)
      const [usingTimePicker, setUsingTimePicker] =
        React.useState<boolean>(false)
      const [timeState, setTimeState] = React.useState<TimeObject>({
        h: 0,
        m: 0,
      })
      const [state, setState] = React.useState<string>(getValue(props.value))
      const timePickerRef = React.useRef<HTMLDivElement>(null)
      const inputRef = React.useRef<HTMLInputElement>(null)
      const [initialized, setInitialized] = React.useState<boolean>(false)

      React.useEffect(() => {
        if (props.value) {
          if (state === getValue(props.value)) return
          setState(getValue(props.value))
        }
      }, [props.value])

      React.useEffect(() => {
        const split = state.split(':')
        if (split.length === 2) {
          setTimeState({
            h: split.length && parseInt(split[0]),
            m: split.length && parseInt(split[1]),
          })
        }
      }, [])

      React.useEffect(() => {
        const [h, m] = state.split(':')
        setTimeState({ h: parseInt(h) || 0, m: parseInt(m) || 0 })

        if (h && m) {
          const dayJsValue = dayjs()
            .hour(parseInt(h) || 0)
            .minute(parseInt(m) || 0)
          triggerAntDChange(dayJsValue)
          return
        }

        triggerAntDChange(undefined)
      }, [state])

      React.useEffect(() => {
        setInitialized(true)
      }, [])

      /* Picker Event Listener */
      React.useEffect(() => {
        if (focused && timePickerRef.current) {
          timePickerRef.current.addEventListener('mouseenter', () => {
            setUsingTimePicker(true)
          })
          timePickerRef.current.addEventListener('mouseleave', () => {
            setUsingTimePicker(false)
          })
        }

        return () => {
          if (timePickerRef.current) {
            timePickerRef.current.removeEventListener('mouseenter', () => {
              setUsingTimePicker(true)
            })
            timePickerRef.current.removeEventListener('mouseleave', () => {
              setUsingTimePicker(false)
            })
          }
        }
      }, [focused])

      function triggerAntDChange(val: Dayjs | undefined) {
        if (!initialized) return
        props.onChange && props.onChange(val)
      }

      function internalChangeHandler(
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      ) {
        const value = event.target.value
        setState(value)
      }

      function blurHandler(event: any) {
        if (usingTimePicker) {
          inputRef.current && inputRef.current.focus()
        }
        if (!usingTimePicker) {
          setFocused(false)
          const split = state.split(':')
          if (split.length === 2) {
            setState(`${zeroFill(split[0], 2)}:${zeroFill(split[1], 2)}`)
          }
        }
      }

      function resetField() {
        setState('')
        unfocus()
      }

      function unfocus() {
        setUsingTimePicker(false)
        setTimeout(() => {
          inputRef.current && inputRef.current.blur()
        }, 100)

        setFocused(false)
      }

      const { value, placeholder, ...rest } = props

      return (
        <TimePickerWrapper
          className={`ant-picker ${focused ? 'ant-picker-focused' : ''}`}
        >
          <div className="ant-picker-input">
            <span className="ant-input-affix-wrapper">
              <input
                ref={mergeRefs(inputRef, forwardedRef)}
                value={state}
                onChange={internalChangeHandler}
                onBlur={blurHandler}
                onFocus={() => setFocused(true)}
                className="ant-input"
                type="text"
                placeholder={placeholder}
              />

              <span
                className="ant-input-suffix"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                {hovered && state ? (
                  <CloseCircleFilled
                    style={{ color: 'rgba(0, 0, 0, 0.25)', cursor: 'pointer' }}
                    onClick={() => resetField()}
                  />
                ) : (
                  <ClockCircleOutlined
                    style={{ color: 'rgba(0, 0, 0, 0.25)' }}
                  />
                )}
              </span>
            </span>
            {focused && (
              <PickerDropdown
                minuteStep={minuteStep}
                setVisible={unfocus}
                time={timeState}
                handleClick={time => {
                  setState(`${zeroFill(time.h, 2)}:${zeroFill(time.m, 2)}`)
                }}
                ref={timePickerRef}
              />
            )}
          </div>
        </TimePickerWrapper>
      )
    }
  )

function zeroFill(number: any, targetLength: any) {
  const absNumber = '' + Math.abs(number),
    zerosToFill = targetLength - absNumber.length

  return '0'.repeat(zerosToFill) + absNumber
}

function getValue(valueFromProps: undefined | Dayjs | string): string {
  if (valueFromProps) {
    if (valueFromProps instanceof dayjs) {
      return dayjs(valueFromProps).format('HH:mm')
    }
    return valueFromProps.toString()
  }
  return ''
}

const TimePickerWrapper = styled.div`
  border: none;
  padding: 0;
  position: relative;
`

type TimeObject = {
  h: number | undefined
  m: number | undefined
}

interface PickerDropdownProps {
  time: TimeObject
  handleClick: (time: TimeObject) => void //TimeObject
  setVisible?: (visible: boolean) => void
  minuteStep?: number
}

export const PickerDropdown = React.forwardRef<any, PickerDropdownProps>(
  (props, ref) => {
    const { time, handleClick, setVisible } = props
    const [hourClicked, setHourClicked] = React.useState<boolean>(false)
    const [minuteClicked, setMinuteClicked] = React.useState<boolean>(false)

    const { minuteStep = 15 } = props

    React.useEffect(() => {
      hourClicked && minuteClicked && setVisible && setVisible(false)
    }, [hourClicked, minuteClicked])

    // React.useEffect(() => {
    //   time.h && document.getElementById(`h-${time.h}`)?.scrollIntoView()
    //   time.m && document.getElementById(`m-${time.m}`)?.scrollIntoView()
    // }, [time])

    return (
      <PickerWrapper ref={ref}>
        <PickerPosition
        // className="ant-picker-dropdown ant-picker-dropdown-placement-bottomLeft" -> antd change of styles
        >
          <div tabIndex={-1} className="ant-picker-panel">
            <div className="ant-picker-time-panel">
              <div className="ant-picker-content">
                <ul
                  className="ant-picker-time-panel-column"
                  style={{ position: 'relative' }}
                >
                  {Array.from({ length: 24 }, (_, i) => i).map(i => {
                    return (
                      <li
                        id={`h-${i}`}
                        key={`h-${i}`}
                        className={`ant-picker-time-panel-cell ${
                          i === time.h
                            ? 'ant-picker-time-panel-cell-selected'
                            : ''
                        }`}
                        onClick={() => {
                          setHourClicked(true)
                          handleClick({ ...time, h: i })
                        }}
                      >
                        <div className="ant-picker-time-panel-cell-inner">
                          {zeroFill(i, 2)}
                        </div>
                      </li>
                    )
                  })}
                </ul>
                <ul
                  className="ant-picker-time-panel-column"
                  style={{ position: 'relative' }}
                >
                  {Array.from({ length: 60 }, (_, i) => i).map(i => {
                    if (!(i % minuteStep === 0)) return null

                    return (
                      <li
                        id={`m-${i}`}
                        key={`m-${i}`}
                        className={`ant-picker-time-panel-cell ${
                          i === time.m
                            ? 'ant-picker-time-panel-cell-selected'
                            : ''
                        }`}
                        onClick={() => {
                          setMinuteClicked(true)
                          handleClick({ ...time, m: i })
                        }}
                      >
                        <div className="ant-picker-time-panel-cell-inner">
                          {zeroFill(i, 2)}
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </PickerPosition>
      </PickerWrapper>
    )
  }
)

const PickerWrapper = styled.div`
  position: absolute;
  bottom: -2px;
`

const PickerPosition = styled.div({
  position: 'absolute',
  top: 0,

  zIndex: 9999,
})
