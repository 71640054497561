import {
  useNotifications,
  NotificationActionsCategory,
  UseNotificationsReturn,
} from '@organice/utils/hooks/useNotifications'
import { List, Menu, Popover, Dropdown, Spin } from 'antd'
import React from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'
import { Notification } from '@organice/atoms'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import Icon, { MenuOutlined } from '@ant-design/icons'
import MailOpen from '@organice/icons/mail_open.svg?react'

enum TabKeys {
  Notifications = '1',
  Comments = '2',
}

const UnreadCount: React.FC<{ count: number | undefined }> = ({ count }) => {
  if (!count) {
    return null
  }
  return <>({count})</>
}

type NotificationsProps = {
  visible?: boolean
  notifications: UseNotificationsReturn
}

export const Notifications: React.FC<NotificationsProps> = ({
  visible,
  notifications,
}) => {
  const { t } = useTranslation()
  const {
    allNotifications,
    allCommentNotifications,
    unReadCommentNotificationsCount,
    unReadNotificationsCount,
    markAllNotificationsAsRead,
    setNotificationReadAt,
    loading,
    settingReadAtLoading,
    fetchMoreNotifications,
    hasMoreNotifications,
    fetchMoreCommentNotifications,
    hasMoreCommentNotifications,
  } = notifications

  const readNotification = async (notificationId: bigint, read: boolean) => {
    setNotificationReadAt(notificationId, read)
  }

  const commentsContexMenu = [
    {
      content: t('notifications.markAllAsRead', {
        itemType: t('notifications.comments'),
      }),
      icon: <Icon component={MailOpen} style={{ fontSize: '1rem' }} />,
      onClick: () => {
        markAllNotificationsAsRead(NotificationActionsCategory.Comments)
      },
      disabled: unReadCommentNotificationsCount === 0,
    },
  ]

  const notificationsContexMenu = [
    {
      content: t('notifications.markAllAsRead', {
        itemType: t('notifications.title'),
      }),
      icon: <Icon component={MailOpen} style={{ fontSize: '1rem' }} />,
      onClick: () => {
        markAllNotificationsAsRead(NotificationActionsCategory.Notfications)
      },
      disabled: unReadNotificationsCount === 0,
    },
  ]

  const [activeKey, setActiveKey] = React.useState<string | undefined>('1')

  /* Notifications Autoload on Scroll */
  const needMoreNotificationsTriggerRef = React.useRef<HTMLDivElement>(null)
  const [needMoreNotifications, setNeedMoreNotifications] =
    React.useState(false)

  const notificationsObserver = React.useMemo(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    }

    return new IntersectionObserver(([entry]) => {
      if (Boolean(entry.isIntersecting) !== needMoreNotifications) {
        setNeedMoreNotifications(Boolean(entry.isIntersecting))
      }
    }, options)
  }, [needMoreNotifications])

  React.useEffect(() => {
    if (needMoreNotificationsTriggerRef.current) {
      notificationsObserver.observe(needMoreNotificationsTriggerRef.current)
    }

    return () => {
      notificationsObserver.disconnect()
    }
  }, [needMoreNotificationsTriggerRef, notificationsObserver])

  React.useEffect(() => {
    if (needMoreNotifications === true && hasMoreNotifications) {
      fetchMoreNotifications()
    }
  }, [needMoreNotifications])

  /* Comment Notifications Autoload on Scroll */
  const needMoreCommentNotificationsTriggerRef =
    React.useRef<HTMLDivElement>(null)
  const [needMoreCommentNotifications, setNeedMoreCommentNotifications] =
    React.useState(false)

  const commentsObserver = React.useMemo(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    }

    return new IntersectionObserver(([entry]) => {
      if (Boolean(entry.isIntersecting) !== needMoreCommentNotifications) {
        setNeedMoreCommentNotifications(Boolean(entry.isIntersecting))
      }
    }, options)
  }, [needMoreCommentNotifications])

  React.useEffect(() => {
    if (needMoreCommentNotificationsTriggerRef.current) {
      commentsObserver.observe(needMoreCommentNotificationsTriggerRef.current)
    }

    return () => {
      commentsObserver.disconnect()
    }
  }, [needMoreCommentNotificationsTriggerRef.current, commentsObserver])

  React.useEffect(() => {
    if (needMoreCommentNotifications === true && hasMoreCommentNotifications) {
      fetchMoreCommentNotifications()
    }
  }, [needMoreCommentNotifications])

  return (
    <NotificationsListContainer>
      <Tabs
        activeKey={activeKey}
        onChange={activeKey => setActiveKey(activeKey)}
      >
        <Tabs.TabPane
          key={TabKeys.Notifications}
          tab={
            <>
              {activeKey === TabKeys.Notifications ? (
                <ActionsMenu
                  menuItems={notificationsContexMenu}
                  type={ActionMenuType.PopOver}
                >
                  {t('notifications.title')}{' '}
                  <UnreadCount count={unReadNotificationsCount} />
                </ActionsMenu>
              ) : (
                <>
                  {t('notifications.title')}{' '}
                  <UnreadCount count={unReadNotificationsCount} />
                </>
              )}
            </>
          }
        >
          {!allNotifications && loading ? (
            <NotificationsLoading />
          ) : (
            <ActionsMenu
              visible={visible}
              menuItems={notificationsContexMenu}
              type={ActionMenuType.ContextMenu}
            >
              <List
                itemLayout="horizontal"
                dataSource={allNotifications}
                renderItem={(notification, i) => {
                  return (
                    <Notification
                      {...notification}
                      setReadHandler={readNotification}
                      loading={settingReadAtLoading}
                    />
                  )
                }}
              />
            </ActionsMenu>
          )}

          <div
            ref={needMoreNotificationsTriggerRef}
            style={{ height: 40, width: '100%', background: 'white' }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key={TabKeys.Comments}
          tab={
            <>
              {activeKey === TabKeys.Comments ? (
                <ActionsMenu
                  menuItems={commentsContexMenu}
                  type={ActionMenuType.PopOver}
                >
                  {t('notifications.comments')}{' '}
                  <UnreadCount count={unReadCommentNotificationsCount} />
                </ActionsMenu>
              ) : (
                <>
                  {t('notifications.comments')}{' '}
                  <UnreadCount count={unReadCommentNotificationsCount} />
                </>
              )}
            </>
          }
        >
          {!allCommentNotifications && loading ? (
            <NotificationsLoading />
          ) : (
            <ActionsMenu
              visible={visible}
              menuItems={commentsContexMenu}
              type={ActionMenuType.ContextMenu}
            >
              <List
                itemLayout="horizontal"
                dataSource={allCommentNotifications}
                renderItem={notification => (
                  <Notification
                    {...notification}
                    setReadHandler={readNotification}
                    loading={settingReadAtLoading}
                  />
                )}
              />
            </ActionsMenu>
          )}

          <div
            ref={needMoreCommentNotificationsTriggerRef}
            style={{ height: 40, width: '100%', background: 'white' }}
          />
        </Tabs.TabPane>
      </Tabs>
    </NotificationsListContainer>
  )
}

enum ActionMenuType {
  PopOver = 'popOver',
  ContextMenu = 'contextMenu',
}

type ActionsMenuProps = {
  menuItems: MenuItemType[]
  type?: ActionMenuType
  visible?: boolean
}

const ActionsMenu: React.FC<ActionsMenuProps> = ({
  children,
  menuItems,
  type = ActionMenuType.ContextMenu,
  visible: parentIsVisible,
}) => {
  const [visible, setVisible] = React.useState<boolean>(false)

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible)
  }

  React.useEffect(() => {
    if (parentIsVisible === false) {
      setVisible(false)
    }
  }, [parentIsVisible])

  if (!Array.isArray(menuItems)) return <>{children}</>

  const actionMenuStyles = (
    <Global
      styles={css`
  .context-menu-overlay {
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  }
    .popver-content-no-padding {
      .ant-popover-inner-content {
        padding: 0;
    
        .ant-menu {
          .ant-menu-item {
            padding: 0 2rem;
            margin-bottom: 0;
            margin-bottom: 0;
          }
          .ant-divider {
            margin: 0;
          }
        }
      }
    `}
    />
  )

  if (type === ActionMenuType.PopOver) {
    return (
      <>
        <ActionsMenuStyled>
          <Popover
            open={visible}
            content={
              <ContextMenu menuItems={menuItems} setVisible={setVisible} />
            }
            placement="bottomLeft"
            overlayClassName="popver-content-no-padding"
            onOpenChange={handleVisibleChange}
          >
            <MenuOutlined style={{ color: '#000' }} />
          </Popover>
          {children}
        </ActionsMenuStyled>
        {actionMenuStyles}
      </>
    )
  }

  return (
    <>
      <Dropdown
        overlayClassName="context-menu-overlay"
        overlay={<ContextMenu menuItems={menuItems} setVisible={setVisible} />}
        trigger={['contextMenu']}
        open={visible}
        onOpenChange={handleVisibleChange}
      >
        {children}
      </Dropdown>
      {actionMenuStyles}
    </>
  )
}

const NotificationsLoading: React.FC = () => {
  return (
    <div
      style={{
        height: '100%',
        minHeight: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin />
    </div>
  )
}

type MenuItemType = {
  content: React.ReactNode
  icon: React.ReactNode
  onClick: () => void
  disabled?: boolean
}
interface ContextMenuProps {
  menuItems: MenuItemType[]
  setVisible?: (visible: boolean) => void
}

export const ContextMenu: React.FC<ContextMenuProps> = ({
  menuItems,
  setVisible,
}) => {
  const { t } = useTranslation()
  return (
    <StyledMenu mode="vertical">
      {Array.isArray(menuItems) &&
        menuItems.map((m, i) => (
          <Menu.Item
            icon={m.icon}
            onClick={() => {
              setVisible && setVisible(false)
              m.onClick && m.onClick()
            }}
            disabled={m.disabled}
            key={`context-menu-item-${i}`}
          >
            {m.content}
          </Menu.Item>
        ))}
    </StyledMenu>
  )
}

const StyledMenu = styled(Menu)`
  li.ant-menu-item {
    //  padding-left: 0;
    margin-top: 0;
  }
  ..ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }
`

const NotificationsListContainer = styled.div`
  // height: 18.75rem;

  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 1.25rem 0 0.25rem;
  margin-right: -1rem;
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;

  .ant-tabs-nav {
    margin-bottom: 0;
  }
`

const ActionsMenuStyled = styled.div``
