import React from 'react'
import { Form, Collapse, Divider, Alert } from 'antd'
import { Button, Icon, Paragraph, Heading } from '@organice/atoms'
import { NewAttributeComponent } from '@organice/organisms/eventServiceAttribute'
import { ServiceModulePublishState } from '@organice/molecules/serviceModulePublishState'
import {
  AttributeValue,
  AttributeGroup,
  ServiceModule,
  AttributeValueGroup,
} from '../../types/service'
import styled from '@emotion/styled'
import i18n from '../../i18n/i18n'
import {
  SEPERATOR,
  NEW_GROUP_INDICATOR,
} from '../../components/templates/eventService/useUpload'
import {
  ServiceDisplayModeContextProvider,
  ServiceDisplayContextProviderProps,
  useServiceDisplayMode,
  ServiceDisplayMode,
  ServiceModulePermissionsProvider,
} from '@organice/contexts'
import CalAdd from '@organice/icons/duplicate-group.svg?react'
import { MeState } from '@organice/contexts'
import { FormInstance } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import {
  regexStringsDecorator,
  regexStringsDecoratorRenderer,
} from '@organice/utils/stringsDecorator'
import linesPattern from 'assets/svg/lines_pattern.svg'

export type RemoveExistingAttributeValueGroupCallback = (
  id: AttributeValueGroup['id']
) => void

export const attributeValueMapper = (
  a: AttributeValue,
  i: number,
  attributeValueGroupId: AttributeValueGroup['id']
): JSX.Element => {
  return (
    <Form.Item key={`value_group_${attributeValueGroupId}_index_${i}`} noStyle>
      <NewAttributeComponent {...a}></NewAttributeComponent>
    </Form.Item>
  )
}

export const attributeValueGroupReducer = (
  accElements: JSX.Element[],
  currAttributeValueGroup: AttributeValueGroup,
  attributesInGroup: AttributeGroup['attributes']
): JSX.Element[] => {
  /*
  /* UPDATE: Loop over all attributes in attributesInGroup, find the value in currAttributeValueGroup or use a default empty attributeValue object & generate the field
  /* Initially this was looping over attributeValues, but fileUploads in repeated attributeValueGroups did not have a value in earlier
  /* services, because the value was not created when a new attributeValueGroup was saved. 
  /* Therefore the fileUploads field in a repeated valueGroup would not be rendered.
  /*
  /* Since this update, an empty value for fileUploads in repeated valueGroups is created when a new valueGroup is saved, but to support fileUploads 
  /* in repeated field that have been created prior to this update, the generation of fields is based on attributes as well. (instead of attributeValues)
  */

  function getAttributeValueByAttribute(attr: AttributeGroup['attributes'][0]) {
    /*
    /* helper function to find the attributeValue for a given attribute in currAttributeValueGroup
    /* return a default empty attributeValue object if there is no value for the attibute
    /* This might happen with fileUploads in repeated attributeValueGroups in eary services
    */
    return (
      currAttributeValueGroup.attributeValues.find(
        av => av.attribute.id === attr.id
      ) || {
        id: null,
        value: null,
        attribute: attr,
        attributeValueFiles: [],
        attributeValueGroupId: currAttributeValueGroup.id,
        comments_aggregate: [],
        createdAt: null,
        updatedAt: null,
        updatedBy: null,
      }
    )
  }

  const newAttributeValues = attributesInGroup.map((a, i) => {
    const attrValue = getAttributeValueByAttribute(a)
    return attributeValueMapper(
      attrValue as AttributeValue,
      i,
      currAttributeValueGroup.id
    )
  })

  // const newAttributeValues = currAttributeValueGroup.attributeValues.map(
  //   (a, i) => {
  //     return attributeValueMapper(a, i, currAttributeValueGroup.id)
  //   }
  // )

  return [...accElements, ...newAttributeValues]
}

export interface CreateNewAttributeValueGroup {
  id: AttributeValueGroup['id']
  attributeGroup: AttributeGroup
  fieldName?: string
}

export const createNewAttributeValueGroup = ({
  id,
  attributeGroup,
}: CreateNewAttributeValueGroup): AttributeValueGroup => {
  return {
    id,
    attributeValues:
      attributeGroup.attributeValueGroups.length > 0
        ? attributeGroup.attributeValueGroups[0].attributeValues.map<AttributeValue>(
            attributeValue => ({
              attribute: attributeValue.attribute,
              createdAt: null,
              updatedAt: null,
              id: [id, String(attributeValue.attribute.id)],
              attributeValueFiles: [],
              attributeValueGroupId: attributeGroup.id,
              value: { value: undefined },
              comments_aggregate: {
                __typename: 'attributeValueComments_aggregate',
                aggregate: {
                  count: 0,
                  __typename: 'attributeValueComments_aggregate_fields',
                },
              },
            })
          )
        : [],
  }
}

const GroupHeading: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [state] = useServiceDisplayMode()

  return (
    <Heading
      level={state.serviceDisplayMode === ServiceDisplayMode.EXPORT ? 4 : 3}
    >
      {children}
    </Heading>
  )
}

interface RepeatableGroupsProps {
  attributeGroup: AttributeGroup
  onExistingAttributeValueGroupRemove?: RemoveExistingAttributeValueGroupCallback
}

const RepeatableAttributeGroup: React.FC<RepeatableGroupsProps> = ({
  attributeGroup,
  onExistingAttributeValueGroupRemove,
}) => {
  const [state] = useServiceDisplayMode()

  const hasWriteAccess =
    state.serviceDisplayMode == ServiceDisplayMode.WRITE_ACCESS

  const attributesInGroup: AttributeGroup['attributes'] =
    attributeGroup.attributes

  return (
    <>
      {attributeGroup.attributeValueGroups.reduce<JSX.Element[]>(
        (elements, currAttributeValueGroup, index) => {
          const renderedAttributeValueGroup = attributeValueGroupReducer(
            [],
            currAttributeValueGroup,
            attributesInGroup
          )

          return [
            ...elements,

            <React.Fragment key={index}>
              <div className="attributeValueGroup">
                {renderedAttributeValueGroup}
                {index > 0 && hasWriteAccess && (
                  <DeleteButtonContainer>
                    <Button
                      onClick={() =>
                        onExistingAttributeValueGroupRemove &&
                        onExistingAttributeValueGroupRemove(
                          currAttributeValueGroup.id
                        )
                      }
                    >
                      {i18n.t('service.group.delete')}
                    </Button>
                  </DeleteButtonContainer>
                )}
                <Divider></Divider>
              </div>
            </React.Fragment>,
          ]
        },
        []
      )}
      <Form.List
        name={[
          `${NEW_GROUP_INDICATOR}${SEPERATOR}groupId${SEPERATOR}${attributeGroup.id}`,
        ]}
      >
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, fieldIndex) => {
                const attributeValueGroup: AttributeValueGroup =
                  createNewAttributeValueGroup({
                    id: field.name,
                    attributeGroup: attributeGroup,
                  })

                const newElements = attributeValueGroupReducer(
                  [],
                  attributeValueGroup,
                  attributesInGroup
                )

                return (
                  <div className="attributeValueGroup">
                    <Form.Item key={field.key} noStyle>
                      {fieldIndex !== 0 && <Divider />}
                      <Form.Item noStyle>{newElements}</Form.Item>
                      <DeleteButtonContainer>
                        <Button onClick={() => remove(field.name)}>
                          {i18n.t('service.group.delete')}
                        </Button>
                      </DeleteButtonContainer>
                      {fieldIndex === fields.length - 1 && <LastDivider />}
                    </Form.Item>
                  </div>
                )
              })}

              {hasWriteAccess && (
                <Form.Item>
                  <RepeatButtonContainer>
                    <Button
                      onClick={() => add()}
                      icon={<Icon component={CalAdd} />}
                    >
                      {i18n.t('service.group.repeat')}
                    </Button>
                  </RepeatButtonContainer>
                </Form.Item>
              )}
            </>
          )
        }}
      </Form.List>
    </>
  )
}

function checkIfAttributeGroupHasBeenTouched(attributeGroup: AttributeGroup) {
  const { attributeValueGroups } = attributeGroup
  if (attributeValueGroups) {
    return attributeValueGroups.some(avg => {
      return avg.attributeValues.some(av => {
        // attributeValue groups has been edited (or has comments)
        return (
          !dayjs(av.createdAt).isSame(dayjs(av.updatedAt)) ||
          (av.comments_aggregate.aggregate?.count &&
            av.comments_aggregate.aggregate?.count > 0)
        )
      })
    })
  }

  return true
}

export const attributeGroupReducer = (
  accElements: JSX.Element[],
  currAttributeGroup: AttributeGroup,
  attributeIdFromUrl?: string | undefined,
  isExport?: boolean,
  onExistingAttributeValueGroupRemove?: RemoveExistingAttributeValueGroupCallback
): JSX.Element[] => {
  const isTouched = checkIfAttributeGroupHasBeenTouched(currAttributeGroup)
  const containsAttributeIdFromUrl =
    attributeIdFromUrl &&
    currAttributeGroup.attributes.find(a => a.id === Number(attributeIdFromUrl))

  const newElement = (
    <StyledCollapse
      defaultActiveKey={
        isTouched || isExport || containsAttributeIdFromUrl ? 1 : undefined
      }
      ghost
      key={currAttributeGroup.id}
    >
      <Collapse.Panel
        header={<GroupHeading>{currAttributeGroup.name}</GroupHeading>}
        key={1}
      >
        {!isExport && (
          <Paragraph style={{ whiteSpace: 'pre-line' }}>
            {currAttributeGroup.description &&
              regexStringsDecorator(
                currAttributeGroup.description,
                regexStringsDecoratorRenderer
              )}
          </Paragraph>
        )}

        {/* if a group is not repeatable, render this */}
        {!currAttributeGroup.valuesRepeatable &&
          currAttributeGroup.attributeValueGroups.reduce<JSX.Element[]>(
            (elements, currAttributeValueGroup) =>
              attributeValueGroupReducer(
                [],
                currAttributeValueGroup,
                currAttributeGroup.attributes
              ),
            []
          )}

        {/* if a group is repeatable, render this */}
        {currAttributeGroup.valuesRepeatable && (
          <RepeatableAttributeGroup
            attributeGroup={currAttributeGroup}
            onExistingAttributeValueGroupRemove={
              onExistingAttributeValueGroupRemove
            }
          ></RepeatableAttributeGroup>
        )}
      </Collapse.Panel>
    </StyledCollapse>
  )

  return [...accElements, newElement]
}

export interface RenderVirtualDomConfig {
  moduleData: Omit<ServiceModule, 'order'>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onValuesChange?(allValues: any, changedValues: any): void
  onExistingAttributeValueGroupRemove?: RemoveExistingAttributeValueGroupCallback
  serviceDisplayState: ServiceDisplayContextProviderProps['value']
  form?: FormInstance
  currentUser?: MeState
  attributeIdFromUrl?: string | undefined
  isExport?: boolean
}

export const renderVirtualDom = ({
  moduleData,
  onExistingAttributeValueGroupRemove,
  onValuesChange,
  serviceDisplayState,
  form,
  currentUser,
  attributeIdFromUrl,
  isExport = false,
}: RenderVirtualDomConfig): React.ReactNode => {
  const hasWriteAccess =
    serviceDisplayState.serviceDisplayMode === ServiceDisplayMode.WRITE_ACCESS
  const canPublish = currentUser?.isAdmin || currentUser?.isNM
  const canSeePublishSettings =
    canPublish && (currentUser?.isAdmin || currentUser?.isNM || hasWriteAccess)

  return (
    <ServiceDisplayModeContextProvider value={serviceDisplayState}>
      <ServiceModulePublishState module={moduleData}>
        <ServiceModulePermissionsProvider
          serviceModulePermissions={{
            writable: !!moduleData.writable,
            serviceModuleId: moduleData.id,
          }}
        >
          {(currentUser?.isAdmin || currentUser?.isNM) &&
            !moduleData.writable && (
              <Alert
                message={
                  currentUser?.isAdmin
                    ? i18n.t(
                        'messages.serviceModules.isAdminButNoWritePermissions'
                      )
                    : i18n.t(
                        'messages.serviceModules.isNMUserButNoWritePermissions'
                      )
                }
                type="info"
                style={{ marginBottom: '1rem' }}
              />
            )}
          <StyledForm
            isDraftMode={!moduleData?.published && !canSeePublishSettings}
            form={form}
            onValuesChange={(changedValues, allValues) => {
              // console.log('onValuesChange', changedValues, allValues)
              onValuesChange && onValuesChange(changedValues, allValues)
            }}
            onFinish={console.log}
          >
            {moduleData.attributeGroups.reduce<JSX.Element[]>(
              (curr, acc) =>
                attributeGroupReducer(
                  curr,
                  acc,
                  attributeIdFromUrl,
                  isExport,
                  onExistingAttributeValueGroupRemove
                ),
              []
            )}
          </StyledForm>
        </ServiceModulePermissionsProvider>
      </ServiceModulePublishState>
    </ServiceDisplayModeContextProvider>
  )
}

const StyledCollapse = styled(Collapse)(({ theme }) => ({
  '& .ant-collapse-header': {
    display: 'flex',
    alignItems: 'center',
    '&:hover ': {
      backgroundColor: theme.lightBackground,
    },
    '& > h3, & > h4': {
      margin: 0,
    },
  },
}))

const RepeatButtonContainer = styled.div({
  margin: '1.25rem 0',
})

const DeleteButtonContainer = styled.div({
  marginTop: '1.25rem',
})

const LastDivider = styled(Divider)({
  marginBottom: 0,
})

const StyledForm = styled(Form, {
  shouldForwardProp: props => props !== 'isDraftMode',
})<{ isDraftMode?: boolean | null }>(({ isDraftMode }) => ({
  position: 'relative',

  '&:before': isDraftMode
    ? {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        opacity: 0.2,
        backgroundImage: `url("${linesPattern}")`,
        //backgroundSize: '5%',
        zIndex: -1,
      }
    : {},
}))
