import React from 'react'
import { Label } from '@organice/atoms'
import styled from '@emotion/styled'

type LabelProps = JSX.IntrinsicElements['label']

export interface LabelContainerProps {
  label?: string
  labelProps?: LabelProps
}

export const LabelContainer = React.forwardRef<
  HTMLLabelElement,
  React.PropsWithChildren<LabelContainerProps>
>(({ children, label, labelProps }, ref) => {
  return (
    <Container>
      <ContainerLabel>
        <Label {...labelProps} ref={ref}>
          {label}
        </Label>
      </ContainerLabel>

      <ContainerChildren>{children}</ContainerChildren>
    </Container>
  )
})

const Container = styled.div({ flex: 1 })

const ContainerLabel = styled.div({ marginBottom: '0.375rem' })

const ContainerChildren = styled.div({
  display: 'flex',
})
