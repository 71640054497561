import React from 'react'
import {
  withErrorBoundary as errorBoundary,
  FallbackProps,
  useErrorBoundary,
  ErrorBoundary as ErrorBoundaryWrapper,
} from 'react-error-boundary'
import { Alert } from '@organice/atoms/alert'

const Fallback: React.ComponentType<FallbackProps> = props => {
  return (
    <Alert
      message="Error"
      description={props?.error?.stack?.toString()}
      type="error"
      showIcon
    />
  )
}

const errorLogger = (error: Error, info: React.ErrorInfo) => {
  // Maybe implement a custom logger?
  console.error('Error ->', error, info)
}

export const ErrorBoundary: React.FC<{
  children?: React.ReactNode
  fallbackComponent?: React.ComponentType<FallbackProps>
}> = ({ children, fallbackComponent }) => (
  <ErrorBoundaryWrapper
    FallbackComponent={fallbackComponent || Fallback}
    onError={errorLogger}
  >
    {children}
  </ErrorBoundaryWrapper>
)

export function withErrorBoundary<P extends object>(
  Component: React.ComponentType<P>
) {
  return errorBoundary(Component, {
    FallbackComponent: Fallback,
    onError: errorLogger,
  })
}
export { useErrorBoundary }
