import React from 'react'

export enum ServiceDisplayMode {
  NO_ACCESS = 'noAccess',
  READ_ACCESS = 'readAccess',
  WRITE_ACCESS = 'writeAccess',
  EXPORT = 'export',
}

export interface ServiceDisplayModeContextState {
  serviceDisplayMode: ServiceDisplayMode
}

export type ServiceDisplayModeContextAction = {
  type: 'SET_SERVICE_DISPLAY_MODE'
  payload: Pick<ServiceDisplayModeContextState, 'serviceDisplayMode'>
}

const serviceDisplayModeContextReducer: React.Reducer<
  ServiceDisplayModeContextState,
  ServiceDisplayModeContextAction
> = (state, action) => {
  switch (action.type) {
    case 'SET_SERVICE_DISPLAY_MODE':
      return { ...state, ...action.payload }
  }
}

export const DEFAULT_SERVICE_DISPLAY_MODE_STATE: ServiceDisplayModeContextState =
  {
    serviceDisplayMode: ServiceDisplayMode.NO_ACCESS,
  }

export type ServiceDisplayContextType = [
  ServiceDisplayModeContextState,
  React.Dispatch<ServiceDisplayModeContextAction>,
]

export const ServiceDisplayModeContext =
  React.createContext<ServiceDisplayContextType>([
    DEFAULT_SERVICE_DISPLAY_MODE_STATE,
    () => undefined,
  ])

export const useServiceDisplayMode = (): ServiceDisplayContextType =>
  React.useContext(ServiceDisplayModeContext)

export interface ServiceDisplayContextProviderProps {
  value: ServiceDisplayModeContextState
}

export const ServiceDisplayModeContextProvider: React.FC<
  React.PropsWithChildren<ServiceDisplayContextProviderProps>
> = ({ value, children }) => {
  const [state, dispatch] = React.useReducer<
    React.Reducer<
      ServiceDisplayModeContextState,
      ServiceDisplayModeContextAction
    >
  >(serviceDisplayModeContextReducer, value)

  // if the provider gets rerendered with new value.serviceDisplayMode, dispatch the change
  React.useEffect(() => {
    if (state.serviceDisplayMode !== value.serviceDisplayMode) {
      dispatch({
        type: 'SET_SERVICE_DISPLAY_MODE',
        payload: { serviceDisplayMode: value.serviceDisplayMode },
      })
    }
  }, [value.serviceDisplayMode])

  return (
    <ServiceDisplayModeContext.Provider value={[state, dispatch]}>
      {children}
    </ServiceDisplayModeContext.Provider>
  )
}
