import React, { ReactNode } from 'react'
import { RoomFolderListQuery } from '@organice/graphql'
import { Popover } from '../popover'
import { Modal } from '../modal'
import { useTranslation } from 'react-i18next'
import { Divider } from 'antd'
import styled from '@emotion/styled'
import {
  DeleteOutlined,
  EditOutlined,
  FolderAddOutlined,
} from '@ant-design/icons'
import { useRoomFolderMutations } from '@organice/utils/hooks'

import { RoomFolderModal } from './roomFolderModal'

const ICON_FONT_SIZE = '1.25rem'

export type RoomFolder = NonNullable<RoomFolderListQuery['roomFolders'][0]>

export interface RoomFolderPopoverProps {
  id: RoomFolder['id']
  name: RoomFolder['name'] | undefined
}

interface RoomFolderPopoverState {
  showPopover?: boolean
}

export const RoomFolderPopover: React.FC<RoomFolderPopoverProps> = ({
  children,
  id,
  name,
}) => {
  const [state, setState] = React.useState<RoomFolderPopoverState>({})
  const [modal, setModal] = React.useState<ReactNode | undefined>()
  const { t } = useTranslation()
  const { deleteRoomFolder, addRoomFolder, updateRoomFolder, loading, error } =
    useRoomFolderMutations({
      onDeleted: () => setModal(undefined),
      onAdded: () => setModal(undefined),
      onUpdated: () => setModal(undefined),
    })

  const handleRemove = () => {
    closePopover()

    Modal.confirm({
      title: t('configuration.rooms.list.deleteModal.title'),
      okText: t('configuration.rooms.list.deleteModal.deleteBtn'),
      cancelText: t('configuration.rooms.list.deleteModal.cancelBtn'),
      content: t('configuration.rooms.list.deleteModal.content'),
      closable: true,
      maskClosable: true,
      onOk: () => {
        deleteRoomFolder(id)
      },
    })
  }

  const handleRename = () => {
    closePopover()
    setModal(
      <RoomFolderModal
        title={t('configuration.rooms.list.contextMenu.renameFolder')}
        onClose={() => setModal(undefined)}
        roomFolderName={name}
        onFinish={(values: any) =>
          updateRoomFolder(id, {
            name: values.name,
          })
        }
        loading={loading}
      />
    )
  }

  const handleCreateSubFolder = () => {
    closePopover()
    setModal(
      <RoomFolderModal
        title={t('configuration.rooms.list.contextMenu.addSubFolder')}
        onClose={() => setModal(undefined)}
        onFinish={(values: any) =>
          addRoomFolder({ name: values.name, parentId: id })
        }
        loading={loading}
      />
    )
  }

  const closePopover = () => {
    setState(s => ({ ...s, showPopover: false }))
  }

  const renderMenu = () => (
    <>
      <ContextMenuList>
        <ContextMenuListItem onClick={handleRename}>
          <RenameIcon />
          {t('common.actions.rename')}
        </ContextMenuListItem>

        <ContextMenuListItem onClick={handleCreateSubFolder}>
          <AddSubFolderIcon />{' '}
          {t('configuration.rooms.list.contextMenu.addSubFolder')}
        </ContextMenuListItem>

        <ContextMenuDivider />

        <ContextMenuListItemDelete onClick={handleRemove}>
          <DeleteIcon></DeleteIcon>
          {t('common.actions.delete')}
        </ContextMenuListItemDelete>
      </ContextMenuList>
      {modal}
    </>
  )

  return (
    <Popover
      open={state.showPopover}
      trigger="click"
      onOpenChange={showPopover => setState(s => ({ ...s, showPopover }))}
      placement="rightTop"
      content={renderMenu}
    >
      {children}
    </Popover>
  )
}

const ContextMenuList = styled.ul({
  padding: 0,
  margin: 0,
})

const ContextMenuListItem = styled.li(({ theme }) => ({
  listStyleType: 'none',
  padding: '.5rem',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  transition: 'color .3s',

  '&:hover': {
    backgroundColor: theme.lightBackground,
    color: theme.primaryColor,
  },
}))

const ContextMenuListItemDelete = styled(ContextMenuListItem)(props => ({
  color: props.theme.errorColor,

  '&:hover': {
    color: props.theme.errorColor,
  },
}))

const DeleteIcon = styled(DeleteOutlined)(props => ({
  color: props.theme.errorColor,
  fontSize: ICON_FONT_SIZE,
  marginRight: '.5rem',
}))

const RenameIcon = styled(EditOutlined)({
  fontSize: ICON_FONT_SIZE,
  marginRight: '.5rem',
})

const AddSubFolderIcon = styled(FolderAddOutlined)({
  fontSize: ICON_FONT_SIZE,
  marginRight: '.5rem',
})

const ContextMenuDivider = styled(Divider)({ margin: '.5rem 0' })
