import { TimeUtil } from '../date'
import i18n from '../../i18n/i18n'
import { Dayjs } from 'dayjs'
const dayjs = TimeUtil.getDayjs()

function minutesOfDay(date: Dayjs) {
  return date.minute() + date.hour() * 60
}

const validateDateTimeRange = async (rule: any, value: any) => {
  // not an array (undefined) > treat as empty

  if (!Array.isArray(value)) {
    return true
    throw new Error(i18n.t('common.form.error.fillOutAllFields'))
  }

  // every element undefined or null is an empty
  if (value.every((date: unknown) => date === undefined || date === null)) {
    return true
  }

  if (value.length !== 3) {
    throw new Error(i18n.t('common.form.error.fillOutAllFields'))
  }

  const timeRegex = /(\d{2}):(\d{2})/
  if (typeof value[1] === 'string' && !value[1].match(timeRegex)) {
    throw new Error(i18n.t('common.form.error.startTimeFormat'))
  }
  if (typeof value[2] === 'string' && !value[2].match(timeRegex)) {
    throw new Error(i18n.t('common.form.error.endTimeFormat'))
  }

  if (
    !value.every((date: unknown) => {
      return (
        date &&
        (date instanceof dayjs ||
          dayjs(`${dayjs(value[0]).format('YYYY-MM-DD')} ${date}`).isValid())
      )
    })
  ) {
    throw new Error(i18n.t('common.form.error.fillOutAllFields'))
  }

  const startTime: Dayjs =
    value[1] instanceof dayjs
      ? (value[1] as Dayjs)
      : dayjs(`${dayjs(value[0]).format('YYYY-MM-DD')} ${value[1]}`)
  const endTime: Dayjs =
    value[2] instanceof dayjs
      ? (value[2] as Dayjs)
      : dayjs(`${dayjs(value[0]).format('YYYY-MM-DD')} ${value[2]}`)

  // Compare only time of day (minutes), because 2nd date can be at any day (only time is used as endTime)
  if (minutesOfDay(endTime) <= minutesOfDay(startTime)) {
    throw new Error(i18n.t('common.form.error.endTimeBeforeStartTime'))
  }
  return true
}

const validateDateRangeTime = async (rule: any, value: any) => {
  console.log(value)

  if (!Array.isArray(value)) return true
  if (
    Array.isArray(value) &&
    value.every(date => date === undefined || date === null)
  )
    return true

  const [startDate, endDate] = value
  if (value.some(date => !(date instanceof dayjs))) {
    throw new Error(i18n.t('common.form.error.fillOutAllFields'))
  }

  if (startDate.isAfter(endDate)) {
    throw new Error(i18n.t('common.form.error.endTimeBeforeStartTime'))
  }
}

export const validationRules = {
  validateDateTimeRange,
  validateDateRangeTime,
}
