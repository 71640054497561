import React from 'react'
import { Link } from 'react-router-dom'
import { Users, Events, Organisations } from '@organice/graphql'
import { superSplit } from '../superSplit'
import { Text } from '@organice/atoms/text'
import {
  regexStringsDecorator,
  regexStringsDecoratorRenderer,
} from '@organice/utils/stringsDecorator'
import { Person } from '@organice/molecules'

export const MENTIONS_PREFIXES = {
  users: '@',
  organisations: '@',
  events: '#',
}

export interface CommentMentionsResult {
  users: Users[]
  events: Events[]
  organisations: Organisations[]
}

interface MentionObject {
  label: string
  user?: string
  event?: string
  orga?: string
}

function safelyParseJSON(json: string): Array<string | MentionObject> {
  let parsed
  try {
    parsed = JSON.parse(json) as Array<string | MentionObject>
  } catch (e) {
    parsed = [json]
    console.warn('FAILED TO PARSE', json)
  }
  return parsed
}
export class MentionsParser {
  public static decode = (
    input: string | Array<string | MentionObject>,
    raw = false,
    isAdmin = false
  ): React.ReactNode | string => {
    const data: Array<string | MentionObject> =
      typeof input === 'object' ? input : safelyParseJSON(input)

    if (!Array.isArray(data)) return input

    const ret = data?.map(value => {
      if (MentionsParser.isMentionObject(value)) {
        return !raw ? (
          <> {MentionsParser.parseObject(value, false, isAdmin)} </>
        ) : (
          ` ${MentionsParser.parseObject(value, true, isAdmin)} `
        )
      }

      return !raw ? (
        <Text style={{ whiteSpace: 'pre-line' }}>
          {regexStringsDecorator(
            value.toString(),
            regexStringsDecoratorRenderer
          )}
        </Text>
      ) : (
        (value.toString() as string)
      )
    })
    return !raw
      ? ret.map((m, i) => <React.Fragment>{m}</React.Fragment>)
      : ret.join('')
  }

  public static encode(input: string, mentions: CommentMentionsResult): string {
    const { users, events, organisations } = mentions || {}
    const splitted: string | string[] = superSplit(input, [
      ...(users?.map(
        user => `${MENTIONS_PREFIXES.users}${user?.firstName} ${user?.lastName}`
      ) || []),

      ...(organisations?.map(o => `${MENTIONS_PREFIXES.users}${o?.name}`) ||
        []),
      ...(organisations?.map(
        o => `${MENTIONS_PREFIXES.organisations}${o?.name}`
      ) || []),
      ...(events?.map(event => `${MENTIONS_PREFIXES.events}${event?.name}`) ||
        []),
    ])

    if (typeof splitted === 'string') return splitted
    const output = splitted
      .map((part: string) => {
        const u = users?.find(
          user =>
            `${MENTIONS_PREFIXES.users}${user?.firstName} ${user?.lastName}` ===
            part
        )

        if (u) {
          return {
            label: `${u.firstName} ${u.lastName}`,
            user: u.id,
          }
        }

        const o = organisations?.find(org => {
          return `${MENTIONS_PREFIXES.users}${org?.name}` === part
        })

        return o
          ? {
              label: `${o.name}`,
              orga: o.id,
            }
          : typeof part === 'string'
            ? part.trim()
            : part
      })
      .map(part => {
        const e = organisations?.find(org => {
          return `${MENTIONS_PREFIXES.organisations}${org?.name}` === part
        })
        return e
          ? {
              label: `${e.name}`,
              orga: e.id,
            }
          : typeof part === 'string'
            ? part.trim()
            : part
      })
      .map(part => {
        const e = events?.find(
          event => `${MENTIONS_PREFIXES.events}${event?.name}` === part
        )
        return e
          ? {
              label: e.name,
              event: e.id,
            }
          : typeof part === 'string'
            ? part.trim()
            : part
      })

    return JSON.stringify(output)
  }

  public static isMentionObject(obj: unknown): obj is MentionObject {
    return (
      (obj as MentionObject)?.label !== undefined &&
      ((obj as MentionObject)?.user !== undefined ||
        (obj as MentionObject)?.orga !== undefined ||
        (obj as MentionObject)?.event !== undefined)
    )
  }

  public static parseObject(
    data: MentionObject,
    raw = false,
    isAdmin = false
  ): React.ReactNode {
    if (data?.user) return this.parseUser(data, raw, isAdmin)
    if (data?.orga) return this.parseOrganisation(data, raw, isAdmin)
    if (data?.event) return this.parseEvent(data, raw)

    // Fallback
    return data.toString()
  }

  private static parseUser(
    data: MentionObject,
    raw = false,
    isAdmin = false
  ): React.ReactNode {
    if (raw) return `${MENTIONS_PREFIXES.users}${data.label}`

    return (
      <Person user={{ id: Number(data.user) }}>
        <Text strong>
          {MENTIONS_PREFIXES.users}
          {data.label}
        </Text>
      </Person>
    )
  }

  private static parseOrganisation(
    data: MentionObject,
    raw = false,
    isAdmin = false
  ): React.ReactNode {
    if (raw) return `${MENTIONS_PREFIXES.organisations}${data.label}`
    if (isAdmin)
      return (
        <Link to={`/accounts/organisations/${data.orga}`}>
          <Text strong>
            {MENTIONS_PREFIXES.organisations}
            {data.label}
          </Text>
        </Link>
      )
    return (
      <Text strong>
        {MENTIONS_PREFIXES.organisations}
        {data.label}
      </Text>
    )
  }

  private static parseEvent(data: MentionObject, raw = false): React.ReactNode {
    if (raw) return `${MENTIONS_PREFIXES.events}${data.label}`
    return (
      <Link to={`/events/${data.event}`}>
        <Text strong>
          {MENTIONS_PREFIXES.events}
          {data.label}
        </Text>
      </Link>
    )
  }
}
