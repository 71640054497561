import React from 'react'
import { useTranslation } from 'react-i18next'
import { Popover, Menu } from 'antd'
import { tagCategories } from './tag-categories'
import styled from '@emotion/styled'
import { DownOutlined } from '@ant-design/icons'

interface TagTypeSelectorProps extends React.HTMLAttributes<HTMLDivElement> {
  tagCategory: (typeof tagCategories)[0]
  onTagSelect?: (tagCategory: (typeof tagCategories)[0]) => void
  isFocused: boolean
}

export const TagTypeSelector: React.FC<TagTypeSelectorProps> = ({
  tagCategory,
  onTagSelect,
  isFocused = false,
}) => {
  const { t } = useTranslation()
  const [visible, setVisible] = React.useState<boolean>()

  const popverContent = React.useMemo(
    () => (
      <Menu>
        {tagCategories.map(tt => (
          <Menu.Item
            key={tt.name}
            onClick={() => {
              onTagSelect && onTagSelect(tt)
              setVisible(false)
            }}
          >
            {tt.icon} {t(tt.name)}
          </Menu.Item>
        ))}
      </Menu>
    ),
    []
  )

  return (
    <TagTypeSelectorWrapper isFocused={isFocused}>
      <Popover
        placement="right"
        content={popverContent}
        open={visible}
        onOpenChange={setVisible}
        trigger="click"
        overlayClassName="tagCategorySelector"
      >
        {tagCategory.icon} <DownOutlined style={{ fontSize: 10 }} />
      </Popover>
    </TagTypeSelectorWrapper>
  )
}

const TagTypeSelectorWrapper = styled.div<NewTagSideButtons>(
  ({ theme, isFocused = false }) => `
    height: 32px;
    padding: 0 5px;
    cursor: pointer;
    background: ${theme.lightGreyBackground};
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border: 1px solid ${isFocused ? theme.primaryColor : '#d9dadb'};
    border-right: 1px solid #d9dadb;
    display: flex;
    justify-content: center;
    align-items: center;

    ${
      isFocused
        ? `box-shadow: 0 0 0 2px rgb(255 80 20 / 20%);   clip-path: inset(-2px 0 -2px -2px);`
        : ``
    }
  
  `
)
