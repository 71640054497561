export const isLocalStorageEnabled = (): boolean => {
  const key = 'localstoragetest'
  try {
    localStorage.setItem(key, key)
    localStorage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}

export const isCookieEnabled = (): boolean => {
  try {
    document.cookie = 'cookietest=1'
    const cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'
    return cookiesEnabled
  } catch (e) {
    return false
  }
}
