import React, { useReducer } from 'react'
import { Events, AttributeValues, EventRooms } from '@organice/graphql'

// TODO: Fix Typing
type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Record<string, unknown>
    ? DeepPartial<T[P]>
    : T[P]
}

export type EventAllOptional = Omit<DeepPartial<Events>, 'attributeValues'> & {
  attributeValues?: DeepPartial<AttributeValues>[]
}

export type EventColor = {
  background: string
  text: string
  border: string
}

export type EventWithColor = Events & { color?: EventColor }

export type EventContextState = {
  event?: EventAllOptional | null | undefined
  events?: (Events & { color?: string })[] | Events[] | null | undefined
  eventRooms?: EventRooms['room'][]
}

const defaultState: EventContextState = { event: null }

const EventContext = React.createContext<
  EventContextState & {
    dispatch: React.Dispatch<any>
  }
>({
  ...defaultState,
  dispatch: () => null,
})
EventContext.displayName = 'EventContext'

type EventContextProviderProps = {
  children: React.ReactNode
  initialState?: EventContextState
}

const reducer = (state: EventContextState, action: any): EventContextState => {
  switch (action.type) {
    case 'SET_EVENT':
      return { ...state, event: action.payload }
    case 'SET_EVENTS':
      return { ...state, events: action.payload }
    // Add more cases for other actions as needed
    default:
      return state
  }
}

export function EventContextProvider({
  children,
  initialState,
}: EventContextProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState || defaultState)

  return (
    <EventContext.Provider value={{ ...state, dispatch }}>
      {children}
    </EventContext.Provider>
  )
}

export function useEventContext() {
  const context = React.useContext(EventContext)

  if (!context) {
    throw new Error(
      `useEventContext() can only be used within EventContextProvider.`
    )
  }

  return context
}
