import {
  EventOrganisers,
  EventOrganisers_Insert_Input,
} from '@organice/graphql'
import { GeneralDict } from '@organice/utils'

export type OrganizerSelectObject = {
  label: string
  value: string
}

type ConvertOrganizerModelProps =
  | OrganizerSelectObject
  | OrganizerSelectObject[]
  | EventOrganisers
  | EventOrganisers[]

/* 
/* Conversion of User | User[] to OrganizerSelectObject | OrganizerSelectObject[]
/* or OrganizerSelectObject | OrganizerSelectObject[] to OrganizerInput | OrganizerInput[]
/* This is useful to get a label/value Object from a User type input for a Select field
/* or get the submittable Array of Organizers to submit in your GraphQL Mutation
*/
export const convertOrganizerModel = (
  input?: ConvertOrganizerModelProps,
  values?: GeneralDict
) => {
  function convert(item: OrganizerSelectObject | EventOrganisers) {
    // if input has a `value` property > it is an OrganizerSelectObject
    if ('value' in item) {
      const eventOrganizerInput: EventOrganisers_Insert_Input = {
        userId: item.value,
      }
      return eventOrganizerInput
    }

    if (!item.user) {
      return
    }

    return {
      label: `${item.user.firstName} ${item.user.lastName}`,
      value: item.user.id,
    }
  }

  if (!input) return

  if (input instanceof Array) {
    if (input.length === 0) {
      return [undefined]
    }
    return input.map(item => {
      return convert(item)
    })
  }
  return convert(input)
}
