import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip, Button } from 'antd'
import styled from '@emotion/styled'
import { Users } from '@organice/graphql'
import { Dayjs } from 'dayjs'
import { TimeUtil } from '@organice/utils/date'
import { useTranslation } from 'react-i18next'
const dayjs = TimeUtil.getDayjs()

interface LastUpdatedIcon {
  createdAt?: string | Dayjs | undefined
  updatedAt: string | Dayjs | undefined
  updatedBy: Pick<Users, 'firstName' | 'lastName'> | undefined | null
  style?: React.CSSProperties
}

export const LastUpdatedIcon: React.FC<LastUpdatedIcon> = ({
  createdAt,
  updatedAt,
  updatedBy,
  style,
}) => {
  const { t } = useTranslation()

  const dateFormat = 'DD.MM.YYYY'
  const timeFormat = 'HH:mm'

  const createdAtDayjs =
    (createdAt && dayjs(createdAt).isValid() && dayjs(createdAt)) || undefined

  const updatedAtDayjs =
    (updatedAt && dayjs(updatedAt).isValid() && dayjs(updatedAt)) || undefined

  const updatedAtDate =
    updatedAtDayjs instanceof dayjs && updatedAtDayjs.format(dateFormat)
  const updatedAtTime =
    updatedAtDayjs instanceof dayjs && updatedAtDayjs.format(timeFormat)

  const updatedAtUser =
    updatedBy && `${updatedBy?.firstName} ${updatedBy?.lastName}`

  const isUntouched = createdAt === updatedAt && !updatedAtUser

  function prepareDateString(d: Dayjs | undefined) {
    if (!d) return ''

    if (d.isSame(dayjs(), 'day')) {
      return t('events.attributeInfo.today', { updatedAtDate, updatedAtTime })
    }
    if (d.isSame(dayjs().subtract(1, 'day'), 'day')) {
      return t('events.attributeInfo.yesterday', {
        updatedAtDate,
        updatedAtTime,
      })
    }
    return t('events.attributeInfo.anyDay', { updatedAtDate, updatedAtTime })
  }

  const attributeInfoMessage = isUntouched
    ? t('events.attributeInfo.isUntouched')
    : updatedAtUser
      ? t('events.attributeInfo.lastUpdatedWithUser', {
          dateString: prepareDateString(updatedAtDayjs),
          updatedAtUser,
        })
      : t('events.attributeInfo.lastUpdatedWithoutUser', {
          dateString: prepareDateString(updatedAtDayjs),
          updatedAtUser,
        })

  if (!updatedAt) {
    return (
      <StyledButton disabled style={style}>
        <InfoCircleOutlined />
      </StyledButton>
    )
  }

  return (
    <Tooltip title={attributeInfoMessage} placement="topRight">
      <StyledButton style={style}>
        <InfoCircleOutlined />
      </StyledButton>
    </Tooltip>
  )
}

const StyledButton = styled(Button)`
  padding: 0px;
  height: auto;
  line-height: 1;
  display: flex;
  align-items: center;
  span {
    //   border-right: 1px solid #d9dadb;
    padding: 2px;
  }
  div {
    padding: 2px;
    min-width: 23px;
  }
`
