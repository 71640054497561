import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import RouteWithSubRoutes from './RouteWithSubRoutes'
import { IRoute } from './config'
import { routesFlat } from './util'
import { LastLocationProvider } from 'react-router-last-location'
import { CommentsContextProvider } from '@organice/contexts/comments'
import { DefaultLayout } from '../components/layouts'
import { useLocation, matchPath } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { usePageTitle } from '@organice/utils/hooks/use-page-title'
import { useMeContext } from '@organice/contexts'

interface ConfigRouterProps {
  breforeSwitches?: React.ReactNode
  children?: React.ReactNode
}

export const ConfigRouter: React.FC<ConfigRouterProps> = props => {
  return (
    <Router
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <RouterContent {...props} />
    </Router>
  )
}

const RouterContent: React.FC = props => {
  const { pathname } = useLocation()

  const currentRouteConfig = routesFlat.find(rfp => matchPath(pathname, rfp))

  const pageTitle = usePageTitle(currentRouteConfig?.title)

  return (
    <LastLocationProvider>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {/* <BreadcrumbProvider> */}
      <CommentsContextProvider>
        <LayoutWrapper
          wrapperComponent={currentRouteConfig?.layout || DefaultLayout}
          isProtected={currentRouteConfig?.protected}
        >
          <Switch>
            {routesFlat.map((route: IRoute) => (
              <RouteWithSubRoutes key={route.path} {...route} />
            ))}
          </Switch>
          {/* @ts-expect-error - ts not seeing children prop of LayoutWrapper !?! */}
          {props.children}
        </LayoutWrapper>
      </CommentsContextProvider>
      {/* </BreadcrumbProvider> */}
    </LastLocationProvider>
  )
}

interface LayoutWrapper {
  wrapperComponent?: React.FC
  isProtected?: boolean
  children: React.ReactNode
}

const LayoutWrapper: React.FC<LayoutWrapper> = ({
  wrapperComponent,
  isProtected = true,
  children,
}: LayoutWrapper): any => {
  const { state } = useMeContext()

  if (isProtected && !state.me) {
    return <>{children}</>
  }

  return wrapperComponent ? (
    React.createElement(wrapperComponent, {}, children)
  ) : (
    <>{children}</>
  )
}

export * from 'react-router-dom'
