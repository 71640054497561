import React from 'react'
import {
  useUpdateEventConfigMutation,
  useGetEventConfigLazyQuery,
  GetEventConfigDocument,
} from '@organice/graphql'
import { TimeUtil } from '@organice/utils/date'
import { useMeContext } from '@organice/contexts'
import { notification } from 'antd'
import { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
const dayjs = TimeUtil.getDayjs()

type UseEventConfigOptions = {
  eventId?: bigint
  eventIds?: bigint[]
}

type EventConfig = {
  roomPlan: {
    isApproved: boolean | undefined
    userId: bigint | null
    time: Dayjs | null
  }
}

const defaultConfig: EventConfig = {
  roomPlan: {
    isApproved: undefined,
    userId: null,
    time: null,
  },
}

export const useEventConfig = (options: UseEventConfigOptions = {}) => {
  const { eventId } = options
  const { t } = useTranslation()

  const {
    state: { me },
  } = useMeContext()

  const [
    fetchEventConfig,
    { data: fetchData, loading: fetchLoading, error: fetchError },
  ] = useGetEventConfigLazyQuery()

  React.useEffect(() => {
    if (!eventId) return
    fetchEventConfig({
      variables: { eventId: eventId },
    })
  }, [eventId])

  const [eventConfig, setEventConfig] = React.useState<
    EventConfig | undefined
  >()

  React.useEffect(() => {
    if (fetchData) {
      const eventConfig = fetchData?.events_by_pk?.config
      const { roomPlan = defaultConfig.roomPlan, ...restOfConfig } = eventConfig
      setEventConfig({
        ...restOfConfig,
        roomPlan: {
          ...roomPlan,
          time: roomPlan.time ? dayjs(roomPlan.time) : roomPlan.time,
        },
      })
    }
  }, [fetchData])

  const [
    updateEvent,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateEventConfigMutation({
    refetchQueries: [
      { query: GetEventConfigDocument, variables: { eventId: eventId } },
    ],
  })

  const setRoomPlanApproved = (isApproved: boolean) => {
    updateEvent({
      variables: {
        eventId: eventId,
        eventConfigUpdate: {
          ...eventConfig,
          roomPlan: {
            isApproved: isApproved,
            time: dayjs().utc().format(),
            userId: me?.id,
          },
        },
      },
    })
  }

  const toggleRoomPlanApproved = React.useCallback(() => {
    if (!eventConfig) return
    updateEvent({
      variables: {
        eventId: eventId,
        eventConfigUpdate: {
          ...eventConfig,
          roomPlan: {
            isApproved: !eventConfig?.roomPlan.isApproved,
            time: dayjs().utc().format(),
            userId: me?.id,
          },
        },
      },
    })
  }, [eventId, eventConfig])

  React.useEffect(() => {
    if (updateError) {
      notification.error({
        message: updateError.message,
      })
    }
  }, [updateError])

  return {
    setRoomPlanApproved,
    toggleRoomPlanApproved,
    eventConfigInit: Boolean(fetchData),
    loading: fetchLoading || updateLoading,
    updateLoading: updateLoading,
    error: fetchError || fetchError,
    updateData: updateData,
    ...defaultConfig,
    ...eventConfig,
  }
}
