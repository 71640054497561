import React from 'react'
import styled from '@emotion/styled'
import { ConfigServiceModule } from '@organice/contexts'
import { useService } from '@organice/contexts'
import DragIconVertical from '@organice/icons/drag_indicator_vertical.svg?react'

export interface ConfigServiceTabProps {
  tempId: ConfigServiceModule['tempId']
  onClick?(): void
  onOverrideClick?(): void
  icon?: React.ReactNode
  active?: boolean
  className?: string
  ref?: any
}

export const ConfigServiceTab: React.FC<ConfigServiceTabProps> =
  React.forwardRef<
    HTMLButtonElement,
    React.PropsWithChildren<ConfigServiceTabProps>
  >(
    (
      {
        tempId,
        children,
        onClick,
        onOverrideClick,
        icon = <DragIconVertical />,
        active,
        className,
      },
      ref
    ) => {
      const [{ activeModule }, dispatch] = useService()

      const act = active !== undefined ? active : tempId === activeModule

      return (
        <TabButton
          ref={ref}
          className={className}
          onClick={() => {
            if (onOverrideClick) {
              return onOverrideClick()
            }

            dispatch({ type: 'SET_ACTIVE_MODULE', payload: tempId })
            onClick && onClick()
          }}
          active={act}
        >
          <TabButtonInnerContainer>
            <DragIconContainer active={act} className="moduleHandle">
              {icon}
            </DragIconContainer>
            {children}
          </TabButtonInnerContainer>
        </TabButton>
      )
    }
  )

const TabButtonInnerContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const DragIconContainer = styled.span<{ active: boolean }>(
  ({ theme, active }) => ({
    display: 'inline-flex',
    marginRight: '.5rem',
    cursor: 'grab',

    '&:active': {
      cursor: 'grabbing',
    },

    '& path': {
      fill: active ? theme.primaryColor : theme.textColor,
    },
  })
)

const TabButton = styled.button<{ active: boolean }>(({ theme, active }) => ({
  borderWidth: '0 0 2px 0',
  borderColor: active ? theme.primaryColor : 'transparent',
  borderStyle: 'solid',
  backgroundColor: 'transparent',
  padding: '0.5rem',
  fontWeight: 'bold',
  color: active ? theme.primaryColor : theme.textColor,
  cursor: active ? 'default' : 'pointer',
  whiteSpace: 'nowrap',
}))
