import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import { Row as AntDRow, Col } from 'antd'

interface KeyValueRowsComponent extends React.FC {
  Key: typeof Key
  Value: typeof Value
  Row: typeof Row
  SingleColumRow: typeof SingleColumRow
}

export const KeyValueRows: KeyValueRowsComponent = ({
  children,
}): ReactElement => {
  return <KeyValueRowsStyled>{children}</KeyValueRowsStyled>
}

const Row: React.FC = ({ children }) => {
  return <RowStyled>{children}</RowStyled>
}
KeyValueRows.Row = Row

const Key: React.FC<{ required?: boolean }> = ({
  required = false,
  children,
}) => {
  return (
    <KeyStyled className={required ? 'required' : ''}>{children}</KeyStyled>
  )
}
KeyValueRows.Key = Key

const Value: React.FC = ({ children }) => {
  return <ValueStyled>{children}</ValueStyled>
}
KeyValueRows.Value = Value

const SingleColumRow: React.FC = ({ children }) => {
  return <SingleColumStyled>{children}</SingleColumStyled>
}
KeyValueRows.SingleColumRow = SingleColumRow

const KeyValueRowsStyled = styled.div`
  padding: 10px 0;

  > div {
    &:nth-of-type(odd) {
      background: ${props => props.theme.grey3};
    }
  }
`

const RowStyled = styled(AntDRow)`
  padding: 10px;
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }
`

const SingleColumStyled = styled(Col)`
  padding: 20px 10px 0 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  button {
    margin-right: 10px;
  }
`

const KeyStyled = styled(Col)`
  font-weight: 700;
  width: 100%;
  padding: 5px 12px;
  @media (min-width: ${props => props.theme.screenMd}) {
    width: 30%;
    padding: 5px 0;
  }

  &.required {
    &:before {
      display: inline-block;
      margin-right: 4px;
      color: ${props => props.theme.primaryColor};
      font-family: sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      content: '*';
    }
  }
`

const ValueStyled = styled(Col)`
  flex: 1;

  > span {
    display: inline-block;
    padding: 5px 12px; // 1px border of input added
  }
`
