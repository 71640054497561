import React from 'react'
import { Popover, PopoverProps } from 'antd'
import { useResponsive } from '@organice/utils/hooks/useResponsive'

type DeviceSizes =
  | 'isMobile'
  | 'isTablet'
  | 'isDesktopOrLaptop'
  | 'isBigScreen'
  | 'isPortrait'

interface PopoverOnMobileProps extends PopoverProps {
  showPopoverIf?: DeviceSizes[]
  triggerContent: React.ReactNode | string
  mobileContent?: React.ReactNode
  desktopContent?: React.ReactNode
  customMaxWidth?: number
}

export const PopoverOnMobile = React.forwardRef<any, PopoverOnMobileProps>(
  (
    {
      showPopoverIf = ['isMobile'],
      triggerContent,
      mobileContent,
      desktopContent,
      children,
      customMaxWidth,
      visible: visibleProp,
      ...popoverProps
    },
    ref
  ) => {
    const {
      isMobile,
      isTablet,
      isDesktopOrLaptop,
      isPortrait,
      isBigScreen,
      windowSize,
    } = useResponsive()

    const [visible, setVisible] = React.useState(false)

    React.useEffect(() => {
      if (visibleProp === undefined) return
      setVisible(visibleProp)
    }, [visibleProp])

    const inputRef = React.useRef()
    React.useImperativeHandle(ref, () => ({
      close: () => {
        setVisible(false)
      },
      open: () => {
        setVisible(true)
      },
    }))

    if (
      (showPopoverIf.includes('isMobile') && isMobile) ||
      (showPopoverIf.includes('isTablet') && isTablet) ||
      (showPopoverIf.includes('isDesktopOrLaptop') && isDesktopOrLaptop) ||
      (showPopoverIf.includes('isPortrait') && isPortrait) ||
      (showPopoverIf.includes('isBigScreen') && isBigScreen) ||
      (customMaxWidth && windowSize.innerWidth < customMaxWidth)
    ) {
      return (
        <Popover
          content={mobileContent || children}
          trigger={['click']}
          overlayStyle={{ minWidth: '350px' }}
          destroyTooltipOnHide={true}
          open={visible}
          onOpenChange={visible => setVisible(visible)}
          {...popoverProps}
        >
          {triggerContent}
        </Popover>
      )
    }

    return <>{desktopContent || children}</>
  }
)
