import { Breadcrumb } from '../../molecules/breadcrumb' //'@organice/molecules'
import React from 'react'
import { BreadcrumbItem } from '@organice/atoms'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbs } from '@organice/router/useBreadcrumbs'
import { IRouteForBC } from '@organice/router/useBreadcrumbs'
import styled from '@emotion/styled'

const urlParamsRegEx = /\/:[a-z]/

// export interface BreadcrumbsProps {}
export const Breadcrumbs: React.FC = () => {
  const { routes } = useBreadcrumbs()
  const { t } = useTranslation()

  const routeTitle = (route: IRouteForBC) =>
    typeof route.title === 'string' ? <>{t(route.title)}</> : route.title

  return (
    <>
      <StyledBreadcrumb>
        {routes.map((route, i, arr) => {
          const pathContainsParams = urlParamsRegEx.test(route.path)
          return (
            <BreadcrumbItem key={route.path}>
              {arr.length - 1 === i ? (
                routeTitle(route)
              ) : (
                <>
                  {pathContainsParams ? (
                    <span>{routeTitle(route)}</span>
                  ) : (
                    <Link to={route.path}>{routeTitle(route)}</Link>
                  )}
                </>
              )}
            </BreadcrumbItem>
          )
        })}
      </StyledBreadcrumb>
    </>
  )
}

const StyledBreadcrumb = styled(Breadcrumb)`
  .ant-spin {
    max-height: 17px;
    position: relative;
    top: -3px;
  }

  .ant-breadcrumb-link {
    // display: flex;
    // align-items: center;
  }
`
