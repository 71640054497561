import React from 'react'
import {
  useMeQuery,
  Users,
  OrganisationGroups,
  MeQuery,
  useLogoutMutation,
  LogoutMutation,
  LogoutMutationFn,
  MeDocument,
} from '@organice/graphql'
import { LoadingIndicator } from '@organice/atoms/loadingIndicator'
import { ApolloQueryResult, NetworkStatus } from '@apollo/client'
type CurrentUser = Users & {
  organisationGroups: Pick<OrganisationGroups, 'id' | 'name'>[]
}

export interface MeState {
  me: CurrentUser | null
  isAdmin?: boolean
  isOrgaAdmin?: boolean // Organisation Admin
  // true, if the user is part of the Nuernberg Messe team
  isNM?: boolean
  // Agreement Version accepted
  agreementVersion?: number
  loading?: boolean
  error?: boolean
  jaggaerUsername?: string
  isGuest?: boolean
}

const DEFAULT_VALUE: MeState = {
  me: null,
  loading: true,
  error: false,
  agreementVersion: 0,
}

export interface MeAction {
  type: 'ME_LOADED' | 'ME_ERROR' | 'AGREEMENT_ACCEPTED' | 'ME_LOGGED_OUT'
  state?: MeState
  agreementVersion?: number
}

export const NM_ORGANISATION_ID = 1

const reducer: React.Reducer<MeState, MeAction> = (state, action) => {
  switch (action.type) {
    case 'ME_LOADED':
      return {
        ...state,
        ...action.state,
        isAdmin: action.state?.me?.isAdmin === true,
        isOrgaAdmin: action.state?.me?.isOrgaAdmin ? true : false,
        agreementVersion: action.state?.me?.agreementVersion || 0,
        loading: false,
        isNM: action.state?.me?.organisationId === NM_ORGANISATION_ID,
        isGuest: action.state?.me?.organisation?.isGuestOrga ? true : false,
      }
    case 'ME_LOGGED_OUT':
      return { ...DEFAULT_VALUE, loading: false }
    case 'AGREEMENT_ACCEPTED':
      return {
        ...state,
        agreementVersion: action?.agreementVersion || 0,
      }
    case 'ME_ERROR':
      return { ...state, error: true, loading: false }
    default:
      return DEFAULT_VALUE
  }
}

export const MeContext = React.createContext<{
  state: MeState
  dispatch: React.Dispatch<MeAction>
  refetch: () => Promise<ApolloQueryResult<MeQuery>>
  logout: () => Promise<boolean>
}>({
  state: DEFAULT_VALUE,
  dispatch: () => null,
  refetch: async () => ({
    data: undefined as unknown as MeQuery,
    loading: false,
    networkStatus: NetworkStatus.ready,
  }),
  logout: async () => false,
})

export const MeContextProvider: React.FC<{
  children?: React.ReactNode
}> = props => {
  const [state, dispatch] = React.useReducer(reducer, DEFAULT_VALUE)
  const { data, loading, error, refetch } = useMeQuery()
  const [
    logoutMutation,
    { data: logoutData, loading: logoutLoading, error: loadoutError },
  ] = useLogoutMutation({
    update: cache => {
      cache.writeQuery<MeQuery>({
        query: MeDocument,
        data: {
          currentUser: null,
        },
      })
    },
  })

  async function logout() {
    try {
      const logoutResult = await logoutMutation()

      dispatch({
        type: 'ME_LOGGED_OUT',
        state: DEFAULT_VALUE,
      })

      return logoutResult.data?.logout?.logout === true
    } catch (error) {
      console.error('Logout Error:', error)
      return false
    }
  }

  React.useEffect(() => {
    if (!loading && !error && data) {
      const currentUser = data?.currentUser
      const { organisationGroupUsers, ...user } = currentUser || {}
      const orgGroups = organisationGroupUsers?.map(g => g.organisationGroup)

      dispatch({
        type: 'ME_LOADED',
        state: {
          me: { ...user, organisationGroups: orgGroups } as CurrentUser,
        },
      })
    }
  }, [loading, data])

  React.useEffect(() => {
    if (error) dispatch({ type: 'ME_ERROR' })
  }, [error])
  return (
    <MeContext.Provider value={{ state, dispatch, refetch, logout }}>
      {!loading ? props.children : <LoadingIndicator />}
    </MeContext.Provider>
  )
}

export const useMeContext = () => React.useContext(MeContext)
