import React from 'react'
import { UploadOutlined } from '@ant-design/icons'
import {
  AttributeValueProps,
  isAttributeValue,
  TextAttributeValue,
  NumberAttributeValue,
  BooleanAttributeValue,
  DateAttributeValue,
  RepeatableDateAttributeValue,
  FileAttributeValue,
  DateType,
  AttributeType,
  SelectAttributeValue,
  SelectTypes,
  UserAttributeValue,
} from '../../../types/service'
import { AttributeValueFiles, AttributeFiles } from '@organice/graphql'
import { Checkbox, Button, Tag, PrintCheckbox } from '@organice/atoms'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { TimeUtil } from '@organice/utils/date'
import { FileUpload } from '@organice/molecules/fileUpload'
import { useServiceDisplayMode, ServiceDisplayMode } from '@organice/contexts'
import { MultilineString } from '@organice/atoms/multiline-string'
import { dateConfig } from '@organice/config'
import { Dayjs } from 'dayjs'
import { Space } from 'antd'
import { Person } from '@organice/molecules'

export const AttributeDisplay: React.FC<
  AttributeValueProps
> = attributeValue => {
  return <AttributeUserDisplay {...attributeValue}></AttributeUserDisplay>
}

const AttributeUserDisplay: React.FC<AttributeValueProps> = props => {
  const { t } = useTranslation()

  if (isAttributeValue<TextAttributeValue>(props, AttributeType.Text)) {
    return <TextAttributeDisplay {...props} />
  }

  if (isAttributeValue<NumberAttributeValue>(props, AttributeType.Number)) {
    return <NumberAttributeDisplay {...props} />
  }

  if (isAttributeValue<BooleanAttributeValue>(props, AttributeType.Boolean)) {
    return <BooleanAttributeDisplay {...props}></BooleanAttributeDisplay>
  }

  if (isAttributeValue<DateAttributeValue>(props, AttributeType.Date)) {
    return <DateAttributeDisplay {...props} />
  }

  if (isAttributeValue<SelectAttributeValue>(props, AttributeType.Select)) {
    return <SelectAttributeDisplay {...props} />
  }

  if (isAttributeValue<FileAttributeValue>(props, AttributeType.File)) {
    return (
      <FileUpload
        attribute={props.attribute.id}
        attributeFiles={props.attribute.attributeFiles as AttributeFiles[]}
        attributeGroupId={props.attributeValueGroupId}
        fileList={props.attributeValueFiles as AttributeValueFiles[]}
      >
        <Button icon={<UploadOutlined />}>
          {t('service.attribute.upload.cta')}
        </Button>
      </FileUpload>
    )
  }

  if (isAttributeValue<UserAttributeValue>(props, AttributeType.User)) {
    return <UserAttributeDisplay {...props} />
  }

  return (
    <>{`No input defined for ${
      props.attribute.attributeType
    }, got this for props: ${JSON.stringify(props, undefined, 2)}`}</>
  )
}

const TextAttributeDisplay: React.FC<TextAttributeValue> = attributeValue => (
  <MultilineString input={attributeValue?.value?.value} />
)

const UserAttributeDisplay: React.FC<UserAttributeValue> = attributeValue => {
  const [state] = useServiceDisplayMode()
  const isExport = state.serviceDisplayMode === ServiceDisplayMode.EXPORT

  return (
    <>
      {isExport ? (
        <>
          {attributeValue.user ? (
            <Person user={attributeValue.user} isPrint={true} />
          ) : (
            '-'
          )}
        </>
      ) : (
        <>{attributeValue.user ? <Person user={attributeValue.user} /> : '-'}</>
      )}
    </>
  )
}

const NumberAttributeDisplay: React.FC<
  NumberAttributeValue
> = attributeValue => <>{attributeValue?.value?.value?.toString()}</>

const BooleanAttributeDisplay: React.FC<
  BooleanAttributeValue
> = attributeValue => {
  const { t } = useTranslation()
  const [state] = useServiceDisplayMode()
  const isExport = state.serviceDisplayMode === ServiceDisplayMode.EXPORT

  return !isExport ? (
    <Checkbox
      checked={
        attributeValue.value.value !== undefined
          ? attributeValue.value.value
          : false
      }
    >
      {t('service.attribute.checkbox.active')}
    </Checkbox>
  ) : (
    <PrintCheckbox
      checked={
        attributeValue.value.value !== undefined
          ? attributeValue.value.value
          : false
      }
    >
      {attributeValue.value.value !== undefined
        ? attributeValue.value.value
          ? t('service.export.attribute.checkbox.checked')
          : t('service.export.attribute.checkbox.default')
        : t('service.export.attribute.checkbox.default')}
    </PrintCheckbox>
  )
}

const SelectAttributeDisplay: React.FC<
  SelectAttributeValue
> = attributeValue => {
  const config = attributeValue.attribute.config
  const type = config.type || 'tags'
  const value = Array.isArray(attributeValue.value.value)
    ? attributeValue.value.value
    : []

  switch (type) {
    case SelectTypes.Tags:
      return (
        <TagsWrapper>
          {value.map((v, i) => (
            <Tag key={`tag-${attributeValue.attribute.id}-${i}`}>{v}</Tag>
          ))}
        </TagsWrapper>
      )
    default:
      console.warn(`SelectAttributeDisplay: Unknown type ${type}`)
  }

  return null
}

const DateAttributeDisplay: React.FC<DateAttributeValue> = attributeValue => {
  if (attributeValue.attribute.config.repeatable) {
    return (
      <RepeatableDateDisplay
        {...(attributeValue as RepeatableDateAttributeValue)}
      ></RepeatableDateDisplay>
    )
  }

  return (
    <DateListContainer>
      {DateFormatedTypeDisplay({
        value: attributeValue.value?.value as string | (string | undefined)[], // Need to fix
        type: attributeValue.attribute.config.dateType,
      })}
    </DateListContainer>
  )
}

export const DateFormatedTypeDisplay = ({
  value,
  type,
}: {
  value: string | (string | undefined)[]
  type: DateType
}) => {
  const [state] = useServiceDisplayMode()
  const isExport = state.serviceDisplayMode === ServiceDisplayMode.EXPORT

  if (!value || (Array.isArray(value) && !value[0])) return !isExport ? '-' : ''

  switch (type) {
    case DateType.DATE:
      if (!String(value)) return '-'
      return TimeUtil.get(String(value)).format(dateConfig.DATE_FORMAT)

    case DateType.DATE_TIME:
      if (!String(value)) return '-'
      return TimeUtil.get(String(value)).format(
        `${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT} [Uhr]`
      )

    case DateType.DATE_RANGE:
      if (!value[0] || !value[1]) return '-'
      return `${TimeUtil.get(value[0]).format(
        dateConfig.DATE_FORMAT
      )} - ${TimeUtil.get(value[1]).format(dateConfig.DATE_FORMAT)}`

    case DateType.DATE_RANGE_TIME:
      if (!value[0] || !value[1]) return '-'
      return `${TimeUtil.get(value[0]).format(
        `${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT} [Uhr]`
      )} - ${TimeUtil.get(value[1]).format(
        `${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT} [Uhr]`
      )}`

    case DateType.DATE_TIME_RANGE:
      if (!value[0] || !value[1]) return '-'

      if (value[0] && value[1] && value[2]) {
        return `${TimeUtil.get(value[0]).format(
          `${dateConfig.DATE_FORMAT}`
        )} ${TimeUtil.get(value[1]).format(
          `${dateConfig.TIME_FORMAT} [Uhr]`
        )} - ${TimeUtil.get(value[2]).format(
          `${dateConfig.TIME_FORMAT} [Uhr]`
        )}`
      }
      return `${TimeUtil.get(value[0]).format(
        `${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT}`
      )} - ${TimeUtil.get(value[1]).format(`${dateConfig.TIME_FORMAT} [Uhr]`)}`

    default:
      return `DATETYPE NOT DEFINED: ${type}`
  }
}

export const RepeatableDateDisplay: React.FC<
  RepeatableDateAttributeValue
> = attributeValue => {
  if (!Array.isArray(attributeValue?.value?.value)) {
    return null
  }
  const attrVal = [...(attributeValue?.value?.value || [])]

  if (attrVal.length !== 0) {
    TimeUtil.sortDateArray2D(attrVal as (Dayjs | string)[][])
  }

  return (
    <DateListContainer>
      {attrVal?.map((value, key) => {
        const dateStr: string = DateFormatedTypeDisplay({
          value,
          type: attributeValue.attribute.config.dateType,
        })
        if (dateStr) {
          return <div key={key}>{dateStr}</div>
        }
      }) || <div>–</div>}
    </DateListContainer>
  )
}

const DateListContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: 0,
  margin: 0,
})

const TagsWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 5,
})
