import React from 'react'

export interface DeepLinkContextState {
  firstRender: boolean
}

export const DEFAULT_DEEPLINK_CONTEXT_STATE: DeepLinkContextState = {
  firstRender: true,
}

export type DeepLinkContextAction = {
  type: 'SET_FIRST_RENDER'
  payload: Pick<DeepLinkContextState, 'firstRender'>
}

export const deepLinkContextReducer: React.Reducer<
  DeepLinkContextState,
  DeepLinkContextAction
> = (state, action) => {
  switch (action.type) {
    case 'SET_FIRST_RENDER':
      return { ...state, ...action.payload }
  }
}

export const DeepLinkContext = React.createContext<
  [DeepLinkContextState, React.Dispatch<DeepLinkContextAction>]
>([DEFAULT_DEEPLINK_CONTEXT_STATE, () => undefined])

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDeeplink = () => React.useContext(DeepLinkContext)

export const DeepLinkContextProvider: React.FC = ({ children }) => {
  const value = React.useReducer(
    deepLinkContextReducer,
    DEFAULT_DEEPLINK_CONTEXT_STATE
  )

  return (
    <DeepLinkContext.Provider value={value}>
      {children}
    </DeepLinkContext.Provider>
  )
}
