export { Checkbox } from 'antd'
import { Checkbox as AntdCheckbox } from 'antd'
import styled from '@emotion/styled'

export const PrintCheckbox = styled(AntdCheckbox)(({ theme }) => ({
  alignItems: 'center',

  '.ant-checkbox': {
    top: 0,

    '.ant-checkbox-inner': {
      width: '1.25rem',
      height: '1.25rem',
      backgroundColor: '#fff',
      border: `1px solid ${theme.textColor}`,
    },
  },

  '.ant-checkbox-checked .ant-checkbox-inner': {
    '&:after': {
      left: '28%',
      borderColor: theme.textColor,
    },
  },
}))
