import React from 'react'
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Tooltip } from '@organice/atoms'
import { TooltipProps } from 'antd'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'

interface IconToolTipProps {
  icon: 'info' | 'warning'
  message: string
  fontSize?: string
}

type FullProps = IconToolTipProps & Partial<TooltipProps>

export const IconToolTip: React.FC<FullProps> = props => {
  const { icon, message, fontSize = '1rem' } = props
  const theme = useTheme()

  const icons = {
    info: (
      <InfoCircleOutlined style={{ color: theme.grey4, fontSize: fontSize }} />
    ),
    warning: (
      <WarningOutlined
        style={{ color: theme.primaryColor, fontSize: fontSize }}
      />
    ),
  }

  return (
    <StyledToolTip title={message} {...props}>
      {icons[icon as keyof typeof icons]}
    </StyledToolTip>
  )
}

const StyledToolTip = styled(Tooltip)`
  margin: 0 5px;
`
