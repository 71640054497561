import {
  RoomListDocument,
  Rooms,
  Maybe,
  RoomServices,
  RoomServices_Mutation_Response,
  GetRoomByIdDetailedDocument,
} from '@organice/graphql'
import { RoomConfigContextState } from '@organice/contexts/configRoom'

import React from 'react'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { gql, ApolloError, DocumentNode } from '@apollo/client'
import { SelectionSetNode, OperationDefinitionNode } from 'graphql/language/ast'
import apolloClient from '../../../apollo/apollo'
import {
  prepareRoomServiceMutations,
  createDeleteRoomAttributeGroupsFragment,
  injectMutations,
  prepareRoomInfoChanges,
  prepareRoomServicesChanges,
} from './utils'

/***** Custom Update Mutation Hook with dynamic aliased update_by_pks ******/

/*
 *
 * WHY IS THERE A CUSTOM UPDATE MUTAION HOOK???
 * (instead of the regular Apollo Hooks or the GraphQL Code Generator Custom Hooks?)
 *
 * The goal is to have all updates combined to a single large Mutation, so all UPDATEs are handled within a single database transaction
 * and succeed or fail together. Making mutliple separate mutations can have the disadvanage of having some failed and some succeeded
 * mutations, leaving the application in a "half-saved" state.
 *
 * The difficulty comes from the combination of the following factors:
 *
 * 1) Saving a room or a roomService requires to update mutliple related tables
 *    (rooms > roomServices > roomAttributeGroups > roomAttributes > roomAttributeFiles)
 * 2) The absence of relational UPDATEs, which makes it necessary to combine multiple updates_by_pk
 * 3) The requirement to alias multiple updates_by_pk
 * 4) No option to combine multiple aliased mutations using apollo hooks
 *
 * This solution is using apolloClient.mutate(mutation: gql`${completeMutationString}`) and generating `completeMutationString`
 * by combining `updateRoomMutationString` (as a base mutation string) and the GraphQL Mutation Strings of each individual mutation
 * using String operations (where necessary).
 *
 * The exceptions are update_rooms_by_pk, insert_roomServices & delete_roomServices, that added as variables for `updateRoomMutationString`,
 * because that is more straight forward.
 *
 * Before combining all mutations to `completeMutationString`, each mutation exists in the form of an
 * AliasedGraphQLMutationObject ({alias: string, mutationString: string}), so the alias could be used to get the right property from the
 * mutation return (not used for anything at this point).
 *
 */

type UseRoomUpdateMutationReturn = [
  (id: bigint, roomState: RoomConfigContextState) => Promise<void>,
  {
    data: DynamicUpdateRoomMutation | undefined
    loading: boolean
    error: ApolloError | undefined
  },
]

export function useUpdateRoomMutation(): UseRoomUpdateMutationReturn {
  const { t } = useTranslation()
  const [updateLoading, setUpdateLoading] = React.useState<boolean>(false)
  const [updateError, setUpdateError] = React.useState<
    ApolloError | undefined
  >()
  const [updateData, setUpdateData] = React.useState<
    DynamicUpdateRoomMutation | undefined
  >()

  async function updateRoom(id: bigint, roomState: RoomConfigContextState) {
    const roomInfoChanges = prepareRoomInfoChanges(roomState)
    const roomServicesChanges = prepareRoomServicesChanges(roomState) // Changes Split by opreation type (INSERT / UPDATE / DELETE)

    if (
      Object.keys(roomInfoChanges).length === 0 &&
      roomServicesChanges.updates.length === 0 &&
      roomServicesChanges.inserts.length === 0 &&
      roomServicesChanges.deletes.length === 0
    ) {
      notification.error({
        message: t('configRoom.notifications.errorNoChanges'),
      })
      return
    }

    // DELETEs of RoomAttributeGroups are handled below and not within this function, because it is easier

    const roomServiceUpdateMutations =
      prepareRoomServiceMutations(roomServicesChanges)
    // Flat Array of AliasedGraphQLMutationObject ({variable: Record<string, any>, gql: DocumentNode})

    const combinedUpdateMutationVraiableValues =
      roomServiceUpdateMutations.reduce((res, cur) => {
        if (cur.variables) {
          return { ...res, ...cur.variables }
        }
        return cur
      }, {})

    const deleteRoomAttributeGroupsMutation =
      createDeleteRoomAttributeGroupsFragment({
        roomAttributeGroups: roomState.deletedRoomAttributeGroups,
      })

    const allMutationVraiableValues = {
      ...combinedUpdateMutationVraiableValues,
      ...deleteRoomAttributeGroupsMutation.variables,
    }

    const allTogether = injectMutations(updateRoomBaseMutation, [
      ...roomServiceUpdateMutations.map(m => m.gql),
      deleteRoomAttributeGroupsMutation.gql,
    ])

    // console.log('allTogether', allTogether)

    setUpdateLoading(true)
    return apolloClient
      .mutate({
        mutation: allTogether,
        variables: {
          id: id,
          ...roomState.roomInfo,
          roomData: { ...roomInfoChanges },
          newRoomServices: roomServicesChanges.inserts.map(s => ({
            ...s,
            roomId: roomState.roomInfo.id,
          })),
          roomServiceIdsToDelete: roomServicesChanges.deletes.map(rs => rs.id),
          ...allMutationVraiableValues,
        },
        refetchQueries: [
          {
            query: GetRoomByIdDetailedDocument,
            variables: {
              id: id,
            },
          },
          {
            query: RoomListDocument,
          },
        ],
      })
      .then(mutationResult => {
        setUpdateData(mutationResult as DynamicUpdateRoomMutation)
        setUpdateLoading(false)
      })
      .catch(e => {
        const error = e as ApolloError
        setUpdateError(error)
        setUpdateLoading(false)
      })
  }

  return [
    updateRoom,
    { data: updateData, loading: updateLoading, error: updateError },
  ]
}

type DynamicUpdateRoomMutation = { __typename?: 'mutation_root' } & {
  update_rooms_by_pk?: Maybe<
    { __typename?: 'rooms' } & Pick<
      Rooms,
      'id' | 'name' | 'shortDescription' | 'description' | 'roomFolderId'
    >
  >
  insert_roomServices?: Maybe<
    { __typename?: 'roomServices_mutation_response' } & {
      returning: Array<
        { __typename?: 'roomServices' } & Pick<
          RoomServices,
          'id' | 'name' | 'roomId' | 'order'
        >
      >
    }
  >
  delete_roomServices?: Maybe<
    { __typename?: 'roomServices_mutation_response' } & Pick<
      RoomServices_Mutation_Response,
      'affected_rows'
    > & {
        returning: Array<
          { __typename?: 'roomServices' } & Pick<RoomServices, 'id' | 'name'>
        >
      }
  >
}

function getFullupdateRoomMutationString(
  variableDefinitions: string[] //Record<string, string>
) {
  console.log(
    'lets check',
    gql`
mutation dynamicUpdateRoom(
  $id: bigint!
  $roomData: rooms_set_input
  $newRoomServices: [roomServices_insert_input!]!
  $roomServiceIdsToDelete: [bigint!]
  ${variableDefinitions}
) {
  update_rooms_by_pk(pk_columns: { id: $id }, _set: $roomData) {
    id
    name
    shortDescription
    description
    roomFolderId
  }
  insert_roomServices(objects: $newRoomServices) {
    returning {
      id
      name
      roomId
      order
    }
  }
  delete_roomServices(where: { id: { _in: $roomServiceIdsToDelete } }) {
    affected_rows
    returning {
      id
      name
    }
  }
}`
  )

  return `
    mutation dynamicUpdateRoom(
      $id: bigint!
      $roomData: rooms_set_input
      $newRoomServices: [roomServices_insert_input!]!
      $roomServiceIdsToDelete: [bigint!]
      ${variableDefinitions}
    ) {
      update_rooms_by_pk(pk_columns: { id: $id }, _set: $roomData) {
        id
        name
        shortDescription
        description
        roomFolderId
      }
      insert_roomServices(objects: $newRoomServices) {
        returning {
          id
          name
          roomId
          order
        }
      }
      delete_roomServices(where: { id: { _in: $roomServiceIdsToDelete } }) {
        affected_rows
        returning {
          id
          name
        }
      }
    }
    `
}

const updateRoomBaseMutation = gql`
  mutation dynamicUpdateRoom(
    $id: bigint!
    $roomData: rooms_set_input
    $newRoomServices: [roomServices_insert_input!]!
    $roomServiceIdsToDelete: [bigint!]
  ) {
    update_rooms_by_pk(pk_columns: { id: $id }, _set: $roomData) {
      id
      name
      shortDescription
      description
      roomFolderId
    }
    insert_roomServices(objects: $newRoomServices) {
      returning {
        id
        name
        roomId
        order
      }
    }
    delete_roomServices(where: { id: { _in: $roomServiceIdsToDelete } }) {
      affected_rows
      returning {
        id
        name
      }
    }
  }
`
