import { IRoute, routes } from './config'

export function flattenRouteConfig(
  routes: IRoute[],
  parentRoute: IRoute | null = null
): IRoute[] {
  let routesFlat: IRoute[] = []

  routes.forEach(r => {
    routesFlat.push({ ...r, parentRoute: parentRoute })
    if (r.routes) {
      routesFlat = [...routesFlat, ...flattenRouteConfig(r.routes, r)]
    }
  })
  return routesFlat
}

export const routesFlat = flattenRouteConfig(routes)
export const routesFlatPaths = routesFlat.map(r => ({
  path: r.path,
  exact: r.exact,
  strict: false,
  hideBreadcrumb: r.hideBreadcrumb,
}))

export const routesMenu = flattenRouteConfig(routes).filter(
  route => route.showMenu
)

export const routesBreadcrumbs = flattenRouteConfig(routes).filter(
  route => !route.hideBreadcrumb
)

export function getPathAsRoutesArray(
  currentPath: string,
  breadcrumbsOnly = false
): IRoute[] {
  let breadcrumb: IRoute[] = []
  const currentRouteDefinition = routesFlat.find(r => r.path === currentPath)

  if (currentRouteDefinition) {
    if (breadcrumbsOnly) {
      !currentRouteDefinition.hideBreadcrumb === true &&
        breadcrumb.push(currentRouteDefinition)
    }
    if (currentRouteDefinition.parentRoute) {
      breadcrumb = [
        ...getPathAsRoutesArray(
          currentRouteDefinition.parentRoute.path,
          breadcrumbsOnly
        ),
        ...breadcrumb,
      ]
    }
  }

  return breadcrumb
}
