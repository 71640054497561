import React from 'react'
import {
  ArchiveAttributeValueCommentMutation,
  useArchiveAttributeValueCommentMutation,
  useArchiveUploadCommentMutation,
  ArchiveUploadCommentMutation,
  useArchiveRoomAttributeValueCommentMutation,
  ArchiveRoomAttributeValueCommentMutation,
} from '@organice/graphql'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'
import { ApolloError } from '@apollo/client'
import { TimeUtil } from '@organice/utils/date'
import { CommentTypes_Enum } from '@organice/contexts/comments/commentsContext'

type UseArchiveCommentReturn = {
  archiveComment(id: string): void
  restoreComment(id: string): void
  data:
    | ArchiveAttributeValueCommentMutation['update_attributeValueComments_by_pk']
    | ArchiveUploadCommentMutation['update_uploadComments_by_pk']
    | ArchiveRoomAttributeValueCommentMutation['update_roomAttributeValueComments_by_pk']
    | null
    | undefined
  loading: boolean
  error: ApolloError | undefined
  success: boolean
}

type Options = {
  commentType: CommentTypes_Enum
}

export function useArchiveComment(options: Options): UseArchiveCommentReturn {
  const { commentType } = options
  const { t } = useTranslation()

  const [
    archiveAttributeValueCommentCommentByIdMutation,
    {
      data: archiveAttributeValueCommentData,
      loading: archiveAttributeValueCommentLoading,
      error: archiveAttributeValueCommentError,
    },
  ] = useArchiveAttributeValueCommentMutation()

  const [
    archiveUploadCommentByIdMutation,
    {
      data: archiveUploadData,
      loading: archiveUploadLoading,
      error: archiveUploadError,
    },
  ] = useArchiveUploadCommentMutation()

  const [
    archiveRoomAttributeValueCommentByIdMutation,
    {
      data: archiveRoomAttributeValueCommentData,
      loading: archiveURoomAttributeValueCommentLoading,
      error: archiveRoomAttributeValueCommentError,
    },
  ] = useArchiveRoomAttributeValueCommentMutation()

  const data =
    archiveAttributeValueCommentData?.update_attributeValueComments_by_pk ||
    archiveUploadData?.update_uploadComments_by_pk ||
    archiveRoomAttributeValueCommentData?.update_roomAttributeValueComments_by_pk
  const error =
    archiveAttributeValueCommentError ||
    archiveUploadError ||
    archiveRoomAttributeValueCommentError
  const loading =
    archiveAttributeValueCommentLoading ||
    archiveUploadLoading ||
    archiveURoomAttributeValueCommentLoading

  React.useEffect(() => {
    if (data?.id) {
      notification.success({
        duration: 3,
        message: t(
          data?.isDone
            ? 'comment.actions.archiveSuccess'
            : 'comment.actions.restoreSuccess'
        ),
      })
    }
  }, [data])

  React.useEffect(() => {
    if (error) {
      notification.error({
        duration: 3,
        message: t('comment.actions.archiveError'),
      })
    }
  }, [error])

  function archiveComment(id: string) {
    if (!id) return
    switch (commentType) {
      case CommentTypes_Enum.AttribtueValueComment:
        return archiveAttributeValueCommentCommentByIdMutation({
          variables: {
            id: id,
            isDone: TimeUtil.getNow().format(),
          },
        })
      case CommentTypes_Enum.UploadComment:
        return archiveUploadCommentByIdMutation({
          variables: {
            id: id,
            isDone: TimeUtil.getNow().format(),
          },
        })
      case CommentTypes_Enum.RoomAttributeValueComment:
        return archiveRoomAttributeValueCommentByIdMutation({
          variables: {
            id: id,
            isDone: TimeUtil.getNow().format(),
          },
        })

      default:
        console.warn(
          `useArchiveComment(): No Mutation for Comment Type "${commentType}"`
        )
    }
  }

  function restoreComment(id: string) {
    if (!id) return

    switch (commentType) {
      case CommentTypes_Enum.AttribtueValueComment:
        return archiveAttributeValueCommentCommentByIdMutation({
          variables: {
            id: id,
            isDone: null,
          },
        })
      case CommentTypes_Enum.UploadComment:
        return archiveUploadCommentByIdMutation({
          variables: {
            id: id,
            isDone: null,
          },
        })
      case CommentTypes_Enum.RoomAttributeValueComment:
        return archiveRoomAttributeValueCommentByIdMutation({
          variables: {
            id: id,
            isDone: null,
          },
        })

      default:
        console.warn(
          `useArchiveComment(): No Mutation for Comment Type "${commentType}"`
        )
    }
  }

  return {
    archiveComment,
    restoreComment,
    data,
    loading,
    error,
    success: !!data?.id,
  }
}
