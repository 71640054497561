import React from 'react'
import { Tooltip as AntdTooltip, TooltipProps } from 'antd'
import { Global, css } from '@emotion/react'

export const Tooltip: React.FC<TooltipProps> = props => {
  return (
    <>
      <Global
        styles={css`
          .ant-tooltip-inner {
            a {
              color: #fff;
              text-decoration: underline;
            }
          }
        `}
      />
      <AntdTooltip {...props} />
    </>
  )
}
