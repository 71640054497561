// this file contains the emotion Theme only
// you may want to have a look at the src/types.d.ts as well

import { camelCased } from './theme'
import { css } from '@emotion/react'

const breakpointsValues = [576, 768, 992, 1200, 1800]
const breakpoints = breakpointsValues.map(bp => ({
  px: bp,
  mq: `@media (min-width: ${bp}px)`,
}))

export type AntdVariables = typeof camelCased

const markerColors: string[] = [
  '#FF501444',
  '#FCB90044',
  '#7BDCB544',
  '#00D08444',
  '#8ED1FC44',
  '#0693E344',
  '#ABB8C344',
  '#EB144C44',
  '#F78DA744',
  '#9900EF44',
]

export interface EmotionTheme extends AntdVariables {
  // put emotion theme globals in here
  // keep in mind that they won't overwrite antd's globals (see theme.js for that)
  headerColor: string
  lightGreyBackground: string
  greyFont: string
  grey1: string
  grey2: string
  grey3: string
  grey4: string
  grey5: string
  grey6: string
  grey7: string
  btnHoverBackground: string
  btnBorderColor: string
  ganttViewRowHeight: string
  roomMatrixRowHeight: string | number
  roomMatrixTopBarRowHeight: string | number
  roomMatrixFolderRowHeight: string | number
  roomMatrixRoomOccurrenceBarHeight: string | number
  roomMatrixRoomServiceOccurrenceBarHeight: string | number
  roomMatrixPreviewBarsColor: string
  timelineSpace: number
  breakpoints: { px: number; mq: string }[]
  markerColors: string[]
}

export const emotionTheme: EmotionTheme = {
  ...camelCased,
  headerColor: 'lightgrey',
  lightGreyBackground: '#f6f6f6',
  greyFont: '#a1a2a3',
  grey1: '#3E3D40',
  grey2: '#58585A',
  grey3: '#F6F6F6',
  grey4: '#87888A',
  grey5: '#D9DADB',
  grey6: '#E3E4E4',
  grey7: '#ECEDED',
  btnHoverBackground: '#FFFCFA',
  btnBorderColor: '#FFA586',
  ganttViewRowHeight: '1.875rem',
  roomMatrixRowHeight: 40,
  roomMatrixTopBarRowHeight: '2rem',
  roomMatrixFolderRowHeight: 40,
  roomMatrixRoomOccurrenceBarHeight: 28,
  roomMatrixRoomServiceOccurrenceBarHeight: 24,
  roomMatrixPreviewBarsColor: '#acacac',
  timelineSpace: 40,
  breakpoints,
  markerColors,
}
