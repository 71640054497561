import i18n from '../../../../i18n/i18n'
import { RoomMatrixBarData, MinimalBarData } from '../roomMatrixTypes'
import { TimeUtil } from '@organice/utils/date'
import { Dayjs } from 'dayjs'
import { is } from 'cypress/types/bluebird'
const dayjs = TimeUtil.getDayjs()

export const validateCollisions = async (
  value: any[],
  barsToCompareTo: MinimalBarData[]
) => {
  const [start, end] = value
  if (!dayjs.isDayjs(start) || !dayjs.isDayjs(start)) {
    throw new Error(i18n.t('common.form.error.fillOutAllFields'))
  }
  if (!barsToCompareTo.length) {
    return true
  }

  barsToCompareTo.forEach(bar => {
    const { start: barStart, end: barEnd } = bar
    if (barStart.isAfter(end) && barEnd.isBefore(start)) return true
    if (barStart.isSameOrBefore(start) && barEnd.isAfter(start))
      throw new Error(
        i18n.t('rooms.barStartTimeCollision', {
          barTitle: bar.content || bar.name,
          barTime: barEnd.format('HH:mm'),
        })
      )
    if (barStart.isBefore(end) && barEnd.isAfter(start))
      throw new Error(
        i18n.t('rooms.barEndTimeCollision', {
          barTitle: bar.content || bar.name,
          barTime: barStart.format('HH:mm'),
        })
      )
  })
  return true
}

export function createFieldName(
  id: number | string | number[] | string[],
  type: string
) {
  return `${type}.${Array.isArray(id) ? id.join('.') : id}`
}

export function getIdsFromFieldKey(fieldKey: string) {
  if (!fieldKey) return null
  const [type, groupId, attrId] = fieldKey.split('.')
  const [newPrefix, attributeGroupId] = String(groupId).split('-')

  return {
    type: String(type),
    roomAttributeValueGroupId: Number.isNaN(parseInt(groupId))
      ? false
      : parseInt(groupId),
    roomAttributeId: Number.isNaN(attrId) ? null : parseInt(attrId),
    ...(attributeGroupId
      ? { roomAttributeGroupId: parseInt(attributeGroupId) }
      : {}),
  }
}

export const prepareValueForSubmission = (value: any) => {
  const type = typeof value

  switch (type) {
    case 'string':
    case 'boolean':
    case 'number':
      return { value: { value: value } }
    case 'object':
      // [Date, Date] || [[Date, Date]]
      if (Array.isArray(value)) {
        if (value.every(entry => dayjs.isDayjs(entry))) {
          return { value: { value: value.map(entry => entry.utc().format()) } }
        }
        if (value.every(entry => Array.isArray(entry) || entry === undefined)) {
          return {
            value: {
              value: value.reduce((result, e) => {
                if (!e) return result
                if (e.every((d: unknown) => dayjs.isDayjs(d))) {
                  return [...result, e.map((d: Dayjs) => d.utc().format())]
                }
                return result
              }, []),
            },
          }
        }
      }

      if (dayjs(value).isValid()) {
        return { value: { value: [dayjs(value).utc().format()] } }
      }

      // fileList
      if (value === Object(value) && 'fileList' in value) {
        return {
          roomAttributeValueFiles: {
            data: value.fileList
              .map((f: any) => {
                if (typeof f.uid === 'number') {
                  return { fileId: f.uid }
                }
                if (typeof f.response?.uid === 'number') {
                  return { fileId: f.response.uid }
                }
              })
              .filter(Boolean),
          },
        }
      }
  }
}
