import React from 'react'
import {
  DeleteAttributeValueCommentMutation,
  useDeleteAttributeValueCommentMutation,
  DeleteUploadCommentMutation,
  useDeleteUploadCommentMutation,
  useDeleteRoomAttributeValueCommentMutation,
  DeleteRoomAttributeValueCommentMutation,
} from '@organice/graphql'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'
import { ApolloError } from '@apollo/client'
import { CommentTypes_Enum } from '@organice/contexts/comments/commentsContext'

type UseDeleteCommentReturn = {
  deleteComment(id: string): void
  data:
    | DeleteAttributeValueCommentMutation['delete_attributeValueComments_by_pk']
    | DeleteUploadCommentMutation['delete_uploadComments_by_pk']
    | DeleteRoomAttributeValueCommentMutation['delete_roomAttributeValueComments_by_pk']
    | null
    | undefined
  loading: boolean
  error: ApolloError | undefined
  success: boolean
}

type Options = {
  commentType: CommentTypes_Enum
}

export function useDeleteComment(options: Options): UseDeleteCommentReturn {
  const { commentType } = options
  const { t } = useTranslation()

  const [
    deleteAttributeValueCommentById,
    {
      data: attributeValueCommentData,
      loading: attributeValueCommentLoading,
      error: attributeValueCommentError,
    },
  ] = useDeleteAttributeValueCommentMutation()

  const [
    deleteUploadCommentById,
    {
      data: uploadCommentData,
      loading: uploadCommentLoading,
      error: uploadCommentError,
    },
  ] = useDeleteUploadCommentMutation()

  const [
    deleteRoomAttributeValueCommentById,
    {
      data: roomAttributeValueCommentData,
      loading: roomAttributeValueCommentLoading,
      error: roomAttributeValueCommentError,
    },
  ] = useDeleteRoomAttributeValueCommentMutation()

  const data =
    attributeValueCommentData?.delete_attributeValueComments_by_pk ||
    uploadCommentData?.delete_uploadComments_by_pk ||
    roomAttributeValueCommentData?.delete_roomAttributeValueComments_by_pk
  const loading =
    attributeValueCommentLoading ||
    uploadCommentLoading ||
    roomAttributeValueCommentLoading
  const error =
    attributeValueCommentError ||
    uploadCommentError ||
    roomAttributeValueCommentError

  React.useEffect(() => {
    if (data?.id) {
      notification.success({
        duration: 3,
        message: t('comment.actions.deleteSuccess'),
      })
    }
  }, [data])

  React.useEffect(() => {
    if (error) {
      notification.error({
        duration: 3,
        message: t('comment.actions.deleteError'),
      })
    }
  }, [error])

  function deleteComment(id: string) {
    if (!id) return
    switch (commentType) {
      case CommentTypes_Enum.AttribtueValueComment:
        return deleteAttributeValueCommentById({
          variables: {
            id: id,
          },
        })

      case CommentTypes_Enum.UploadComment:
        return deleteUploadCommentById({
          variables: {
            id: id,
          },
        })

      case CommentTypes_Enum.RoomAttributeValueComment:
        return deleteRoomAttributeValueCommentById({
          variables: {
            id: id,
          },
        })

      default:
        console.warn(
          `useDeleteComment(): No Delete Mutation for CommentType "${commentType}"`
        )
    }
  }

  return {
    deleteComment,
    data,
    loading,
    error,
    success: !!data?.id,
  }
}
