import React from 'react'
import {
  useAttributeValueCommentsByReferencePollingSubscription,
  useRoomAttributeValueCommentsByReferencePollingSubscription,
  useUploadCommentsByReferencePollingSubscription,
  RoomAttributeValueComments_Bool_Exp,
  AttributeValueCommentsByReferencePollingSubscription,
  UploadCommentsByReferencePollingSubscription,
  RoomAttributeValueCommentsByReferencePollingSubscription,
  AttributeValueComments_Bool_Exp,
  UploadComments_Bool_Exp,
} from '@organice/graphql'
import {
  CommentTypes_Enum,
  AllCommentGraphQLTypes as Comments,
  Comments_Bool_Exp,
} from '@organice/contexts/comments/commentsContext'
import { ApolloError } from '@apollo/client'
import { convertTimstampToBoolean } from '@organice/utils/form/conversion-functions'

const getQueryArguments = (
  type: CommentTypes_Enum,
  id: Pick<Comments, 'id'> | null
):
  | Comments_Bool_Exp
  | RoomAttributeValueComments_Bool_Exp
  | AttributeValueComments_Bool_Exp
  | UploadComments_Bool_Exp => {
  if (type === CommentTypes_Enum.UploadComment) {
    return { uploadId: { _eq: id } }
  }

  // if (type === CommentTypes_Enum.UploadVersion) {
  //   return { uploadVersionId: { _eq: id } }
  // }

  if (type === CommentTypes_Enum.AttribtueValueComment) {
    return { attributeValueId: { _eq: id } }
  }

  if (type === CommentTypes_Enum.RoomAttributeValueComment) {
    return { roomAttributeValueId: { _eq: id } }
  }

  return { attributeValueId: { _eq: id } }
}

type UseCommentsQueriesProps = {
  publicComments?: boolean
  type: CommentTypes_Enum
  organisationId?: number
  id: Pick<Comments, 'id'> | null
}

type UseCommentsQueriesReturn = {
  data:
    | AttributeValueCommentsByReferencePollingSubscription['attributeValueComments']
    | UploadCommentsByReferencePollingSubscription['uploadComments']
    | RoomAttributeValueCommentsByReferencePollingSubscription['roomAttributeValueComments']
    | undefined
  loading: boolean
  error: ApolloError | undefined
}

export const useCommentsQueries = (
  options: UseCommentsQueriesProps
): UseCommentsQueriesReturn => {
  const { id, type, organisationId, publicComments = true } = options

  const {
    data: avCommentsData,
    loading: avCommentsLoading,
    error: avCommentsError,
  } = useAttributeValueCommentsByReferencePollingSubscription({
    onSubscriptionData: () => {
      //   dispatch({ type: 'UPDATED_DATA' })
    },
    variables: {
      where: {
        parentId: { _is_null: true },
        ...(getQueryArguments(type, id) as AttributeValueComments_Bool_Exp),
        organisationId: publicComments
          ? { _is_null: true }
          : { _eq: organisationId },
      },
    },
    skip: type !== CommentTypes_Enum.AttribtueValueComment,
  })

  const {
    data: uploadCommentsData,
    loading: uploadCommentsLoading,
    error: uploadCommentsError,
  } = useUploadCommentsByReferencePollingSubscription({
    onSubscriptionData: () => {
      //   dispatch({ type: 'UPDATED_DATA' })
    },
    variables: {
      where: {
        parentId: { _is_null: true },
        ...(getQueryArguments(type, id) as UploadComments_Bool_Exp),
        organisationId: publicComments
          ? { _is_null: true }
          : { _eq: organisationId },
      },
    },
    skip: type !== CommentTypes_Enum.UploadComment,
  })

  const {
    data: roomAvCommentsData,
    loading: roomAvCommentsLoading,
    error: roomAvCommentsError,
  } = useRoomAttributeValueCommentsByReferencePollingSubscription({
    onSubscriptionData: () => {
      //   dispatch({ type: 'UPDATED_DATA' })
    },
    variables: {
      where: {
        parentId: { _is_null: true },
        ...(getQueryArguments(type, id) as RoomAttributeValueComments_Bool_Exp),
        organisationId: publicComments
          ? { _is_null: true }
          : { _eq: organisationId },
      },
    },
    skip: type !== CommentTypes_Enum.RoomAttributeValueComment,
  })

  const loading =
    avCommentsLoading || roomAvCommentsLoading || uploadCommentsLoading
  const error = avCommentsError || roomAvCommentsError || uploadCommentsError
  const data =
    avCommentsData?.attributeValueComments ||
    roomAvCommentsData?.roomAttributeValueComments ||
    uploadCommentsData?.uploadComments

  return { data, loading, error }
}
