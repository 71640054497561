import { useMediaQuery } from 'react-responsive'
import { useTheme } from '@emotion/react'
import React from 'react'

function getWindowSize() {
  const { innerWidth, innerHeight } = window
  return { innerWidth, innerHeight }
}

export const useResponsive = () => {
  const { breakpoints } = useTheme()

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints[1].px}px)`,
  })
  const isTablet = useMediaQuery({
    query: `(min-width: ${breakpoints[1].px}px) and (max-width: ${breakpoints[3].px}px)`,
  })

  const isMobileOrTablet = isMobile || isTablet

  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${breakpoints[3].px}px)`,
  })
  const isBigScreen = useMediaQuery({
    query: `(min-width: ${breakpoints[4].px}px)`,
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  const [windowSize, setWindowSize] = React.useState(getWindowSize())

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return {
    isMobile,
    isTablet,
    isDesktopOrLaptop,
    isBigScreen,
    isPortrait,
    isRetina,
    isMobileOrTablet,
    windowSize,
  }
}
