import React from 'react'
import {
  regexStringsDecorator,
  regexStringsDecoratorRenderer,
} from '@organice/utils/stringsDecorator'
interface MultilineString {
  input: string
}

export const MultilineString: React.FC<MultilineString> = ({ input = '' }) => {
  const parsedString = regexStringsDecorator(
    input,
    regexStringsDecoratorRenderer
  )

  return <span style={{ whiteSpace: 'pre-line' }}>{parsedString}</span>
}
