import React from 'react'
import {
  NotificationChannels_Enum,
  NotificationActions_Enum,
  ModuleTypes_Enum,
  useMarkNotificationAsReadMutation,
  MarkNotificationAsReadMutation,
  useMarkAllNotificationsAsReadMutation,
  useQueryNotificationMessagesListLazyQuery,
  useUnreadNotificationMessagesCountSubscription,
  useUnreadCommentNotificationMessagesCountSubscription,
  useQueryCommentNotificationMessagesListLazyQuery,
  QueryNotificationMessagesListQuery,
  // useEventListLazyQuery,
} from '@organice/graphql'
// import { useEventListQuery } from '@organice/graphql'
import { useTranslation } from 'react-i18next'
import { ApolloError } from '@apollo/client'
import { TimeUtil } from '@organice/utils/date'
import { notification as antdNotidfication, message } from 'antd'
import { useLocation } from 'react-router-dom'
import { useMeContext } from '@organice/contexts/me'
import { findCommentIdByRegEx } from '@organice/utils/hooks/comments/utils'
import { idToKey } from '@organice/organisms/roomMatrix/roomMatrixUtil'
import {
  BarTypePrefixes,
  RowTypePrefixes,
} from '@organice/organisms/roomMatrix/roomMatrixTypes'

const dayjs = TimeUtil.getDayjs()

type NotificationsListSubscriptionRecord =
  QueryNotificationMessagesListQuery['notificationMessages'][number]

export interface NotificationProps extends NotificationsListSubscriptionRecord {
  notificationAction?: NotificationActions_Enum
  notificationActionMessage: string
  authorFirstName?: string
  authorLastName?: string
  eventName?: string
  serviceName?: string
  serviceModuleName?: string
  to?: string
  roomAttributeName?: string | null
  roomAttributeDescription?: string | null
  roomServiceOccurrenceName?: string | null
  roomServiceOccurrenceStartDate?: string
  roomServiceName?: string | null
  roomName?: string | null
}

export interface UseNotificationsReturn {
  allNotifications: NotificationProps[] | undefined
  unReadNotifications: NotificationProps[] | undefined
  unReadNotificationsCount: number | undefined
  readNotifications: NotificationProps[] | undefined
  readNotificationsCount: number | undefined
  allCommentNotifications: NotificationProps[] | undefined
  unReadCommentNotifications: NotificationProps[] | undefined
  unReadCommentNotificationsCount: number | undefined
  readCommentNotifications: NotificationProps[] | undefined
  readCommentNotificationsCount: number | undefined
  prepareNotifications: (
    notificationsDataInput: QueryNotificationMessagesListQuery['notificationMessages']
  ) => NotificationProps[]
  setNotificationReadAt(notificationMessageId: bigint, read: boolean): void
  markAsReadData: MarkNotificationAsReadMutation | null | undefined
  error: ApolloError | undefined
  loading: boolean
  settingReadAtLoading: boolean
  markAllNotificationsAsRead: (
    notificationActionTypes:
      | NotificationActions_Enum[]
      | NotificationActionsCategory
  ) => void
  fetchMoreNotifications: () => void
  hasMoreNotifications: boolean
  fetchMoreCommentNotifications: () => void
  hasMoreCommentNotifications: boolean
  notificationsTotal: number | undefined
}

export enum NotificationActionsCategory {
  Notfications = 'notifications',
  Comments = 'comments',
}

interface UseNotificationOptions {
  notificationsPerPage?: number
}

const notificatitionActionCategories = {
  [NotificationActionsCategory.Notfications]: Object.keys(
    NotificationActions_Enum
  )
    .map(notificationAction => {
      if (!notificationAction.startsWith('Comment')) {
        return NotificationActions_Enum[
          notificationAction as keyof typeof NotificationActions_Enum
        ]
      }
    })
    .filter(<T>(n?: T): n is T => Boolean(n)),
  [NotificationActionsCategory.Comments]: Object.keys(NotificationActions_Enum)
    .map(notificationAction => {
      if (notificationAction.startsWith('Comment')) {
        return NotificationActions_Enum[
          notificationAction as keyof typeof NotificationActions_Enum
        ]
      }
    })
    .filter(<T>(n?: T): n is T => Boolean(n)),
}

export function useNotifications(
  options?: UseNotificationOptions
): UseNotificationsReturn {
  const { notificationsPerPage = 10 } = options || {}
  const { t } = useTranslation()

  // keep track of first subscription data to preven notifiactions fetch
  // initial data is already present from initial fetch
  const subscriptionsInit = React.useRef({
    notifications: false,
    comments: false,
  })

  // initial Fetch
  React.useEffect(() => {
    fetchCommentNotifications({
      variables: {
        notificationChannel: NotificationChannels_Enum.Web,

        limit:
          notificationsPage === 0
            ? notificationsPerPage
            : notificationsPerPage * notificationsPage,
      },
      fetchPolicy: 'network-only',
    })
    fetchNotifications({
      variables: {
        notificationChannel: NotificationChannels_Enum.Web,
        notificationActionsFilter: {
          _nin: notificatitionActionCategories[
            NotificationActionsCategory.Comments
          ],
        },
        limit: notificationsPerPage,
      },
      fetchPolicy: 'network-only',
    })
  }, [])

  /* Mark as read */
  const [
    markNotificationAsReadMutation,
    {
      data: markAsReadData,
      error: markAsReadError,
      loading: markAsReadLoading,
    },
  ] = useMarkNotificationAsReadMutation()

  function setNotificationReadAt(notificationMessageId: bigint, read: boolean) {
    markNotificationAsReadMutation({
      variables: {
        notificationMessageId: notificationMessageId,
        read: read ? 'now' : null,
      },
    })
  }

  /* Mark all as read */
  const [
    markAllNotificationsAsReadMutation,
    {
      data: markAllAsReadData,
      loading: markAllAsReadLoading,
      error: markAllAsReadError,
    },
  ] = useMarkAllNotificationsAsReadMutation()

  async function markAllNotificationsAsRead(
    notificationActionTypes:
      | NotificationActions_Enum[]
      | NotificationActionsCategory = NotificationActionsCategory.Notfications
  ) {
    const actionTypesArray = Array.isArray(notificationActionTypes)
      ? notificationActionTypes
      : notificatitionActionCategories[notificationActionTypes]

    await markAllNotificationsAsReadMutation({
      variables: {
        notificationActionsFilter: {
          _in: actionTypesArray,
        },
        notificationChannelsFilter: {
          _eq: NotificationChannels_Enum.Web,
        },
      },
    })

    if (notificationActionTypes === NotificationActionsCategory.Notfications) {
      refetchCurrentNotifications()
    }

    if (notificationActionTypes === NotificationActionsCategory.Comments) {
      refetchCurrentcommentNotifications()
    }
  }

  React.useEffect(() => {
    if (markAsReadError || markAllAsReadError) {
      message.error({
        duration: 3,
        content: t('notifications.errorNotificationRead'),
      })
    }
  }, [markAsReadError, markAllAsReadError])

  /* Notificaions Data */
  const [notificationsPage, setNotificationsPage] = React.useState<number>(0)

  const [
    fetchNotifications,
    {
      data: notificationsData,
      error: notificationsError,
      loading: notificationsLoading,
    },
  ] = useQueryNotificationMessagesListLazyQuery()

  async function refetchCurrentNotifications() {
    fetchNotifications({
      variables: {
        notificationChannel: NotificationChannels_Enum.Web,
        notificationActionsFilter: {
          _nin: notificatitionActionCategories[
            NotificationActionsCategory.Comments
          ],
        },
        limit:
          notificationsPage === 0
            ? notificationsPerPage
            : notificationsPerPage * notificationsPage,
      },
      fetchPolicy: 'network-only',
    })
  }

  function fetchMoreNotifications() {
    fetchNotifications({
      variables: {
        notificationChannel: NotificationChannels_Enum.Web,
        notificationActionsFilter: {
          _nin: notificatitionActionCategories[
            NotificationActionsCategory.Comments
          ],
        },
        limit: notificationsPerPage,
        skip: (notificationsPage + 1) * notificationsPerPage,
      },
    })
    setNotificationsPage(notificationsPage + 1)
  }

  const [allNotificationsState, setAllNotificationsState] =
    React.useState<QueryNotificationMessagesListQuery['notificationMessages']>()

  const hasMoreNotifications = React.useMemo(() => {
    return (
      (allNotificationsState || []).length <
      (notificationsData?.allNotificationMessagesAggregate.aggregate?.count ||
        0)
    )
  }, [allNotificationsState, notificationsData])

  React.useEffect(() => {
    const uniqueIds = new Set()

    const updatedNotifications = [
      ...(allNotificationsState || []),
      ...(notificationsData?.notificationMessages || []),
    ]
      .filter(element => {
        const isDuplicate = uniqueIds.has(element.id)
        uniqueIds.add(element.id)
        return isDuplicate ? false : true
      })
      .sort((a, b) => (dayjs(a.createdAt).isSameOrBefore(b.createdAt) ? 1 : -1))
    setAllNotificationsState(updatedNotifications)
  }, [notificationsData])

  const { data: unreadNotificationsCount } =
    useUnreadNotificationMessagesCountSubscription({
      variables: {
        notificationChannel: NotificationChannels_Enum.Web,
        notificationActionsFilter: {
          _nin: notificatitionActionCategories[
            NotificationActionsCategory.Comments
          ],
        },
      },
    })

  React.useEffect(() => {
    if (
      !(
        typeof unreadNotificationsCount?.notificationMessages_aggregate
          .aggregate?.count === 'number'
      )
    )
      return

    // Skip first, datea exists from initial fetch
    if (!subscriptionsInit.current.notifications) {
      subscriptionsInit.current.notifications = true
      return
    }

    allNotificationsState && refetchCurrentNotifications()
  }, [unreadNotificationsCount, notificationsPage])

  /* Comment Notifications Data */
  const [commentNotificationsPage, setCommentNotificationsPage] =
    React.useState<number>(0)

  // Unread CommentNotifications Count
  const {
    data: unreadCommentNotificationsCount,
    error: xcyerr,
    loading: xcyloading,
  } = useUnreadCommentNotificationMessagesCountSubscription({
    variables: {
      notificationChannel: NotificationChannels_Enum.Web,
    },
  })

  async function refetchCurrentcommentNotifications() {
    fetchCommentNotifications({
      variables: {
        notificationChannel: NotificationChannels_Enum.Web,

        limit:
          notificationsPage === 0
            ? notificationsPerPage
            : notificationsPerPage * notificationsPage,
      },
      fetchPolicy: 'network-only',
    })
  }

  React.useEffect(() => {
    if (
      !(
        typeof unreadCommentNotificationsCount?.notificationMessages_aggregate
          .aggregate?.count === 'number'
      )
    )
      return

    // Skip first, datea exists from initial fetch
    if (!subscriptionsInit.current.comments) {
      subscriptionsInit.current.comments = true
      return
    }

    refetchCurrentcommentNotifications()
  }, [unreadCommentNotificationsCount, notificationsPage])

  // CommentNotifications Query for paginated existing Data
  const [
    fetchCommentNotifications,
    {
      data: commentNotificationsData,
      error: commentNotificationsError,
      loading: commentNotificationsLoading,
    },
  ] = useQueryCommentNotificationMessagesListLazyQuery()

  React.useEffect(() => {
    fetchCommentNotifications({
      variables: {
        notificationChannel: NotificationChannels_Enum.Web,
        limit: notificationsPerPage,
        skip: commentNotificationsPage * notificationsPerPage,
      },
      fetchPolicy: 'network-only',
    })
  }, [])

  function fetchMoreCommentNotifications() {
    fetchCommentNotifications({
      variables: {
        notificationChannel: NotificationChannels_Enum.Web,
        limit: notificationsPerPage,
        skip: (commentNotificationsPage + 1) * notificationsPerPage,
      },
    })
    setCommentNotificationsPage(commentNotificationsPage + 1)
  }

  const [allCommentNotificationsState, setAllCommentNotificationsState] =
    React.useState<QueryNotificationMessagesListQuery['notificationMessages']>()

  const hasMoreCommentNotifications = React.useMemo(() => {
    return (
      (allCommentNotificationsState || []).length <
      (commentNotificationsData?.allCommentNtificationsAggregat.aggregate
        ?.count || 0)
    )
  }, [allCommentNotificationsState, commentNotificationsData])

  React.useEffect(() => {
    const uniqueIds = new Set()
    const updatedCommentNotifications = [
      ...(allCommentNotificationsState || []),
      ...(commentNotificationsData?.notificationMessages || []),
    ]
      .filter(element => {
        const isDuplicate = uniqueIds.has(element.id)
        uniqueIds.add(element.id)
        return isDuplicate ? false : true
      })
      .sort((a, b) => (dayjs(a.createdAt).isSameOrBefore(b.createdAt) ? 1 : -1))

    setAllCommentNotificationsState(updatedCommentNotifications)
  }, [commentNotificationsData])

  // React.useEffect(() => {
  //   if (commentNotificationsSubscriptionData?.notificationMessages.length) {
  //     const uniqueIds = new Set()

  //     const updatedCommentNotifications = [
  //       ...(commentNotificationsSubscriptionData?.notificationMessages || []),
  //       ...(allCommentNotificationsState || []),
  //     ]
  //       .filter(element => {
  //         const isDuplicate = uniqueIds.has(element.id)
  //         uniqueIds.add(element.id)
  //         return isDuplicate ? false : true
  //       })
  //       .sort((a, b) =>
  //         dayjs(a.createdAt).isSameOrBefore(b.createdAt) ? 1 : -1
  //       )
  //     setAllCommentNotificationsState(updatedCommentNotifications)
  //   }
  // }, [commentNotificationsSubscriptionData])

  /* The Apollo Cache Update & propagation after setNotificationReadAt is really slow */
  /* This will updated the state directily with the API return & then it will be updated again after the the cache update */
  React.useEffect(() => {
    if (markAsReadData?.update_notificationMessages_by_pk) {
      const updatedNotification =
        markAsReadData.update_notificationMessages_by_pk

      const isComment = allCommentNotificationsState?.find(
        c => c.id === updatedNotification.id
      )

      if (isComment) {
        const updatedNotifiactionState = allCommentNotificationsState?.map(
          n => {
            if (n.id === updatedNotification?.id) {
              return { ...n, readAt: updatedNotification?.readAt }
            }
            return { ...n }
          }
        )
        setAllCommentNotificationsState(updatedNotifiactionState)
        return
      }

      const updatedNotifiactionState = allNotificationsState?.map(n => {
        if (n.id === updatedNotification?.id) {
          return { ...n, readAt: updatedNotification?.readAt }
        }
        return { ...n }
      })
      setAllNotificationsState(updatedNotifiactionState)
    }
  }, [markAsReadData])

  React.useEffect(() => {
    if (markAllAsReadData?.update_notificationMessages) {
      const updatedNotifications =
        markAllAsReadData?.update_notificationMessages.returning

      const isComment = allCommentNotificationsState?.find(
        c => c.id === updatedNotifications?.[0].id
      )

      if (isComment) {
        const updatedNotifiactionState = allCommentNotificationsState?.map(
          n => {
            const updatedNotification = updatedNotifications.find(
              updatedN => n.id === updatedN.id
            )
            if (n.id === updatedNotification?.id) {
              return { ...n, readAt: updatedNotification?.readAt }
            }
            return { ...n }
          }
        )
        setAllCommentNotificationsState(updatedNotifiactionState)
        return
      }

      const updatedNotifiactionState = allNotificationsState?.map(n => {
        const updatedNotification = updatedNotifications.find(
          updatedN => n.id === updatedN.id
        )
        if (n.id === updatedNotification?.id) {
          return { ...n, readAt: updatedNotification?.readAt }
        }
        return { ...n }
      })
      setAllNotificationsState(updatedNotifiactionState)
    }
  }, [markAllAsReadData])

  function prepareNotifications(
    notificationsDataInput: QueryNotificationMessagesListQuery['notificationMessages']
  ) {
    return notificationsDataInput?.map(notificationMessage => {
      const notificationAction =
        notificationMessage?.notification?.notificationAction
      const variables = {
        ...notificationMessage,
        'user.firstName':
          notificationMessage?.notification?.user?.firstName || '',
        'user.lastName':
          notificationMessage?.notification?.user?.lastName || '',
        'service.name': notificationMessage?.notification?.service?.name || '',
        'serviceModule.name':
          notificationMessage?.notification?.serviceModule?.name || '',
        'event.name': notificationMessage?.notification?.event?.name || '',
        activeTab:
          notificationMessage?.notification?.event?.guestOrganisationId !==
            null &&
          notificationMessage?.notification?.event?.guestOrganisationId ===
            (
              notificationMessage?.notification?.attributeValueComment ||
              notificationMessage?.notification?.uploadComment ||
              notificationMessage?.notification?.roomAttributeValueComment
            )?.organisationId
            ? 0
            : notificationMessage?.notification?.user?.organisationId ===
                (
                  notificationMessage?.notification?.attributeValueComment ||
                  notificationMessage?.notification?.uploadComment ||
                  notificationMessage?.notification?.roomAttributeValueComment
                )?.organisationId
              ? 1
              : 2,
        fileCommentId:
          notificationMessage?.notification?.uploadComment?.uploadId || null,
        roomAttributeName:
          notificationMessage.notification.roomAttributeValue?.roomAttribute
            .name,
        roomAttributeDescription:
          notificationMessage.notification.roomAttributeValue?.roomAttribute
            .description,
        roomServiceOccurrenceName:
          notificationMessage?.notification?.roomServiceOccurrence?.name,
        roomServiceOccurrenceStartDate:
          notificationMessage?.notification?.roomServiceOccurrence?.startDate,
        roomServiceName: notificationMessage?.notification?.roomService?.name,
        roomName: notificationMessage?.notification?.room?.name,
      }
      let linkTo = ''

      switch (notificationAction) {
        case NotificationActions_Enum.CommentMentionUser:
        case NotificationActions_Enum.CommentMentionOrganisation:
        case NotificationActions_Enum.CommentAuthoredReply: {
          if (notificationMessage?.notification?.serviceModule) {
            const attributeOrUpload =
              notificationMessage?.notification?.serviceModule?.moduleType ===
              ModuleTypes_Enum.Uploads
                ? `notifications.actions.${notificationAction}.linkToFile`
                : `notifications.actions.${notificationAction}.linkTo`

            if (
              (notificationMessage?.notification?.attributeValueId ||
                (notificationMessage?.notification?.fileId &&
                  notificationMessage?.notification?.uploadComment
                    ?.uploadId)) &&
              notificationMessage?.notification?.serviceModule
            ) {
              linkTo = t(attributeOrUpload, {
                ...variables.notification,
                commentId: findCommentIdByRegEx(variables.notification),
                activeTab: variables.activeTab,
                fileCommentId: variables.fileCommentId,
              })
            }
          } else {
            const occurrenceId =
              notificationMessage?.notification?.roomServiceOccurrence?.id
            const roomServiceOccurrenceKey = idToKey(
              occurrenceId,
              BarTypePrefixes.RoomServiceOccurrence
            )
            const roomAttributeValueId =
              notificationMessage?.notification?.roomAttributeValueId

            const { organisationId } =
              notificationMessage?.notification.roomAttributeValueComment || {}
            const activeTab = organisationId
              ? notificationMessage?.notification?.event
                  ?.guestOrganisationId === organisationId
                ? 0
                : 1
              : 2

            const linkToRoomAttributeValueComment = `notifications.roomAttributeValueComments.actions.${notificationAction}.linkTo`

            linkTo = t(linkToRoomAttributeValueComment, {
              eventId: variables.notification.eventId,
              roomServiceOccurrenceKey: roomServiceOccurrenceKey,
              roomAttributeValueId: roomAttributeValueId,
              commentId: findCommentIdByRegEx(variables.notification),
              activeTab: activeTab,
            })
          }

          break
        }
        case NotificationActions_Enum.RoomServiceOccurrenceChange: {
          const roomServiceOccurrenceId =
            notificationMessage?.notification?.roomServiceOccurrence?.id
          const roomServiceOccurrenceKey = idToKey(
            roomServiceOccurrenceId,
            BarTypePrefixes.RoomServiceOccurrence
          )
          linkTo = t(`notifications.actions.${notificationAction}.linkTo`, {
            eventId: variables.notification.eventId,
            roomServiceOccurrenceKey: roomServiceOccurrenceKey,
          })
          break
        }
        case NotificationActions_Enum.RoomOccurrenceChange: {
          const roomId = notificationMessage?.notification?.room?.id
          const roomKey = idToKey(roomId, RowTypePrefixes.Rooms)
          linkTo = t(`notifications.actions.${notificationAction}.linkTo`, {
            eventId: variables.notification.eventId,
            roomKey: roomKey,
          })
          break
        }
        case NotificationActions_Enum.JaggaerSubmissionFailure:
        case NotificationActions_Enum.JaggaerSubmissionSuccess: {
          linkTo = t(`notifications.actions.${notificationAction}.linkTo`, {
            eventId: variables.notification.eventId,
            serviceId: variables.notification.serviceId,
            serviceModuleId: variables.notification.serviceModuleId,
            fileId: variables.notification.fileId,
          })
          break
        }

        default:
          linkTo = t(
            `notifications.actions.${notificationAction}.linkTo`,
            variables.notification
          )
      }

      const notificationActionMessage = variables.notification
        .roomAttributeValueId
        ? `notifications.roomAttributeValueComments.actions.${notificationAction}.message`
        : `notifications.actions.${notificationAction}.message`

      return {
        ...notificationMessage,
        notificationActionMessage: notificationActionMessage,
        notificationAction: notificationAction,
        authorFirstName: variables['user.firstName'],
        authorLastName: variables['user.lastName'],
        eventName: variables['event.name'],
        serviceName: variables['service.name'],
        serviceModuleName: variables['serviceModule.name'],
        to: linkTo,
        roomAttributeName:
          variables['roomAttributeName'] ||
          variables['roomAttributeDescription'],
        roomAttributeDescription: variables['roomAttributeDescription'],
        roomServiceOccurrenceName: variables['roomServiceOccurrenceName'],
        roomServiceOccurrenceStartDate:
          variables['roomServiceOccurrenceStartDate'],
        roomServiceName: variables['roomServiceName'],
        roomName: variables['roomName'],
      }
    })
  }

  const allNotifications = React.useMemo(() => {
    return allNotificationsState && prepareNotifications(allNotificationsState)
  }, [allNotificationsState])

  const unReadNotifications = React.useMemo(
    () => allNotifications?.filter(notification => !notification?.readAt),
    [allNotifications]
  )
  const readNotifications = React.useMemo(
    () => allNotifications?.filter(notification => notification?.readAt),
    [allNotifications]
  )

  const allCommentNotifications = React.useMemo(() => {
    return (
      allCommentNotificationsState &&
      prepareNotifications(allCommentNotificationsState)
    )
  }, [allCommentNotificationsState])

  const unReadCommentNotifications = React.useMemo(
    () =>
      allCommentNotifications?.filter(notification => !notification?.readAt),
    [allCommentNotifications]
  )

  const readCommentNotifications = React.useMemo(
    () => allCommentNotifications?.filter(notification => notification?.readAt),
    [allCommentNotifications]
  )

  return {
    allNotifications,
    unReadNotifications,
    unReadNotificationsCount:
      notificationsData?.unreadNotificationMessagesAggregate.aggregate?.count,
    // unreadNotificationsCount?.notificationMessages_aggregate.aggregate?.count,
    readNotifications,
    readNotificationsCount: unReadNotifications?.length,
    prepareNotifications,
    setNotificationReadAt,
    allCommentNotifications,
    unReadCommentNotifications,
    unReadCommentNotificationsCount:
      commentNotificationsData?.unreadCommentNtificationsAggregate.aggregate
        ?.count,
    // unreadCommentNotificationsCount?.notificationMessages_aggregate.aggregate,
    //   ?.count,
    readCommentNotifications,
    readCommentNotificationsCount: readCommentNotifications?.length,
    error: markAsReadError || notificationsError || markAllAsReadError,
    markAsReadData,
    loading: notificationsLoading,
    settingReadAtLoading: markAsReadLoading || markAllAsReadLoading,
    markAllNotificationsAsRead,
    fetchMoreNotifications,
    hasMoreNotifications,
    fetchMoreCommentNotifications,
    hasMoreCommentNotifications,
    notificationsTotal:
      (notificationsData?.unreadNotificationMessagesAggregate.aggregate
        ?.count || 0) +
      (commentNotificationsData?.unreadCommentNtificationsAggregate.aggregate
        ?.count || 0),
  }
}

/*****  Hacky Workaround *****/
/* This is a workaround to pretend a decoupled event creation flow due to currently long event creation times. */
/* A user should hit the save button receive an optimistic success message and a notfication once the event has been created. */
export function useEventCreationHack() {
  const { t } = useTranslation()
  // const history = useHistory()
  const { pathname: currentPath } = useLocation()
  const me = useMeContext()
  // const [refetchEventList] = useEventListLazyQuery()
  const [notificationsInit, setNotificationsInit] = React.useState(false)
  const [
    recentEventCreationNotifications,
    setRecentEventCreationNotifications,
  ] = React.useState<
    QueryNotificationMessagesListQuery['notificationMessages']
  >([])
  const { unReadNotifications, setNotificationReadAt } = useNotifications()

  React.useEffect(() => {
    const notificationMessages = unReadNotifications

    const recentlyCreatedByMeEvents =
      notificationMessages?.filter(
        nd =>
          nd.notification.notificationAction === 'eventCreated' && // only creation of ne event
          nd.notification.userId === me.state.me?.id && // only created by me
          dayjs(nd.createdAt).isAfter(dayjs().subtract(15, 'minutes')) && // within the last 15 min
          !recentEventCreationNotifications.find(recn => {
            return recn.id === nd.id
          }) // not alread shown as message
      ) || []

    if (notificationsInit) {
      // if (recentlyCreatedByMeEvents && recentlyCreatedByMeEvents.length > 0) {
      //   refetchEventList()
      //   recentlyCreatedByMeEvents.map(eventCreateNotification => {
      //     const eventName = eventCreateNotification.notification.event?.name
      //     const eventId = eventCreateNotification.notification.eventId
      //     // bring messages only if user is not still on /events/new (will be redirected automatically) or alreade redirected to event
      //     if (
      //       currentPath !== '/events/new' &&
      //       currentPath !== `/events/${eventId}`
      //     ) {
      //       antdNotidfication.success({
      //         duration: 3,
      //         message: t('events.eventCreationSuccess', {
      //           eventName: eventName,
      //         }),
      //         description: t('events.clickToGoToEvent'),
      //         onClick: () => {
      //           history.push(`/events/${eventId}`)
      //           antdNotidfication.destroy()
      //           setNotificationReadAt(eventCreateNotification.id, true)
      //         },
      //         style: {
      //           cursor: 'pointer',
      //         },
      //       })
      //     }
      //     setRecentEventCreationNotifications([
      //       ...recentEventCreationNotifications,
      //       ...recentlyCreatedByMeEvents,
      //     ])
      //   })
      // }
    }

    if (notificationMessages && !notificationsInit) {
      setNotificationsInit(true)
      setRecentEventCreationNotifications([
        ...recentEventCreationNotifications,
        ...recentlyCreatedByMeEvents,
      ])
    }
  }, [unReadNotifications, recentEventCreationNotifications, currentPath])

  return { unReadNotifications }
}
