import { UpdatesSplitByMutation, FileIdObject } from '../types'
import {
  ConfigRoomAttribute,
  StateId,
  ConfigRoomService,
  ConfigRoomAttributeGroup,
} from '@organice/contexts/configRoom'
import {
  RoomAttributes_Insert_Input,
  RoomAttributeGroups_Insert_Input,
} from '@organice/graphql'

import { gql, DocumentNode } from '@apollo/client'

type AliasedGraphQLMutationObject = {
  // alias?: string
  // mutationString: string
  variables?: Record<string, any>
  gql: DocumentNode
}

export function prepareRoomServiceMutations(
  roomServicesChanges: UpdatesSplitByMutation<ConfigRoomService>
): AliasedGraphQLMutationObject[] {
  // RoomService INSERTs and DELETEs are not handled here

  // RoomService UPDATEs
  return roomServicesChanges.updates
    .map(u => {
      const mutationsArray: AliasedGraphQLMutationObject[] = []
      const { id: roomServiceId, roomAttributeGroups, ..._set } = u
      const mutationAlias = `roomService_${roomServiceId}_update`

      if (Object.keys(_set).length > 0) {
        mutationsArray.push({
          ...createRoomServiceUpdateFragment({
            alias: mutationAlias,
            id: roomServiceId,
            _set: _set,
          }),
        })
      }

      roomAttributeGroups &&
        mutationsArray.push(
          ...prepareRoomAttributeGroupsMutations(
            roomAttributeGroups,
            roomServiceId
          )
        )

      return mutationsArray
    })
    .reduce((result, arr) => [...result, ...arr], [])
}

function prepareRoomAttributeGroupsMutations(
  roomAttributeGroups: UpdatesSplitByMutation<ConfigRoomAttributeGroup>,
  roomServiceId: StateId
): AliasedGraphQLMutationObject[] {
  const mutationsArray: AliasedGraphQLMutationObject[] = []

  // roomAttributeGroups INSERTS
  if (roomAttributeGroups?.inserts.length > 0) {
    const roomAttributeGroupsInsertObjects: RoomAttributeGroups_Insert_Input[] =
      roomAttributeGroups?.inserts?.map(insert => {
        const { id, ...insertObject } = insert
        return {
          ...(insertObject as RoomAttributeGroups_Insert_Input),
          roomServiceId,
        }
      }) || []

    mutationsArray.push({
      ...createRoomAttributeGroupInsertFragment({
        objects: roomAttributeGroupsInsertObjects,
      }),
    })
  }

  // roomAttributeGroups DELETEs are not handled here

  // roomAttributeGroups UPDATES
  roomAttributeGroups?.updates?.forEach(update => {
    const { id: attributeGroupId, roomAttributes, ..._set } = update
    const mutationAlias = `roomAttributeGroups_${attributeGroupId}_update`

    // RoomAttributeGroup Updates
    if (Object.keys(_set).length > 0) {
      mutationsArray.push({
        ...createRoomAttributeGroupUpdateFragment({
          alias: mutationAlias,
          id: attributeGroupId as bigint,
          _set: _set,
        }),
      })
    }

    // Prepare RoomAttributeMutations
    roomAttributes &&
      mutationsArray.push(
        ...prepareRoomAttributeMutations(roomAttributes, attributeGroupId)
      )
  })

  return mutationsArray
}

function prepareRoomAttributeMutations(
  roomAttributes: UpdatesSplitByMutation<ConfigRoomAttribute>,
  attributeGroupId: StateId
): AliasedGraphQLMutationObject[] {
  const mutationsArray: AliasedGraphQLMutationObject[] = []

  // roomAttribute INSERTs
  if (roomAttributes?.inserts.length) {
    const roomAttributeInsertObjects: RoomAttributes_Insert_Input[] =
      roomAttributes?.inserts.map(a => {
        const { id, roomAttributeFiles, ...attribute } = a
        return {
          ...attribute,
          roomAttributeGroupId: attributeGroupId,
        }
      })

    mutationsArray.push({
      ...createRoomAttributeInsertMultipleFragment({
        alias: `insert_roomAttributes_for_${attributeGroupId}`,
        attributeGroupId: attributeGroupId as bigint,
        objects: roomAttributeInsertObjects,
      }),
    })
  }

  // roomAttribute DELETEs
  if (roomAttributes?.deletes.length) {
    const roomAttributeIdsToDelete = roomAttributes.deletes.map(
      attrDelete => attrDelete.id
    )
    if (roomAttributeIdsToDelete.length) {
      const attributesDeleteAlias = `delete_roomAttributes_${roomAttributeIdsToDelete.join(
        '_'
      )}`

      mutationsArray.push({
        ...createRoomAttributeDeleteMultipleFragment({
          alias: attributesDeleteAlias,
          ids: roomAttributeIdsToDelete as bigint[],
        }),
      })
    }
  }

  // roomAttribute UPDATEs

  if (roomAttributes?.updates.length) {
    roomAttributes.updates.forEach(attrUpdate => {
      const updateMutationAlias = `roomAttribute_${attrUpdate.id}_update`
      const {
        id: roomAttributeId,
        roomAttributeFiles,
        ...roomAttr_set
      } = attrUpdate

      mutationsArray.push({
        ...createRoomAttributeUpdateFragment({
          alias: updateMutationAlias,
          id: roomAttributeId as bigint,
          _set: roomAttr_set,
        }),
      })

      if (roomAttributeFiles) {
        const attrFilesMutationAlias = `roomAttributeFiles_for_${attrUpdate.id}_update`

        mutationsArray.push({
          ...createRoomAttributeFilesUpdateFragment({
            alias: attrFilesMutationAlias,
            roomAttributeId: roomAttributeId as bigint,
            roomAttributeFiles: roomAttributeFiles,
          }),
        })
      }
    })
  }

  return mutationsArray
}

/* Single GraphQL Mutation Generator Functions  */

interface CreateRoomServiceUpdateFragmentProps {
  alias: string
  id: bigint
  _set: Record<string, any> //RoomServices_Set_Input
}
function createRoomServiceUpdateFragment(
  props: CreateRoomServiceUpdateFragmentProps
) {
  const { alias, id, _set } = props

  const aliasedVarNames = {
    id: `update_roomServices_by_pk_${id}_id`,
    _set: `update_roomServices_by_pk_${id}_set`,
  }

  return {
    variables: {
      [aliasedVarNames.id]: id,
      [aliasedVarNames._set]: _set,
    },
    gql: gql`
    mutation(
      $update_roomServices_by_pk_${id}_id: bigint!, 
      $update_roomServices_by_pk_${id}_set: roomServices_set_input! ) {
   ${alias}: update_roomServices_by_pk(pk_columns: {id: $${aliasedVarNames.id}}, _set: $${aliasedVarNames._set}){
        id
        name
        order
    }
}`,
  }
}

interface CreateRoomAttributeGroupUpdateFragmentProps {
  alias: string
  id: bigint
  _set: Record<string, any> //RoomAttributeGroup_Set_Input
}
function createRoomAttributeGroupUpdateFragment(
  props: CreateRoomAttributeGroupUpdateFragmentProps
) {
  const { alias, id, _set } = props

  const aliasedVarNames = {
    id: `update_roomAttributeGroups_by_pk_${id}_id`,
    _set: `update_roomAttributeGroups_by_pk_${id}_set`,
  }

  return {
    variables: {
      [aliasedVarNames.id]: id,
      [aliasedVarNames._set]: _set,
    },
    gql: gql`
      mutation (
        $update_roomAttributeGroups_by_pk_${id}_id: bigint!,
        $update_roomAttributeGroups_by_pk_${id}_set: roomAttributeGroups_set_input!
      ){
        ${alias}: update_roomAttributeGroups_by_pk(pk_columns: {id: $${aliasedVarNames.id}}, _set: $${aliasedVarNames._set}){
          id
          name
          description
      valuesRepeatable
      order
      }
      }`,
  }
}

interface CreateRoomAttributeFragmentProps {
  alias: string
  id: bigint
  _set: Record<string, any> //RoomAttributeGroup_Set_Input
}
function createRoomAttributeUpdateFragment(
  props: CreateRoomAttributeFragmentProps
) {
  const { alias, id, _set } = props

  const aliasedVarNames = {
    id: `update_roomAttributes_by_pk_${id}_id`,
    _set: `update_roomAttributes_by_pk_${id}_set`,
  }

  return {
    variables: {
      [aliasedVarNames.id]: id,
      [aliasedVarNames._set]: _set,
    },
    gql: gql`
        mutation (
          $${aliasedVarNames.id}: bigint!,
          $${aliasedVarNames._set}: roomAttributes_set_input!
        ){
        ${alias}: update_roomAttributes_by_pk(pk_columns: { id: $${aliasedVarNames.id} }, _set: $${aliasedVarNames._set}){
            attributeType
            order
            config
            createdAt
            description
            id
            name
            updatedAt
            roomAttributeFiles {
              # downloadUrl
              fileId
              file {
                id
                size
                name
                extension
              }
            }
            }
        }
`,
  }
}

interface CreateRoomAttributeGroupInsertFragmentProps {
  alias?: string
  objects: RoomAttributeGroups_Insert_Input[] //RoomAttributeGroup_Set_Input
}
function createRoomAttributeGroupInsertFragment(
  props: CreateRoomAttributeGroupInsertFragmentProps
) {
  const { alias, objects } = props

  const aliasedVarNames = {
    objects: `insert_roomAttributeGroups_objects_${alias}`,
  }

  return {
    variables: {
      [aliasedVarNames.objects]: objects,
    },
    gql: gql`
        mutation (
          $${aliasedVarNames.objects}: [roomAttributeGroups_insert_input!]!,
        ){
          ${alias ? alias + ': ' : ''}insert_roomAttributeGroups(objects: $${
            aliasedVarNames.objects
          }){
        returning{
          id
          name
          description
          valuesRepeatable
          order
        }
        }
        }
`,
  }
}

interface CreateRoomAttributeFilesUpdateFragmentProps {
  alias?: string
  roomAttributeId: StateId
  roomAttributeFiles: UpdatesSplitByMutation<FileIdObject> //ConfigRoomAttribute['roomAttributeFiles'] //RoomAttributeGroup_Set_Input
}
function createRoomAttributeFilesUpdateFragment(
  props: CreateRoomAttributeFilesUpdateFragmentProps
) {
  const { alias, roomAttributeId, roomAttributeFiles } = props

  const insertObjects = roomAttributeFiles.inserts.map(f => ({
    roomAttributeId: roomAttributeId,
    fileId: f.fileId,
  }))

  const deleteObjects = roomAttributeFiles.deletes.map(f => ({
    roomAttributeId: { _eq: roomAttributeId },
    fileId: { _eq: f.fileId },
  }))

  const aliasedVarNames = {
    delete_objects: `delete_roomAttributeFiles_for_${roomAttributeId}_delete_objects`,
    insertObjects: `insert_roomAttributeFiles_for_${roomAttributeId}_insert_objects`,
  }

  return {
    variables: {
      [aliasedVarNames.delete_objects]: deleteObjects,
      [aliasedVarNames.insertObjects]: insertObjects,
    },
    gql: gql`
        mutation (
          $${aliasedVarNames.delete_objects}: [roomAttributeFiles_bool_exp!],
          $${
            aliasedVarNames.insertObjects
          }: [roomAttributeFiles_insert_input!]!,
        ){
          

          delete_roomAttributeFiles_for_${roomAttributeId}: delete_roomAttributeFiles(where: {
		_or: $${aliasedVarNames.delete_objects}
	}){
		affected_rows
		returning {
			roomAttributeId
			fileId
		}
	}
      ${alias ? alias + ': ' : ''}insert_roomAttributeFiles(objects: $${
        aliasedVarNames.insertObjects
      }){
        returning{
          fileId
                file {
                  id
                  size
                  name
                  extension
                }
        }
        }
    
         
        
        }
        
`,
  }
}

interface CreateRoomAttributeDeleteMultipleFragmentProps {
  alias?: string
  ids: StateId[]
}
function createRoomAttributeDeleteMultipleFragment(
  props: CreateRoomAttributeDeleteMultipleFragmentProps
) {
  const { alias, ids } = props

  const aliasedVarNames = {
    roomAttributeIds: `delete_roomAttributes_${alias}`,
  }

  return {
    variables: {
      [aliasedVarNames.roomAttributeIds]: ids,
    },
    gql: gql`
        mutation (
          $${aliasedVarNames.roomAttributeIds}: [bigint!]!,
        ){
          ${alias ? alias + ': ' : ''} delete_roomAttributes(where: {
            id: {
          _in:  $${aliasedVarNames.roomAttributeIds}
        }}){
            affected_rows
            returning {
                id
            }
        }
        }
        
`,
  }
}

interface CreateRoomAttributeInsertMultipleFragment {
  alias?: string
  attributeGroupId: StateId
  objects: RoomAttributes_Insert_Input[]
}
function createRoomAttributeInsertMultipleFragment(
  props: CreateRoomAttributeInsertMultipleFragment
) {
  const { alias, objects } = props

  const aliasedVarNames = {
    objects: `insert_roomAttributes_objects_${alias}`,
  }

  return {
    variables: {
      [aliasedVarNames.objects]: objects,
    },
    gql: gql`
        mutation (
          $${aliasedVarNames.objects}: [roomAttributes_insert_input!]!,
        ){
          ${alias ? alias + ': ' : ''} insert_roomAttributes(objects: $${
            aliasedVarNames.objects
          }){
        affected_rows
        returning{
        attributeType
        order
        config
        createdAt
        description
        id
        name
        updatedAt
        roomAttributeGroupId
        roomAttributeFiles {
          # downloadUrl
          fileId
          file {
            id
            size
            name
            extension
          }
        }
        }
    }
        }
`,
  }
}

interface CreateDeleteRoomAttributeGroupsFragment {
  roomAttributeGroups: ConfigRoomAttributeGroup[]
}
export function createDeleteRoomAttributeGroupsFragment(
  props: CreateDeleteRoomAttributeGroupsFragment
) {
  const { roomAttributeGroups } = props

  const deletedAttrGroupIds = roomAttributeGroups.map(d => d.id)

  const aliasedVarNames = {
    deletedAttrGroupIds: `deletedAttrGroupIds`,
  }

  return {
    variables: {
      [aliasedVarNames.deletedAttrGroupIds]: deletedAttrGroupIds,
    },
    gql: gql`
        mutation (
          $${aliasedVarNames.deletedAttrGroupIds}: [bigint!]!,
        ){
          
      delete_roomAttributeGroups(where: {
        id: {_in: $${aliasedVarNames.deletedAttrGroupIds}}
      }){
        affected_rows
        returning {
          id
        }
      }
      
        }
`,
  }
}
