import styled from '@emotion/styled'
import orgaNiceLogo from 'assets/svg/organice_logo.svg'

const WIDTH = 169
const HEIGHT = 39
const DEFAULT_FACTOR = 1

export interface OrgaNiceLogoProps {
  factor?: number
}

export const OrgaNiceLogo = styled.div<OrgaNiceLogoProps>(
  ({ factor = DEFAULT_FACTOR }) => ({
    background: `url("${orgaNiceLogo}")`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: WIDTH * factor,
    height: HEIGHT * factor,
  })
)
