import React from 'react'
import styled from '@emotion/styled'
import DragIconVertical from '@organice/icons/drag_indicator_vertical.svg?react'
import { Tabs } from 'antd'

export interface ConfigRoomTabProps {
  tempId: number
  onClick?(): void
  onOverrideClick?(): void
  icon?: React.ReactNode
  active?: boolean
  draggable?: boolean
  className?: string
  ref?: any
}

export const ConfigRoomTab: React.FC<ConfigRoomTabProps> = React.forwardRef<
  HTMLButtonElement,
  ConfigRoomTabProps
>(
  (
    {
      tempId,
      children,
      onClick,
      onOverrideClick,
      draggable = true,
      icon = <DragIconVertical />,
      active,
      className,
    },
    ref
  ) => {
    return (
      <TabButton active={!!active} className={className} ref={ref}>
        <TabButtonInnerContainer>
          <IconContainer
            active={!!active}
            className={draggable ? 'moduleHandle' : undefined}
          >
            {icon}
          </IconContainer>
          {children}
        </TabButtonInnerContainer>
      </TabButton>
    )
  }
)

const TabButtonInnerContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const IconContainer = styled.span<{ active: boolean }>(({ theme, active }) => ({
  display: 'inline-flex',
  marginRight: '.5rem',
  cursor: 'grab',

  '&:active': {
    cursor: 'grabbing',
  },

  '& path': {
    fill: active ? theme.primaryColor : theme.textColor,
  },
}))

const TabButton = styled.button<{ active: boolean }>(({ theme, active }) => ({
  borderWidth: '0 0 2px 0',
  borderColor: active ? theme.primaryColor : 'transparent',
  borderStyle: 'solid',
  backgroundColor: 'transparent',
  padding: '0.5rem',
  fontWeight: 'bold',
  color: active ? theme.primaryColor : theme.textColor,
  cursor: active ? 'default' : 'pointer',
  whiteSpace: 'nowrap',
}))
