import React from 'react'
import { Typography } from 'antd'
import { TextProps as AntdTextProps } from 'antd/lib/typography/Text'
import styled from '@emotion/styled'

export interface TextProps extends AntdTextProps {
  error?: boolean
  disabled?: boolean
}

// the error prop needs to be filtered so it doesn't get passed to the DOM element
// https://github.com/styled-components/styled-components/issues/1198#issuecomment-425650423

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Text = styled(({ error, disabled, ...props }: TextProps) => (
  <Typography.Text {...props} />
))<Pick<TextProps, 'error' | 'disabled'>>(props => ({
  color: props.error
    ? props.theme.errorColor
    : props.disabled
      ? props.theme.textColorLight
      : undefined,
}))
