import React from 'react'
import { isLocalStorageEnabled } from './localstorage-cookie-detection'

export const lazyWithRefresh = (componentImport: any) => {
  const storageEnabled = isLocalStorageEnabled()

  return React.lazy(async () => {
    const pageHasBeenRefreshed = JSON.parse(
      storageEnabled
        ? window.localStorage.getItem('organice-forced-refresh') || 'false'
        : 'false'
    )

    try {
      const component = await componentImport()
      if (storageEnabled) {
        window.localStorage.setItem('organice-forced-refresh', 'false')
      }
      return component
    } catch (error) {
      if (!pageHasBeenRefreshed && storageEnabled) {
        window.localStorage.setItem('organice-forced-refresh', 'true')
        return window.location.reload()
      }
      // Page refresh is not helping > throw Error
      throw error
    }
  })
}
