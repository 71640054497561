import React from 'react'
import { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'
import {
  RangePickerBaseProps,
  RangePickerDateProps,
  RangePickerTimeProps,
} from 'antd/es/date-picker/generatePicker'
import 'antd/es/date-picker/style/index'

export const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

type DateExtensionObject = {
  type: 'add' | 'subtract'
  amount: number
  unit: 'day'
}

type RangeExtensionObject = {
  startDate?: DateExtensionObject
  endDate?: DateExtensionObject
}

type LimitRange = {
  parentRange?: [Dayjs, Dayjs]
  limitToParentRange?: boolean | RangeExtensionObject
  showTime?: any // TODO: Check why showTime is missing in AntDProps
}

export type RangePickerProps =
  | (RangePickerBaseProps<Dayjs> & LimitRange)
  | (RangePickerDateProps<Dayjs> & LimitRange)
  | (RangePickerTimeProps<Dayjs> & LimitRange)

/*
Unfoturnately defaultPickerValue is not working when showTime is set
https://github.com/ant-design/ant-design/issues/30005
The problem should be fixed in the next release of antd (4.16.14)
*/

function extendDate(
  date: Dayjs,
  extension: DateExtensionObject | undefined | boolean
) {
  if (!extension || typeof extension === 'boolean') return date

  switch (extension.type) {
    case 'add':
      return date.add(extension.amount, extension.unit)
      break

    case 'subtract':
      return date.subtract(extension.amount, extension.unit)
      break
    default:
      return date
  }
}

export const RangePickerExtended: React.FC<RangePickerProps> = props => {
  const { parentRange, limitToParentRange = false, showTime } = props
  const rangePickerRef = React.useRef<any>()

  if (parentRange) {
    // if showTime: always set startDate (00:00:00)  and endDate (23:59:59) to full days because it is compared to now
    // otherwise the last day would depend on the time of day a user opens the date picker
    const startDate = parentRange[0]
    const endDate = parentRange[1]

    return (
      <DatePicker.RangePicker
        ref={rangePickerRef}
        disabledDate={currentDate => {
          if (!startDate || !endDate || !limitToParentRange) {
            return false
          }
          const startDateFullDay = parentRange[0].hour(0).minute(0).second(0)
          const endDateFullDay = parentRange[1].hour(23).minute(59).second(59)

          const extendedStartDate =
            typeof limitToParentRange === 'boolean'
              ? startDateFullDay
              : extendDate(startDateFullDay, limitToParentRange?.startDate)

          const extendedEndDate =
            typeof limitToParentRange === 'boolean'
              ? endDateFullDay
              : extendDate(endDateFullDay, limitToParentRange?.endDate)

          if (
            currentDate.isSameOrAfter(extendedStartDate) &&
            currentDate.isSameOrBefore(extendedEndDate)
          ) {
            return false
          }

          return true
        }}
        dateRender={(currentDate, today, info) => {
          const style = { border: 'none' }
          if (startDate && endDate) {
            if (
              currentDate.isSameOrAfter(startDate) &&
              currentDate.isSameOrBefore(endDate)
            ) {
              style.border = '1px solid #ddd'
            }
          }

          return (
            <div className="ant-picker-cell-inner" style={style}>
              {currentDate.date()}
            </div>
          )
        }}
        defaultPickerValue={[startDate, endDate]}
        panelRender={panelNode => {
          return panelNode
        }}
        {...props}
      />
    )
  }
  return <DatePicker.RangePicker {...props} />
}
