import React from 'react'
import styled from '@emotion/styled'
import { Global, useTheme } from '@emotion/react'
import { HeaderProps, useHeader } from '@organice/organisms/header'
import { MENU_WIDTH_PX } from '../sidebar'

export interface LayoutProps {
  headerProps?: HeaderProps
  hasSideBar?: boolean
  children?: React.ReactNode
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  headerProps,
  hasSideBar = false,
}) => {
  const theme = useTheme()
  const { setHeaderProps } = useHeader()

  React.useEffect(() => {
    setHeaderProps(headerProps)
  }, [headerProps])

  const GLOBAL_STYLES = `
  svg [class$="path"]{
    fill:${theme.iconBaseColor};
  }

  svg [class$="shape"]{
    fill:${theme.iconShadeColor};
  }

  button:hover svg [class$="path"]{
    fill:${theme.iconActiveBaseColor};
  }

  button:hover svg [class$="shape"]{
    fill:${theme.iconActiveShadeColor};
  }
  
`

  return (
    <LayoutContainer>
      <Global styles={GLOBAL_STYLES} />
      {children}
    </LayoutContainer>
  )
}

const LayoutContainer = styled.div({
  display: 'flex',
  height: '100%',
  width: '100%',
})

const ContentSidebarContainer = styled.div({
  paddingLeft: MENU_WIDTH_PX,
})
