import React from 'react'
import { Dayjs } from 'dayjs'
import { Button } from 'antd'
import { TimeUtil } from '@organice/utils/date'
import { dateConfig } from '@organice/config'
import { DateType } from '../../../types/service'
import { useTranslation } from 'react-i18next'
import { isRangeType } from './utils'
import type {
  TargetField,
  SourceField,
  DateSuggestionOptions,
  DateVariants,
} from './types'

const dayjs = TimeUtil.getDayjs()

export const DateTimeRangeSuggestion: React.FC<{
  sourceField: SourceField
  dateVariants: DateVariants | DateVariants[]
  targetField?: TargetField
  onSelect: (selected: DateSuggestionOptions) => void
}> = ({ sourceField, onSelect, targetField, dateVariants }) => {
  const { t } = useTranslation()
  const [startDate, startTimeDate, endTimeDate] = sourceField.value
  const endDate = dayjs(startDate)
    .hour(dayjs(endTimeDate || startTimeDate).hour())
    .minute(dayjs(endTimeDate || startTimeDate).minute())
  const targetIsRepeatable = targetField?.config.repeatable || false
  const targetIsRangeType = isRangeType(targetField?.config.dateType)

  const arrayWrapper = (output: Dayjs | Dayjs[]) => {
    if (targetIsRepeatable) return [output]
    return output
  }

  const selectDate = (selected: Dayjs | Dayjs[]) => {
    onSelect(arrayWrapper(selected))
  }

  const dateToString = (date: Dayjs, timeOnly = false) => {
    if (timeOnly) {
      return dayjs(date).format(dateConfig.TIME_FORMAT)
    }

    return dayjs(date).format(
      `${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT}`
    )
  }

  if (targetIsRangeType) {
    return (
      <div style={{ marginLeft: targetIsRangeType ? 0 : 14 }}>
        <Button
          type="link"
          onClick={() =>
            selectDate(targetIsRangeType ? [startDate, endDate] : startDate)
          }
        >
          {dateToString(startDate)} - {dateToString(endDate, true)}
        </Button>
      </div>
    )
  }

  if (targetField?.config.dateType === DateType.DATE_TIME_RANGE) {
    return (
      <div style={{ marginLeft: targetIsRangeType ? 0 : 14 }}>
        <Button
          type="link"
          onClick={() => selectDate([startDate, startDate, endDate])}
        >
          {dateToString(startDate)} - {dateToString(endDate, true)}
        </Button>
      </div>
    )
  }

  return (
    <div style={{ marginLeft: targetIsRangeType ? 0 : 14 }}>
      <Button type="link" onClick={() => selectDate(startDate)}>
        {dateToString(startDate)}
      </Button>{' '}
      -{' '}
      <Button type="link" onClick={() => selectDate(endDate)}>
        {dateToString(endDate, true)}
      </Button>
    </div>
  )
}
