import React from 'react'
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/react'
import { Text, Heading } from '@organice/atoms'
import { Events } from '@organice/graphql'
import { Row, Col, Tag } from 'antd'
import { useHistory } from '@organice/router'
import { TimeUtil } from '@organice/utils/date'
import { useEventMutations } from '@organice/utils/hooks'
import {
  StarOutlined,
  StarFilled,
  RightOutlined as RightOutlinedAntd,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const dayjs = TimeUtil.getDayjs()

export interface EventListEntryProps {
  event: Events | null
  onRowClick?: (event: Events) => void
  onStarClick?: (event: Events) => void
}

export const EventListEntry: React.FC<EventListEntryProps> = ({
  event,
  onRowClick,
  onStarClick,
}) => {
  if (!event) return null

  const { t } = useTranslation()
  const history = useHistory()
  const isSubEvent = event?.parentId
  const { toggelFavourite } = useEventMutations()

  function defaultOnRowClickHandler(e: React.MouseEvent): void {
    if (!event) return
    const eventPath = isSubEvent
      ? `/events/${event.parentId}/${event.id}`
      : `/events/${event.id}`
    history.push(eventPath)
  }

  function defaultOnStarClickkHandler(e: React.MouseEvent): void {
    toggelFavourite(event)
  }

  const subEvents = event?.events

  return (
    <EventListEntryContainer>
      <Row
        onClick={
          onRowClick
            ? () => {
                onRowClick(event)
              }
            : defaultOnRowClickHandler
        }
      >
        <Col xs={{ span: 19, order: 2 }} md={{ span: 22, order: 1 }}>
          <Row align="middle" gutter={[16, 0]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 10 }}
              xl={{ span: 12 }}
            >
              <EventTitleWrpapper>
                <Heading level={isSubEvent ? 3 : 2}>
                  {isSubEvent && <RightOutlined />}
                  <Text>{event?.name}</Text>
                </Heading>
              </EventTitleWrpapper>
              {!isSubEvent && <SubLine>{event?.subTitle}</SubLine>}
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 7 }}
              xl={{ span: 6 }}
            >
              <EventDate css={event.isCanceled && isCancelledStyle}>
                {TimeUtil.getRangeString(
                  dayjs(event.startDate),
                  dayjs(event.endDate),
                  true
                )}
              </EventDate>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 7 }}
              xl={{ span: 6 }}
            >
              <TagsGroup>
                {event.isCanceled ? (
                  <Tag color="brown">{t('events.statusCancelled')} </Tag>
                ) : (
                  <React.Fragment>
                    {event?.eventAttributeValues?.map(v =>
                      v?.value?.value.map((tag: string, i: number) => (
                        <Tag key={`tag-${event.id}-${i}`}>{tag}</Tag>
                      ))
                    )}
                  </React.Fragment>
                )}
              </TagsGroup>
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ order: 1, flex: 'auto' }}
          md={{ span: 2, order: 3 }}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <FavouriteMarker
            onClick={e => {
              e.stopPropagation()

              if (onStarClick) {
                onStarClick(event)
                return
              }
              defaultOnStarClickkHandler(e)
            }}
          >
            {event?.isFavourite ? <StarFilled /> : <StarOutlined />}
          </FavouriteMarker>
        </Col>
      </Row>

      {subEvents?.map(subEvent => (
        <Row key={`subEvent-${subEvent.id}`}>
          <SubEventContainer className="subEvent">
            <EventListEntry
              event={subEvent}
              onRowClick={onRowClick}
              onStarClick={onStarClick}
            />
          </SubEventContainer>
        </Row>
      ))}
    </EventListEntryContainer>
  )
}

const EventTitleWrpapper = styled.div`
  .ant-typography {
    color: ${props => props.theme.grey1};
    margin-bottom: 0;
    font-weight: 500;
  }
`

const SubLine = styled(Text)`
  display: block;
  color: ${props => props.theme.grey4};
`

const EventDate = styled(Text)`
  background-color: ${props => props.theme.grey3};
  color: ${props => props.theme.grey2};
  border-radius: ${props => props.theme.borderRadiusBase};
  padding: 5px;
  display: inline-block;
  margin: 10px 0;
  font-weight: 500;
`

const isCancelledStyle = css`
  text-decoration: line-through;
`

const EventListEntryContainer = styled.div`
  > .ant-row:first-of-type {
    border-bottom: 1px solid ${props => props.theme.grey6};
    padding: 11px 20px;

    @media screen and (max-width: 768px) {
      padding: 10px;
    }
  }

  .subEvent {
    .ant-row:first-of-type {
      padding-top: 0;
      padding-bottom: 0;

      @media screen and (max-width: 768px) {
        padding: 5px 10px;
      }
    }
  }

  display: flex;
  flex-wrap: wrap;
  cursor: pointer;

  > .ant-row {
    width: 100%;
  }

  & .ant-row:hover {
    background-color: ${props => props.theme.lightGreyBackground};
  }
`

const FavouriteMarker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 32px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 768px) {
    margin: 7px 20px 7px 0;
    align-self: flex-start;
  }

  > .anticon {
    color: ${props => props.theme.grey4};
  }
`

const SubEventContainer = styled.div`
  width: 100%;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
    font-weight: bold;
  }
  .ant-collapse {
    border: none;
    background: transparent;
  }
  .ant-collapse > .ant-collapse-item {
    border: none;
  }
`

const RightOutlined = styled(RightOutlinedAntd)`
  color: ${props => props.theme.grey4};
  font-size: 14px;
  margin-right: 14px;
`
const TagsGroup = styled.div`
  .ant-tag {
    margin-bottom: 8px;
    white-space: normal;
  }
`
