import React from 'react'
import { SwapRightOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Dayjs } from 'dayjs'
import { DatePickerAutoConfirm } from './datepicker-autoconfirm'
import { Global, css, useTheme } from '@emotion/react'
import {
  //   RangePickerBaseProps,
  //   RangePickerDateProps,
  //   RangePickerTimeProps,
  RangePickerProps,
} from 'antd/es/date-picker/generatePicker'
import { valuesAreEqual } from '@organice/utils/date/date-values-equal'

type DateValue = Dayjs | undefined | null

type AddedProps = {
  parentRange?: [Dayjs, Dayjs]
  showTime?: any // TODO: Check why showTime is missing in AntDProps
  format?: string
  inputFormat?: string
  displayFormat?: string
  onChange?: (values: [DateValue, DateValue]) => void
  minuteStep?: number
}

type RangePickerAutoConfirm = RangePickerProps<Dayjs> & AddedProps

export const RangePickerAutoConfirm: React.FC<
  RangePickerAutoConfirm
> = props => {
  const {
    parentRange,
    inputFormat,
    displayFormat,
    format,
    minuteStep = 15,
    defaultValue,
  } = props

  const parentStartDate = parentRange && parentRange[0]
  const parentEndDate = parentRange && parentRange[1]

  const defaultPickerValue = parentRange
    ? [parentStartDate, parentEndDate]
    : undefined

  const [initialized, setInitialized] = React.useState<boolean>(false)
  const [state, setState] = React.useState<[DateValue, DateValue]>(
    props.value || props.defaultValue || [undefined, undefined]
  )

  React.useEffect(() => {
    if (props.value) {
      if (!valuesAreEqual(props.value, state)) {
        setState(props.value)
      }
    }
  }, [props.value])

  const [startDatePanelOpen, setStartDatePanelOpen] =
    React.useState<boolean>(false)
  const [endDatePanelOpen, setEndDatePanelOpen] = React.useState<boolean>(false)

  const theme = useTheme()

  const startDate = state[0]
  const endDate = state[1]

  const startDateInputRef = React.useRef<HTMLInputElement>(null)
  const endDateInputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (initialized) {
      props.onChange && props.onChange(state)
    }
    setInitialized(true)
  }, [state])

  function renderDateCell(currentDay: Dayjs) {
    let inParentRange = false
    if (parentStartDate && parentEndDate) {
      if (
        currentDay.isSameOrAfter(parentStartDate, 'day') &&
        currentDay.isSameOrBefore(parentEndDate, 'day')
      ) {
        inParentRange = true
      }
    }

    if (startDate) {
      if (currentDay.isSame(startDate, 'day')) {
        return (
          <div
            className={`ant-picker-cell-inner ant-picker-cell-inner--start-date ${
              inParentRange ? 'ant-picker-cell-inner--in-parent-range' : ''
            }`}
          >
            {currentDay.format('D')}
          </div>
        )
      }
    }

    if (endDate) {
      if (currentDay.isSame(endDate, 'day')) {
        return (
          <div
            className={`ant-picker-cell-inner ant-picker-cell-inner--end-date ${
              inParentRange ? 'ant-picker-cell-inner--in-parent-range' : ''
            }`}
          >
            {currentDay.format('D')}
          </div>
        )
      }
    }

    if (startDate && endDate) {
      if (currentDay.isBetween(startDate, endDate)) {
        return (
          <div
            className={`ant-picker-cell-inner ant-picker-cell-inner--date-in-range ${
              inParentRange ? 'ant-picker-cell-inner--in-parent-range' : ''
            }`}
          >
            {currentDay.format('D')}
          </div>
        )
      }
    }

    return (
      <div
        className={`ant-picker-cell-inner ${
          inParentRange ? 'ant-picker-cell-inner--in-parent-range' : ''
        }`}
      >
        {currentDay.format('D')}
      </div>
    )
  }

  return (
    <>
      <DateRangeWrapper
        className={`${
          startDatePanelOpen || endDatePanelOpen ? 'is-focused' : ''
        }`}
      >
        <StyledDatePickerAutoConfirm
          minuteStep={minuteStep}
          defaultPickerValue={parentStartDate}
          suffixIcon={<SwapRightOutlined />}
          ref={startDateInputRef}
          autoConfirm
          value={state[0]}
          onChange={value => {
            setState([value, endDate])
            if (props.showTime) return

            if (value && !endDate) {
              endDateInputRef.current && endDateInputRef.current.focus()
              return
            }

            startDateInputRef.current && startDateInputRef.current.blur()
          }}
          disabledDate={currentDate =>
            endDate ? currentDate.isAfter(endDate, 'day') : false
          }
          onOk={() => {
            if (startDate && !endDate) {
              endDateInputRef.current && endDateInputRef.current.focus()
              return
            }

            startDateInputRef.current && startDateInputRef.current.blur()
          }}
          dateRender={renderDateCell}
          open={startDatePanelOpen}
          onFocus={() => setStartDatePanelOpen(true)}
          onOpenChange={open => setStartDatePanelOpen(open)}
          onBlur={() => {
            if (startDate && !endDate) {
              endDateInputRef.current && endDateInputRef.current.focus()
            }
          }}
          format={
            startDatePanelOpen ? inputFormat || format : displayFormat || format
          }
          showTime={props.showTime}
          disabledHours={() => {
            if (startDate && endDate && startDate.isSame(endDate, 'day')) {
              const h = endDate.hour()
              const disbaledHours = Array.from(
                { length: 24 - h },
                (_, i) => i
              ).map(i => 24 - i)
              return disbaledHours
            }
            return []
          }}
          disabledMinutes={() => {
            if (startDate && endDate && startDate.isSame(endDate, 'day')) {
              const endDateHour = endDate.hour()
              if (endDateHour === startDate.hour()) {
                const m = endDate.minute()
                const disbaledMinutes = Array.from(
                  { length: 59 - m },
                  (_, i) => i
                ).map(i => 59 - i)
                return disbaledMinutes
              }
            }
            return []
          }}
        />
        <StyledDatePickerAutoConfirm
          minuteStep={minuteStep}
          defaultPickerValue={parentEndDate}
          ref={endDateInputRef}
          autoConfirm
          value={state[1]}
          onChange={value => {
            setState([startDate, value])
            if (props.showTime) return
            if (value && !startDate) {
              startDateInputRef.current && startDateInputRef.current.focus()
              return
            }
            endDateInputRef.current && endDateInputRef.current.blur()
          }}
          disabledDate={currentDate =>
            startDate ? currentDate.isBefore(startDate, 'day') : false
          }
          disabledHours={() => {
            if (startDate && endDate && startDate.isSame(endDate, 'day')) {
              const h = startDate.hour()
              const disbaledHours = Array.from({ length: h }, (_, i) => i).map(
                i => i
              )
              return disbaledHours
            }
            return []
          }}
          disabledMinutes={() => {
            if (startDate && endDate && startDate.isSame(endDate, 'day')) {
              const startDateHour = startDate.hour()
              if (startDateHour === endDate.hour()) {
                const m = startDate.minute()
                const disbaledMinutes = Array.from(
                  { length: m },
                  (_, i) => i
                ).map(i => i)
                return disbaledMinutes
              }
            }
            return []
          }}
          dateRender={renderDateCell}
          open={endDatePanelOpen}
          onFocus={() => setEndDatePanelOpen(true)}
          onOpenChange={open => setEndDatePanelOpen(open)}
          onBlur={() => {
            if (endDate && !startDate) {
              startDateInputRef.current && startDateInputRef.current.focus()
              return
            }
            endDateInputRef.current && endDateInputRef.current.blur()
          }}
          onOk={() => {
            if (!startDate) {
              startDateInputRef.current && startDateInputRef.current.focus()
            }
          }}
          format={
            endDatePanelOpen ? inputFormat || format : displayFormat || format
          }
          showTime={props.showTime}
        />
      </DateRangeWrapper>

      <Global
        styles={css`
          .ant-picker-cell-in-view.ant-picker-cell-selected
            .ant-picker-cell-inner,
          .ant-picker-cell-in-view.ant-picker-cell-range-start
            .ant-picker-cell-inner,
          .ant-picker-cell-in-view.ant-picker-cell-range-end
            .ant-picker-cell-inner {
            background-color: transparent;
            &:after {
              content: '';
              width: 120%;
              height: 120%;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: ${theme.primaryColor};
              z-index: -1;
            }
          }

          .ant-picker-cell-inner {
            &--start-date,
            &--end-date {
              //   background-color: ${theme.primaryColor};
              color: #fff;
              position: relative;
              z-index: 1;

              &:after {
                content: '';
                width: 120%;
                height: 120%;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: ${theme.primaryColor};
                z-index: -1;
              }
            }
            &--date-in-range {
              //   background-color: ${theme.primaryColor}50;
              &:after {
                content: '';
                width: 120%;
                height: 120%;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: ${theme.primaryColor}50;
                z-index: -1;
              }
            }

            &--in-parent-range:after {
              content: '';
              width: 120%;
              height: 120%;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              border: 1px dashed ${theme.grey1}30;
            }
          }
        `}
      />
    </>
  )
}

const DateRangeWrapper = styled.div`
  display: flex;
  flex-grow: 1;

  &.is-focused {
    border: none;
  }

  .ant-picker:first-of-type {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .ant-picker-suffix {
      margin-right: -5px;
    }
  }
  .ant-picker:last-of-type {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`
const StyledDatePickerAutoConfirm = styled(DatePickerAutoConfirm)`
  width: 50%;
`
