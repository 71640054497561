import React from 'react'
import styled from '@emotion/styled'
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { useTheme } from '@emotion/react'
import { Text } from '../text'
import { TimeUtil } from '@organice/utils/date'
export interface ActiveStateProps {
  deactive?: boolean | string
}

export const ActiveState: React.FC<ActiveStateProps> = ({
  deactive = false,
}) => {
  const theme = useTheme()
  return (
    <Text>
      {!deactive ? (
        <StateText style={{ color: theme.successColor }}>
          <CheckCircleOutlined style={{ fontSize: '18px' }} /> Aktiv
        </StateText>
      ) : (
        <StateText style={{ color: theme.errorColor }}>
          <CloseCircleOutlined style={{ fontSize: '18px' }} /> Deaktiviert{' '}
          {typeof deactive === 'string' &&
            ` am ${TimeUtil.get(deactive).format('LLL [Uhr]')}`}
        </StateText>
      )}
    </Text>
  )
}

const StateText = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  '.anticon': { marginRight: '5px' },
})
