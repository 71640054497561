import React from 'react'
import {
  useAddUploadMutation,
  AddUploadMutationOptions,
  useDeleteUploadsMutation,
  DeleteUploadsMutationOptions,
  useDeleteUploadVersionsMutation,
  DeleteUploadVersionsMutationOptions,
  useDeleteAttributeValueFileMutation,
  useDeleteAttributeFileMutation,
  DeleteAttributeValueFileMutation,
  DeleteAttributeValueFileMutationOptions,
  DeleteAttributeFileMutationOptions,
  DeleteAttributeFileMutation,
  Uploads,
  UploadVersions,
  Files,
  AddUploadMutation,
} from '@organice/graphql'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'
import { FetchResult, ApolloError } from '@apollo/client'

type UseUploadReturn = {
  addUpload(
    options: AddUploadMutationOptions
  ): Promise<
    FetchResult<AddUploadMutation, Record<string, any>, Record<string, any>>
  >
  deleteUpload(options: DeleteUploadsMutationOptions): void
  deleteUploadVersion(options: DeleteUploadVersionsMutationOptions): void
  deleteAttributeValueFile(
    options: DeleteAttributeValueFileMutationOptions
  ): Promise<
    FetchResult<
      DeleteAttributeValueFileMutation,
      Record<string, any>,
      Record<string, any>
    >
  >
  deleteAttributeFile(
    options: DeleteAttributeFileMutationOptions
  ): Promise<
    FetchResult<
      DeleteAttributeFileMutation,
      Record<string, any>,
      Record<string, any>
    >
  >

  addLoading: boolean
  addError: ApolloError | undefined

  deleteLoading: boolean
  deleteError: ApolloError | undefined

  deleteUploadVersionsLoading: boolean
  deleteUploadVersionsError: ApolloError | undefined

  deleteAttributeValueFileLoading: boolean
  deleteAttributeValueFileError: ApolloError | undefined

  deleteAttributeFileLoading: boolean
  deleteAttributeFileError: ApolloError | undefined
}

interface UploadMutationCallbacks {
  disabledNotifications?: boolean
  onAdded?(upload?: Uploads): void
  onDeleted?(upload?: Uploads): void
  onDeletedUploadVersion?(upload?: UploadVersions): void
  onFileDeleted?(file?: Files): void
}

export function useUploadMutations(
  callbacks?: UploadMutationCallbacks
): UseUploadReturn {
  const { t } = useTranslation()

  /**************/
  /* ADD UPLOAD */
  /**************/
  const [addUpload, { loading: addLoading, error: addError, data: addData }] =
    useAddUploadMutation()

  React.useEffect(() => {
    if (addData) {
      if (!callbacks?.disabledNotifications) {
        notification.success({
          duration: 3,
          message: t('uploads.actions.uploadsAdded'),
        })
      }
      if (callbacks?.onAdded)
        callbacks.onAdded(addData.insert_uploads_one as Uploads)
    }
  }, [addData])

  /*************************/
  /* DELETE UPLOADVERSIONS */
  /*************************/
  const [
    deleteUploadVersion,
    {
      loading: deleteUploadVersionsLoading,
      error: deleteUploadVersionsError,
      data: deleteUploadVersionsData,
    },
  ] = useDeleteUploadVersionsMutation()

  React.useEffect(() => {
    // deleteUploadVersionsData.delete_uploadVersions_by_pk means it failed
    if (
      deleteUploadVersionsData &&
      deleteUploadVersionsData.delete_uploadVersions_by_pk == null
    ) {
      notification.error({
        duration: 3,
        message: t('uploads.actions.uploadsDeletedError'),
      })
      return
    }

    if (deleteUploadVersionsData) {
      notification.success({
        duration: 3,
        message: t('uploads.actions.uploadsDeleted'),
      })
      if (callbacks?.onDeletedUploadVersion)
        callbacks.onDeletedUploadVersion(
          deleteUploadVersionsData.delete_uploadVersions_by_pk as UploadVersions
        )
    }
  }, [deleteUploadVersionsData])

  /***************/
  /* DELETE UPLOAD */
  /***************/
  const [
    deleteUpload,
    { loading: deleteLoading, error: deleteError, data: deleteData },
  ] = useDeleteUploadsMutation()

  React.useEffect(() => {
    // deleteData.delete_uploads_by_pk == null means it failed
    if (deleteData && deleteData.delete_uploads_by_pk == null) {
      notification.error({
        duration: 3,
        message: t('uploads.actions.uploadsDeletedError'),
      })
      return
    }

    if (deleteData && !callbacks?.disabledNotifications) {
      notification.success({
        duration: 3,
        message: t('uploads.actions.uploadsDeleted'),
      })
      if (callbacks?.onDeleted)
        callbacks.onDeleted(deleteData.delete_uploads_by_pk as Uploads)
    }
  }, [deleteData])

  /***************/
  /* DELETE ATTRIBUTE VALUE FILE */
  /***************/
  const [
    deleteAttributeValueFile,
    {
      loading: deleteAttributeValueFileLoading,
      error: deleteAttributeValueFileError,
      data: deleteAttributeValueFileData,
    },
  ] = useDeleteAttributeValueFileMutation()

  React.useEffect(() => {
    if (deleteAttributeValueFileData) {
      const deletedFile =
        deleteAttributeValueFileData?.delete_attributeValueFiles_by_pk

      if (deletedFile) {
        notification.success({
          duration: 3,
          message: t('uploads.actions.uploadsDeleted'),
        })
        return
      }

      console.warn('delete_attributeValueFiles_by_pk return is null')
      notification.error({
        duration: 3,
        message: t('uploads.actions.uploadsDeletedError'),
      })
      //  if (callbacks?.onDeleted)
      //   callbacks.onDeleted(deletedFile)
    }
  }, [deleteAttributeValueFileData])

  /***************/
  /* DELETE ATTRIBUTE FILE (like deleteAttributeValueFile for configuration mode when there is no attributeValueId) */
  /***************/

  const [
    deleteAttributeFile,
    {
      loading: deleteAttributeFileLoading,
      error: deleteAttributeFileError,
      data: deleteAttributeFileData,
    },
  ] = useDeleteAttributeFileMutation()

  React.useEffect(() => {
    if (deleteAttributeFileData) {
      const deletedFile = deleteAttributeFileData?.delete_attributeFiles_by_pk

      if (deletedFile) {
        notification.success({
          duration: 3,
          message: t('uploads.actions.uploadsDeleted'),
        })
        return
      }

      console.warn('delete_attributeFiles_by_pk return is null')
      notification.error({
        duration: 3,
        message: t('uploads.actions.uploadsDeletedError'),
      })
      //  if (callbacks?.onDeleted)
      //   callbacks.onDeleted(deletedFile)
    }
  }, [deleteAttributeFileData])

  /**********/
  /* ERRORS */
  /**********/
  React.useEffect(() => {
    if (addError) {
      notification.error({
        duration: 3,
        message: addError?.toString(),
      })
    }
  }, [addError])

  React.useEffect(() => {
    const error =
      deleteError || deleteUploadVersionsError || deleteAttributeValueFileError
    if (error) {
      notification.error({
        duration: 3,
        message: error?.toString(),
      })
    }
  }, [deleteError, deleteUploadVersionsError, deleteAttributeValueFileError])

  return {
    addUpload,
    addLoading,
    addError,
    deleteUpload,
    deleteLoading,
    deleteError,
    deleteUploadVersion,
    deleteUploadVersionsLoading,
    deleteUploadVersionsError,
    deleteAttributeValueFile,
    deleteAttributeValueFileLoading,
    deleteAttributeValueFileError,
    deleteAttributeFile,
    deleteAttributeFileLoading,
    deleteAttributeFileError,
  }
}
