import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Divider } from 'antd'
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons'
import { useLogoutMutation } from '@organice/graphql'
import { Link, useHistory } from 'react-router-dom'
import { notification } from 'antd'
import { useMeContext } from '@organice/contexts'

export const AccountMenu = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { logout: logoutMutation } = useMeContext()
  // const [logoutMutation] = useLogoutMutation()

  const logout = async () => {
    const logoutSuccess = await logoutMutation()

    if (logoutSuccess) {
      history.push('/login')
      return
    } else {
      notification.error({
        duration: 3,
        message: t('logout.error'),
      })
    }
  }

  return (
    <ContextMenuList>
      <ContextMenuListItem>
        <Link to={'/account'}>
          <SettingsIcon />
          {t('account.account')}
        </Link>
      </ContextMenuListItem>
      <ContextMenuDivider />
      <ContextMenuListItemLogout onClick={logout}>
        <LogoutIcon /> {t('account.logout')}
      </ContextMenuListItemLogout>
    </ContextMenuList>
  )
}
const ICON_FONT_SIZE = '1.25rem'

const ContextMenuList = styled.ul({
  padding: 0,
  margin: 0,
})

const ContextMenuListItem = styled.li(({ theme }) => ({
  listStyleType: 'none',
  padding: '.5rem',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  transition: 'color .3s',

  '&:hover': {
    backgroundColor: theme.lightBackground,
    color: theme.primaryColor,
  },
}))

const ContextMenuListItemLogout = styled(ContextMenuListItem)(props => ({
  color: props.theme.errorColor,
  '&:hover': {
    color: props.theme.errorColor,
  },
}))

const SettingsIcon = styled(SettingOutlined)(props => ({
  fontSize: ICON_FONT_SIZE,
  marginRight: '.5rem',
}))

const LogoutIcon = styled(LogoutOutlined)(props => ({
  color: props.theme.errorColor,
  fontSize: ICON_FONT_SIZE,
  marginRight: '.5rem',
}))
const ContextMenuDivider = styled(Divider)({ margin: '.5rem 0' })
