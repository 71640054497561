import React, { ComponentType, LazyExoticComponent, ReactNode } from 'react'
import { Spin } from 'antd'
import Icon, { ShoppingCartOutlined } from '@ant-design/icons'
import IconEvents from '@organice/icons/events.svg?react'
import IconUsers from '@organice/icons/users.svg?react'
import IconConfiguration from '@organice/icons/configuration.svg?react'
import { HomeOutlined } from '@ant-design/icons'
import { LoadingIndicator } from '@organice/atoms/loadingIndicator'
import { lazyWithRefresh } from '@organice/utils/general'

import { EmptyLayout } from '../components/layouts/empty-layout'

export interface IRoute {
  title: ReactNode // i118n String | Title String | LoadingIndicator
  path: string
  exact?: boolean
  fallback: NonNullable<ReactNode> | null
  component?: LazyExoticComponent<ComponentType<any>>
  showMenu?: boolean
  hideBreadcrumb?: boolean
  iconMenu?: React.ReactNode
  routes?: IRoute[]
  redirect?: string
  protected?: boolean
  adminOnly?: boolean
  orgaAdminOnly?: boolean
  parentRoute?: IRoute | null
  layout?: React.FC
}

export const routes: IRoute[] = [
  {
    title: <HomeOutlined />,
    path: '/',
    exact: true,
    redirect: '/events',
    protected: true,
    fallback: <LoadingIndicator />,
    routes: [
      {
        title: 'login.title', // i118n
        path: '/login',
        component: lazyWithRefresh(() => import('../pages/login')),
        exact: true,
        protected: false,
        fallback: <LoadingIndicator />,
        layout: EmptyLayout,
      },
      {
        title: 'confidentialityAgreement.heading', // i118n
        path: '/account/confidentiality-agreement',
        component: lazyWithRefresh(
          () => import('../pages/confidentialityAgreement')
        ),
        exact: true,
        protected: true,
        fallback: <LoadingIndicator />,
        layout: EmptyLayout,
      },
      {
        title: 'heading.heading', // i118n
        path: '/forgot-password',
        component: lazyWithRefresh(() => import('../pages/forgotPassword')),
        exact: true,
        protected: false,
        fallback: <LoadingIndicator />,
        layout: EmptyLayout,
      },
      {
        title: 'events.title', // i118n
        path: '/events',
        component: lazyWithRefresh(() => import('../pages/event-index')),
        showMenu: true,
        hideBreadcrumb: true, // Home and /events are the same, so hiding will free space in header bar
        iconMenu: <Icon component={IconEvents} />,
        exact: true,
        protected: true,
        fallback: <LoadingIndicator />,
        routes: [
          {
            title: 'events.newEvent',
            path: '/events/new',
            component: lazyWithRefresh(() => import('../pages/event-new')),
            exact: true,
            protected: true,
            fallback: <LoadingIndicator />,
          },
          {
            title: 'events.title', // ReactNode as title (e.g. Loading Spinner replaced by Item Title after Query)
            path: '/events/export',
            component: lazyWithRefresh(() => import('../pages/events-export')),
            exact: true,
            protected: true,
            fallback: <LoadingIndicator />,
            layout: EmptyLayout,
          },
          {
            title: 'rooms.multiEventMatrixViewTitle', // ReactNode as title (e.g. Loading Spinner replaced by Item Title after Query)
            path: '/events/rooms',
            component: lazyWithRefresh(
              () => import('../pages/multi-event-rooms')
            ),
            exact: true,
            protected: true,
            fallback: <LoadingIndicator />,
          },
          {
            title: <Spin />, // ReactNode as title (e.g. Loading Spinner replaced by Item Title after Query)
            path: '/events/:eventId',
            component: lazyWithRefresh(() => import('../pages/event-detail')),
            exact: true,
            protected: true,
            fallback: <LoadingIndicator />,
            routes: [
              {
                title: 'events.export',
                path: '/events/:eventId/export',
                component: lazyWithRefresh(
                  () => import('../pages/event-export')
                ),
                exact: true,
                protected: true,
                fallback: <LoadingIndicator />,
                layout: EmptyLayout,
              },
              {
                title: 'events.editServices',
                path: '/events/:eventId/edit-services',
                component: lazyWithRefresh(
                  () => import('../pages/event-edit-services')
                ),
                exact: true,
                protected: true,
                fallback: <LoadingIndicator />,
              },
              {
                title: 'events.editServices',
                path: '/events/:eventId/edit-services/:serviceId',
                component: lazyWithRefresh(
                  () => import('../pages/event-service-config')
                ),
                exact: true,
                protected: true,
                fallback: <LoadingIndicator />,
              },
              {
                title: <Spin />,
                path: '/events/:eventId/service/:serviceId/module/:moduleId/attributeValue/:attributeValueId',
                component: lazyWithRefresh(
                  () => import('../pages/event-service')
                ),
                exact: true,
                protected: true,
                fallback: <LoadingIndicator />,
              },
              {
                title: <Spin />,
                path: '/events/:eventId/service/:serviceId/module/:moduleId/attribute/:attributeId',
                component: lazyWithRefresh(
                  () => import('../pages/event-service')
                ),
                exact: true,
                protected: true,
                fallback: <LoadingIndicator />,
              },
              {
                title: <Spin />,
                path: '/events/:eventId/service/:serviceId/module/:moduleId/fileComment/:fileCommentId',
                component: lazyWithRefresh(
                  () => import('../pages/event-service')
                ),
                exact: true,
                protected: true,
                fallback: <LoadingIndicator />,
              },
              {
                title: <Spin />,
                path: '/events/:eventId/service/:serviceId/module/:moduleId',
                component: lazyWithRefresh(
                  () => import('../pages/event-service')
                ),
                exact: true,
                protected: true,
                fallback: <LoadingIndicator />,
              },
              {
                title: <Spin />,
                path: '/events/:eventId/service/:serviceId',
                component: lazyWithRefresh(
                  () => import('../pages/event-service')
                ),
                exact: true,
                protected: true,
                fallback: <LoadingIndicator />,
              },
              {
                title: 'events.newSubEvent',
                path: '/events/:eventId/new',
                component: lazyWithRefresh(() => import('../pages/event-new')),
                exact: true,
                protected: true,
                fallback: <LoadingIndicator />,
              },
              {
                title: 'rooms.title', // ReactNode as title (e.g. Loading Spinner replaced by Item Title after Query)
                path: '/events/:eventId/rooms',
                component: lazyWithRefresh(
                  () => import('../pages/event-rooms')
                ),
                exact: true,
                protected: true,
                fallback: <LoadingIndicator />,
              },
              {
                title: 'rooms.title', // ReactNode as title (e.g. Loading Spinner replaced by Item Title after Query)
                path: '/events/:eventId/rooms/export',
                component: lazyWithRefresh(
                  () => import('../pages/event-rooms-export')
                ),
                exact: true,
                protected: true,
                fallback: <LoadingIndicator />,
                layout: EmptyLayout,
              },
              {
                title: 'rooms.title', // ReactNode as title (e.g. Loading Spinner replaced by Item Title after Query)
                path: '/events/rooms/multiexport',
                component: lazyWithRefresh(
                  () => import('../pages/multi-event-rooms-export')
                ),
                exact: true,
                protected: true,
                fallback: <LoadingIndicator />,
                layout: EmptyLayout,
              },
              {
                title: <Spin />, // ReactNode as title (e.g. Loading Spinner replaced by Item Title after Query)
                path: '/events/:eventId/:subEventId',
                component: lazyWithRefresh(
                  () => import('../pages/event-detail')
                ),
                exact: true,
                protected: true,
                fallback: <LoadingIndicator />,
              },
              {
                title: 'rooms.title', // ReactNode as title (e.g. Loading Spinner replaced by Item Title after Query)
                path: '/events/:eventId/:subEventId/rooms',
                component: lazyWithRefresh(
                  () => import('../pages/event-rooms')
                ),
                exact: true,
                protected: true,
                fallback: <LoadingIndicator />,
              },
            ],
          },
        ],
      },
      {
        title: 'configuration.title', // i118n
        path: '/configuration',
        component: lazyWithRefresh(() => import('../pages/configuration')),
        showMenu: true,
        iconMenu: <Icon component={IconConfiguration} />,
        exact: true,
        adminOnly: true,
        protected: true,
        fallback: <LoadingIndicator />,
        routes: [
          {
            title: 'configuration.bundlesTitle',
            path: '/configuration/bundle/:bundleId',
            component: lazyWithRefresh(() => import('../pages/configBundle')),
            fallback: <LoadingIndicator />,
            protected: true,
          },
          {
            title: 'configuration.addNewBundleTitle',
            path: '/configuration/add-new-bundle',
            component: lazyWithRefresh(() => import('../pages/addNewBundle')),
            fallback: <LoadingIndicator />,
            protected: true,
          },
          {
            title: 'configuration.configServiceTitle',
            path: '/configuration/service/:serviceId',
            protected: true,
            component: lazyWithRefresh(() => import('../pages/configService')),
            fallback: <LoadingIndicator />,
          },
          {
            title: 'configuration.addNewServiceTitle',
            path: '/configuration/add-new-service',
            protected: true,
            component: lazyWithRefresh(() => import('../pages/addNewService')),
            fallback: <LoadingIndicator></LoadingIndicator>,
          },
          {
            title: 'configuration.configRoomTitle',
            path: '/configuration/room/:roomId/roomService/:roomServiceId',
            protected: true,
            component: lazyWithRefresh(() => import('../pages/configRoom')),
            fallback: <LoadingIndicator />,
          },
          {
            title: 'configuration.configRoomTitle',
            path: '/configuration/room/:roomId/:tabId',
            protected: true,
            component: lazyWithRefresh(() => import('../pages/configRoom')),
            fallback: <LoadingIndicator />,
          },
          {
            title: 'configuration.configRoomTitle',
            path: '/configuration/room/:roomId',
            protected: true,
            component: lazyWithRefresh(() => import('../pages/configRoom')),
            fallback: <LoadingIndicator />,
          },
          {
            title: 'configuration.configRoomTitle',
            path: '/configuration/room/',
            protected: true,
            component: lazyWithRefresh(
              () => import('../pages/config-room-index')
            ),
            fallback: <LoadingIndicator />,
          },
        ],
      },
      {
        title: 'accounts.title', // i118n
        path: '/accounts',
        component: lazyWithRefresh(() => import('../pages/accounts')),
        showMenu: true,
        iconMenu: <Icon component={IconUsers} />,
        exact: true,
        protected: true,
        orgaAdminOnly: true,
        fallback: <LoadingIndicator />,
        routes: [
          {
            title: 'accounts.users',
            path: '/accounts/users',
            exact: true,
            redirect: '/accounts',
            fallback: <LoadingIndicator />,
            protected: true,
            routes: [
              {
                title: <Spin />,
                path: '/accounts/users/:userId',
                component: lazyWithRefresh(
                  () => import('../pages/user-detail')
                ),
                exact: false,
                protected: true,
                fallback: <LoadingIndicator />,
              },
            ],
          },
          {
            title: 'accounts.organisations',
            path: '/accounts/organisations',
            exact: true,
            redirect: '/accounts',
            fallback: <LoadingIndicator />,
            protected: true,
            routes: [
              {
                title: <Spin />, // ReactNode as title (e.g. Loading Spinner replaced by Item Title after Query)
                path: '/accounts/organisations/:organisationId',
                component: lazyWithRefresh(
                  () => import('../pages/organisation-detail')
                ),
                exact: false,
                protected: true,
                fallback: <LoadingIndicator />,
              },
            ],
          },
        ],
      },
      {
        title: 'account.title', // i118n
        path: '/account',
        component: lazyWithRefresh(() => import('../pages/account')),
        exact: true,
        protected: true,
        fallback: <LoadingIndicator />,
      },
      {
        title: 'account.title', // i118n
        path: '/account/:accountTab',
        component: lazyWithRefresh(() => import('../pages/account')),
        exact: false,
        protected: true,
        fallback: <LoadingIndicator />,
      },
      /* {
        title: 'history.title', // i118n
        path: '/history',
        component: lazyWithRefresh(() => import('../pages/history')),
        exact: true,
        protected: false,
        fallback: <LoadingIndicator />,
      }, */
    ],
  },
  // {
  //   title: 'Gantt View Preview',
  //   fallback: <LoadingIndicator />,
  //   protected: false,
  //   path: '/gantt-preview',
  //   component: lazyWithRefresh(() => import('../pages/ganttViewPreview')),
  // },
  {
    title: 'notifications.title', // i118n
    path: '/notification-messages/:notificationMessageId',
    component: lazyWithRefresh(() => import('../pages/notification-message')),
    exact: false,
    protected: true,
    fallback: <LoadingIndicator />,
    layout: EmptyLayout,
  },
  // {
  //   title: 'orders.title', // i118n
  //   path: '/orders',
  //   component: lazyWithRefresh(() => import('../pages/orders')),
  //   showMenu: true,
  //   hideBreadcrumb: true,
  //   // iconMenu: <ShoppingCartOutlined />,
  //   exact: true,
  //   protected: true,
  //   fallback: <LoadingIndicator />,
  //   routes: [],
  // },
  {
    title: '404',
    fallback: <LoadingIndicator />,
    protected: true,
    path: '/404',
    component: lazyWithRefresh(() => import('../pages/404')),
  },
]
