import { Dayjs } from 'dayjs'
import { Attribute, DateType } from '../../types/service'
import { TimeUtil } from '@organice/utils/date'
const dayjs = TimeUtil.getDayjs()

type ProcessingAttribute = Pick<Attribute, 'id' | 'config' | 'attributeType'>

const prepareDateArrayEntry = (date: Dayjs | undefined) => {
  if (date === undefined || date === null) {
    return
  }

  return date.second(0).utc().format()
}

const combineDates = (date1: Dayjs | undefined, date2: Dayjs | undefined) => {
  if (date1 === undefined) {
    return
  }

  if (date2 === undefined) {
    return date1
  }
  return date1.hour(date2.hour()).minute(date2.minute())
}

const removeInvalidDateArrays = (
  rawDateValuesFromForm: (Dayjs | undefined | Dayjs[])[]
): Dayjs[] => {
  /* Remove arrays containing undefined, null, or arrays of arrays containing undefined and null */

  const preparedValues: Dayjs[] = rawDateValuesFromForm
    .map(singleDateValue => {
      // if 2 dimensional (repeatables: singleDateValue is itself an array) && every item is a dayjs object
      if (
        singleDateValue &&
        Array.isArray(singleDateValue) &&
        singleDateValue.length > 0 &&
        singleDateValue.every(
          (singleDate: Dayjs | string | undefined | null) =>
            singleDate instanceof dayjs
        )
      ) {
        return singleDateValue
      }

      return singleDateValue instanceof dayjs && singleDateValue
    })
    .filter(Boolean as any)
  // Boolean as any: .filter(Boolean) prevents false from being a possible value,
  // but TypeScript infers (Dayjs | false)[] instead of Dayjs[] which is not correct.

  return preparedValues
}

export const prepareDate = (
  rawValue: (Dayjs | undefined) | (Dayjs | undefined)[],
  attribute: ProcessingAttribute
): Record<'value', (string | undefined)[] | (string | undefined)[][]> => {
  if (attribute.config.repeatable) {
    const repeatableDatesAray = prepareRepeatableDate(
      rawValue as Dayjs[],
      attribute
    )
    return repeatableDatesAray
  }

  if (Array.isArray(rawValue)) {
    switch (attribute.config.dateType) {
      case DateType.DATE_RANGE:
      case DateType.DATE_RANGE_TIME:
        return {
          value: rawValue.every(singleDate => Boolean(singleDate))
            ? [
                prepareDateArrayEntry(rawValue[0]),
                prepareDateArrayEntry(rawValue[1]),
              ]
            : [],
        }
      case DateType.DATE_TIME_RANGE:
        return {
          value: rawValue.every(singleDate => singleDate instanceof dayjs)
            ? [
                prepareDateArrayEntry(combineDates(rawValue[0], rawValue[1])),
                prepareDateArrayEntry(combineDates(rawValue[0], rawValue[2])),
              ]
            : [],
        }
    }
  }

  // this works for DateType.DATE and DateType.DATE_TIME as well
  return { value: [prepareDateArrayEntry(rawValue as Dayjs | undefined)] }
}

const prepareRepeatableDate = (
  rawValue: (Dayjs | undefined | Dayjs[])[],
  attribute: ProcessingAttribute
): Record<'value', (string | undefined)[][]> => {
  rawValue = removeInvalidDateArrays(rawValue)

  switch (attribute.config.dateType) {
    case DateType.DATE:
    case DateType.DATE_TIME: {
      TimeUtil.sortDateArray(rawValue as (Dayjs | undefined)[])

      const value = (rawValue as (Dayjs | undefined)[]).map<
        (string | undefined)[]
      >(date => {
        if (date === undefined || date === null) {
          return [undefined]
        }

        return [prepareDateArrayEntry(date)]
      })

      return {
        value,
      }
    }
    case DateType.DATE_RANGE:
    case DateType.DATE_RANGE_TIME: {
      TimeUtil.sortDateArray2D(rawValue as Dayjs[][])

      const value = (rawValue as Dayjs[][]).map(dateArray => {
        if (dateArray === undefined || dateArray === null) {
          return [undefined, undefined]
        }

        return [
          prepareDateArrayEntry(dateArray[0]),
          prepareDateArrayEntry(dateArray[1]),
        ]
      })

      return { value }
    }
    case DateType.DATE_TIME_RANGE: {
      const value = (rawValue as Dayjs[][]).map(date => {
        if (date === undefined || date === null) {
          return [undefined, undefined]
        }

        return [
          prepareDateArrayEntry(combineDates(date[0], date[1])),
          prepareDateArrayEntry(combineDates(date[0], date[2])),
        ]
      })
      TimeUtil.sortDateArray2D(value as (Dayjs | string)[][])

      return { value }
    }
  }

  throw new Error(
    `Unknown dateType ${attribute.config.dateType} on ${JSON.stringify(
      attribute,
      null,
      2
    )}`
  )
}
