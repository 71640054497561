import React from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined } from '@ant-design/icons'
import { Button, Table, TableColumnsType } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import i18n from '../../../i18n/i18n'
import { ServiceModule } from '../../../types/service'
import { ServiceModuleAccesses } from '@organice/graphql'

export interface ServicePermissionsMatrixProps {
  rows: ServicePermissionsMatrixRow[]
  onAccessDelete?(id: ServiceModuleAccesses['id']): void
  onWritableChange?(
    id: ServiceModuleAccesses['id'],
    writable: boolean,
    serviceModuleId: ServiceModule['id']
  ): void
  readOnly?: boolean
}

export interface ServicePermissionsMatrixRow {
  serviceModuleId: ServiceModule['id']
  serviceModuleName: ServiceModule['name']
  serviceModuleAccessId: ServiceModuleAccesses['id']
  writable: ServiceModuleAccesses['writable']
}

export const ServicePermissionsMatrix: React.FC<
  ServicePermissionsMatrixProps
> = ({ rows, onAccessDelete, onWritableChange, readOnly }) => {
  const { t } = useTranslation()

  const columns: TableColumnsType<ServicePermissionsMatrixRow> = [
    {
      title: t('permissions.table.access'),
      dataIndex: 'serviceModuleName',
      key: 'serviceModuleName',
      sorter: (a, b) => {
        const nameA = a.serviceModuleName || ''
        const nameB = b.serviceModuleName || ''

        return nameA.localeCompare(nameB)
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: t('permissions.table.read'),
      dataIndex: 'read',
      key: 'read',
      align: 'center',
      render: () => <Checkbox disabled checked />,
    },
    {
      title: t('permissions.table.write'),
      dataIndex: 'writable',
      key: 'writable',
      align: 'center',
      render: (
        writable: boolean,
        record: { serviceModuleAccessId: bigint; serviceModuleId?: bigint }
      ) => {
        return (
          <Checkbox
            checked={writable}
            disabled={readOnly}
            onChange={e =>
              onWritableChange &&
              onWritableChange(
                record.serviceModuleAccessId,
                e.target.checked,
                record.serviceModuleId
              )
            }
          />
        )
      },
    },
    {
      title: i18n.t('permissions.table.delete'),
      dataIndex: '',
      key: 'action',
      align: 'center',
      render: (
        _: unknown,
        record: { serviceModuleAccessId: bigint; serviceModuleId?: bigint }
      ) => (
        <Button
          icon={<DeleteOutlined />}
          danger
          type="text"
          size="small"
          onClick={() =>
            onAccessDelete && onAccessDelete(record.serviceModuleAccessId)
          }
        ></Button>
      ),
    },
  ]

  const columnsToUse = readOnly ? [columns[0], columns[1], columns[2]] : columns

  const data = rows.map((r, key) => ({ ...r, key }))

  return <Table pagination={false} columns={columnsToUse} dataSource={data} />
}
