import React from 'react'
import {
  useCommentMentionsLazyQuery,
  Events,
  Users,
  Organisations,
} from '@organice/graphql'
import { CommentMentionsResult } from '@organice/utils'
import { useMeContext } from '../me/meContext'
import { LoadingIndicator } from '@organice/atoms'

interface MentionsState extends CommentMentionsResult {
  loading?: boolean
  error?: boolean
}

const DEFAULT_VALUE: MentionsState = {
  users: [],
  organisations: [],
  events: [],
  loading: true,
  error: false,
}

export interface MentionsAction {
  type: 'MENTIONS_LOADED' | 'MENTIONS_ERROR'
  state?: MentionsState
}

const reducer: React.Reducer<MentionsState, MentionsAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'MENTIONS_LOADED':
      return { ...state, ...action.state, loading: false }
    case 'MENTIONS_ERROR':
      return { ...state, error: true }
    default:
      return DEFAULT_VALUE
  }
}

export const MentionsContext = React.createContext<{
  state: MentionsState
  dispatch: React.Dispatch<MentionsAction>
}>({ state: DEFAULT_VALUE, dispatch: () => null })

export const MentionsContextProvider: React.FC<{
  children: React.ReactNode
}> = props => {
  const { state: currentUserState } = useMeContext()
  const [state, dispatch] = React.useReducer(reducer, DEFAULT_VALUE)
  const [fetchCommentMentions, { data, loading, error }] =
    useCommentMentionsLazyQuery()

  React.useEffect(() => {
    if (!currentUserState.me) return
    fetchCommentMentions()
  }, [currentUserState])

  React.useEffect(() => {
    if (!loading && !error && data)
      dispatch({
        type: 'MENTIONS_LOADED',
        state: {
          users: data.allUsers as Users[],
          events: data.events as Events[],
          organisations: data.organisations as Organisations[],
        }, // Neet to fix TS
      })
  }, [loading])

  React.useEffect(() => {
    if (error) dispatch({ type: 'MENTIONS_ERROR' })
  }, [error])

  if (currentUserState.loading) return null

  return (
    <MentionsContext.Provider value={{ state, dispatch }}>
      {props.children}
    </MentionsContext.Provider>
  )
}

export const useMentionsContext = () => React.useContext(MentionsContext)
