import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import { ApolloProvider } from '@apollo/client'
import apolloClient from './apollo/apollo'
import { ThemeProvider } from '@emotion/react'
import { emotionTheme } from './emotionTheme'
import { TimeUtil } from '@organice/utils/date'
import { withErrorBoundary } from '@organice/utils/errorBoundary'
import { ConfigRouter } from '@organice/router'
import { LoadingIndicator } from '@organice/atoms/loadingIndicator'
import { HeaderProvider } from '@organice/organisms/header'
import { createRoot } from 'react-dom/client'

import 'antd/dist/antd.less'
import './i18n/i18n'
import locale_de from 'dayjs/locale/de'
import deDE from 'antd/lib/locale/de_DE'
import {
  MentionsContextProvider,
  MeContextProvider,
  DeepLinkContextProvider,
} from './contexts'
import { BreadcrumbProvider } from './router/useBreadcrumbs'
import { BranchInfo } from '@organice/organisms/branch-info/branch-info'

/* eslint-disable */
// Conditional mocking
// if (import.meta.env.VITE_NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser')
//   worker.start()
// }
/* eslint-enable */

const SHOW_BRANCH_INFO = import.meta.env.VITE_SHOW_BRANCH_INFO

export const App = withErrorBoundary(() => {
  TimeUtil.setLocale(locale_de)

  return (
    <React.Suspense fallback={<LoadingIndicator />}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={emotionTheme}>
          <ConfigProvider locale={deDE}>
            {SHOW_BRANCH_INFO === '1' ? <BranchInfo /> : null}
            <MeContextProvider>
              <MentionsContextProvider>
                <DeepLinkContextProvider>
                  <BreadcrumbProvider>
                    <HeaderProvider>
                      <ConfigRouter breforeSwitches={null} />
                    </HeaderProvider>
                  </BreadcrumbProvider>
                </DeepLinkContextProvider>
              </MentionsContextProvider>
            </MeContextProvider>
          </ConfigProvider>
        </ThemeProvider>
      </ApolloProvider>
    </React.Suspense>
  )
})
