import { TimeUtil } from '../../date'
import { GeneralDict } from '../conversions'
import {
  AttributeTypes_Enum,
  EventAttributeValues,
  EventAttributeValues_Insert_Input,
} from '@organice/graphql'
import { prepareDate } from '../../service/prepareDate'
import { DateType } from '@organice/contexts/configService'

const dayjs = TimeUtil.getDayjs()

type EventAttributeDefinition =
  | {
      id: number
      attributeType: AttributeTypes_Enum
      config: any
    }
  | null
  | undefined

function convertEventAttributeValuesToFormValues(
  eventAttributeValues: EventAttributeValues[] | undefined,
  values: GeneralDict
) {
  const eventAttributesFormValues: { [key: number]: any } = {}
  const eventAttributeDefinitions: { [key: number]: any } =
    values.eventAttributeDefinitions as any

  if (!eventAttributeValues) return eventAttributesFormValues

  if (!eventAttributeDefinitions) {
    console.warn(
      `convertEventAttributeValuesToFormValues: No eventAttributeDefinitions found.`
    )
    return eventAttributesFormValues
  }

  eventAttributeValues.forEach(av => {
    const attrId = av.eventAttribute?.id
    const attrDefinition = eventAttributeDefinitions[parseInt(attrId)]
    const isRepeatable = attrDefinition.config?.repeatable || false
    const config = attrDefinition.config || {}

    if (!attrDefinition) {
      console.warn(
        `convertEventAttributeValuesToFormValues: No attrDefinition found for ${JSON.stringify(
          av
        )}.`
      )
      return
    }

    const attrValue = av.value

    switch (attrDefinition?.attributeType) {
      case AttributeTypes_Enum.Text:
        eventAttributesFormValues[attrDefinition.id] =
          attrValue.value?.toString() || ''
        break
      case AttributeTypes_Enum.Number:
        eventAttributesFormValues[attrDefinition.id] = Number(attrValue.value)
        break
      case AttributeTypes_Enum.Boolean:
        eventAttributesFormValues[attrDefinition.id] = !!attrValue.value
        break
      case AttributeTypes_Enum.Date:
        if (!attrDefinition.config.dateType) {
          console.warn(
            `convertEventAttributeValuesToFormValues: No dateType in attrDefinition found for ${JSON.stringify(
              av
            )}`
          )
          return
        }

        if (
          (Array.isArray(attrValue.value) && !attrValue.value[0]) ||
          !attrValue.value
        ) {
          return []
        }

        if (
          config.dateType === DateType.DATE ||
          config.dateType === DateType.DATE_TIME
        ) {
          let val
          // isRepeatable plus TypeCheck, in case the attribute config has changed,
          // but the values are still processed with old config (switching from repeatable to not repeatable)
          // Frontend Output in Display Mode:  Invalid Date
          if (!isRepeatable && typeof attrValue.value[0] === 'string') {
            val = dayjs(attrValue.value[0])
          }

          if (isRepeatable && Array.isArray(typeof attrValue.value[0])) {
            val = attrValue.value[0].map((v: string) => dayjs(v))
          }
          return val
        }

        if (config.dateType === DateType.DATE_TIME_RANGE) {
          const val: string[] = attrValue.value
          return [val.map(v => dayjs(v)), dayjs(val[1]) || undefined]
        }

        eventAttributesFormValues[attrDefinition.id] = Array.isArray(
          attrValue.value
        )
          ? attrValue.value.map((val: string | string[]) => {
              if (!isRepeatable && typeof val === 'string') {
                return dayjs(val)
              }

              if (!isRepeatable || val.length === 1) return dayjs(val[0])

              if (isRepeatable && Array.isArray(val)) {
                return val.map((v: string) => (v ? dayjs(v) : undefined))
              }
            })
          : [undefined]
        break
      case AttributeTypes_Enum.File:
        eventAttributesFormValues[attrDefinition.id] = attrValue.value
        break
    }
  })

  return eventAttributesFormValues
}

function convertFormValuesToEventAttributeValues(
  input: any,
  values: GeneralDict
) {
  const eventAttributeValues: EventAttributeValues[] =
    values.eventAttributeDefinitions as EventAttributeValues[]
  const eventAttributes: EventAttributeValues_Insert_Input[] = []

  if (!eventAttributeValues) {
    console.warn(
      `convertFormValuesToEventAttributeValues: eventAttributeValues are missing in input for FormUtil.convertValues().`
    )
    return eventAttributes
  }

  if (!input) {
    return eventAttributes
  }

  Object.keys(input).forEach((attrId: any) => {
    const formValue = input[attrId]
    const eventAttributeDefinitions: { [key: number]: any } =
      values.eventAttributeDefinitions as any

    const attrDefinition = eventAttributeDefinitions[parseInt(attrId)]

    if (!attrDefinition) {
      console.warn(
        `convertFormValuesToEventAttributeValues: Could not find eventAttribute with id ${attrId}.`
      )
      return
    }

    switch (attrDefinition?.attributeType) {
      case AttributeTypes_Enum.Text:
        eventAttributes.push({
          eventAttributeId: attrDefinition.id,
          value: {
            value: formValue?.toString(),
          },
        })

        break
      case AttributeTypes_Enum.Number:
        eventAttributes.push({
          eventAttributeId: attrDefinition.id,
          value: {
            value: Number(formValue),
          },
        })
        break
      case AttributeTypes_Enum.Boolean:
        eventAttributes.push({
          eventAttributeId: attrDefinition.id,
          value: {
            value: !!formValue,
          },
        })
        break
      case AttributeTypes_Enum.Select:
        eventAttributes.push({
          eventAttributeId: attrDefinition.id,
          value: {
            value: formValue,
          },
        })
        break
      case AttributeTypes_Enum.Date:
        if (!attrDefinition.config.dateType) {
          console.warn(
            `convertEventAttributeValuesToFormValues: No dateType in attrDefinition found for ${JSON.stringify(
              formValue
            )}`
          )
          return
        }

        if (!formValue) {
          eventAttributes.push({
            eventAttributeId: attrDefinition.id,
            value: {
              value: [],
            },
          })
          return
        }

        if (attrDefinition.config.dateType === DateType.DATE) {
          eventAttributes.push({
            eventAttributeId: attrDefinition.id,
            value: {
              value: prepareDate(formValue, attrDefinition).value || [],
            },
          })
          return
        }

        eventAttributes.push({
          eventAttributeId: attrDefinition.id,
          value: {
            value: prepareDate(formValue, attrDefinition).value,

            // value: formValue.some((val: any) => val === undefined)
            //   ? []
            //   : prepareDate(formValue, attrDefinition).value, //formValue.map((date: Dayjs) => dayjs(date).utc().format()),
          },
        })
        break
      case AttributeTypes_Enum.File:
        eventAttributes.push({
          eventAttributeId: attrDefinition.id,
          value: {
            value: formValue,
          },
        })
        break
    }
  })

  return eventAttributes
}

export const convertEventAttributes = (input: any, values: GeneralDict) => {
  if (input !== undefined) {
    return convertFormValuesToEventAttributeValues(input, values)
  }

  if (!values.eventAttributeValues) return
  const eventAttributeValues =
    values.eventAttributeValues as EventAttributeValues[]

  return convertEventAttributeValuesToFormValues(eventAttributeValues, values)
}
