import React from 'react'
import { Service } from '../servicesListEntryPopover'
import { ListItem } from '../listItem'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { useHistory } from 'react-router-dom'
import { Tooltip } from '@organice/atoms'
import { StarOutlined, StarFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useParams } from '@organice/router'
import { useSidebarContext } from '../sidebar'
import { HasChangesMarker } from '@organice/atoms'

export type ServiceListEntryData = Pick<
  Service,
  | 'id'
  | 'name'
  | 'serviceModules'
  | 'eventId'
  | 'serviceModules_aggregate'
  | 'userData'
>

export interface ServicesListEntryProps {
  data: ServiceListEntryData
  getLink?(id: string): string
  favourite?: boolean
  onFavouriteChange?(id: ServiceListEntryData['id'], fav: boolean): void
  showFavourites?: boolean
}

const TOOLTIP_APPEARANCE_DELAY = 0.8

export const ServicesListEntry: React.FC<ServicesListEntryProps> = ({
  data,
  getLink = (id: string) => `/service/${id}`,
  onFavouriteChange,
  favourite,
  showFavourites,
}) => {
  const history = useHistory()
  const nameLength = data.name.length
  const toolTipFrom = 30
  const addToolTip = nameLength > toolTipFrom
  const theme = useTheme()
  const { t } = useTranslation()
  const { serviceId } = useParams<{ serviceId: string }>()
  const handleStarClick = (fav: boolean) => {
    onFavouriteChange && onFavouriteChange(data.id, fav)
  }

  const userData = data?.userData?.[0]?.data

  const { setSidebarState } = useSidebarContext()

  const handleCItemClick = (id: bigint) => {
    history.push(getLink(String(id)))

    setSidebarState && setSidebarState({ isMobileSidebarOpen: false })
  }

  return (
    <BorderlessListItem
      className={data.id === parseInt(serviceId) ? 'active' : ''}
      style={
        typeof userData?.color === 'number'
          ? { backgroundColor: `${theme.markerColors[userData?.color]}` }
          : undefined
      }
    >
      <HasChangesMarker
        hasChanges={
          data.serviceModules_aggregate.aggregate?.count
            ? data.serviceModules_aggregate.aggregate?.count > 0
            : false
        }
        placement="leftOutside"
      />
      {addToolTip ? (
        <Tooltip placement="top" title={data.name} mouseLeaveDelay={0}>
          <ListItemText onClick={() => handleCItemClick(data.id)}>
            {data.name}{' '}
          </ListItemText>
        </Tooltip>
      ) : (
        <ListItemText onClick={() => handleCItemClick(data.id)}>
          {data.name}
        </ListItemText>
      )}
      {showFavourites && (
        <AdditionalContentContainer>
          {!favourite && (
            <Tooltip
              placement="top"
              title={t('messages.serviceModules.tooltips.isNotFavourite')}
              mouseEnterDelay={TOOLTIP_APPEARANCE_DELAY}
            >
              <StarOutlined onClick={() => handleStarClick(true)} />
            </Tooltip>
          )}
          {favourite && (
            <Tooltip
              placement="top"
              title={t('messages.serviceModules.tooltips.isFavourite')}
              mouseEnterDelay={TOOLTIP_APPEARANCE_DELAY}
            >
              <StarFilled onClick={() => handleStarClick(false)} />{' '}
            </Tooltip>
          )}
        </AdditionalContentContainer>
      )}
    </BorderlessListItem>
  )
} /*,
  (prevProps, nextProps) =>
    prevProps.data.id === nextProps.data.id &&
    prevProps.data.name === nextProps.data.name &&
    prevProps.favourite === nextProps.favourite
)*/

const AdditionalContentContainer = styled.div(({ theme }) => ({
  '.anticon': {
    color: theme.grey4,
    '&:hover': {
      color: theme.primaryColor,
    },
  },
}))

const BorderlessListItem = styled(ListItem)(({ theme }) => ({
  '&&&': {
    border: 0,
    cursor: 'pointer',
    lineHeight: '1.467',
    padding: '0.3125rem 0.625rem',
    '&:hover': {
      backgroundColor: theme.lightBackground,
      borderRadius: theme.borderRadiusBase,
    },
    '&.active': {
      backgroundColor: theme.lightBackground,
    },
  },
}))

const ListItemText = styled.span({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  paddingRight: '0.625rem',
  fontSize: '15px',
  fontWeight: 500,
  flex: 1,
})
