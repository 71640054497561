import { diff } from 'deep-diff'
import { FormInstance } from 'antd'
import {
  convertOrganizerModel as convertOrganizer,
  convertRubinStammModel as convertRubinStamm,
  convertDate,
  convertToArrayOfIds,
  convertTimstampToBoolean,
  convertEventAttributes,
} from './conversion-functions'
import { eventTypesSelect } from './event-types'

export type GeneralDict<T = unknown> = {
  [key: string]: T
}

const conversionMethods = {
  convertDate,
  convertOrganizer,
  convertRubinStamm,
  convertToArrayOfIds,
  convertTimstampToBoolean,
  convertEventAttributes,
}

type CustomConversionFunction = (input: any, values: GeneralDict) => any
type FuncType = typeof conversionMethods
type ConversionFunction = CustomConversionFunction | FuncType[keyof FuncType]

interface ConversionConfig {
  fieldConversionSettings: {
    [key: string]: ConversionFunction
  }
  //propertySelection?: string[] // if you only need a few props > e.g. ['title', 'subTitle', 'startDate']
  propertySelection?: string[]
}

const convertValues = (values: any, config: ConversionConfig) => {
  if (!values) return {}

  const { fieldConversionSettings, propertySelection } = config

  const returnValues = { ...values }

  Object.keys(fieldConversionSettings).forEach(objKey => {
    const originalValue = returnValues[objKey]
    const conversionMethod = fieldConversionSettings[objKey]
    returnValues[objKey] = conversionMethod(originalValue, returnValues)
  })

  //return returnValues
  return propertySelection
    ? pickFromObject(returnValues, propertySelection)
    : returnValues
}

function pickFromObject(
  object: GeneralDict,
  properties: string[]
): GeneralDict {
  const returnObject: GeneralDict = {}
  properties.forEach(key => {
    if (key in object) {
      returnObject[key] = object[key]
    }
  })
  return returnObject
}

const valuesChanged = (
  initial: GeneralDict[],
  form: FormInstance,
  exclude: string[] = []
): string[] | boolean => {
  const differences = diff(initial, form.getFieldsValue())
  if (!differences) {
    return false
  }

  const newFormValues = form.getFieldsValue()

  const found: string[] = []
  differences.forEach(difference => {
    if (difference.kind === 'N') {
      // check if new value is maybe just an empty form field (and ignore if that is the case)
      const newValue = difference.path?.reduce((newValue, key) => {
        return newValue ? newValue[key] : newFormValues[key]
      }, null)

      if (newValue && isEmptyValue(newValue)) return
    }
    const p = difference?.path?.[0]
    if (p && !exclude.includes(p)) found.push(p)
  })
  return found.length < 1 ? false : Array.from(new Set(found))
}

const isEmptyValue = (value: any) => {
  if (typeof value !== 'boolean' && !value) return true
  if (Array.isArray(value) && value.length === 0) return true
  if (
    Array.isArray(value) &&
    value.every(v => Array.isArray(v) && v.length === 0)
  )
    return true
  return false
}

const isValid = (form: FormInstance): boolean =>
  !form.getFieldsError().some(item => item.errors.length > 0)

export const FormUtil = {
  convertValues,
  conversionMethods,
  valuesChanged,
  isValid,
  eventTypesSelect,
}
