import React from 'react'
import { PickerProps } from 'antd/es/date-picker/generatePicker'
import { DatePicker } from './datepicker'
import { Dayjs } from 'dayjs'
import { Global, css, useTheme } from '@emotion/react'

export type DatepickerAutoconfirmProps<T = PickerProps<Dayjs>> = T & {
  autoConfirm?: boolean
  showTime?: boolean
  format?: string
  inputFormat?: string
  displayFormat?: string
  parentRange?: [Dayjs, Dayjs]
  minuteStep?: number
}

export const DatePickerAutoConfirm = React.forwardRef<
  any,
  DatepickerAutoconfirmProps
>((props, ref) => {
  const {
    parentRange,
    inputFormat,
    displayFormat,
    format,
    minuteStep = 15,
  } = props

  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const parentStartDate = parentRange && parentRange[0]
  const parentEndDate = parentRange && parentRange[1]
  const theme = useTheme()

  function renderDateCell(currentDate: Dayjs) {
    let inParentRange = false
    if (parentStartDate && parentEndDate) {
      if (
        currentDate.isSameOrAfter(parentStartDate, 'day') &&
        currentDate.isSameOrBefore(parentEndDate, 'day')
      ) {
        inParentRange = true
      }
    }

    return (
      <div
        className={`ant-picker-cell-inner ${
          inParentRange ? 'ant-picker-cell-inner--in-parent-range' : ''
        }`}
      >
        {currentDate.format('D')}
      </div>
    )
  }

  return (
    <>
      <Global
        styles={css`
          .ant-picker-cell-in-view.ant-picker-cell-selected
            .ant-picker-cell-inner,
          .ant-picker-cell-in-view.ant-picker-cell-range-start
            .ant-picker-cell-inner,
          .ant-picker-cell-in-view.ant-picker-cell-range-end
            .ant-picker-cell-inner {
            background-color: transparent;
            &:after {
              content: '';
              width: 120%;
              height: 120%;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: ${theme.primaryColor};
              z-index: -1;
            }
          }

          .ant-picker-cell-inner {
            &--start-date,
            &--end-date {
              //   background-color: ${theme.primaryColor};
              color: #fff;
              position: relative;
              z-index: 1;

              &:after {
                content: '';
                width: 120%;
                height: 120%;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: ${theme.primaryColor};
                z-index: -1;
              }
            }
            &--date-in-range {
              //   background-color: ${theme.primaryColor}50;
              &:after {
                content: '';
                width: 120%;
                height: 120%;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: ${theme.primaryColor}50;
                z-index: -1;
              }
            }

            &--in-parent-range:after {
              content: '';
              width: 120%;
              height: 120%;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              border: 1px dashed ${theme.grey1}30;
            }
          }
        `}
      />

      <DatePicker
        minuteStep={minuteStep}
        onOpenChange={status => setIsOpen(status)}
        ref={ref}
        onSelect={
          props.showTime && props.autoConfirm
            ? (v: Dayjs) => {
                props.onChange &&
                  props.onChange(
                    v,
                    props.format ? v.format(String(props.format)) : v.format()
                  )
              }
            : undefined
        }
        format={isOpen ? inputFormat || format : displayFormat || format}
        dateRender={renderDateCell}
        {...props}
      />
    </>
  )
})
